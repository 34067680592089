import React from "react";
import "./MessagerieAdmin.css";
import Sommaire from "./Sommaire";
import DetailsMessagerieAdmin from "./DetailsMessagerieAdmin";

function MessagerieAdmin() {
  return (
    <div className="content-position-after-header px-0 h-90vh position-relative page-content-base-container">
      <div className="admin-page-container-layout admin-page-container-height custom-admin-page-container-height">
        <div className="dashbord-admin-component">
          <div className="dash-admin-page-content-container mb-3">
            <div className="landing-page-container">
              <div className="admin-page-title-header-container">
                <div className="admin-tabs-container">
                  <div className="tabs-component-container-admin">
                    <div className="users-network-connect-list mt-4">
                      <div className="no-view-mobile">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                              <div>
                                <p className="titre-messages-bloc ps-3 pb-3">
                                  Message
                                </p>
                              </div>
                              <div className="bloc-search-message mb-3 position-relative">
                                <form>
                                  <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Rechercher"
                                  />
                                  <button type="submit" value="search">
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </form>
                              </div>

                              <ul className="list-group list-group-message list-group-flush">
                                <Sommaire />
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <DetailsMessagerieAdmin />
                          </div>
                        </div>
                      </div>
                      <div className="no-view-desktop">
                        <div className="bloc-content-message-mobile">
                          <div className="bloc-search-message mb-3 position-relative">
                            <form>
                              <input
                                className="form-control"
                                type="search"
                                placeholder="Rechercher"
                              />
                              <button type="submit" value="search">
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </form>
                          </div>
                          <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                            <div>
                              <p className="titre-messages-bloc ps-3 pb-3">
                                Vos discussions
                              </p>
                            </div>
                            <ul className="list-group list-group-message list-group-flush">
                              <Sommaire />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="NewDiscussionModale"
                  aria-labelledby="NewDiscussionModaleLabel"
                  aria-hidden="true"
                >
                  {/* <NewDiscussionModale onSelect={setSelectedUser} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagerieAdmin;
