import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Order, OrderFormData } from "./order.type";
import { ProductApi } from "../product/product.api";

export const OrderApi = createApi({
	reducerPath: "orderApi",
	tagTypes: [
		"order",
		"orderList",
		"orderByVisitor",
		"orderById",
		"allOrderList",
		"orderByParticulier",
		"orderByUser",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getListOrderAdmin: builder.query<
			PaginationResults<Order>,
			TypeQuery
		>({
			providesTags: ["orderList"],
			query: (query) => QueryUrl("orders/", query),
		}),
		getOrderList: builder.query<PaginationResults<Order>, TypeQuery>({
			providesTags: ["order"],
			query: (query) => QueryUrl("orders/me/", query),
		}),
		getListOrder: builder.query<any | Order[], void>({
			providesTags: ["orderList"],
			query: () => {
				return "orders/";
			},
		}),
		addOrEditOrder: builder.mutation<Order,{ slug?: string; data: OrderFormData | FormData }>({
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(
					ProductApi.util.invalidateTags(["cartByUser"])
				);
			},
			invalidatesTags: [
				"orderList",
				"orderByVisitor",
				"order",
				"orderByUser",
			],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `orders/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `orders/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteOrder: builder.mutation<OrderFormData, string>({
			query: (id) => ({
				url: `orders/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["orderList", "orderByVisitor", "order"],
		}),
		OrderBySlug: builder.query<Order, string>({
			query: (slug) => `orders/${slug}/`,
			providesTags: ["orderById"],
		}),
		OrderByParticulier: builder.query<Order | any, TypeQuery>({
			query: ({ slug, ...query }) =>
				QueryUrl(`user/${slug}/orders/`, query),
			providesTags: ["orderByParticulier"],
		}),
		OrderByTransactionID: builder.query<Order | any, string>({
			query: (id) => `orders/order-by-transaction/${id}/`,
			providesTags: ["orderById"],
		}),
		getCanceledOrderByUser: builder.query<Order | any, TypeQuery>({
			providesTags: ["order"],
			query: ({ slug, ...query }) =>
				QueryUrl(`user/${slug}/canceled_orders/`, query),
		}),
		getOrderByUser: builder.query<Order | any, TypeQuery>({
			providesTags: ["orderByUser"],
			query: ({ slug, ...query }) =>
				QueryUrl(`user/${slug}/orders/`, query),
		}),
	}),
});

export const {
	useAddOrEditOrderMutation,
	useGetListOrderAdminQuery,
	useGetListOrderQuery,
	useLazyOrderBySlugQuery,
	useLazyOrderByParticulierQuery,
	useGetOrderListQuery,
	useLazyOrderByTransactionIDQuery,
	useOrderByParticulierQuery,
	useDeleteOrderMutation,
	useGetCanceledOrderByUserQuery,
	useGetOrderByUserQuery,
} = OrderApi;

export function useOrderFromLocation(): [
	Order,
	boolean,
	string,
	(slug: string) => any
] {
	const itemState = useLocationState<Order | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] =
		useLazyOrderBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			console.log("data", data);
			setItem(data);
		}
	}, [data]);

	return [item as Order, isLoading, slug as string, findBySlug];
}
