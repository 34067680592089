import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const locale: any = window.sessionStorage.getItem('locale');

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    debug: true,
    lng: (locale !== undefined) ? locale : 'fr',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          nav_home: "HOME",
          nav_events: "EVENTS",
          nav_resources: "RESOURCES",
          sub_nav_webinar: "WEBINARS",
          sub_nav_colloque: "CONFERENCES",
          sub_nav_econference: "E-CONFERENCES",
          nav_multimedias: "MULTIMEDIAS",
          sub_nav_photos: "PHOTOS",
          sub_nav_livestreams: "LIVESTREAMS",
          sub_nav_videos: "VIDEOS",
          nav_blog: "BLOG",
          nav_news: "NEWS",
          content: "Contents",
          associations: "Associations",
          auto_evaluation: "Self Evaluation",
          recommandations: "Recommandations",
          support: "Support",
          news_uppercase: "NEWS",
          news_lowercase: "News",
          read_more: "Read more",
          see_all_news: "Show all news",
          empty_alert_text: "NO DATA AVAILABLE AT THE MOMENT",
          by: "By",
          latest_blog_posts: "Latest blog posts",
          all_our_posts: "All our posts",
          latest_news_posts: "Latest News",
          all_our_news: "All our news",
          filter_by: "Filter by",
          french: "French",
          english: "English",
          language: "Language",
          year: "Year",
          country: "Country",
          share: "Share",
          read_also: "Read also",
          online_courses: "Online Courses",
          publications: "Publications",
          all_resources: "All Resources",
          resource_details: "Resource details",
          write_us: "Write Us",
          fullname: "Fullname",
          new_message: "New message",
          inprogress: "In progress ...",
          partners: "Partners",
          they_support_us: "THEY SUPPORT US",
          diary: "Diary",
          events_to_come: "EVENTS TO COME",
          why_faeufao: "WHY FAUEFAO ?",
          learn_more: "LEARN MORE",
          newsletter_subscriber_title: "SUBSCRIBE",
          newsletter_archives: "Newsletter archives",
          privacy_policy: "Privacy Policy",
          legal_notice: "Legal Notice",
          add_your_email_address: "Add your email address",
          incoming_events: "Incoming Events",
          past_events: "Past Events",
          type_of_events: "Type of events",
          domains: "Domains",
          communities: "<p className='subtitle-theme-app mb-2'>COMMUNITIES</p>",
          pratical_communities: "<h2 className='title-home-page-banner'>OUR COMMUNITIES <br /> of practices</h2>",
          i_join_communities: "I join the communities",
          event_details: "Event Details",
          contacter: "Contact YFS",
          pages: "Pages",
          theme: "Theme",
          date: "Date",
          place: "Place",
          upcoming_conferences: "Upcoming conferences",
          past_conferences: "Past conferences",
          see_more_conferences: "See all conferences",
          download_agenda: "Download the detailed agenda",
          speakers: "Speakers",
          fauefao_conferences: "FAUEFAO conferences",
          subscribe: "Subscribe",
          find_more_conferences: "Find out more about upcoming conferences",
          hours: "Hours",
          empty_download_agenda: "not available",
          i_am_registering: "I'm registering",
          register_for_event: "Register for the event",
          pays: "Country",
          send_date: "Sending date",
          close: "Close",
          fauefao_webinar: "FAUEFAO webinar",
          find_more_webinars: "Find out more about upcoming webinars",
          see_all_webinars: "Find all webinar",
          register_for_webinars: "Register for webinar",
          fauefao_econference: "FAUEFAO e-conference",
          find_more_econferences: "Find out more about upcoming e-conferences",
          see_all_econference: "Find all e-conferences",
          register_for_econference: "Register for this e-conference",
          communities_home: "Communities Home",
          joined_since: "joined since",
          see_website: "See the website",
          biography: "Biography",
          additional_infos: "Additional Information",
          website: "Website",
          categories: "Category",
          sort_by: "Sort by",
          members: "Members",
          communities_wt: "Communities",
          results: "Result(s)",
          filter: "Filter",
          discussions: "Discussions",
          documents: "Documents",
          personal_infos: "Personal informations",
          prof_infos: "Professional information",
          next: "Next",
          previous: "Previous",
          nav_members: "Members",
          nav_institutions: "Institutions",
          username: "Username",
          title: "Title",
          hold_position: "What position do you hold ?",
          organisation_category: "Category of your organization",
          description: "Description",
          short_description_placeholder: "Add a short description to help others get to know you better",
          linkedin_profile: "Your LinkedIn Profile URL",
          x_profile: "Your X Profile URL",
          facebook_profile: "Your Facebook Profile URL",
          email_or_username: "Identifier (Email or Username)",
          communities_practice: "COMMUNITIES OF PRACTICE",
          explore_learn_grow: "Explore, Learn, Grow",
          join_our_communities_practice: "Join our communities of practice",
          see_all_communities: "See all communities",
          communaute_joined_requirement_alert: "You must join the community before you can post a message.",
          success: "SUCCESS",
          success_joined_message: "Well done! You now have write access in this community.",
          member_since: "member since",
          resources: "Resources",
          events: "Events",
          publish: "Publish",
          post_title: "Post Title",
          add_link: "Add an URL",
          add_text_contribution: "Add your text contribution",
          add_illustrative_image: "Add an illustrative image",
          attach_pdf_file: "Attach a PDF file",
          filename: "Filename",
          join: "JOIN",
          partners_list: "List of partners",
          admin_list_label: "List of administrators",
          resource_type: "Resource types",
          link: "Link",
          photo: "Photo",
          search_admin: "Search an Admin",
          change_my_password: "Change my password",
          logout: "Logout",
          change_profile_avatar: "Change my profile picture",
          update: "Update",
          directories: "Directories",
          send_a_message: "Send a message",
          position_held: "Position held",
          banned_message: "You have been banned from this community.",
          participant_details: "Participant details",
          required: "* Required",
          faq_text: "Frequently Asked Questions",
          overview: "Overview",
          number_test: "Number of tests",
          avg_score: "Average score",
          total_minutes_spent: "Total time spent",
          ranking: "Ranking",
          news: "News",
          score_evolution_theme: "Score evolution by theme",
          evaluation: "Assessments",
          start: "Start",
          completed: "Completed",
          uncompleted: "Unfinished",
          nb_questions: "Number of questions",
          see_details: "View details",
          see_score: "View score",
          continue: "Resume",
          last_score: "Latest scores",
          save_finish_later: "Save and finish later",
          finish: "Finish",
          organization_name: "Organization name",
          organization_description: "Organization description",
          representant: "Representative",
          policy_recommandations: "Policy Recommandations",
          profile_infos: "Profile details",
          general_infos: "General informations",
          text_profile: "Names, organization name, logo, contact informations",
          details_evaluation: "Assessment details",
          assessment: "Assessment",
          assessment_name: "Assessment name",
          of_answered_questions: "of answered questions",
          out_of_4: "out of 4",
          evaluate_your_impact: "YOUTH FRIENDLY STANDARDS SELF ASSESSMENT TOOL Empowering Youth. Building Tomorrow.",
          join_movement: "Join the Movement for Meaningful Youth Engagement Today.",
          the_platform_guide: " Over 50% of the world's population is under 30. Harness this incredible potential with Youth-Friendly Standards assessment tool a premier global platform that equips organizations with a comprehensive systems-based approach to empower and engage young people.",
          start_self_assessment: "Start my assessment",
          tool_overview: "Youth Friendly Standards Tool Overview",
          tool_guide: "The guided self-assessment aims to facilitate discussions and introspection regarding existing strengths and ways to enhance processes that foster positive transformation in Youth Empowerment and Inclusion within your projects and organization.It also allows for gauging progress toward transformative practices and outcomes for your project and organization. Participants are empowered to identify strategies for strengthening youth equality, diversity, and social inclusion, explore avenues for effective change, and pinpoint areas for improvement in their work.To effectively utilize the Self- Assessment Tool for the Youth Friendly Standards a systematic approach can be adopted. The process involves utilizing mainly five domains to evaluate progress and ensure comprehensive implementation across the continuum.",
          systems_strengthening: "Systems Strengthening: Enhancing national and subnational systems within the organization to promote youth-friendly practices and policies, with a particular focus on promoting inclusivity, diversity, and youth participation.",
          leave_no_one_behind: "Leave No One Behind: Promoting equitable and universal access to youth-friendly services, programs, and opportunities, specifically targeting marginalized and underrepresented youth populations.",
          transformative_change: "Leave No One Behind: Promoting equitable and universal access to youth-friendly services, programs, and opportunities, specifically targeting marginalized and underrepresented youth populations.",
          knowledge_and_learning: "Knowledge and Learning: Promoting the adoption of new knowledge, innovative approaches, and best practices in youth development and engagement by the organization and other stakeholders, including civil society organizations, governmental bodies, and international partners.",
          organizational_culture_and_practice: "Organizational Culture and Practice: Fostering a youth-friendly organizational culture that embraces diversity, inclusivity, and meaningful youth engagement. This involves aligning policies, processes, and systems within the organization to ensure that young people's voices and perspectives are valued and their needs are met effectively.",
          self_assessment_enables: "This self-assessment enables organizations to identify strengths, weaknesses, and areas for improvement, ultimately creating more youth-friendly environments and services.",
          instructions: "Self-Assessment Instructions",
          proceed_self_assessement: "To proceed with the self-assessment, please take into consideration the following:",
          allocate_sufficient_time: "Allocate Sufficient Time:",
          set_aside_dedicated: "Set aside dedicated time to complete the self-assessment questionnaire thoroughly.",
          avoid_rushing_through: "Avoid rushing through the process to ensure accurate and meaningful responses.",
          consider_involving_other: "Consider involving other team members or experts to gather diverse perspectives and insights.",
          ensure_consistency: "Ensure Consistency:",
          maintain_consistency: "Maintain consistency in your responses throughout the questionnaire.",
          cross_reference: "Cross-reference related questions to ensure your answers align logically and consistently.",
          follow_up_plan: "Follow-Up and Action Planning:",
          once_assessment_completed: "Once the self-assessment is complete, review the results and identify areas for improvement or further action.",
          collaborate_with_relevant: "Collaborate with relevant stakeholders to develop an action plan based on the assessment findings.",
          monitor_process: "Monitor progress, implement necessary changes, and regularly revisit the self-assessment process to track improvements over time.",
          familiarize_yourself: "Familiarize Yourself with the Youth Friendly Standards Framework:",
          ensure_clear_understanding: "Ensure you have a clear understanding of the principles, objectives, and components of the Youth Friendly Standards.",
          review_available_documentation: "Review any available documentation or resources related to the Youth Friendly Standards and its standards.",
          familiarize_purpose: "Familiarize yourself with the self-assessment purpose.",
          collect_informations: "Collect Relevant Information:",
          gather_necessary_information: "Gather all necessary information and documentation required to answer the questionnaire accurately.",
          consult_stakeholders: "Consult with relevant stakeholders or departments within your organization to obtain the required data.",
          ensure_up_to_date: "Ensure the information collected is up to date and reflects the current state of your organization's practices.",
          answer_questions: "Answer Questions Carefully:",
          read_each_question: "Read each question carefully and understand its intent before providing a response.",
          consider_context: "Consider the specific context of your organization and its practices when answering the questions.",
          provide_honest_response: "Provide honest and accurate responses based on your organization's actual practices.",
          review_and_validate: "Review and Validate responses:",
          take_time_to_review: "Take the time to review your answers and validate their accuracy before finalizing the self-assessment.",
          double_check_calculation: "Double-check calculations or scoring if applicable to ensure accuracy.",
          valuable_opportunity: "Remember, the self-assessment process is a valuable opportunity to evaluate your organization's compliance with YFS and identify areas for growth. Approach it with diligence and a commitment to continuous improvement.",
          yfs_implementation: "Youth Friendly Standards Implementation",
          effectively_implement: "To effectively implement the Youth Friendly Standards within an organization and project, a step-by-step process can be followed. The process includes diagnosing current practices, performing a comparative analysis with the Youth Friendly Standards, choosing priority standards to focus on, training the team to understand the value of the standard, and finally, developing a transformation strategy and framework in collaboration with managers. The outline below provides an overview of the main elements on which the Youth Friendly Standards is based:",
          current_diagnostic: "Diagnostic on Current Practices:",
          conduct_assessment: "Conduct an assessment of the organization's current practices regarding youth inclusion and engagement. This involves evaluating existing policies, programs, services, human resources practices, business relationships, community development initiatives, and institutional partnerships. Identify strengths, weaknesses, gaps, and areas for improvement in relation to youth-friendly practices.",
          comparative_analysis: "Comparative Analysis with Youth Friendly Standards:",
          compare_current_practice: "Compare the organization's current practices against the standards outlined in the Youth Friendly Standards framework. Assess the extent to which the organization aligns with the YFS across the six thematic axes: governance, programs/products/services, human resources, business relationships, community development, and institutional partnerships. Identify gaps and areas where the organization falls short of the desired standards.",
          train_the_team: "Train the Team to Understand Value and Adopt the Standards :",
          provide_training: "Provide training and capacity-building initiatives to the organization's team members, including staff, managers, and stakeholders. This training should focus on raising awareness about the value and benefits of youth-friendly practices, as well as providing guidance on how to adopt and implement the identified priority standards. Foster a shared understanding and commitment to youth inclusion throughout the organization.",
          choose_standards: "Choose Priority Standards to Reach:",
          based_on_comparative: "Based on the comparative analysis, prioritize the standards that require immediate attention and improvement. Select the areas where the organization's practices are furthest from the Youth Friendly Standards benchmarks or where the greatest impact can be achieved through targeted interventions. These priority standards will guide the transformation efforts.",
          develop_a_strategy: "Develop a Transformation Strategy and Framework for the leadership of the organization:",
          implemente_change: "Collaborate with leading team members and key stakeholders to develop a comprehensive transformation strategy and framework. This strategy should outline the specific actions, timelines, responsibilities, and resources required to bridge the gaps identified in the comparative analysis and achieve the desired youth-friendly standards. The Youth Friendly Standards strategy ensures that it is aligned with the organization's goals and objectives.",
          our_newsletter: "Our newsletter",
          we_d_loved_to: "We'd love to share with you exciting moment on our journey to reimagine youth",
          will_be_notified: "You will be notified as soon as you are allowed to retake the assessment.",
          reference: "Reference",
          reply: "Reply",
          answer_dont_empty: "The answer must not be empty",
          top_ten: "Top 10 associations",
          average_rating: "Average rating",
          creation_date : "Creation date",
          nbr_evaluation_do : "Number of assessments carried do",
          authorize_retesting: 'Authorize retesting',
          not_authorize_retesting: 'Do not allow retesting',
          add_rating: "Add a rating",
          add_recommendation: "Add a recommendation",
          add_sdg: "Add a SDG",
          add_au: "Add an AU",
          add_subadmin: "Add subadmin",
          edit_subadmin: "Modify subadmin",
          libelle: "Wording",
          content_text: "Text content",
          content_image: "Picture content",
          add_theme: "Add a theme",
          edit_theme: "Modify a theme",
          see: "See",
          wording_pc: "TEXTUAL CONTENT OF THE PRIVACY POLICY PAGE",
          wording_cgu: "TEXTUAL CONTENT OF LEGAL INFORMATION PAGE",
          not_inform: "Do not fill in",
          inform: "To be completed",
          how_it_works: "How it works",
          other:"Other",



          shop:"Shop",
          all:"All",
          new:"New",
          slogan:"Discover the authentic freshness of",
          order:"Order",
          customers_say:"What our customers say about us",
          show_more: "Show more",
          our_partners: "Our Partners",
          home: "Home",
          about: "About",
          faq_title: "FAQ",
          our_news: "Our news",
          contact: "Contact",
          become_pro: "Becoming a professional",
          login: "login",
          about_us: "About us",
          frequently_question: "Frequently asked questions",
          points_sale: "Our points of sale",
          useful_links: "Useful links",
          our_products: "Our products",
          job_offers: "Job Offers",
          my_account: "My Account",
          professional_website: "Professional website",
          connection: "Connection",
          registration:'Registration',
          settings: "Settings",
          orders:"Orders",
          no_products_found: "No products found",
          for_this_category : "for this category",
          join_newsletter: "Join our newsletter",
          newsletter_description: "Immerse yourself in the Casadeliz adventure! Subscribe to our newsletter and discover our secrets, new products and exclusive offers, delivered straight to your inbox. Don't miss a drop of our passion for sustainable, fair-trade innovation !",
          read_all: "Read all",
          nams: "Names",
          email: "Email",
          subject: "Subject",
          message: "Message",
          send_message: "Send message",
          hearing_from_you: "We look forward to hearing from you !",
          hearing_from_you_description: "Do you have a question or a problem? Don't hesitate to by phone or e-mail. Our teams are available to answer and help you.",
          contact_us: "Contact us",
          location: "Location",
          our_address: "0ur address",
          our_address_description: "Lorem ipsum dolor sit amet consectetur. Lacinia morbi mauris eget tincidunt. Quam magna enim commodo sem amet ante. Morbi dui non non.",
          the_story: "the story",
          our_story: "Our story",
          why_choose_us : "Why choose us",
          team: "Team",
          no_data_found : "No data found",
          Do_you_have_questions: "Do you have any questions",
          still_need_help: "Toujours besoin d’aide",
          still_need_help_description: "If you haven't found the answer to your question in our FAQ, please don't hesitate to contact us directly by telephone or via our e-mail form. Our team is at your to provide you with any assistance you may require. Keep us informed;",
          send_us_message: "Send us a message",
          address: "Address",
          cart: "Cart",
          customer_service: "Customer service" ,
          home_delivery: "Home delivery",
          details: "Details",
          quantity: "quantity",
          favourites: "Favourites",
          add_cart : "Add to cart",
          post_comment:"Post a comment",
          message_to_connect: "Please log in to leave your opinion.",
          comments: "Comments",
          comment: "Comment",
          links: "Links",
          video: "Videos",
          see_the_product : "See the product",
          add_to_favorites : "Add to favorites",
          out_of_stock: "Out of stock",
          product_out_of_stock: "Product out of stock",
          becoming_professional : "Becoming a professional",
          becoming_professional_description : "Would you like to create an account to place an order online? order online? Simply fill in this form and our validate your profile within 1 to 2 working days. working days.",
          company: "Company",
          first_name: "First Name",
          last_name: "Last Name",
          phone: "Phone",
          send: "Send",
          activity_sector: "Activity sector",
          city: "City",
          register_at: "Register at",
          register_facebook: "Register with facebook",
          register_google: "Register with google",
          login_facebook: "Login with facebook",
          login_google: "Login with google",
          or:"Or",
          password: "Password",
          password_confirm: "Confirm password",
          already_registered: "Already registered",
          register: "Register",
          login_us: "Login",
          remember_me: "Remember me",
          forget_password: "Forgot your password ?",
          no_account_yet: "No account yet",
          buy:"Buy",
          useful_pages: "Useful pages",
          edit_your_profile: "Edit your profile",
          save: "Save",
          current_password: "Current password",
          new_password: "New password",
          your_password_must_contain : "Your password must contain",
          minimum_8_characters : "Minimum 8 characters",
          at_least_1_capital_letter : "At least 1 capital letter",
          minimum_1_lowercase_letter : "Minimum 1 lowercase letter",
          at_least_1_digit : "At least 1 digit",
          at_least_1_special_character: "At least 1 special character",
          my_order:"My orders",
          payment: "Payment",
          method_payment: "Method of payment",
          status: "Status",
          confirm: "Confirmed",
          delivered : "Delivered",
          cancelled : "Cancelled",
          my_favorites : "My Favorites",
          category : "Category",
          see_all : "See all",
          price_scale : "Price scale",
          any_of_them : "Any of them",
          fees: "Fees",
          hot: "Hot",
          items_found : "Items found",
          this_product_not_refundable : "This product is not refundable",
          manage_my_account: "Manage my account",
          my_profile: "My Profile",
          my_password: "My Password",
          my_cancellations: "My cancellations",
          dashboard: "Dashboard",         
          products_purchased: "Products purchased",
          since_last_month: "Since last month",
          cart_on_hold:" Cart on hold",
          products_in_stock: "Products in stock",
          recent_orders : "Recent orders",
          order_history : "Order history",
          customer: "Customer" ,
          price : "Price" ,
          sales: "Ventes",
          confirms: "Confirmed",
          delivereds : "Delivered",
          cancelleds : "Cancelled",
          products: "Products",
          date_add: "Date Added",
          amount: "Amount" ,
          search: "Search",
          profile: "Profile",
          edit_profile: "Edit profile",
          payment_status: "Payment status",
          paid: "Paid",
          not_yet_paid: "Not yet paid",
          delivery_status: "Delivery status",
          nark_as_delivered: "Mark as delivered",
          billing_details: "Billing details",
          delivery_address: "Delivery address",
          order_details : "Order details",
          order_summary: "Order summary",
          delivery: "Delivery",
          product: "Product",
          price_per_unit: "Price per unit",
          progress: "In progress",
          product_details:"Product details",
          technical_data:"Technical data",
          size: "Size",
          general_information: "General information ",
          name_product: 'Name product',
          available_from_stock: "Available from stock",
          see_catalog: "See catalog",
          catalog: "Catalog",
          number_of_items_in_cart:"Number of items in cart",
          see_all_the_notifications: "See all the notifications",
          there_is_no_notification: "There is no notification",
          modify_stock: "Modify stock",
          add_quantity_sold  : "Add quantity sold",
          you_might_like: "You might like",
          click_to_edit: "Click to edit the quantity",
          total_stock_has_been_reached : "Total stock has been reached.",
          remove:"Remove",
          apply: "Apply",
          back_to_store: "Back to store",
          remove_all:"Remove all",
          apply_code:"Apply code",
          my_products:"My products",
          professionals:"Professionals",
          customers:"Customers",
          page_important:"Important pages",
          your_cart_empty:'Your cart is empty',
          your_cart_not_empty:"Your cart contains products",
          secure_payment:"Secure payment",
          delivery_at_your_expense: "Delivery at your expense",
          total_price: "Total price",
          discount: "Discount",
          online_payment:"Online payment",
          payment_on_delivery: "Payment on delivery",
          payer:"Payer",
          You_may_be_interested_articles:"You may be interested in these articles",
          administrators: "Administrators",
          please_log_to_join_discussion : "Please log in to join the discussion.",
          call: "Call",












        },
      },
      fr: {
        translation: {
          nav_home: "ACCUEIL",
          nav_events: "ÉVÉNEMENTS",
          nav_resources: "RESSOURCES",
          sub_nav_webinar: "WÉBINAIRES",
          sub_nav_colloque: "COLLOQUES",
          sub_nav_econference: "E-CONFERENCES",
          nav_multimedias: "MULTIMÉDIAS",
          sub_nav_photos: "PHOTOS",
          sub_nav_livestreams: "DIRECTS",
          sub_nav_videos: "VIDÉOS",
          nav_blog: "BLOG",
          nav_news: "ACTUALITÉS",
          content: "Contenus",
          associations: "Associations",
          recommandations: "Recommandations",
          auto_evaluation: "Auto Évaluation",
          support: "Support",
          news_uppercase: "ACTUALITÉS",
          news_lowercase: "Actualités",
          read_more: "Lire plus",
          see_all_news: "Voir toutes les actualités",
          empty_alert_text: "AUCUNE DONNÉE DISPONIBLE POUR LE MOMENT",
          by: "Par",
          latest_blog_posts: "Derniers articles de blog",
          all_our_posts: "Tous nos articles",
          latest_news_posts: "Dernières actualités",
          all_our_news: "Toutes nos actualités",
          filter_by: "Filtrer par",
          french: "Français",
          english: "Anglais",
          language: "Langue",
          year: "Année",
          country: "Pays",
          share: "Partager",
          read_also: "A lire aussi",
          online_courses: "Cours en ligne",
          publications: "Publications",
          all_resources: "Toutes les ressources",
          resource_details: "Détails ressource",
          write_us: "Écrivez-nous",
          fullname: "Prénom et Nom",
          new_message: "Nouveau message",
          inprogress: "En cours ...",
          partners: "Partenaires",
          they_support_us: "ILS NOUS SOUTIENNENT",
          diary: "Agenda",
          events_to_come: "ÉVÉNEMENTS À VENIR",
          why_faeufao: "POURQUOI LE FAUEFAO ?",
          learn_more: "EN SAVOIR PLUS",
          newsletter_subscriber_title: "ABONNEZ-VOUS",
          newsletter_archives: "Archives de la newsletter",
          privacy_policy: "Politique de confidentialité",
          legal_notice: "Mentions Légales",
          add_your_email_address: "Ajoutez votre adresse email",
          incoming_events: "Événement à venir",
          past_events: "Événements passés",
          type_of_events: "Type d'événement",
          domains: "Domaines",
          communities: "<p className='subtitle-theme-app mb-2'>COMMUNAUTÉS</p>",
          pratical_communities: "<h2 className='title-home-page-banner'>NOS COMMUNAUTÉS <br /> de pratiques</h2>",
          i_join_communities: "I join the communities",
          event_details: "Détails de l’événement ",
          contacter: "Contacter YFS",
          pages: "Pages",
          theme: "Théme",
          date: "Date",
          place: "Lieu",
          
          upcoming_conferences: "Colloques à venir",
          past_conferences: "Colloques passés",
          see_more_conferences: "Voir tous les colloques",
          download_agenda: "Télécharger l'agenda détaillé",
          speakers: "Intervenants",
          fauefao_conferences: "Colloques du FAUEFAO",
          subscribe: "S'abonner",
          find_more_conferences: "En savoir plus sur les prochains colloques",
          hours: "Heures",
          empty_download_agenda: "non disponible",
          i_am_registering: "Je m'inscris",
          register_for_event: "S'inscrire à cet événement",
          send_date: "Date d'envoie",
          pays: "Pays",
          close: "Fermer",
          fauefao_webinar: "Wébinaire du FAUEFAO",
          find_more_webinars: "En savoir plus sur les prochains wébinaires",
          see_all_webinars: "Voir tous les wébinaires",
          register_for_webinars: "S'inscrire au wébinaires",
          fauefao_econference: "E-Conference de FAUEFAO",
          find_more_econferences: "En savoir plus sur les prochaines e-conferences",
          see_all_econference: "Voir toutes les e-conference",
          register_for_econference: "S'inscrire à cette e-conference",
          communities_home: "Accueil Communautés",
          joined_since: "à rejoint depuis",
          see_website: "Voir le site web",
          biography: "Biographie",
          additional_infos: "Informations supplémentaires",
          website: "Site web",
          categories: "Catégories",
          sort_by: "Trier par ",
          members: "Membres",
          communities_wt: "Communautés",
          results: "Résultat(s)",
          filter: "Filtrer",
          discussions: "Discussions",
          documents: "Documents",
          personal_infos: "Informations Personnelles",
          prof_infos: "Informations Professionnelles",
          next: "Suivant",
          previous: "Suivant",
          nav_members: "Membres",
          nav_institutions: "Institutions",
          username: "Pseudonyme",
          title: "Tritre",
          hold_position: "Quel poste occupez-vous ?",
          organisation_category: "Catégorie de votre organisation",
          description: "Description",
          short_description_placeholder: "Ajouter une petite description pour permettre aux autres de mieux vous connaître",
          linkedin_profile: "URL de votre Profil LinkedIn",
          x_profile: "URL de votre Profil X",
          facebook_profile: "URL de votre Profil Facebook",
          email_or_username: "Identifiant (Email ou Pseudonyme)",
          communities_practice: "COMMUNAUTÉS DE PRATIQUE",
          explore_learn_grow: "Explorez, Apprenez, Grandissez",
          join_our_communities_practice: "Rejoignez nos communautés de pratiques",
          see_all_communities: "Voir toutes les communuatés",
          communaute_joined_requirement_alert: "Vous devez rejoindre la communauté avant de pouvoir poster un message.",
          success: "SUCCÈS",
          success_joined_message: "Bravo! Vous avez maintenant un accès en écriture dans cette communauté.",
          member_since: "membre depuis",
          resources: "Ressources",
          events: "Événements",
          publish: "Publier",
          post_title: "Titre du post",
          add_link: "Ajouter une URL",
          add_text_contribution: "Ajouter votre contribution textuel",
          add_illustrative_image: "Ajouter une image illustrative",
          attach_pdf_file: "Joindre un fichier PDF",
          filename: "Nom du fichier",
          join: "REJOINDRE",
          partners_list: "Liste des partenaires",
          admin_list_label: "Liste des administrateur",
          resource_type: "Types de ressources",
          link: "Lien",
          photo: "Photo",
          search_admin: "Chercher un Admin",
          change_my_password: "Modifier mon mot de passe",
          logout: "Se déconnecter",
          change_profile_avatar: "Changer ma photo de profil",
          update: "Modifier",
          directories: "Annuaire",
          send_a_message: "Envoyer un message",
          position_held: "Poste occupé",
          banned_message: "Vous avez été banni de cette communauté.",
          participant_details: "Détails du participant",
          required: "* Obligatoire",
          faq_text: "Questions Fréquentes",
          overview: "Vue d'ensemble",
          number_test: "Nombre d'essais",
          avg_score: "Score moyen",
          total_minutes_spent: "Total de temps passées",
          ranking: "Classement",
          news: "Actualités",
          score_evolution_theme: "L’évolution du score par thématique",
          evaluation: "Évaluations",
          start: "Démarrer",
          completed: "Terminé",
          uncompleted: "Pas terminé",
          nb_questions: "Nombre de questions",
          see_details: "Voir les details",
          see_score: "Voir le score",
          continue: "Reprendre",
          last_score: "Derniers scores",
          save_finish_later: "Sauvegarder et terminer plus tard",
          finish: "Terminer",
          organization_name: "Nom de l'organisation",
          organization_description: "Description de l'organisation",
          representant: "Représentant",
          policy_recommandations: "Recommandations de politiques",
          profile_infos: "Détails du profile",
          general_infos: "Informations générales",
          text_profile: "Noms, nom de l’organisation, logo, information de contacts",
          details_evaluation: "Détails évaluation",
          assessment: "Evaluation",
          assessment_name: "Nom de l'évaluation",
          of_answered_questions: "de questions répondues",
          out_of_4: "sur 4",
          evaluate_your_impact: "OUTIL D'AUTO-ÉVALUATION YOUTH FRIENDLY STANDARDS Renforcer la jeunesse. Construire l'avenir.",
          the_platform_guide: "Plus de 50 % de la population mondiale a moins de 30 ans. Exploitez cet incroyable potentiel grâce à l'outil d'évaluation Youth-Friendly Standards, une plateforme mondiale de premier plan qui dote les organisations d'une approche globale basée sur les systèmes afin de responsabiliser et d'impliquer les jeunes.",
          join_movement: "Rejoignez le mouvement pour un engagement significatif des jeunes dès aujourd'hui.",
          start_self_assessment: "Commencer mon évaluation",
          tool_overview: "Vue d'ensemble de l'outil Youth Friendly Standards",
          tool_guide: "L'auto-évaluation guidée vise à faciliter les discussions et l'introspection concernant les forces existantes et les moyens d'améliorer les processus qui favorisent une transformation positive de l'autonomisation des jeunes et de l'inclusion au sein de vos projets et de votre organisation. Les participants sont habilités à identifier des stratégies pour renforcer l'égalité, la diversité et l'inclusion sociale des jeunes, à explorer les voies d'un changement efficace et à identifier les domaines à améliorer dans leur travail. Pour utiliser efficacement l'outil d'auto-évaluation des Youth Friendly Standards, une approche systématique peut être adoptée. Le processus implique d'utiliser principalement cinq domaines pour évaluer les progrès et assurer une mise en œuvre complète dans le continuum.",
          systems_strengthening: "Renforcement des systèmes : Renforcement des systèmes nationaux et infranationaux au sein de l'organisation afin de promouvoir des pratiques et des politiques adaptées aux jeunes, en mettant particulièrement l'accent sur la promotion de l'inclusion, de la diversité et de la participation des jeunes.",
          leave_no_one_behind: "Ne laisser personne de côté : Promouvoir un accès équitable et universel à des services, des programmes et des opportunités adaptés aux jeunes, en ciblant spécifiquement les populations de jeunes marginalisés et sous-représentés.",
          transformative_change: "Ne laisser personne de côté : Promouvoir un accès équitable et universel à des services, des programmes et des opportunités adaptés aux jeunes, en ciblant spécifiquement les populations de jeunes marginalisés et sous-représentés.",
          knowledge_and_learning: "Connaissance et apprentissage : Promouvoir l'adoption de nouvelles connaissances, d'approches innovantes et de meilleures pratiques en matière de développement et d'engagement des jeunes par l'organisation et d'autres parties prenantes, y compris les organisations de la société civile, les organismes gouvernementaux et les partenaires internationaux.",
          organizational_culture_and_practice: "Culture et pratique organisationnelles : Favoriser une culture organisationnelle favorable aux jeunes qui embrasse la diversité, l'inclusion et l'engagement significatif des jeunes. Cela implique d'aligner les politiques, les processus et les systèmes au sein de l'organisation afin de garantir que les voix et les perspectives des jeunes sont valorisées et que leurs besoins sont satisfaits de manière efficace.",
          self_assessment_enables: "Cette auto-évaluation permet aux organisations d'identifier leurs forces, leurs faiblesses et les domaines à améliorer, afin de créer des environnements et des services plus accueillants pour les jeunes.",
          instructions: "Instructions pour l'auto-évaluation",
          proceed_self_assessement: "Pour procéder à l'auto-évaluation, veuillez prendre en considération les éléments suivants :",
          allocate_sufficient_time: "Allouer suffisamment de temps:",
          set_aside_dedicated: "Réservez du temps pour compléter l'auto-évaluation d'auto-évaluation d'auto-évaluation à fond.",
          avoid_rushing_through: "Évitez de vous précipiter processus afin de garantir des des réponses précises et significatives.",
          consider_involving_other: "Envisager d'impliquer d'autres membres de l'équipe ou des experts pour recueillir des points de vue perspectives et points de vue.",
          ensure_consistency: "Assurer la cohérence:",
          maintain_consistency: "Restez cohérent dans vos réponses tout au long du questionnaire.",
          cross_reference: "Renvoi à des questions connexes pour vous assurer que vos réponses que vos réponses sont logiques et cohérentes.",
          follow_up_plan: "Suivi et plan d'action :",
          once_assessment_completed: "Une fois l'auto-évaluation terminée, examinez les résultats et identifiez les domaines à améliorer ou les actions à entreprendre.",
          collaborate_with_relevant: "Collaborer avec les parties prenantes concernées pour élaborer un plan d'action basé sur les résultats de l'évaluation.",
          monitor_process: "Suivre les progrès, mettre en œuvre les changements nécessaires et revenir régulièrement sur le processus d'auto-évaluation pour suivre les améliorations au fil du temps.",
          familiarize_yourself: "Familiarisez-vous avec le cadre de travail de Youth Friendly Standards",
          ensure_clear_understanding: "Assurez-vous d'avoir une compréhension claire des principes, objectifs et des composantes de Youth Friendly Standards.",
          review_available_documentation: "Examiner toute documentation ou ressource disponible relative à Youth Friendly Standards et à ses normes",
          familiarize_purpose: "Familiarisez-vous avec l'objectif de l'auto-évaluation.",
          collect_informations: "Collecter des informations pertinentes :",
          gather_necessary_information: "Rassemblez toutes les informations et tous les documents nécessaires pour répondre correctement au questionnaire.",
          consult_stakeholders: "Consultez les parties prenantes ou les services concernés au sein de votre organisation pour obtenir les données nécessaires.",
          ensure_up_to_date: "Veillez à ce que les informations recueillies soient à jour et reflètent l'état actuel des pratiques de votre organisation.",
          answer_questions: "Répondez soigneusement aux questions :",
          read_each_question: "Lisez attentivement chaque question et comprenez-en l'intention avant d'y répondre.",
          consider_context: "Tenez compte du contexte spécifique de votre organisation et de ses pratiques lorsque vous répondez aux questions.",
          provide_honest_response: "Fournissez des réponses honnêtes et précises basées sur les pratiques réelles de votre organisation.",
          review_and_validate: "Examiner et valider les réponses :",
          take_time_to_review: "Prenez le temps de revoir vos réponses et de valider leur exactitude avant de finaliser l'auto-évaluation.",
          double_check_calculation: "Revérifier les calculs ou la notation, le cas échéant, pour s'assurer de leur exactitude.",
          valuable_opportunity: "N'oubliez pas que le processus d'auto-évaluation est une occasion précieuse d'évaluer la conformité de votre organisation avec le YFS et d'identifier les domaines à améliorer. Abordez-le avec diligence et en vous engageant à vous améliorer en permanence.",
          yfs_implementation: "Mise en œuvre de YFS",
          effectively_implement: "Pour mettre en œuvre efficacement les YFS au sein d'une organisation et d'un projet, il est possible de suivre un processus étape par étape. Ce processus comprend le diagnostic des pratiques actuelles, la réalisation d'une analyse comparative avec les YFS, le choix des normes prioritaires sur lesquelles se concentrer, la formation de l'équipe pour qu'elle comprenne la valeur de la norme et, enfin, l'élaboration d'une stratégie et d'un cadre de transformation en collaboration avec les responsables. Le schéma ci-dessous donne un aperçu des principaux éléments sur lesquels reposent les YFS :",
          current_diagnostic: "Diagnostic sur les pratiques actuelles :",
          conduct_assessment: "Procéder à une évaluation des pratiques actuelles de l'organisation en matière d'inclusion et d'engagement des jeunes. Il s'agit d'évaluer les politiques, les programmes, les services, les pratiques en matière de ressources humaines, les relations d'affaires, les initiatives de développement communautaire et les partenariats institutionnels existants. Identifier les forces, les faiblesses, les lacunes et les points à améliorer en ce qui concerne les pratiques favorables aux jeunes.",
          comparative_analysis: "Analyse comparative avec les normes relatives aux jeunes :",
          compare_current_practice: "Comparer les pratiques actuelles de l'organisation aux normes énoncées dans le cadre des normes favorables aux jeunes. Évaluer dans quelle mesure l'organisation s'aligne sur les normes amies des jeunes dans les six axes thématiques : gouvernance, programmes/produits/services, ressources humaines, relations d'affaires, développement communautaire et partenariats institutionnels. Identifier les lacunes et les domaines dans lesquels l'organisation n'atteint pas les normes souhaitées.",
          train_the_team: "Former l'équipe pour qu'elle comprenne la valeur et adopte les normes :",
          provide_training: "Proposer des initiatives de formation et de renforcement des capacités aux membres de l'équipe de l'organisation, y compris le personnel, les responsables et les parties prenantes. Cette formation devrait se concentrer sur la sensibilisation à la valeur et aux avantages des pratiques favorables aux jeunes, ainsi que sur la fourniture de conseils sur la manière d'adopter et de mettre en œuvre les normes prioritaires identifiées. Favoriser une compréhension commune et un engagement en faveur de l'inclusion des jeunes dans l'ensemble de l'organisation.",
          choose_standards: "Choisir les normes prioritaires à atteindre :",
          based_on_comparative: "Sur la base de l'analyse comparative, classez par ordre de priorité les normes qui requièrent une attention et une amélioration immédiates. Sélectionnez les domaines dans lesquels les pratiques de l'organisation sont les plus éloignées des Youth Friendly Standards ou dans lesquels l'impact le plus important peut être obtenu par des interventions ciblées. Ces normes prioritaires guideront les efforts de transformation.",
          develop_a_strategy: "Élaborer une stratégie et un cadre de transformation pour la direction de l'organisation :",
          implemente_change: "Collaborer avec les membres de l'équipe dirigeante et les principales parties prenantes pour élaborer une stratégie et un cadre de transformation complets. Cette stratégie devrait décrire les actions spécifiques, les calendriers, les responsabilités et les ressources nécessaires pour combler les lacunes identifiées dans l'analyse comparative et atteindre les normes souhaitées en matière d'accueil des jeunes. La stratégie relative aux normes favorables aux jeunes doit être alignée sur les buts et objectifs de l'organisation.",
          our_newsletter: "Notre newsletter",
          we_d_loved_to: "Nous aimerions partager avec vous un moment passionnant de notre voyage pour réimaginer la jeunesse.",
          will_be_notified: "Vous serez notifié dès que vous serez autorisé à repasser l'évaluation.",
          reference: "Référence",
          reply: "Répondue",
          answer_dont_empty :"La réponse ne doit pas être vide",
          top_ten: "Top 10 des associations",
          average_rating: "Moyenne évaluation",
          creation_date : "Date de création",
          nbr_evaluation_do : "Nombre d’évaluations faites",
          authorize_retesting: 'Autoriser à retester',
          not_authorize_retesting: 'Ne pas autoriser à retester',
          add_rating: "Ajouter une évaluation",
          add_recommendation: "Ajouter une recommandation",
          add_sdg: "Ajouter une SDG",
          add_au: "Ajouter une AU",
          add_subadmin: "Ajouter un sous-admin",
          edit_subadmin: "Modifier un sous-admin",
          libelle: "Libellé",
          content_text: "Contenue textuel",
          content_image: "Contenue image",
          add_theme: "Ajouter un théme",
          edit_theme: "Modifier un théme",
          see: "Voir",
          text_pc: "CONTENU TEXTUEL DE LA PAGE POLITIQUE DE CONFIDENTIALITÉ",
          wording_cgu: "CONTENU TEXTUEL DE LA PAGE MENTIONS LÉGALES",
          not_inform: "A ne pas renseigner",
          inform: "A renseigner",
          how_it_works: "Comment cela fonctionne-t-il ?",



          shop:"Boutique",
          all:"Tout",
          new:"Nouveau",
          slogan:"Découvrez la fraîcheur authentique de la",
          order:"Commander",
          customers_say:"Ce que nos clients disent de nous",
          show_more: "Voir plus",
          our_partners: "Nos partenaires",
          home: "Accueil",
          about: "À Propos",
          faq_title: "FAQ",
          our_news: "Notre actu",
          contact: "Contact",
          become_pro: "Devenir professionnel",
          login: "Se connecter",
          about_us: "À propos de nous",
          frequently_question: "Questions Fréquentes",
          points_sale: "Nos points de ventes",
          useful_links: "Liens utiles",
          our_products: "Nos produits",
          job_offers: "Offre d'Emploi",
          my_account: "Mon Compte",
          professional_website: "Site professionnel",
          connection: "Connexion",
          registration:'Inscription',
          settings: "Paramètres",
          orders:"Commandes",
          no_products_found: "Aucun produit trouvé",
          for_this_category : "pour cette catégorie",
          join_newsletter: "Rejoindre notre newsletter",
          newsletter_description: "Plongez au cœur de l'aventure Casadeliz ! Inscrivez-vous à notre newsletter pour découvrir nos secrets, nouveautés et offres exclusives directement dans votre boîte mail. Ne manquez pas une goutte de notre passion pour l'innovation durable et équitable",
          read_all: "Lire tout",
          nams: "Noms",
          email: "Email",
          subject: "Sujet",
          message: "Message",
          send_message: "Envoyer le Message",
          hearing_from_you: "Nous sommes à votre écoute !",
          hearing_from_you_description: "Vous avez une question ou un problème ? N'hésitez pas à nous contacter par téléphone ou par email. Nos équipes sont disponibles pour vous répondre et vous aider. ",
          contact_us: "Contactez-nous",
          location: "Localisation",
          our_address: "Notre adresse",
          our_address_description: "Lorem ipsum dolor sit amet consectetur. Lacinia morbi mauris eget tincidunt. Quam magna enim commodo sem amet ante. Morbi dui non non.",
          the_story: "l’histoire",
          our_story: "Notre histoire",
          why_choose_us : "Pourquoi nous choisir",
          team: "Equipe",
          no_data_found : "Aucune donnée trouvée",
          Do_you_have_questions: "Vous avez des questions",
          still_need_help: "Still need help",
          still_need_help_description: "Si vous n'avez pas trouvé de réponse à votre question dans notre FAQ, n'hésitez pas à nous contacter directement par téléphone ou via notre formulaire de messagerie. Notre équipe se tient à votre disposition pour vous apporter toute l'assistance nécessaire. Tenons nous au jus ;",
          send_us_message: "Envoyez nous un message",
          address: "Adresse",
          cart: "Panier",
          customer_service: "Service client" ,
          home_delivery: "Livraison à domicile",
          details: "Détails",
          quantity: "Quantité",
          favourites: "Favoris",
          add_cart : "Ajouter au panier",
          post_comment:"Poster un commentaire",
          message_to_connect: "Veuillez vous connecter, pour pouvoir laisser votre avis.",
          comments: "Commentaires",
          comment: "Commenter",
          links: "Liens",
          video: "Vidéos",
          see_the_product : "Voir le produit",
          add_to_favorites : "Ajouter aux favoris",
          out_of_stock: "En rupture",
          product_out_of_stock: "Produit en rupture de stock",
          becoming_professional : "Devenir professionnel",
          becoming_professional_description : "Vous souhaitez créer un compte pour passer commande en ligne ? Il suffit de remplir ce formulaire et nos équipes valideront votre profil dans un délai d’1 à 2 jours ouvré.",
          company: "Société",
          first_name: "Prénom",
          last_name: "Nom",
          phone: "N° Téléphones",
          send: "Envoyer",
          activity_sector: "Secteur d’activité",
          city: "Ville",
          register_at: "Inscrivez-vous",
          register_facebook: "S’inscrire avec Facebook",
          register_google: "S’inscrire avec google",
          login_facebook: "Se connecter avec Facebook",
          login_google: "Se connecter avec google",
          or:"Ou",
          password: "Mot de passe",
          password_confirm: "Confirmer mot de passe",
          already_registered: "Déjà inscris",
          register: "S'inscrire",
          login_us: "Connectez-vous",
          remember_me: "Se souvenir de moi",
          forget_password: "Mot de passe oublié ?",
          no_account_yet: "No account yet",
          buy:"Acheter",
          useful_pages: "Pages utiles",
          edit_your_profile: "Modifier votre profil",
          save: "Enrégistrer",
          current_password: "Mot de passe actuel",
          new_password: "Nouveau password",
          your_password_must_contain : "Votre mot de passe doit contenir",
          minimum_8_characters : "Minimum 8 caractères",
          at_least_1_capital_letter : "Au minimum 1 majuscule",
          minimum_1_lowercase_letter : "Au minimum 1 minuscule",
          at_least_1_digit : "Au minimum 1 chiffre",
          at_least_1_special_character: "Au minimum 1 caractère spécial",
          my_order:"Mes commandes",
          payment: "Paiement",
          method_payment: "Mode de paiement",
          status: "status",
          confirm: "Confirmée",
          delivered : "Livrée",
          cancelled : "Annulée",
          my_favorites : "Mes Favoris",
          category : "Categorie",
          see_all : "Voir tout",
          price_scale : "Échelle des prix" ,
          any_of_them : "N’importe lequel",
          fees : "Frais",
          hot: "Chaud",
          items_found : "Éléments trouvés",
          this_product_not_refundable : "Ce produit n'est pas remboursable",
          manage_my_account: "Gérer mon compte",
          my_profile: "Mon Profil",
          my_password: "Mon mot de passe",
          my_cancellations: "Mes annulations",
          dashboard: "Tableau de bord",
          products_purchased: "Produits achetés",
          since_last_month: "Depuis le mois dernier",
          cart_on_hold: "Panier en attente",
          products_in_stock: "Produits en stock",
          recent_orders : "Commandes récentes",
          order_history : "Historique commandes",
          customer: "Client" ,
          price : "Prix" ,
          sales: "Ventes",
          confirms: "Confirmées",
          delivereds : "Livrées",
          cancelleds : "Annulées",
          products: "Produits",
          date_add: "Date d’ajout",
          amount: "Montant" ,
          search: "Rechercher",
          profile: "Profil",
          edit_profile: "Editer le profil",
          payment_status: "Statut du paiement",
          paid: "Payé",
          not_yet_paid: "Pas encore payé",
          delivery_status: "Statut de la livraison ",
          nark_as_delivered: "Marquer comme livré",
          billing_details: "Détails de facturation",
          delivery_address: "Adresse de livraison",
          order_details : "Détails de la commande ",
          order_summary: "Récapitulatif de la commande",
          delivery: "Livraison",
          product: "Produit",
          price_per_unit: "Prix unitaire",
          progress: "En cours",
          product_details:"Détail du produit",
          technical_data:"Fiche technique",
          size: "Taille",
          general_information: "Information générale",
          name_product: 'Nom du produit',
          available_from_stock: "Disponible en stock",
          see_catalog: "Voir catalogue",
          catalog: "Catalogue",
          number_of_items_in_cart:"Nombre de produit dans le panier",
          see_all_the_notifications: "Voir toutes les notifications",
          there_is_no_notification: "Il n'y a aucune notification",
          modify_stock: "Modifier le stock",
          add_quantity_sold  : "Ajouter la quantité vendue  ",
          you_might_like: "Vous pourriez aimer",
          click_to_edit: "Cliquez pour modifier la quantité",
          total_stock_has_been_reached : "Le stock total a été atteint.",
          remove:"Supprimer",
          apply: "Appliquer",
          back_to_store: "Retour à la boutique",
          remove_all:"Tout enlever",
          apply_code:"Appliquer le code",
          my_products:"Mes produits",
          professionals:"Professionnels",
          customers:"Clients",
          page_important:"Pages importantes",
          your_cart_empty:'Votre panier est vide',
          your_cart_not_empty:"Votre panier contient des produits",
          secure_payment:"Paiement sécurisé",
          delivery_at_your_expense: "Livraison à vos frais",
          total_price: "Prix total",
          discount: "Remise",
          online_payment:"Paiement en ligne",
          payment_on_delivery: "Paiement à la livraison",
          payer:"Pay",
          You_may_be_interested_articles:"Vous pourriez être intéressé par ces articles",
          administrators: "Administrateurs",
          please_log_to_join_discussion : "Veuillez vous connecter, pour prendre  part à la discussion.",
          call: "Appeler",














        },
      },
    },
  });

export default i18n;