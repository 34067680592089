import React,{useEffect} from "react";
import "./DashboardAdmin.css";
import Icon1 from "../../../assets/icons/icon1.svg";
import Icon2 from "../../../assets/icons/icon2.svg";
import Icon3 from "../../../assets/icons/icon3.svg";
import Icon4 from "../../../assets/icons/icon4.svg";
import Incomming from "./LineChat/Incomming";
import TopVentesTable from "./table/TopVentesTable/TopVentesTable";
import CommandesRecentsTable from "./table/CommandesRecentsTable/CommandesRecentsTable";
import { useGetDashboardDataQuery } from "../../../utils/api/admin/admin.api";
import { formatCurrency } from "../../../utils/Utils";

const DashboardAdmin = () => {
	const { data, isLoading, refetch } = useGetDashboardDataQuery({});

	useEffect(() => {
		if(isLoading == false)
		{
			refetch();
		}
	}, []);

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="mb-4">
					<h2 className="chart-item-admin-title mb-0">
						Dashboard
					</h2>
				</div>
				<div className="row mb-4">
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view-admin">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="icon-item-stat-dashboad-view">
										<p className="titre-item-stat-dashboad-view">
											Abonnés à la newsletter
										</p>
										<img
											src={Icon1}
											alt="Dashboard"
											className="img-icon-dash-view"
										/>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view">
									<p className="chiffre-item-stat-dashboad-view">
										{
											data?.subscribers_nl_count
										}
									</p>
									{/* <p className="content-text-describe-stat-dash mb-0">
										<span className="pe-2 text-nombre-positif">
											+6.50%
										</span>
										<span>
											depuis le mois
											dernier
										</span>
									</p> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view-admin">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="icon-item-stat-dashboad-view">
										<p className="titre-item-stat-dashboad-view">
											CA produits
											vendus
										</p>
										<img
											src={Icon4}
											alt="Dashboard"
											className="img-icon-dash-view"
										/>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view">
									<p className="chiffre-item-stat-dashboad-view">
										{formatCurrency(
											data?.ca
										)}
									</p>
									{/* <p className="content-text-describe-stat-dash mb-0">
										<span className="pe-2 text-nombre-positif">
											+6.50%
										</span>
										<span>
											depuis le mois
											dernier
										</span>
									</p> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view-admin">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="icon-item-stat-dashboad-view">
										<p className="titre-item-stat-dashboad-view">
											Nombre de panier
										</p>
										<img
											src={Icon3}
											alt="Dashboard"
											className="img-icon-dash-view"
										/>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view">
									<p className="chiffre-item-stat-dashboad-view">
										{
											data?.product_in_current_cart_count
										}
									</p>
									{/* <p className="content-text-describe-stat-dash mb-0">
										<span className="pe-2 text-nombre-negatif">
											-2.86%
										</span>
										<span>
											depuis le mois
											dernier
										</span>
									</p> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view-admin">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="icon-item-stat-dashboad-view">
										<p className="titre-item-stat-dashboad-view">
											Produits en
											rupture de
											stock
										</p>
										<img
											src={Icon2}
											alt="Dashboard"
											className="img-icon-dash-view"
										/>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view">
									<p className="chiffre-item-stat-dashboad-view">
										{
											data?.product_with_0_stock_count
										}
									</p>
									{/* <p className="content-text-describe-stat-dash mb-0">
										<span className="pe-2 text-nombre-positif">
											+1.70%
										</span>
										<span>
											depuis le mois
											dernier
										</span>
									</p> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-5 col-md-12 mb-3">
						<div className="content-graph-admin">
							<div className="content-chart-title pb-3">
								<h2 className="chart-item-admin-subtitle mb-0">
									Commandes récentes
								</h2>
								<button className="btn btn-menu-dash-admin">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<mask
											id="mask0_1844_1574"
											style={{
												maskType: "alpha",
											}}
											maskUnits="userSpaceOnUse"
											x="0"
											y="0"
											width="20"
											height="20"
										>
											<rect
												width="20"
												height="20"
												fill="#D9D9D9"
											/>
										</mask>
										<g mask="url(#mask0_1844_1574)">
											<path
												d="M9.99998 16.6673C9.54165 16.6673 9.14942 16.5043 8.82331 16.1782C8.49665 15.8515 8.33331 15.459 8.33331 15.0007C8.33331 14.5423 8.49665 14.1498 8.82331 13.8232C9.14942 13.497 9.54165 13.334 9.99998 13.334C10.4583 13.334 10.8508 13.497 11.1775 13.8232C11.5036 14.1498 11.6666 14.5423 11.6666 15.0007C11.6666 15.459 11.5036 15.8515 11.1775 16.1782C10.8508 16.5043 10.4583 16.6673 9.99998 16.6673ZM9.99998 11.6673C9.54165 11.6673 9.14942 11.504 8.82331 11.1773C8.49665 10.8512 8.33331 10.459 8.33331 10.0007C8.33331 9.54232 8.49665 9.14982 8.82331 8.82315C9.14942 8.49704 9.54165 8.33398 9.99998 8.33398C10.4583 8.33398 10.8508 8.49704 11.1775 8.82315C11.5036 9.14982 11.6666 9.54232 11.6666 10.0007C11.6666 10.459 11.5036 10.8512 11.1775 11.1773C10.8508 11.504 10.4583 11.6673 9.99998 11.6673ZM9.99998 6.66732C9.54165 6.66732 9.14942 6.50398 8.82331 6.17732C8.49665 5.85121 8.33331 5.45898 8.33331 5.00065C8.33331 4.54232 8.49665 4.1501 8.82331 3.82398C9.14942 3.49732 9.54165 3.33398 9.99998 3.33398C10.4583 3.33398 10.8508 3.49732 11.1775 3.82398C11.5036 4.1501 11.6666 4.54232 11.6666 5.00065C11.6666 5.45898 11.5036 5.85121 11.1775 6.17732C10.8508 6.50398 10.4583 6.66732 9.99998 6.66732Z"
												fill="#586A84"
											/>
										</g>
									</svg>
								</button>
							</div>
							<CommandesRecentsTable
								recentOrders={
									data?.recent_orders
								}
								isLoading={isLoading}
							/>
						</div>
					</div>
					<div className="col-lg-7 col-md-12 mb-3">
						<div className="content-graph-admin">
							<Incomming
								ventesGraph={data?.ventesGraph}
							/>
						</div>
					</div>
				</div>
				<div className="content-graph-admin">
					<div className="container-title-dash">
						<h2 className="chart-item-admin-subtitle mb-0">
							TOP VENTES
						</h2>
					</div>
					<div className="admin-table pt-5">
						<TopVentesTable
							topSells={data?.top_ventes}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardAdmin;
