import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./PanierVisiteur.css";
import Panier from "./Panier";

function PanierVisiteur() {
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<Panier />
			<FrontFooter />
		</div>
	);
}

export default PanierVisiteur;
