import React from "react";
import { FormError, Input, RequiredText } from "../../../common/Input";
import ReactQuill from "react-quill";
import { AiOutlinePlus } from "react-icons/ai";
import useCrudCatalogue from "../hooks/useCrudCatalogue";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PreviewImage } from "../../../common/MyDropzone";
import { getImage } from "../../../../utils/Utils";

function OptionCatalogueAdmin() {
	const {
		register,
		errors,
		isLoading,
		onSubmit,
		handleChangeCatalogue,
		handleChangeDescription,
		handleChangeDescriptionEn,
		description,
		descriptionEn,
		handleChangePourcentageText,
		handleChangePourcentageTextEn,
		pourcentageText,
		pourcentageTextEn,
		catalogue,
	} = useCrudCatalogue();

	return (
		<div className="container-fluid">
			<div className="form-add-container">
				<form className="mt-4" onSubmit={onSubmit}>
					<div className="row row-add-student my-3">
						
						<section className="row mb-5">
							{/* <div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Notre histoire
								</div>
							</div> */}
							<div className="col-lg-12">
								<Input
									type="file"
									label="Catalogue"
									id="image"
									required
									onChange={
										handleChangeCatalogue
									}
								/>
								<FormError
									error={
										errors?.catalogue
									}
								/>
							</div>
							<div className="col-lg-12 mb-3 h-25">
								<object data={catalogue?.catalogue} type="application/pdf" width="100%" height="100%">
									<p>Texte alternatif - inclure un lien <a href={catalogue?.catalogue} >vers le pdf du catalogue !</a></p>
								</object>
							</div>
							<div className="col-lg-12 mb-3">
								<Input
									type="text"
									label="Pourcentage"
									id="pourcentage"
									placeholder="Pourcentage"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("pourcentage")}
									error={
										errors?.pourcentage
									}
									required
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_fr"
									className="form-label input-produit-label-form-add"
								>
									Description(Fr)
									<RequiredText />
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={description}
									onChange={
										handleChangeDescription
									}
								/>
								<FormError
									error={
										errors?.description
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_En"
									className="form-label input-produit-label-form-add"
								>
									Description(En)
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={descriptionEn}
									onChange={
										handleChangeDescriptionEn
									}
								/>
								<FormError
									error={
										errors?.description_en
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_fr"
									className="form-label input-produit-label-form-add"
								>
									Texte poucentage(Fr)
									<RequiredText />
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={pourcentageText}
									onChange={
										handleChangePourcentageText
									}
								/>
								<FormError
									error={
										errors?.pourcentage_text_en
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_fr"
									className="form-label input-produit-label-form-add"
								>
									Texte pourcentage(En)
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={pourcentageTextEn}
									onChange={
										handleChangePourcentageTextEn
									}
								/>
								<FormError
									error={
										errors?.pourcentage_text
									}
								/>
							</div>
							<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5 mb-5 ">
								<button
									className="btn btn-cancel-admin"
									type="button"
								>
									Annuler
								</button>
								<button
									className="btn btn-theme-admin w-100"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<span
												className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
												role="status"
											></span>
											<span>
												Enrégistrement...
											</span>
										</>
									) : (
										"Modifier"
									)}
								</button>
							</div>
						</section>
						
					</div>
				</form>
			</div>
		</div>
	);
}

export default OptionCatalogueAdmin;
