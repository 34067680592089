import Swal from "sweetalert2";
import {
	useAddFavoriteProductMutation,
	useAddProductCartMutation,
	useProductFromLocation,
	useRateProductMutation,
} from "../../../../utils/api/product/product.api";
import {
	Product,
	RateProductData,
	TFavoriteProduct,
	Variation,
} from "../../../../utils/api/product/product.type";
import { Color } from "../../../../utils/theme";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { useEffect, useState } from "react";
import {
	addCartProduct,
	calculateTax,
	getCartCount,
	getTotalAmount,
} from "../../../../redux/slice/useCartSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { QueryError } from "../../../../utils/type";
import { isParticulier, isProfessionnel } from "../../../../routes/routerUtils";

export function useProduct() {
	const { user } = useAppSelector((s) => s?.user);
	const [addToCart] = useAddProductCartMutation();
	const [product, isLoading] = useProductFromLocation();
	const [rateProduct] = useRateProductMutation();
	const [isLoadingAddToCard, setIsLoadingAddToCard] = useState<boolean>(false);
	const [productNumber, setProductNumber] = useState<number>(1);
	const [currentVariation, setCurrentVariation] = useState<Variation>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();


	useEffect(() => {
		if (!isLoading && product?.slug) {
			setCurrentVariation(product && product?.variations[0]);
		}
	}, [isLoading, product]);

	function SuccessPopup() {
		Swal.fire({
			icon: "success",
			iconColor: Color.success,
			title: "Produit ajouté au panier avec succès !",
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: "Poursuive",
			cancelButtonText: "Voir le panier",
			confirmButtonColor: Color.themeColor,
			cancelButtonColor: Color.themeColor,
			reverseButtons: true,
		}).then((res) => {
			if (res?.isConfirmed) {
				if (isProfessionnel(user)) {
					navigate("/professionel/catalogue");
				} else {
					navigate("/boutique");
				}
			} else {
				let route = "/panier";
				if (isParticulier(user)) {
					route = "/particulier/panier";
				} else if (isProfessionnel(user)) {
					route = "/professionel/panier";
				}
				navigate(route);
			}
		});
	}

	// Fonction pour ajoutePr un produit au panier
	const handleAddToCart = async (variation?: Variation, produit?: Product) => {
		
		setIsLoadingAddToCard(true)
		if (user?.slug) {
			const res = await addToCart({
				quantite: productNumber,
				variation: variation?.id as number,
			});
			if ("data" in res) {
				SuccessPopup();
			} 
			else if ("error" in res) {
				const err = res?.error as any;
	
				Swal.fire({
					icon: "error",
					title: err?.data?.message ? err?.data?.message : "Une erreur est survenue",
					showConfirmButton: false,
					timer: 5000,
				});
			}
			setIsLoadingAddToCard(false)
		} else {
			let itemProduit = {
				produit: variation?.id,
				prix_total:
					variation?.prix && (parseInt(variation?.prix) * productNumber ),
				quantite: productNumber,
				variation: {...variation,item:{id:produit?.id, slug:produit?.slug, nom:produit?.nom}},
			};
			if ((variation?.stock ? variation?.stock : product?.variations[0]?.stock) == productNumber) {
	
				Swal.fire({
					icon: "error",
					title: "Le produit est en rupture de stock",
					showConfirmButton: false,
					timer: 5000,
				});
			}
			
			//@ts-ignore
			dispatch(addCartProduct(itemProduit));
			SuccessPopup();
			setIsLoadingAddToCard(false)
			dispatch(getCartCount());
			dispatch(calculateTax());
			dispatch(getTotalAmount());
		}
	};

	const handleChangeCurrentVariation = (variation: Variation) => {
		// console.log("hello");
		setCurrentVariation(variation);
	};

	const handleRate = (value: number) => {
		// console.log({ value });
		const data: RateProductData = {
			rating: value,
			user: user?.id,
			produit: currentVariation ? currentVariation?.id : 1,
		};
		rateProduct(data);
	};

	return {
		product,
		productNumber,
		setProductNumber,
		handleAddToCart,
		isLoadingAddToCard,
		variation: currentVariation,
		handleChangeCurrentVariation,
		user,
		handleRate,
	};
}

export function useToggleFavoriteProduct(
	produit: Product,
	isFavorite?: boolean
) {
	const { user } = useAppSelector((s) => s?.user);
	const [addFavorite] = useAddFavoriteProductMutation();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const toggleFavorite = async () => {
		if (user) {
			const data: TFavoriteProduct = {
				user: user?.id,
				produit: produit?.id,
			};
			const res = await addFavorite(data);
			if ("data" in res) {
				Swal.fire({
					icon: "success",
					title:
						isFavorite || produit?.is_favorite
							? "Supprimé des favoris avec succès"
							: "Ajouté aux favoris avec succès",
					iconColor: Color.success,
					showConfirmButton: !(
						isFavorite || produit?.is_favorite
					),
					showCancelButton: !(
						isFavorite || produit?.is_favorite
					),
					cancelButtonText: "Poursuive",
					confirmButtonText: "Voir mes favoris",
					confirmButtonColor: Color.themeColor,
					cancelButtonColor: Color.themeColor,
					timer:
						isFavorite || produit?.is_favorite
							? 1000
							: 10000,
				}).then((result) => {
					let route = '#'
					if (result.isConfirmed) {
						if (isParticulier(user)) {
							route = "/particulier/mon-compte";
						}
						navigate(route, { state: { active: 4 } });;
					} 
					
				});
			} else if ("error" in res) {
				let err = res.error as QueryError;
				Swal.fire({
					icon: "success",
					title: err?.data?.message ?? "error inconnue",
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1000,
				});
			}
		} else {
			Swal.fire({
				icon: "info",
				title: "Vous devez être connecté pour pouvoir effectuer cette action",
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "Se connecter",
				cancelButtonText: "Fermer",
				confirmButtonColor: Color.success,
				timer: 5000,
			}).then((result) => {
				if (result?.isConfirmed) {
					navigate("/connexion", {
						state: { from: pathname },
					});
				}
			});
		}
	};

	return {
		toggleFavorite,
		navigate,
	};
}
