export const ContenuPanierAdminData = [
	{
		id: 1,
		produit: "Jus de cajou",
		taille: "1L",
		prix: "$30.00",
		quantite: "3",
		total: "$90.00",
	},
	{
		id: 2,
		produit: "Jus de cajou",
		taille: "1L",
		prix: "$30.00",
		quantite: "3",
		total: "$90.00",
	},
];
