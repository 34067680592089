import React from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatCurrency, getImage } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";


function DetailsCommandTable({ data, isLoading }) {
	const { t } = useTranslation();

	const nameFormatter = (cell: string, row: any) => {
		console.log('row',row)
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getImage(row?.variation?.image)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">
					{row?.variation?.item?.nom}
				</p>
			</div>
		);
	};
	const prixFormatter = (cell: string, row: any) => {
		return (
			<div>{formatCurrency(row?.prix_unitaire)}</div>
			
		);
	};

	const columns = [
		{
			dataField: "product",
			text: t("product"),
			formatter: nameFormatter,
			headerStyle: () => {
				return { width: "200px", whiteSpace: "normal" };
			},
		},
		{
			dataField: "variation.taille",
			text: "Format",
			// formatter: tailleFormatter,
		},
		{
			dataField: "prix_unitaire",
			text: t("price_per_unit"),
			formatter: prixFormatter,
		},
		{
			dataField: "quantite",
			text: t("quantity"),
		},
		{
			dataField: "prix",
			text: t("amount"),
			formatter: (cell: string, row: any) =>
				formatCurrency(cell),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data}
						columns={columns}
						condensed
						hover
						wrapperClasses="table-responsive admin-table details-commande-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
				</>
			)}
		</>
	);
}

export default DetailsCommandTable;
