import React, {useState,useEffect} from "react";
import "./CommandProfessionnel.css";
import {
	formatCurrency,
	getLabelOrderStatus,
	getName,
	useLocationState,
} from "../../../utils/Utils";
import DetailsCommandTable from "./tables/DetailsCommandTable";
import { useLocation } from "react-router-dom";
import { Order } from "../../../utils/api/order/order.type";
import { useAddOrEditOrderMutation } from "../../../utils/api/order/order.api";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import moment from "moment";
import { useTranslation } from "react-i18next";


const DetailsCommand = () => {
	const { t } = useTranslation();

	const tabs = [t('orders'), t("cart_on_hold")];

	let location = useLocation();
	const commande = useLocationState<Order>(undefined);
	const [item, setItem] = useState<any>(useLocationState<Order>(undefined));
	// useEffect(() => {
	// 	setItem(commande);
	// }, [commande]);

	const [changeStatus, { isLoading }] = useAddOrEditOrderMutation();


	const onChangeStatus = async () => {
		
		const res = await changeStatus({
			slug: item?.slug,
			data: {
				statut: 'livree',
			},
		});
		if ("data" in res) {
			setItem(res?.data);
			Swal.fire({
				icon: "success",
				title: `Status changée avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		} else if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			if (
				err?.data?.motif &&
				err?.data?.motif[0]?.includes(
					"This field may not be blank."
				)
			) {
				return Swal.fire({
					icon: "error",
					title: `Motif est un champ obligatoire`,
					showConfirmButton: false,
					timer: 2000,
				});
			}
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};

	console.log('item',item)
	console.log('comm',commande)

	return (
		<>
			<div className="dashbord-admin-component colgate_wrapper p-3 mb-3">
				<div className="dash-admin-page-content-container">
					<div className="d-flex top_title__container pb-4">
						<h2 className="chart-item-admin-title mb-0">
							{t('orders')}
						</h2>
						{/* {location?.pathname?.startsWith(
							"/professionel/commandes"
						) ? (
							<div className="right-tabs-content mt-3">
								<ul className="tabs-container">
									{tabs.map((tab, key) => (
										<li
											role="button"
											className={`
                                    tab-item 
                                    ${0 === key ? "tab-item-active" : ""}
                                `}
										>
											{tab}
										</li>
									))}
								</ul>
							</div>
						) : null} */}
					</div>
					<div className="command_infos d-flex">
						<div className="command_infos_content">
							<p className="m-0 pb-2 command_id">
								{t('order')} #{item?.code_commande}
							</p>
							<p className="m-0 command_date">
								{moment(
									item?.created_at
								)?.format(
									"DD MMMM YYYY, HH:mm"
								)}
							</p>
							<div className="d-flex align-items-center gap-4 pt-3 command_status">
								<p className="d-flex align-items-center gap-2 command_status_payment">
									<span>
										{t('payment_status')}:
									</span>
									<span className="csd_color__wrapper">
										{item?.paid
											? t("paid")
											: t("not_yet_paid")}
									</span>
								</p>
								<p className="d-flex align-items-center gap-3 command_status_payment">
									<span>
										{t("delivery_status")}:
									</span>
									<span className="csd_color__wrapper bg_warn">
										{getLabelOrderStatus(
											item?.statut
										)}
									</span>
								</p>
							</div>
						</div>
						{(item?.statut === "en_cours" ||
							item?.statut === "confirmee") && (
							<div className="btn-content">
								<button
									role="button"
									className="btn btn-theme-admin"
									onClick={()=>onChangeStatus()}
								>
									{t("nark_as_delivered")}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="custom-content">
				<div className="row row-content">
					<div className="col-md-4 mb-3">
						<div className="colgate-card colgate_wrapper p-3">
							<p className="m-0 card__title pb-4">
								{t("billing_details")}
							</p>
							<p className="m-0 sm__value pb-3 fw-bold">
								{getName(item?.user)}
							</p>
							<p className="m-0 sm__value pb-3">
								{item?.adresse}
							</p>
							<p className="m-0 sm__value pb-2">
								<span>Email:</span>&nbsp;
								<span className="csd_color">
									{item?.user?.email}
								</span>
							</p>
							<p className="m-0 sm__value pb-2">
								<span>{t("phone")}:</span>&nbsp;
								<span className="csd_color">
									+{item?.user?.telephone}
								</span>
							</p>
						</div>
					</div>
					<div className="col-md-4 mb-3">
						<div className="colgate-card colgate_wrapper p-3">
							<p className="m-0 card__title pb-4">
								{t('delivery_address')}
							</p>
							<p className="m-0 sm__value pb-3 fw-bold">
								{getName(item?.user)}
							</p>
							<p className="m-0 sm__value pb-2">
								{item?.user?.adresse}
							</p>
						</div>
					</div>
					<div className="col-md-4 mb-3">
						<div className="colgate-card colgate_wrapper p-3">
							<p className="m-0 card__title pb-4">
								{t('method_payment')}
							</p>
							<p className="m-0 sm__value pb-2 payment_method">
								{/* <img src={paypal} alt="mode" /> */}
                			{item?.moyen_paiement}
							</p>
							<p className="m-0 sm__value pb-2">
								{item?.user?.email}
							</p>
						</div>
					</div>
					<div className="col-md-8 mb-3">
						<div className="colgate-card colgate_wrapper p-3">
							<p className="m-0 card__title">
								{t('order_details')}
							</p>
							<div className="detail-command-table pt-4">
								<DetailsCommandTable
									data={item?.items}
									isLoading={false}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-4 mb-3">
						<div className="colgate-card colgate_wrapper">
							<div className="card__header p-3">
								<p className="m-0 card__title">
									{t('order_summary')}
								</p>
							</div>
							<div className="separator"></div>
							<div className="card__recap_info p-3">
								<p className="d-flex align-items-center justify-content-between m-0 pb-3">
									<span className="sm__value">
										{t('amount')}
									</span>
									<span className="sm__value">
										{formatCurrency(
											item?.total
										)}
									</span>
								</p>
								<p className="d-flex align-items-center justify-content-between m-0 pb-5">
									<span className="sm__value">
										{t('delivery')}
									</span>
									<span className="sm__value">
										0.00 F CFA
									</span>
								</p>
								<p className="d-flex align-items-center justify-content-between m-0">
									<span className="sm__value fw-bold">
										Total
									</span>
									<span className="sm__value fw-bold">
										{formatCurrency(
											item?.total
										)}
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DetailsCommand;
