import React from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { FaShopify, FaShoppingBasket, FaUserFriends } from "react-icons/fa";
import { AiTwotonePieChart, AiTwotoneSetting, AiFillShopping } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import Logo from "../../../../assets/appImages/logo.svg";
import { BiSolidUser } from "react-icons/bi";
import { useAppSelector } from "../../../../redux/hook";
import { FieldType } from "../../../../utils/constante";
import { useTranslation } from "react-i18next";
import VersionChecker from "../../../../versionChecker/VersionChecker";


function SidebarAdmin() {
	const { t } = useTranslation();

	const location = useLocation();
	const { user } = useAppSelector((s) => s?.user);
	const hasAccess = (type: FieldType) => {
		let isAccess = false;

		if (user?.user_type !== "superadmin" && !user[type]) {
			isAccess = true;
		}

		return isAccess;
	};

	return (
		<div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
			<div className="hoverable mt-3" id="scrollbar">
				<div className="content">
					<div className="move-left-side-menu"></div>
					<div className="container-logo-sidebar-nav">
						<NavLink to="/">
						<img src={Logo} alt="Logo" className="logo-sidebar-nav" />
						</NavLink>
					</div>
					<ul className="nav nav-items-custom flex-column">
						{location?.pathname?.startsWith("/admin") && (
							<>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("dashboard")}
								>
									<Link
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/dashboard"
										data-tooltip-content="Dashboard"
									>
										<span className="icon-container-nav-link-vertival">
											<AiTwotonePieChart />
										</span>
										<span className="hiddenable-navlink-label">{t("dashboard")}</span>
									</Link>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("produits")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/mes-produits"
										data-tooltip-content="Mes produits"
									>
										<span className="icon-container-nav-link-vertival">
											<AiFillShopping />
										</span>
										<span className="hiddenable-navlink-label">
											{t("my_products")}
										</span>
									</NavLink>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("commandes")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/commandes"
										data-tooltip-content="Commandes"
									>
										<span className="icon-container-nav-link-vertival">
											<FaShoppingBasket />
										</span>
										<span className="hiddenable-navlink-label">{t("orders")}</span>
									</NavLink>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("professionnels")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/professionnels"
										data-tooltip-content="Professionnels"
									>
										<span className="icon-container-nav-link-vertival">
											<FaUserFriends />
										</span>
										<span className="hiddenable-navlink-label">
											{t("professionals")}
										</span>
									</NavLink>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("particuliers")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/clients"
										data-tooltip-content="Clients"
									>
										<span className="icon-container-nav-link-vertival">
											<FaUserFriends />
										</span>
										<span className="hiddenable-navlink-label">{t("customers")}</span>
									</NavLink>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("messages")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/messages"
										data-tooltip-content="Messages"
									>
										<span className="icon-container-nav-link-vertival">
											<IoMail />
										</span>
										<span className="hiddenable-navlink-label">Messages</span>
									</NavLink>
								</li>
								{/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/messagerie"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsFillChatDotsFill />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                  </NavLink>
                </li> */}
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/profil"
										data-tooltip-content="Profil"
									>
										<span className="icon-container-nav-link-vertival">
											<BiSolidUser />
										</span>
										<span className="hiddenable-navlink-label">{t("profile")}</span>
									</NavLink>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("parametres")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/parametres"
										data-tooltip-content="Paramètres"
									>
										<span className="icon-container-nav-link-vertival">
											<AiTwotoneSetting />
										</span>
										<span className="hiddenable-navlink-label">{t("settings")}</span>
									</NavLink>
								</li>
								<li
									className="nav-item nav-item-vertical-custom"
									hidden={hasAccess("options")}
								>
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/options"
										data-tooltip-content="Options"
									>
										<span className="icon-container-nav-link-vertival">
											<AiTwotoneSetting />
										</span>
										<span className="hiddenable-navlink-label">Options</span>
									</NavLink>
								</li>
							</>
						)}
						{location?.pathname?.startsWith("/professionel") && (
							<>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/professionel/dashboard"
										data-tooltip-content="Dashboard"
									>
										<span className="icon-container-nav-link-vertival">
											<AiTwotonePieChart />
										</span>
										<span className="hiddenable-navlink-label">{t("dashboard")}</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/professionel/commandes"
										data-tooltip-content="Commandes"
									>
										<span className="icon-container-nav-link-vertival">
											<FaShopify />
										</span>
										<span className="hiddenable-navlink-label">{t("orders")}</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/professionel/catalogue"
										data-tooltip-content="Catalogue"
									>
										<span className="icon-container-nav-link-vertival">
											<FaShopify />
										</span>
										<span className="hiddenable-navlink-label">{t("catalog")}</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/professionel/stock"
										data-tooltip-content="Stock"
									>
										<span className="icon-container-nav-link-vertival">
											<FaShoppingBasket />
										</span>
										<span className="hiddenable-navlink-label">Stock</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/professionel/profil"
										data-tooltip-content="Profil"
									>
										<span className="icon-container-nav-link-vertival">
											<BiSolidUser />
										</span>
										<span className="hiddenable-navlink-label">{t("profile")}</span>
									</NavLink>
								</li>
							</>
						)}
					</ul>
					<div className="version-view-container">{"V" + VersionChecker()}</div>
				</div>
			</div>
		</div>
	);
}

export default SidebarAdmin;
