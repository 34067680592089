import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { Product } from "../../../utils/api/product/product.type";
import { useProduct } from "../../front/boutique/hooks/useProduct";

const CatalogProductCard = ({
	number,
	slug,
	product_image,
	full_name,
	prix_pro,
	prix_promo,
	isInPromotion,
	product,
}: {
	number: number;
	slug: string;
	product_image: string;
	full_name: string;
	prix_pro?: string;
	prix_promo?: string;
	isInPromotion?: boolean;
	product: Product;
}) => {
	const { productNumber, setProductNumber, handleAddToCart , isLoadingAddToCard} = useProduct();

	return (
		<div className="catalog-product-card">
			<div className="image_content position-relative">
				{/* {!isInPromotion && (
					<div className="promotion">
						-{promotion_value}%
					</div>
				)} */}
				<img src={getImage(product_image)} alt="product" />
				<div className="stock_status d-flex align-items-center gap-1">
					<BsCheckCircle size={12} />
					<span>En stock</span>
				</div>
			</div>
			<div className="product-info-wrapper p-2">
				<p className="m-0 product-number">No. &nbsp;{number}</p>
				<NavLink
					to={`/professionel/catalogue/produit/${slug}`}
					className="m-0 sm-name-description pt-1"
					state={product}
				>
					{full_name}
				</NavLink>
				<p className="prices d-flex align-items-center justify-content-between m-0 py-2">
					<span className="current-price">
						{Number(prix_promo) > 0
							? formatCurrency(prix_promo)
							: formatCurrency(prix_pro)}
					</span>
					{!isInPromotion && (
						<span className="old-price">
							{formatCurrency(prix_pro)}
						</span>
					)}
				</p>
				<div className="d-flex align-items-center justify-content-between pt-2 increase_and_cart">
					<div className="custom_number_container d-flex align-items-center gap-2">
						<button
							onClick={() =>
								setProductNumber(
									productNumber - 1
								)
							}
							disabled={productNumber === 1}
						>
							{" "}
							-{" "}
						</button>
						<span>{productNumber}</span>
						<button
							onClick={() =>
								setProductNumber(
									productNumber + 1
								)
							}
							disabled={productNumber === 5}
						>
							{" "}
							+{" "}
						</button>
					</div>
					<button
						disabled={isLoadingAddToCard}
						className="btn btn-add-to-cart"
						onClick={() =>
							handleAddToCart(
								product?.variations[0],
								product
							)
						}
					>
						Ajouter au panier
					</button>
				</div>
			</div>
		</div>
	);
};

export default CatalogProductCard;
