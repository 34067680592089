export const CodeValidationData = [
    {
        id: 1,
        num: 9,
    },
    {
        id: 2,
        num: 1,
    },
    {
        id: 3,
        num: 8,
    },
    {
        id: 4,
        num: 2,
    },
    {
        id: 5,
        num: 7,
    },
    {
        id: 6,
        num: 3,
    },
] 