import React from "react";
import AuthWrapper from "./AuthWrapper";
import { BtnSubmit } from "../common/Button";
import { NavLink } from "react-router-dom";
import { CodeValidationData } from "./data";


function ValidateCode() {
    
    return (
		<AuthWrapper>
			<h2 className="top-title text-center">Validation code</h2>
			<p className="custom-top-description pt-5">
				Entrez ci-dessous le code à 6 chiffres que vous avez
				reçu à l’adresse email@exemple.com
			</p>
			<div className="form pt-3 pb-5">
				<form action="">
					<div className="d-flex align-items-center gap-2 pb-3">
						{!!CodeValidationData &&
							CodeValidationData.map(
								(code, key) => (
									<div
										className="input-wrapper"
										key={key}
									>
										<input
											type="number"
											className="form-control custom-code p-1"
											placeholder={`${code.num}`}
											key={code.id}
										/>
									</div>
								)
							)}
					</div>
					<div className="btn-content pt-3">
						<BtnSubmit
							label="Valider"
							isLoading={false}
							className="btn btn-auth w-100"
						/>
					</div>
					<p className="redirection text-center pt-5">
						<NavLink
							to={`/connexion`}
							className="go-to-page"
						>
							Retour à la page de connexion
						</NavLink>
					</p>
				</form>
			</div>
		</AuthWrapper>
    );
}

export default ValidateCode;


