import React from "react";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { BiLogoFacebook, BiLogoTwitter } from "react-icons/bi";
import Forum from "../../common/Forum";
import { BsArrowRightShort } from "react-icons/bs";
import ReactPlayer from "react-player";
import { createMarkup, getImage } from "../../../utils/Utils";
import { useArticleFromLocation } from "../../../utils/api/article/article.api";
import { Article } from "../../../utils/api/article/article.type";
import { AppBaseUrl } from "../../../utils/http";
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { IoLogoWhatsapp } from "react-icons/io";

export function ShareArticle({}: { item: Article }) {
	const SOCIAL_SHARE_URL = AppBaseUrl + window.location.pathname;
	const quote = `Hello, je trouve cet article très intéressant`;
	return (
		<div className="details-article-section-share-container">
			<div className="details-article-section-share-title">
				Partager l’article:
			</div>
			<div className="details-article-section-share-icon-container">
				<FacebookShareButton
					url={SOCIAL_SHARE_URL}
					hashtag="#casadeliz"
				>
					<BiLogoFacebook className="details-article-section-share-icon" />
				</FacebookShareButton>
				<TwitterShareButton
					url={SOCIAL_SHARE_URL}
					title={quote}
					hashtags={["casadeliz"]}
				>
					<BiLogoTwitter className="details-article-section-share-icon" />
				</TwitterShareButton>
				<WhatsappShareButton
					url={SOCIAL_SHARE_URL}
					title={quote}
				>
					<IoLogoWhatsapp className="details-article-section-share-icon" />
				</WhatsappShareButton>
			</div>
		</div>
	);
}

function DetailsActuVideo() {
	const [item] = useArticleFromLocation();

	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content pb-5">
				<div className="container pt-4">
					<div className="col-md-12">
						<div className="container pt-5">
							<div className="row">
								<div className="col-lg-6 mb-3">
									<div className="details-article-video-titre-container">
										<div className="details-article-video-titre mb-5">
											{item?.titre}
										</div>
										<div
											className="details-actu-content mb-3"
											dangerouslySetInnerHTML={createMarkup(
												item?.content
											)}
										/>
										<button className="btn page-actu-card-item-content-btn no-link">
											Lire la vidéo{" "}
											<BsArrowRightShort />
										</button>
									</div>
								</div>
								<div className="col-lg-6 mb-3">
									<div className="custom-video-article-container">
										<ReactPlayer
											className="custom-video-article mb-3"
											controls
											light={getImage(
												item?.backgound_image
											)}
											url={
												item?.link
											}
											playing={
												false
											}
											playIcon={
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="77"
													height="77"
													viewBox="0 0 77 77"
													fill="none"
												>
													<circle
														cx="38.5"
														cy="38.5"
														r="38.5"
														fill="#ED646E"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M34.385 50.6828L47.681 39.0828C47.8946 38.8954 48.0171 38.625 48.0171 38.3408C48.0171 38.0566 47.8946 37.7862 47.681 37.5988L34.381 25.9288C34.0893 25.6773 33.6779 25.6186 33.3276 25.7785C32.9772 25.9384 32.752 26.2877 32.751 26.6728L32.751 49.9398C32.7511 50.3266 32.9771 50.6774 33.329 50.8373C33.6809 50.9973 34.0938 50.9368 34.3851 50.6827L34.385 50.6828Z"
														fill="white"
													/>
												</svg>
											}
											width="100%"
											height={400}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row custom-section-divider-forum"></div>
						<ShareArticle item={item} />
						<div className="row custom-section-divider-forum"></div>
						<div className="details-article-section-comment-title mb-4">
							Commentaires
						</div>
						<Forum item={item} />
						<div className="row custom-section-divider"></div>
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
}

export default DetailsActuVideo;
