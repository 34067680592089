import React from "react";
import AuthWrapper from "./AuthWrapper";
import { Input } from "../common/Input";
import { BtnSubmit } from "../common/Button";
import { useForgetPassword } from "./hooks/useForgetPassword";

function ForgetPassword() {
	const { register, errors, onSubmit, isLoading } = useForgetPassword();

	return (
		<AuthWrapper>
			<h2 className="top-title text-center">
				Mot de passe oublié?
			</h2>
			<p className="custom-top-description pt-5">
				Entrez l'adresse e-mail que vous avez utilisée lors de
				la création du compte, et nous vous enverrons un code
				pour réinitialiser votre mot de passe
			</p>
			<div className="form pt-3 pb-5">
				<form action="" onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-12 input-wrapper">
							<Input
								label="Email"
								type="email"
								placeholder="Email"
								{...register("email")}
								error={errors?.email}
							/>
						</div>
					</div>
					<div className="btn-content pt-3">
						<BtnSubmit
							label="Envoyer"
							isLoading={isLoading}
							className="btn btn-auth w-100"
						/>
					</div>
				</form>
			</div>
		</AuthWrapper>
	);
}

export default ForgetPassword;