import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import {
	Article,
	CommentArticleData,
} from "../../../utils/api/article/article.type";
import { usePostCommentMutation } from "../../../utils/api/article/article.api";
import { useAppSelector } from "../../../redux/hook";
import { cleannerError } from "../../../utils/Utils";
import { QueryError } from "../../../utils/type";

export function usePostComment(item?: Article) {
	const validationSchema = yup.object().shape({
		content: yup.string().required().label("Commentaire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm<CommentArticleData>({
		resolver: yupResolver(validationSchema),
	});
	const [postComment, { isLoading }] = usePostCommentMutation();
	const { user: authUser } = useAppSelector((s) => s?.user);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: CommentArticleData) => {
		const newData: CommentArticleData = {
			...data,
			forum: item?.id as number,
			created_by: authUser?.id,
		};
		console.log({ data, newData });
		const res = await postComment(newData);
		if ("data" in res) {
			setValue("content", "");
		} else if ("error" in res) {
			const err = res?.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		authUser,
	};
}
