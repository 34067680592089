export enum UserType {
	admin = "admin",
	user = "user",
	particulier = "particulier",
	professionnel = "professionnel",
	superadmin = "superadmin",
	pending = "pending",
}

export type CiviliteType = "homme" | "femme";

export interface User {
	id: string;
	slug: string;
	userType: string;
	avatar: string;
	email: string;
	prenom: string;
	nom: string;
	telephone: string;
	password: string;
	civilite: CiviliteType;
	adresse: string;
	created_at: string;
	user_type: UserType;
	ville: string;
	secteur: string;
	is_active: boolean;
	is_archive: boolean;
	longitude: string;
	latitude: string;
	orders: number;
	orders_canceled: number;
	orders_confirmed: number;
}

export type UserFormData = Partial<User>;

export interface AuthState {
	user: User | any;
	token?: string | null;
}

export type PaginationResults<T> = {
	count?: number;
	next?: string | null;
	previous?: string | null;
	results: T[];
	limit?: number;
	nbPage?: number;
	nbPages?: number;
};

export type TypeQuery = Partial<{
	page?: number;
	limit?: number;
	word?: string | null;
	slug?: string;
	name?: string | null;
	user?: string;
	product?: string;
	productType?: "pack" | "product";
	numOrder?: string;
	search?: string;
	userType?: string;
	read?: boolean;
	q?: string;
	statut?: string;
	categorie?: string | null;
}>;

export type AddressFormData = {
	_id: string;
	prenom: string;
	nom: string;
	adresse: string;
	complementAdresse: string;
	quartier: string;
	ville: string;
	telephone1: string;
	telephone2: string;
	isDefault: boolean;
	latitude: number | string;
	longitude: number | string;
	zone: string;
};
