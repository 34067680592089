import React from 'react'
import Comment from "../../../front/boutique/Comment";
import { Product } from "../../../../utils/api/product/product.type";

function Commentaire({ product }: { product: Product }) {
	return (
		<div className="particulier-details-produit-section-tabs-commentaire">
			<Comment product={product} isShowForm />
		</div>
	);
}

export default Commentaire