import { Catalogue } from './../../../../utils/api/config/config.type';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
	CatalogueFormData,
	UploadFileResult,
} from "../../../../utils/api/config/config.type";
import {
	useAddOrUpdateCatalogueMutation,
	useGetCatalogueQuery,
} from "../../../../utils/api/config/config.api";
import { UPLOAD_FILE_END_POINT } from "../../../../utils/constante";

const itemDefaultValue = {
	titre: "",
	titre_en: "",
	description: "",
	description_en: "",
};

function useCrudCatalogue() {
	const validationSchema = yup.object().shape({
		description: yup
			.string()
			.required()
			.label("Description"),
		description_en: yup.string().label("Description"),
		pourcentage_text: yup
			.string()
			.required()
			.label("Description pourcentage"),
		pourcentage_text_en: yup
			.string()
			.label("Description pourcentage"),
		pourcentage: yup.string().required().label("Pourcentage"),
		catalogue: yup.string().label("Catalogue"),
	});
	const { data: catalogue } = useGetCatalogueQuery();
	const [edit, { isLoading }] = useAddOrUpdateCatalogueMutation();

	const [description, setDescription] = useState<string>();
	const [descriptionEn, setDescriptionEn] = useState<string>();
	const [pourcentageText, setPourcentageText] = useState<string>();
	const [pourcentageTextEn, setPourcentageTextEn] = useState<string>();

	const [dataForm, setDataForm] = useState<CatalogueFormData>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		control,
	} = useForm<CatalogueFormData>({
		resolver: yupResolver(validationSchema)
	});


	useEffect(() => {
		if (catalogue) {
			let fields: (keyof CatalogueFormData)[] = [	
				"description",
				"description_en",
				"pourcentage_text",
				"pourcentage_text_en",
				"pourcentage",
				"catalogue",
			];

			for (let field of fields) {
				register(field);
				setValue(field, catalogue[field]);
			}
			setDescription(catalogue.description);
			setDescriptionEn(catalogue.description_en);
			setPourcentageText(catalogue?.pourcentage_text)
			setPourcentageTextEn(catalogue?.pourcentage_text_en)
		}
	}, [catalogue]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
		console.log({ errors });
	}, [errors]);

	const updateImage = async (image, key) => {
		let result;
		try {
			let fd = new FormData();
			let files = image;
			fd.append("project_name", "casadeliz");
			fd.append("media_type", "document");
			fd.append("media", files);

			const response = await fetch(UPLOAD_FILE_END_POINT, {
				method: "POST",
				body: fd,
			});
			
			if (response.ok) {
				const res: UploadFileResult = await response.json();
				if ("success" in res) {
					setValue("catalogue", res.file_url);
					console.log('response',res.file_url)
				}
			}
		} catch (error) {
			console.log(error);
		}
		
	};

	const handleChangeCatalogue = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			updateImage(file, "catalogue");
		}
	};


	const handleChange = (
		propertyName: keyof CatalogueFormData,
		value: string
	) => {
		setDataForm({ ...dataForm, [propertyName]: value });
		setValue(propertyName, value);
	};
	const handleChangeDescription = (value: string) => {
		setDescription(value);
		setValue("description", value);
	};
	const handleChangeDescriptionEn = (value: string) => {
		setDescriptionEn(value);
		setValue("description_en", value);
	};

	const handleChangePourcentageText = (value: string) => {
		setPourcentageText(value);
		setValue("pourcentage_text", value);
	};
	const handleChangePourcentageTextEn = (value: string) => {
		setPourcentageTextEn(value);
		setValue("pourcentage_text_en", value);
	};


	const onSubmit = async (data: CatalogueFormData) => {

		let result = await edit({data: data });

		if ("data" in result) {
			Swal.fire({
				icon: "success",
				title: "Modifié avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in result) {
			const err = result.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						catalogue ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		dataForm,
		handleChangeCatalogue,
		handleChange,
		catalogue,
		handleChangeDescription,
		handleChangeDescriptionEn,
		description,
		descriptionEn,
		handleChangePourcentageText,
		handleChangePourcentageTextEn,
		pourcentageText,
		pourcentageTextEn,
	};
}

export default useCrudCatalogue;
