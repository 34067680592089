import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Article } from "../../../../utils/api/article/article.type";
import {
	createMarkup,
	formatDate,
	getImage,
	getName,
	showModal,
} from "../../../../utils/Utils";
import { useDeleteArticleMutation } from "../../../../utils/api/article/article.api";
import { useDelete } from "../../../../utils/hooks";
import { IconPause, IconPlay } from "../../../shared/Icons";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ApiBaseUrl } from "../../../../utils/http";
import articleIcon from "../../../../assets/icons/article.svg";
import ViewDocModal from "../../../front/actualite/ViewDocModal";

export function DeleteArticle({ item, go }: { item?: Article; go: any }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteArticleMutation();
	const onDelete = useDelete<Article>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cet article!`,
		successMessage: `Article Supprimé`,
		go: () => go("notre-actu"),
	});
	return (
		<button
			className="option-details-actu-btn-delete"
			onClick={onDelete}
		>
			<RiDeleteBin6Line />
		</button>
	);
}
type Props = {
	go: any;
	article?: Article;
	getArticle: (item?: Article) => void;
};
function OptionDetailsActuAdmin({ go, article, getArticle }: Props) {
	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row">
					<div className="col-md-12">
						<div className="content-graph-admin medsain-page-title-container mb-2">
							<div className="container-display-title-theme">
								<div className="medsain-page-title">
									Détail de l’actu
								</div>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="option-details-actu-title-container mb-4">
								<div className="option-details-actu-title">
									<BsArrowLeft
										onClick={() =>
											go(
												"notre-actu"
											)
										}
										role="button"
									/>{" "}
									{article?.titre}
								</div>
								<div className="option-details-actu-btn-edit-container">
									<button
										className="option-details-actu-btn-edit"
										onClick={() => {
											getArticle(
												article
											);
											go(
												"ajout-actu"
											);
										}}
									>
										<MdEdit />
									</button>
									<DeleteArticle
										item={article}
										go={go}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 mb-4">
									{article?.content_type ===
									"video" ? (
										<ReactPlayer
											className="custom-video-article mb-3"
											controls
											light={getImage(
												article?.backgound_image
											)}
											url={
												article?.link
											}
											playing={
												false
											}
											playIcon={
												<IconPlay />
											}
											width="100%"
											height={400}
										/>
									) : (
										<img
											src={getImage(
												article?.backgound_image
											)}
											alt="image détails actualité"
											className="option-details-actu-img"
										/>
									)}
								</div>
								<div className="col-lg-12 mb-4">
									{article?.content_type ===
										"audio" && (
										<div className="offset-lg-3 col-lg-6 mb-5">
											<div className="custom-video-audio-container position-relative">
												<div className="custom-audio-player-container w-100">
													<AudioPlayer
														src={
															ApiBaseUrl +
															"/" +
															article?.fichier
														}
														showJumpControls={
															false
														}
														showDownloadProgress={
															false
														}
														showFilledVolume={
															false
														}
														customIcons={{
															play: (
																<IconPlay />
															),
															pause: (
																<IconPause />
															),
														}}
													/>
												</div>
											</div>
										</div>
									)}
									<div
										className="option-details"
										dangerouslySetInnerHTML={createMarkup(
											article?.content
										)}
									/>
									{article?.content_type ===
										"link" && (
										<div className="mb-3">
											<a
												href={
													article?.link
												}
											>
												{
													article?.link
												}
											</a>
										</div>
									)}
									{article?.content_type ===
										"document" && (
										<div
											className="mb-3"
											role="button"
											onClick={() => {
												showModal(
													"ViewDocModal"
												);
											}}
										>
											Voir le
											document
											<img
												src={
													articleIcon
												}
												alt="icon article"
											/>
										</div>
									)}
									<div className="option-details-actu-autheur">
										Publié le{" "}
										{formatDate(
											article?.created_at
										)}{" "}
										par{" "}
										{getName(
											article?.author
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ViewDocModal item={article} />
			</div>
		</div>
	);
}

export default OptionDetailsActuAdmin;
