import React from "react";
import Imregister from "../../../../assets/appImages/register.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { BtnSubmit } from "../../../common/Button";
import { AppStorage } from "../../../../utils/storage";

function PanierRegisterModal() {
	let navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<div
			className="modal fade"
			id="PanierRegisterModal"
			aria-labelledby="PanierRegisterModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-sm  modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-body">
						<div className="container-form">
							<div className="panier-modal-pas-de-compte-image-container pt-4">
								<img
									src={Imregister}
									alt="icon pas de compte"
									className="panier-modal-pas-de-compte-image mb-4"
								/>
								<div className="panier-modal-pas-de-compte-title mb-4">
									Pas de compte
								</div>
								<div className="panier-modal-pas-de-compte-text mb-4">
									Connectez-vous d’abord
									pour continuer votre achat
								</div>
							</div>
							<BtnSubmit
								className="btn btn-theme-admin w-100"
								isLoading={false}
								type="submit"
								label={"Se connecter"}
								onClick={() => {
									AppStorage.setItem(
										"from",
										pathname
									);
									navigate("/connexion", {
										state: {
											from: pathname,
										},
									});
								}}
								data-bs-dismiss="modal"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PanierRegisterModal;
