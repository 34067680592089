import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { formatDate, getAvatar, getName } from "../../../../utils/Utils";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { usePagination } from "../../../../utils/hooks";
import { useGetProsListQuery } from "../../../../utils/api/professionnel/professionnel.api";
import { ButtonEditLink, ButtonViewLink } from "../../../common/Button";
import { CustomPagination } from "../../../common/CustomPagination";
import { Professionnel } from "../../../../utils/api/professionnel/professionnel.type";
import { FaLock, FaUnlock } from "react-icons/fa";
import { Color } from "../../../../utils/theme";
import { useToggleArchivePro } from "../hooks/useCrudPro";

function ToggleArchivePro({ item }: { item: Professionnel }) {
	const toggleArchive = useToggleArchivePro(item);

	return (
		<button
			className={`btn btn-action-filter-icon with-tooltip ${
				item?.is_archive ? "tooltip-red" : ""
			}`}
			data-tooltip-content={
				item?.is_archive ? "Débloquer" : "Bloquer"
			}
			onClick={toggleArchive}
			style={{
				color: !item?.is_archive ? Color.vert : Color.danger,
			}}
		>
			{!item?.is_archive ? <FaUnlock /> : <FaLock />}
		</button>
	);
}

function ProfessionnelAdminTable({ q }: { q?: string }) {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetProsListQuery({ limit, page, q });

	const actionFormatter = (cell: string, row: Professionnel) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<ButtonViewLink
							to={row?.slug}
							state={row}
						/>
					</div>
					<div className="container-btn-action-icon-modal">
						<ButtonEditLink
							to={`${row?.slug}/modifier`}
							state={row}
						/>
					</div>

					<div className="container-btn-action-icon-modal">
						<ToggleArchivePro item={row} />
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: Professionnel) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getAvatar(row?.avatar)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">
					{getName(row)}
				</p>
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: `Nom`,
			formatter: nameFormatter,
			headerStyle: () => {
				return { width: "200px", whiteSpace: "normal" };
			},
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "civilite",
			text: "Genre",
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default ProfessionnelAdminTable;
