import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import {
	DemandeFormData,
	ProDashboardData,
	ProFormData,
	Professionnel,
} from "./professionnel.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const ProfessionnelApi = createApi({
	reducerPath: "professionnelApi",
	tagTypes: ["pros", "demandes", "pro", "dashboard"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getProsList: builder.query<
			PaginationResults<Professionnel>,
			TypeQuery
		>({
			providesTags: ["pros"],
			query: (query) => QueryUrl("professionnels/", query),
		}),
		getDemandeList: builder.query<
			PaginationResults<Professionnel>,
			TypeQuery
		>({
			providesTags: ["demandes"],
			query: (query) => QueryUrl("demandes/", query),
		}),
		sendDemande: builder.mutation<Professionnel, DemandeFormData>({
			invalidatesTags: ["demandes"],
			query: (data) => {
				return {
					url: `visiteur/demandes/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editDemande: builder.mutation<
			Professionnel,
			{ slug?: string; data: DemandeFormData }
		>({
			invalidatesTags: ["demandes", "pros"],
			query: ({ slug, data }) => {
				return {
					url: `demandes/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteDemande: builder.mutation<Professionnel, string>({
			query: (slug) => ({
				url: `demandes/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["demandes"],
		}),
		validDemande: builder.mutation<Professionnel, string>({
			query: (slug) => ({
				url: `demande/${slug}/valider/`,
				method: "GET",
			}),
			invalidatesTags: ["demandes"],
		}),
		addOrEditPro: builder.mutation<
			Professionnel,
			{ slug?: string; data: ProFormData }
		>({
			invalidatesTags: ["pros"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `professionnels/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `professionnels/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteProfessionnel: builder.mutation<Professionnel, string>({
			query: (slug) => ({
				url: `professionnels/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["pros"],
		}),
		proBySlug: builder.query<Professionnel, string>({
			query: (id) => `professionnels/${id}`,
			providesTags: ["pro"],
		}),
		getProDashboardData: builder.query<ProDashboardData, string>({
			query: (slug) => ({
				url: `professionnel/${slug}/dashboard/`,
			}),
			providesTags: ["dashboard"],
		}),
	}),
});

export const {
	useSendDemandeMutation,
	useDeleteDemandeMutation,
	useGetDemandeListQuery,
	useGetProsListQuery,
	useAddOrEditProMutation,
	useDeleteProfessionnelMutation,
	useValidDemandeMutation,
	useEditDemandeMutation,
	useProBySlugQuery,
	useLazyProBySlugQuery,
	useGetProDashboardDataQuery,
} = ProfessionnelApi;

export function useProFromLocation(): [Professionnel, boolean, string] {
	const itemState = useLocationState<Professionnel | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] =
		useLazyProBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			// console.log("data", data);
			setItem(data);
		}
	}, [data]);

	return [item as Professionnel, isLoading, slug as string];
}
