import React from 'react'
import ContenuPanierAdminTable from '../ClientTable/ContenuPanierAdminTable'
import { NavLink } from 'react-router-dom'
import { useGetCartByUserQuery } from "../../../../utils/api/product/product.api";
import { formatDateHour } from "../../../../utils/Utils";
import { useRelaunchCustomer } from "../../../professionnel/CommandProfessionnel/tables/useRelaunchCustomer";
import { RelauchCustomerBtn } from "../../../professionnel/CommandProfessionnel/tables/PanierAttenteTable";



function DétailsCommandesPanier({client}) {
    console.log('item',client)

    const { data, isLoading } = useGetCartByUserQuery(client?.slug);

    console.log('data',data)

    const { onRelaunch } = useRelaunchCustomer(data);



  return (
    <div>
        <div className='details-commande-infos-panier-contaier mb-3'>
            <div className='details-commande-infos-panier'>
                <div className='details-commande-infos-panier-id mb-2'>Total : {data?.total}</div>
                <div className='details-commande-infos-panier-date mb-3'>{formatDateHour(data?.created_at)}</div>
                {/* <div className='details-commande-infos-panier-statut-label-container'>
                    <div className='details-commande-infos-panier-statut-label'>Statut :</div>
                    <div className='details-commande-infos-panier-statut-value'>Non validé</div>
                </div> */}
            </div>
            <div className='details-commande-infos-panier-btn-relancer-container'>
                {/* <NavLink to="#"  className='btn details-commande-infos-panier-btn-relancer'>Relancer</NavLink> */}
                {data && <RelauchCustomerBtn order={data} />}
            </div>
        </div>
        <div className="row details-pro-admin-infos-produit-container align-items-center py-2 mb-4">
           <div className='details-commande-infos-panier-title'>Contenu du panier</div>
        </div>
        <div className="row">
            <ContenuPanierAdminTable items={data?.items} isLoading={isLoading}/>
        </div>
    </div>
  )
}

export default DétailsCommandesPanier