import React from 'react';
import PanierAttenteTable from '../tables/PanierAttenteTable';

function PanierAttente() {
  return (
    <>
        <div className="panier-content pt-3">
            <PanierAttenteTable />
        </div>
    </>
  )
}

export default PanierAttente;