import { Accueil } from './../../../../utils/api/config/config.type';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
	AccueilFormData,
	UploadFileResult,
} from "../../../../utils/api/config/config.type";
import {
	useAddOrUpdateAccueilMutation,
	useGetAccueilQuery,
} from "../../../../utils/api/config/config.api";
import { UPLOAD_FILE_END_POINT } from "../../../../utils/constante";

const itemDefaultValue = {
	titre: "",
	titre_en: "",
	description: "",
	description_en: "",
};

function useCrudAccueil() {
	const validationSchema = yup.object().shape({
		titre : yup.string().required().label("Titre(fr)"),
		titre_en : yup.string().required().label("Titre(en)"),
		description: yup
			.string()
			.required()
			.label("Description"),
		description_en: yup.string().label("Description"),
		image: yup.string().required().label("Image"),
	});
	const { data: accueil } = useGetAccueilQuery();
	const [edit, { isLoading }] = useAddOrUpdateAccueilMutation();

	const [description, setDescription] = useState<string>();
	const [descriptionEn, setDescriptionEn] = useState<string>();

	const [dataForm, setDataForm] = useState<AccueilFormData>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		control,
	} = useForm<AccueilFormData>({
		resolver: yupResolver(validationSchema)
	});


	useEffect(() => {
		if (accueil) {
			let fields: (keyof AccueilFormData)[] = [
				"titre",
				"titre_en",	
				"description",
				"description_en",
				"image",
			];

			for (let field of fields) {
				register(field);
				setValue(field, accueil[field]);
			}
			setDescription(accueil.description);
			setDescriptionEn(accueil.description_en);
		}
	}, [accueil]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
		console.log({ errors });
	}, [errors]);

	const updateImage = async (image, key) => {
		let result;
		try {
			let fd = new FormData();
			let files = image;
			fd.append("project_name", "casadeliz");
			fd.append("media_type", "image");
			fd.append("media", files);

			const response = await fetch(UPLOAD_FILE_END_POINT, {
				method: "POST",
				body: fd,
			});
			
			if (response.ok) {
				const res: UploadFileResult = await response.json();
				if ("success" in res) {
					setValue("image", res.file_url);
					console.log('response',res.file_url)
				}
			}
		} catch (error) {
			console.log(error);
		}
		
	};

	const handleChangeImage = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			updateImage(file, "accueil");
		}
	};


	const handleChange = (
		propertyName: keyof AccueilFormData,
		value: string
	) => {
		setDataForm({ ...dataForm, [propertyName]: value });
		setValue(propertyName, value);
	};
	const handleChangeDescription = (value: string) => {
		setDescription(value);
		setValue("description", value);
	};
	const handleChangeDescriptionEn = (value: string) => {
		setDescriptionEn(value);
		setValue("description_en", value);
	};



	const onSubmit = async (data: AccueilFormData) => {

		let result = await edit({data: data });

		if ("data" in result) {
			Swal.fire({
				icon: "success",
				title: "Modifié avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in result) {
			const err = result.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						accueil ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		dataForm,
		handleChangeImage,
		handleChange,
		accueil,
		handleChangeDescription,
		handleChangeDescriptionEn,
		description,
		descriptionEn,
	};
}

export default useCrudAccueil;
