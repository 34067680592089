import React from "react";
import { NavLink } from "react-router-dom";
import Banner from "../../assets/appImages/img-banner.png";
import { FaShoppingCart } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {
	useGetAccueilQuery,
} from "../../utils/api/config/config.api";
import { createMarkup,deleteBaliseHtml } from "../../utils/Utils";


function BannerHomepage() {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { data } = useGetAccueilQuery();
	

	return (
		<section className="section-banner-homepage">
			<div className="row align-items-center">
				<div className="col-md-6 col-left-banner-accueil mb-3">
					<div className="content-col-left-banner-accueil">
						<h2 className="title-home-page">
							{locale == 'fr' ? data?.titre : data?.titre_en}

							{/* Et la vie a meilleur <br /> goût avec{" "}
							<span style={{ color: "#ED646E" }}>
								Casadeliz
							</span> */}
						</h2>
						<div className="content-text-home-page-banner my-5">
							<p
								className="text-home-page-banner"
							>
							{data?.description ? deleteBaliseHtml(locale == 'fr' ? data?.description : data?.description_en) : "Plus qu’une boisson aux goûts et saveurs authentiques d’Afrique, Casadeliz est l’innovation au service d’un avenir durable et équitable."}
							</p>
						</div>
						<div className="d-flex gap-4">
							<div className="container-btn-banner-accueil">
								<NavLink
									className="btn btn-theme"
									to="/boutique"
								>
									<span>
										{t('order')} <FaShoppingCart />
									</span>
								</NavLink>
							</div>
							<div className="container-btn-banner-accueil">
								<NavLink to="/contact" className="btn btn-title-home-page">
									{t('call')}
									<BsFillTelephoneFill />
								</NavLink>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-right-banner-accueil mb-3">
					<div className="content-col-right-banner-accueil custom-content-col-right-banner-accueil">
						<div className="container-img-col-right-banner-accueil">
							<img
								src={data?.image ? data?.image : Banner}
								alt="Banniere"
								className="img-col-right-banner-accueil"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default BannerHomepage;
