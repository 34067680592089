import React from "react";
import { Input } from "../../../common/Input";
import { SelectInput } from "../../../common/SelectInput";
import ReactQuill from "react-quill";
import { useCrudArticle } from "../hooks/useCrudArticle";
import { getImage } from "../../../../utils/Utils";
const listeType = [
	{
		value: "text",
		label: "Texte",
	},
	{
		value: "audio",
		label: "Podcast",
	},
	{
		value: "video",
		label: "Video",
	},
	{
		value: "link",
		label: "Lien",
	},
	{
		value: "document",
		label: "Document",
	},
];

function OptionAjoutActualite({ resetArticle, go, article }) {
	const {
		register,
		onSubmit,
		errors,
		description,
		onChangeDescription,
		descriptionEn,
		onChangeDescriptionEn,
		isLoading,
		type,
		onChangeType,
		handleChangeImageCouverture,
		handleChangeFichier,
		imageCouverture,
	} = useCrudArticle(resetArticle, go, article);

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row">
					<div className="col-md-12">
						<div className="content-graph-admin medsain-page-title-container mb-2">
							<div className="container-display-title-theme">
								<div className="medsain-page-title">
									Ajouter une actualité
								</div>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="form-add-container">
								<form onSubmit={onSubmit}>
									<div className="row row-add-student my-3">
										<div className="col-lg-12 mb-3">
											<Input
												type="file"
												label="Bannière"
												id="banner"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												onChange={
													handleChangeImageCouverture
												}
												error={
													errors?.backgound_image
												}
												accept="image/*"
											/>
											<div className="">
												<img
													src={
														imageCouverture ??
														getImage(
															article?.backgound_image
														)
													}
													style={{
														width: "100px",
														height: "100px",
													}}
													className="img-thumbnail"
												/>
											</div>
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="text"
												label="Titre(Fr)"
												id="titre"
												placeholder="Titre(Fr)"
												required
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													"titre"
												)}
												error={
													errors?.titre
												}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="text"
												label="Titre(En)"
												id="titre"
												placeholder="Titre(En)"
												required
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													"titre_en"
												)}
												error={
													errors?.titre
												}
											/>
										</div>
										<div className="col-md-12 mb-3">
											<SelectInput
												label="Type"
												options={
													listeType
												}
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													"content_type"
												)}
												placeholder="Choisir un type"
												hiddenFirstOption
												error={
													errors?.content_type
												}
												onChange={
													onChangeType
												}
												defaultValue={
													type
												}
											/>
										</div>
										{(type ===
											"document" ||
											type ===
												"audio") && (
											<div className="col-lg-12 mb-3">
												<Input
													type="file"
													label={
														type ===
														"document"
															? "Document"
															: "Fichier audio"
													}
													id="fichier"
													customClassLabel={
														"input-parametre-label-form-add"
													}
													customClassInput="input-parametre-form-add"
													error={
														errors?.fichier
													}
													onChange={
														handleChangeFichier
													}
													accept={
														type ===
														"document"
															? "application/pdf"
															: "audio/*"
													}
												/>
											</div>
										)}
										{(type ===
											"video" ||
											type ===
												"link") && (
											<div className="col-lg-12 mb-3">
												<Input
													type="url"
													label={`Lien ${
														type ===
														"video"
															? "de la vidéo"
															: ""
													}`}
													id="lien"
													customClassLabel={
														"input-parametre-label-form-add"
													}
													customClassInput="input-parametre-form-add"
													{...register(
														"link"
													)}
													error={
														errors?.link
													}
												/>
											</div>
										)}
										<div className="col-lg-12 mb-3">
											<label
												htmlFor="description_fr"
												className="form-label input-produit-label-form-add"
											>
												Contenu(Fr)<span className="text-danger" style={{ fontSize: 15 }}>*</span>
											</label>
											<ReactQuill
												className="produit-editor-cgu"
												theme="snow"
												value={
													description
												}
												onChange={
													onChangeDescription
												}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<label
												htmlFor="description_fr"
												className="form-label input-produit-label-form-add"
											>
												Contenu(En)<span className="text-danger" style={{ fontSize: 15 }}>*</span>
											</label>
											<ReactQuill
												className="produit-editor-cgu"
												theme="snow"
												value={
													descriptionEn
												}
												onChange={
													onChangeDescriptionEn
												}
											/>
										</div>

										<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
											<button
												className="btn btn-cancel-admin"
												type="reset"
												onClick={() =>
													go(
														"notre-actu"
													)
												}
											>
												Annuler
											</button>
											<button
												className="btn btn-theme-admin w-100"
												type="submit"
												disabled={
													isLoading
												}
											>
												{isLoading ? (
													<>
														<span
															className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
															role="status"
														></span>
														<span>
															Enrégistrement...
														</span>
													</>
												) : (
													<>
														{article
															? "Modifier"
															: "Ajouter"}
													</>
												)}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OptionAjoutActualite;
