import React from "react";
import "./CommandProfessionnel.css";
import { MdOutlineCancel, MdArrowBack, MdOutlineAddShoppingCart } from "react-icons/md";
import { NavLink } from "react-router-dom";


const tabs = [
    "Commandes",
    "Panier en attente",
]
  

const DetailsPanier = () => {

  return (
		<>
			<div className="dashbord-admin-component colgate_wrapper p-3 mb-3">
				<div className="dash-admin-page-content-container">
					<div className="d-flex top_title__container pb-4">
						<h2 className="chart-item-admin-title mb-0">
							Commandes
						</h2>
						<div className="right-tabs-content mt-3">
							<ul className="tabs-container">
								{tabs.map((tab, key) => (
									<li
										role="button"
										className={`
                                    tab-item 
                                    ${1 === key ? "tab-item-active" : ""}
                                `}
										// onClick={() => alert("Go")}
									>
										{tab}
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="command_infos d-flex">
						<div className="command_infos_content">
							<p className="m-0 pb-2 command_id">
								Panier #25046
							</p>
							<p className="m-0 command_date">
								11 Juillet 2023, 14:26
							</p>
							<p className="d-flex align-items-center gap-2 command_status pt-3">
								<span>Statut:</span>
								<span className="csd_color__wrapper">
									Non validé
								</span>
							</p>
						</div>
						<div className="btn-content">
							<button
								role="button"
								className="btn btn-theme-admin"
							>
								Vider &nbsp;
								<MdOutlineCancel />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="custom-content">
				<div className="row row-content">
					<div className="col-md-12 mb-3">
						<div className="colgate-card colgate_wrapper p-3">
							<p className="m-0 card__title">
								Contenu du panier
							</p>
							<div className="detail-command-table pt-4">
								{/* <DetailsCommandTable /> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="options-container d-flex align-items-center pt-2">
				<button role="button" className="btn btn-theme-go-back">
					<MdArrowBack />
					&nbsp; Ajouter d’autres produits
				</button>
				<NavLink
					role="button"
					className="btn btn-theme-admin"
					to={`/professionel/commandes/panier/payer`}
				>
					Payer &nbsp;
					<MdOutlineAddShoppingCart />
				</NavLink>
			</div>
		</>
  );
};

export default DetailsPanier;
