import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	Article,
	ArticleFormData,
	CommentArticle,
	CommentArticleData,
	ResponseComment,
	ResponseCommentFormData,
	SimilarArticleResult,
	TFavoriteArticle,
	TLikeArticle,
} from "./article.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const ArticleApi = createApi({
	reducerPath: "articlesApi",
	tagTypes: ["forums", "similarArticles", "forum", "comment"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getArticles: build.query<PaginationResults<Article>, TypeQuery>({
			query: (query) => QueryUrl(`forums/`, query),
			providesTags: ["forums"],
		}),
		getSimilarArticles: build.query<SimilarArticleResult, string>({
			query: (slug) => `visiteur/${slug}/forums/`,
			providesTags: ["forums", "similarArticles"],
		}),
		getArticlesForVisitors: build.query<
			PaginationResults<Article>,
			TypeQuery
		>({
			query: (query) => QueryUrl(`visiteur/forums/`, query),
			providesTags: ["forums"],
		}),
		addOrUpdateArticle: build.mutation<
			Article,
			{ slug?: string; data: ArticleFormData | FormData }
		>({
			invalidatesTags: ["forums"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `forums/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `forums/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		findArticleBySlug: build.query<Article, string>({
			query: (slug) => ({
				url: `visiteur/forums/${slug}`,
				method: "GET",
			}),
			providesTags: ["forum"],
			transformResponse: ({ data, other_items }) => ({
				...data,
				other_items,
			}),
		}),
		deleteArticle: build.mutation<Article, string>({
			query: (slug) => ({
				url: `forums/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["forums"],
		}),
		addFavoriteProject: build.mutation<Article, TFavoriteArticle>({
			query: (data) => ({
				url: "favoris/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["forum"],
		}),
		deleteFavoriteProject: build.mutation<Article, string>({
			query: (slug) => ({
				url: `favoris/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["forum"],
		}),
		postComment: build.mutation<Article, CommentArticleData>({
			query: (data) => {
				return {
					url: `comment/forums/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["forum"],
			transformResponse: ({ data }) => data,
		}),
		deleteCommentNew: build.mutation<Article, string>({
			query: (slug) => ({
				url: `commentaires/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["forum"],
		}),
		postResponseComment: build.mutation<
			Article,
			ResponseCommentFormData
		>({
			query: (data) => {
				return {
					url: `response/comment/forum/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
			invalidatesTags: ["forum"],
		}),
		deleteResponseCommentNew: build.mutation<Article, string>({
			query: (slug) => ({
				url: `reponsecommentaires/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["forum"],
		}),
		getResponsesByComment: build.query<
			PaginationResults<ResponseComment>,
			string
		>({
			query: (slug) => `commentaire/${slug}/reponsecommentaires/`,
			providesTags: ["forum"],
		}),
		getCommentByArticle: build.query<CommentArticle, string>({
			query: (slug) => `comment/forums/${slug}/`,
			providesTags: ["comment"],
		}),
		likeArticle: build.mutation<Article, TLikeArticle>({
			query: (data) => ({
				url: "like/forums/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["forum"],
		}),
		deleteLikeArticle: build.mutation<Article, string>({
			query: (slug) => ({
				url: `like/forums/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["forum"],
		}),
	}),
});

export const {
	useGetArticlesQuery,
	useLazyGetArticlesQuery,
	useDeleteArticleMutation,
	useAddOrUpdateArticleMutation,
	useGetArticlesForVisitorsQuery,
	useGetSimilarArticlesQuery,
	useAddFavoriteProjectMutation,
	useDeleteFavoriteProjectMutation,
	useGetCommentByArticleQuery,
	usePostCommentMutation,
	usePostResponseCommentMutation,
	useLazyFindArticleBySlugQuery,
	useLikeArticleMutation,
	useDeleteLikeArticleMutation,
} = ArticleApi;

export function useArticleFromLocation(): [
	Article,
	boolean,
	string,
	(slug: string) => void
] {
	const itemState = useLocationState<Article | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findById, { data, isError, isLoading }] =
		useLazyFindArticleBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findById(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			console.log("data", data);
			setItem(data);
		}
	}, [data]);

	return [item as Article, isLoading, slug as string, findById];
}
