import { AiOutlinePlusCircle } from "react-icons/ai";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import AddPartnerModal from "../modals/AddPartnerModal";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import { Partner } from "../../../../utils/api/config/config.type";
import { getImage, showModal } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import {
	useDeletePartnerMutation,
	useGetPartnerQuery,
} from "../../../../utils/api/config/config.api";
import { CustomPagination } from "../../../common/CustomPagination";
import VolkenoSkeleton from "volkeno-react-skeleton";

export function DeletePartner({ item }: { item?: Partner }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeletePartnerMutation();
	const onDelete = useDelete<Partner>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `ce partenaire!`,
		successMessage: `Partenaire supprimé`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

function OptionPartenaireAdmin() {
	const { limit, page, onChange } = usePagination(15);
	const { data, isLoading } = useGetPartnerQuery({ limit, page });
	const { item, getItem, resetItem } = useItem<Partner>();

	return (
		<div className="container-fluid">
			<div className="form-add-container">
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="container-display-title-theme">
						<button
							className="btn btn-theme-admin"
							onClick={() =>
								showModal("AddPartnerModal")
							}
						>
							<span>
								Ajouter un partenaire
								<AiOutlinePlusCircle />
							</span>
						</button>
					</div>
				</div>
				<div className="row">
					{!isLoading ? (
						data && data?.results?.length > 0 ? (
							<>
								{data?.results?.map((item) => (
									<div
										className="col-md-6 col-xl-4"
										key={item?.slug}
									>
										<div className="partener-item shadow-sm px-2 py-2">
											<div className="item-c gap-2">
												<div className="img-partner-container">
													<img
														src={getImage(
															item?.logo
														)}
														alt={
															item?.nom ??
															"logo partenaire casadeliz"
														}
														className="partner-img img-thumbnail"
													/>
												</div>
												<h5 className="partner-item__title">
													{
														item?.nom
													}
												</h5>
											</div>
											<div className="partner-item__actions-btn d-flex gap-2">
												<ButtonEdit
													onClick={() => {
														getItem(
															item
														);
														showModal(
															"AddPartnerModal"
														);
													}}
												/>
												<DeletePartner
													item={
														item
													}
												/>
											</div>
										</div>
									</div>
								))}
							</>
						) : (
							<AlertInfo message="Aucune donnée trouvée" />
						)
					) : (
						<>
							{[...Array(6)].map((item) => (
								<div
									className="col-md-6 col-xl-4"
									key={item?.slug}
								>
									<div className="partener-item shadow-sm px-2 py-2">
										<VolkenoSkeleton
											variant="rect"
											height={60}
											width={60}
										/>
										<VolkenoSkeleton
											variant="rect"
											height={10}
											width={30}
										/>
										<VolkenoSkeleton
											variant="rect"
											height={10}
											width={20}
										/>
									</div>
								</div>
							))}
						</>
					)}
					{data && data?.results?.length > limit && (
						<div className="custom-pagination-container">
							<CustomPagination
								nbPages={data?.count}
								page={page}
								onChange={onChange}
								perPage={limit}
							/>
						</div>
					)}
				</div>
			</div>
			<AddPartnerModal item={item} resetItem={resetItem} />
		</div>
	);
}

export default OptionPartenaireAdmin;
