import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Variation } from "../../utils/api/product/product.type";

type Cart = {
	cartItems: CartItemType[];
	totalCount: number;
	tax: number;
	delivery: number;
	subAmount: number;
	totalAmount: number;
};

export type CartItemType = {
	produit: string;
	prix_total: number;
	quantite: number;
	variation: Variation;
};
const initialState: Cart = {
	cartItems: [],
	totalCount: 0,
	delivery: 0,
	tax: 0,
	subAmount: 0,
	totalAmount: 0,
};
const useCartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addCartProduct: (state, action: PayloadAction<CartItemType>) => {
			let cartIndex = state.cartItems.findIndex(
				(item) => item?.produit === action.payload.produit
			);
			if (cartIndex >= 0) {
				state.cartItems[cartIndex].quantite +=
					action?.payload?.quantite;
				state.cartItems[cartIndex].prix_total +=
					action?.payload?.quantite *
					parseInt(action?.payload?.variation?.prix);
				// action?.payload?.variations.forEach(element => {
				//   if(!state.cartItems[cartIndex].variations.includes(element) )
				//   {
				//     state.cartItems[cartIndex].variations.push(element)
				//   }
				// });
			} else {
				let tempProduct = { ...action.payload };
				state.cartItems.push(tempProduct);
			}
		},
		editCartProduct: (state, action: PayloadAction<CartItemType>) => {
			let cartIndex = state.cartItems.findIndex(
				(item) => item?.produit === action.payload.produit
			);
			if (cartIndex >= 0) {
				state.cartItems[cartIndex].quantite = action?.payload?.quantite;
				state.cartItems[cartIndex].prix_total = action?.payload?.quantite * parseInt(action?.payload?.variation?.prix);
			} else {
				let tempProduct = { ...action.payload };
				state.cartItems.push(tempProduct);
			}
		},
		getCartProducts: (state) => {
			return {
				...state,
			};
		},
		getCartCount: (state) => {
			let cartCount = state.cartItems.reduce((total, item) => {
				return item?.quantite + total;
			}, 0);
			state.totalCount = cartCount;
		},
		// getSubTotal: (state) => {
		//   state.subAmount = state.cartItems.reduce((acc, item) => {
		//     return (
		//       acc +
		//       (item?.product?.promote?.price || item?.product?.prix) * item?.quantite
		//     );
		//   }, 0);
		// },
		removeCartItem: (state, action: PayloadAction<CartItemType>) => {
			let index = state.cartItems.findIndex(
				(item) =>
					item?.variation?.id ===
					action.payload?.variation?.id
			);
			if (index !== -1) {
				state.cartItems.splice(index, 1);
				// console.log({index})
			}
		},
		increment: (state, action: PayloadAction<CartItemType>) => {
			let index = state.cartItems.findIndex(
				(item) => item?.produit === action.payload?.produit
			);
			state.cartItems[index].quantite += 1;
			state.cartItems[index].prix_total +=
				parseInt(action?.payload?.variation?.prix);
		},
		decrement: (state, action: PayloadAction<CartItemType>) => {
			let index = state.cartItems.findIndex(
				(item) => item?.produit === action.payload?.produit
			);
			if (state.cartItems[index].quantite <= 0) {
				state.cartItems[index].quantite = 0;
				state.cartItems.splice(index, 1);
			} else {
				state.cartItems[index].quantite -= 1;
				state.cartItems[index].prix_total -=
					parseInt(action?.payload?.variation?.prix);
			}
		},
		calculateTax: (state) => {
			// GST value: 18% => action.payload
			let totalTax = (18 / 100) * state.subAmount;
			state.tax = totalTax;
		},
		getDeliveryAmount: (state, action: PayloadAction<number>) => {
			state.delivery = action.payload;
		},
		getTotalAmount: (state) => {
			let somme = 0;
			state?.cartItems.forEach((element) => {
				somme = somme + element?.prix_total;
			});
			state.totalAmount = somme;
		},
		removeCart: (state) => {
			state = initialState;
			return state;
		},
	},
});

export const {
	addCartProduct,
	editCartProduct,
	getCartProducts,
	removeCartItem,
	getCartCount,
	//getSubTotal,
	increment,
	decrement,
	calculateTax,
	getTotalAmount,
	removeCart,
	getDeliveryAmount,
} = useCartSlice.actions;

export default useCartSlice.reducer;
