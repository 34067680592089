import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import imgProd from "../../../../assets/appImages/imgProduit.svg"
import { ContenuPanierAdminData } from "./ClientAdminData";

function ContenuPanierAdminTable({items,isLoading}) {
  const [pages, setPages] = useState(ContenuPanierAdminData);

    const nameFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex gap-3 align-items-center">
          <div className="content-img-profil-itemt-table">
            <img
              src={imgProd}
              alt="Profil"
              className="img-profil-itemt-table"
            />
          </div>
          <p className="name-profil-patient-table mb-0">{row?.variation?.item?.nom}</p>
        </div>
      );
    };

    const tailleFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex gap-3 align-items-center">
          <p className="name-profil-patient-table mb-0">{row?.variation?.taille}</p>
        </div>
      );
    };

    const prixFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex gap-3 align-items-center">
          <p className="name-profil-patient-table mb-0">{row?.variation?.prix_pro ? row?.variation?.prix_pro : row?.variation?.prix }</p>
        </div>
      );
    };

    const columns = [
      {
        dataField: "produit",
        text: `Produits`,
        formatter: nameFormatter,
        headerStyle: () => {
          return { width: "200px", whiteSpace: "normal" };
        },
      },
      {
        dataField: "taille",
        text: "Taille",
        formatter: tailleFormatter,
      },
      {
        dataField: "prix",
        text: "Prix unitaire",
        formatter: prixFormatter,
      },
      {
        dataField: "quantite",
        text: "Quantité",
      },
      {
        dataField: "prix_total",
        text: "Total",
      },
    ];
    const selectRow = {
      mode: "checkbox",
    };
    return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={items || []}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table details-commande-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
					/>
					<div className="custom-pagination-container">
						{/* <CustomPagination
                  nbPages={SousAdminTableData?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              /> */}
					</div>
				</>
			)}
		</>
    );
}

export default ContenuPanierAdminTable