import React from "react";
import { AlertInfo } from "../../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit } from "react-icons/md";
import TableSkeleton from "../../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import "./Table.css";
import { AiFillEye } from "react-icons/ai";
import { HiTrash } from "react-icons/hi2";
import { Variation } from "../../../../../utils/api/product/product.type";
import { formatCurrency, getImage } from "../../../../../utils/Utils";

function TopVentesTable({
	topSells,
	isLoading,
}: {
	topSells?: Variation[];
	isLoading: boolean;
}) {
	const actionFormatter = () => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<NavLink
							to="/admin/"
							className="btn btn-action-filter-icon with-tooltip"
							data-tooltip-content="Voir"
						>
							<AiFillEye />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/`}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Modifier"
						>
							<MdEdit />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<button
							className="btn with-tooltip btn-action-icon-delete"
							data-tooltip-content="Supprimer"
						>
							<HiTrash />
						</button>
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: Variation) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-praticient-table">
					<img
						src={getImage(row?.image)}
						alt="Profil"
						className="img-profil-produit-vente-table"
					/>
				</div>
				<p className="name-profil-vente-table mb-0">{cell}</p>
			</div>
		);
	};

	const columns = [
		{
			dataField: "taille",
			text: `Produits`,
			formatter: nameFormatter,
			headerStyle: () => {
				return { width: "200px", whiteSpace: "normal" };
			},
		},
		{
			dataField: "prix",
			text: "Prix",
			formatter: (cell) => formatCurrency(cell),
		},
		{
			dataField: "stock",
			text: "Quantité",
		},
		// {
		// 	dataField: "prix",
		// 	text: "Montant",
		// },
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={topSells}
						columns={columns}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
				</>
			)}
		</>
	);
}

export default TopVentesTable;
