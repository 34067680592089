import React from "react";

const FormLabel = (props: {
	label: string;
	required?: boolean;
	customClassLabel?: string;
	id?: string;
}) => {
	const { label, required, customClassLabel, id } = props;

	return (
		<label
			htmlFor={id}
			className={`form-label form-label-modal-custom ${customClassLabel}`}
		>
			{label}
			{required && (
				<span className="text-danger" style={{ fontSize: 15 }}>
					*
				</span>
			)}
		</label>
	);
};

export default FormLabel;
