import React from "react";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { FormError, Input } from "../../../common/Input";
import { useEditVariation } from "../hooks/useCrudProduct";
import { Variation } from "../../../../utils/api/product/product.type";
import FormLabel from "../../../common/Label";
import { BtnSubmit } from "../../../common/Button";
import { getImage } from "../../../../utils/Utils";

const EditVariationModal = ({ item, resetItem }: { item?: Variation; resetItem: () => void }) => {
	const { register, onSubmit, errors, isLoading, reset, handleChangeImage, image } = useEditVariation(item);
	const onClose = () => {
		resetItem();
		reset();
	};
	return (
		<div
			className="modal fade"
			id="EditVariationModal"
			aria-labelledby="EditVariationModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog  modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header py-2 flex-m">
						<h5 className="admin-modal-title text-start">Modifier la variation</h5>
						<CloseModalBtn onClick={onClose} />
					</div>
					<div className="modal-body">
						<div className="container-form">
							<form onSubmit={onSubmit}>
								<div className="itm-center justify-content-between gap-3 mb-3">
									<div>
										<div className="d-flex align-items-center gap-1">
											<input
												className="form-check-input m-0 mb-2"
												type="checkbox"
												id={`disponible`}
												{...register(`disponible`)}
												defaultChecked={true}
											/>
											<FormLabel label="Disponible" id={`disponible`} />
										</div>
										<FormError error={errors?.disponible} />
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 mb-3">
										<Input
											type="file"
											label="Image"
											id={`default_image`}
											placeholder="Image principale"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											error={errors?.image}
											accept="image/*"
											onChange={handleChangeImage}
										/>
										{(image || item?.image) && (
											<img
												src={image ?? getImage(item?.image)}
												style={{
													width: "100px",
													height: "100px",
												}}
												className="img-thumbnail"
											/>
										)}
									</div>
									<div className="col-md-6">
										<Input
											label="Taille"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`taille`)}
											error={errors?.taille}
										/>
									</div>
									<div className="col-md-6">
										<Input
											type="number"
											label="Quantité"
											id="quantite"
											placeholder="Quantité"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`stock`)}
											error={errors?.stock}
										/>
									</div>

									<div className="col-lg-6 mb-3">
										<Input
											type="number"
											label="Prix(F CFA)"
											id="prix"
											placeholder="Prix(F CFA)"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`prix`)}
											error={errors?.prix}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="number"
											label="Marge de pourcentage pour les professionnel"
											id="marge"
											max={100}
											placeholder="Marge de pourcentage pour les professionnel"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`marge_pourcentage_pro`)}
											error={errors?.marge_pourcentage_pro}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="text"
											label="Coupon"
											id="coupon"
											placeholder="Coupon"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`code_promo`)}
											error={errors?.code_promo}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="number"
											label="Pourcentage"
											id="pourcentage"
											max={100}
											placeholder="pourcentage"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`discount_percentage`)}
											error={errors?.discount_percentage}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="date"
											label="Date de début"
											id="date_debut"
											placeholder="Date de début"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`date_debut`)}
											error={errors?.date_debut}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="date"
											label="Date de fin"
											id="date_fin"
											placeholder="Date de fin"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register(`date_fin`)}
											error={errors?.date_fin}
										/>
									</div>
								</div>
								<div className="col-md-3 offset-md-9 auth-submit-btn-container pt-5">
									<BtnSubmit
										className="btn btn-theme-admin w-100"
										isLoading={isLoading}
										type="submit"
										label={item ? "Modifier" : "Ajouter"}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditVariationModal;
