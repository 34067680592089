import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { DeliveryMode, DeliveryModeFormData } from "./deliveryMode.type";

export const DeliveryModeApi = createApi({
  reducerPath: "deliveryModeApi",
  tagTypes: [
    "deliveryMode",
    "deliveryModeList",
    "deliveryModeByVisitor",
    "deliveryModeById",
    "allDeliveryModeList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListDeliveryModeAdmin: builder.query<DeliveryMode[], TypeQuery>({
      providesTags: ["deliveryModeList"],
      query: (query) => QueryUrl("mode-livraison/for-admin", query),
    }),
    getDeliveryModeList: builder.query<DeliveryMode[], TypeQuery>({
      providesTags: ["deliveryMode"],
      query: (query) => QueryUrl("mode-livraison/", query),
    }),
    addOrEditDeliveryMode: builder.mutation<
      DeliveryMode,
      { id?: string; data: DeliveryModeFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            DeliveryModeApi.util.invalidateTags([
              "deliveryModeList",
              "deliveryModeByVisitor",
              "deliveryMode",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `mode-livraison/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `mode-livraison/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteDeliveryMode: builder.mutation<DeliveryMode, string>({
      query: (id) => ({
        url: `mode-livraison/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "deliveryMode",
        "deliveryModeList",
        "deliveryModeByVisitor",
      ],
    }),
    deliveryModeById: builder.query<DeliveryMode | any, string>({
      query: (id) => `mode-livraison/${id}/`,
      providesTags: ["deliveryModeById"],
    }),
  }),
});

export const {
  useAddOrEditDeliveryModeMutation,
  useDeleteDeliveryModeMutation,
  useGetListDeliveryModeAdminQuery,
  useGetDeliveryModeListQuery,
} = DeliveryModeApi;
