import { Article } from "../../../utils/api/article/article.type";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { ApiBaseUrl } from "../../../utils/http";
import { AlertInfo } from "../../common/Alert";
import { useTranslation } from "react-i18next";


function ViewDocModal({ item }: { item?: Article }) {
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { t } = useTranslation();
	return (
		<div
			className="modal fade"
			id="ViewDocModal"
			aria-labelledby="ChangeStatusProfessionelModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ViewDocModal"
						>
							{locale == 'fr' ? item?.titre : item?.titre }
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						{item?.fichier ? (
							<iframe
								height={500}
								width="100%"
								src={ApiBaseUrl + item?.fichier}
							/>
						) : (
							<AlertInfo message="Aucun fichier trouvé" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViewDocModal;
