import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import MyDropzone, { ImageGrid, ImagesView } from "../../common/MyDropzone";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { WithContext as ReactTags } from "react-tag-input";
import { FormError, Input } from "../../common/Input";
import ReactQuill from "react-quill";
import useCrudProduct, { variationDefaultValue } from "./hooks/useCrudProduct";
import { BtnSubmit } from "../../common/Button";
import Select from "react-select";
import FormLabel from "../../common/Label";
import { IoMdCloseCircleOutline } from "react-icons/io";

function AddOrUpdateProduit() {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		description,
		onChangeDescription,
		descriptionEn,
		onChangeDescriptionEn,
		categories,
		handleChangeFiche,
		categorie,
		onChangeCategory,
		fields,
		append,
		remove,
		tags,
		handleAddition,
		handleDelete,
		handleDrag,
		images,
		setImages,
		deleteImage,
		handleChangeImage,
		image,
		product,
	} = useCrudProduct();

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Mes produits" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title mb-4">
						Ajouter un produit
					</div>
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-lg-4">
								<div className="add-produit-custom-subtitle mb-4">
									Détails
								</div>
								<div className="mb-3">
									<MyDropzone
										maxFile={10}
										color={"#ED646E"}
										background={
											"#EEF0F4"
										}
										setImages={
											setImages
										}
									/>
									<FormError
										error={
											errors?.images
										}
									/>
									<div className="add-produit-custom-label-indicator">
										Ajoutez{" "}
										{10 -
											images?.length}{" "}
										photos
										supplémentaires pour
										compléter la galerie
										de vos produits !
									</div>
									{product?.images && (
										<div className="grid-images">
											<ImagesView
												images={
													product?.images
												}
											/>
										</div>
									)}
									<div className="grid-images">
										<ImageGrid
											images={
												images
											}
											deleteFile={
												deleteImage
											}
										/>
									</div>
								</div>
								{/* <div className="add-produit-custom-divider"></div>
								<div className="add-produit-custom-statut-container mb-3">
									<div className="add-produit-custom-statut-label">
										Status
									</div>
									{status ? (
										<div className="add-produit-custom-statut-badge">
											Disponible
										</div>
									) : (
										<div className="add-produit-custom-statut-badge unavailable">
											Indisponible
										</div>
									)}
								</div>
								<div>
									<div className="form-check">
										<input
											className="form-check-input add-produit-custom-form-check-input"
											type="radio"
											name="status"
											id="flexRadioDefault1"
											onChange={
												onChangeStatus
											}
											value="1"
											checked={
												status ===
												true
											}
										/>
										<label
											className="form-check-label add-produit-custom-form-check-label"
											htmlFor="flexRadioDefault1"
										>
											Disponible en
											stock
										</label>
									</div>
									<div className="form-check">
										<input
											className="form-check-input add-produit-custom-form-check-input"
											type="radio"
											name="status"
											id="flexRadioDefault2"
											onChange={
												onChangeStatus
											}
											value="0"
											checked={
												status ===
												false
											}
										/>
										<label
											className="form-check-label add-produit-custom-form-check-label"
											htmlFor="flexRadioDefault2"
										>
											Indisponible
										</label>
									</div>
								</div> */}
								<div className="add-produit-custom-divider" />
								<div className="col-lg-12 mb-3">
									<FormLabel label="Catégories" />
									<Select
										options={categories}
										value={categorie}
										onChange={
											onChangeCategory
										}
										placeholder="Sélectionner une catégorie"
										isClearable
										classNamePrefix="custom-select"
									/>
									<FormError
										error={
											errors?.categorie
										}
									/>
								</div>
								<div className="add-produit-custom-divider" />
								{/* <div className="col-lg-12 mb-3">
									{tailles?.map(
										(item, i) => (
											<div
												className="d-flex align-items-start gap-3"
												key={i}
											>
											
												{i >
													0 && (
													<button
														type="button"
														className="button-remove"
														onClick={() =>
															removeTaille(
																i
															)
														}
													>
														<IoMdCloseCircleOutline
															color="#ED646E"
															fontSize={
																24
															}
														/>
													</button>
												)}
											</div>
										)
									)}
									<button
										className="btn btn-add-autre-taille px-0"
										type="button"
										onClick={() =>
											addTaille([
												"",
											])
										}
									>
										<AiOutlinePlusCircle />{" "}
										Ajouter une autre
										taille
									</button>
								</div> */}
								<div className="col-lg-12 mb-3">
									<label className="form-label input-produit-label-form-add mb-2">
										Tags
									</label>
									<ReactTags
										tags={tags}
										handleDelete={
											handleDelete
										}
										handleAddition={
											handleAddition
										}
										handleDrag={
											handleDrag
										}
										inputFieldPosition="top"
										autocomplete
										placeholder="Entrer au moins 9 tags"
										classNames={{
											tags: "tagsClass",
											tagInput: "tagInputClass",
											tagInputField:
												"tagInputFieldClass",
											selected: "selectedClass",
											tag: "tagClass",
											remove: "removeClass",
										}}
									/>
									<FormError
										error={errors?.tags}
									/>
								</div>
								<div className="col-lg-12 mb-3">
									<input
											className="custom-detail-form-check form-check-input m-0"
											type="checkbox"
											value=""
											id="mise_en_avant"
											{...register("mise_en_avant")}
											/>
									<label
										htmlFor="description_fr"
										className="form-label input-produit-label-form-add m-l-3 p-b-4"
									>
										
									 	<strong>Métre en avant</strong>	 
									</label>
								</div>
								<div className="add-produit-custom-divider" />
								<div>
									<label
										htmlFor="fiche_technique"
										className="add-produit-custom-subtitle mb-4"
									>
										Fiche technique
									</label>
									<div className="col-12 mb-3">
										<Input
											type="file"
											label="Ajouter la fiche technique du produit"
											id="fiche_technique"
											placeholder="Ajouter la fiche technique du produit"
											customClassLabel={
												"input-produit-label-form-add"
											}
											accept="application/pdf"
											customClassInput="input-produit-form-add"
											onChange={
												handleChangeFiche
											}
											error={
												errors.fiche_technique
											}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="add-produit-custom-subtitle mb-4">
									Information générale
								</div>
								<div className="row">
									<div className="col-lg-6 mb-3">
										<Input
											type="text"
											label="Nom du produit(Fr)"
											id="nom_produit"
											placeholder="Nom du produit(Fr)"
											customClassLabel={
												"input-produit-label-form-add"
											}
											customClassInput="input-produit-form-add"
											{...register(
												"nom"
											)}
											error={
												errors.nom
											}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="text"
											label="Nom du produit(En)"
											id="nom_produit"
											placeholder="Nom du produit(En)"
											customClassLabel={
												"input-produit-label-form-add"
											}
											customClassInput="input-produit-form-add"
											{...register(
												"nom_en"
											)}
											error={
												errors.nom_en
											}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<label
											htmlFor="description_fr"
											className="form-label input-produit-label-form-add"
										>
											Description(Fr)
										</label>
										<ReactQuill
											className="produit-editor-cgu"
											theme="snow"
											value={
												description
											}
											onChange={
												onChangeDescription
											}
										/>
										<FormError
											error={
												errors?.description
											}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<label
											htmlFor="description_en"
											className="form-label input-produit-label-form-add"
										>
											Description(En)
										</label>
										<ReactQuill
											className="produit-editor-cgu"
											theme="snow"
											value={
												descriptionEn
											}
											onChange={
												onChangeDescriptionEn
											}
										/>
									</div>
									{/* <div className="add-produit-custom-subtitle mb-4">
										Prix et stock
									</div> */}
									<div className="add-produit-custom-btn-add-variation-container mb-3 mt-4">
										<div className="add-produit-custom-subtitle">
											Variations
										</div>
									</div>
									{fields?.map(
										(item, i) => (
											<div
												key={i}
												className="variation-item"
											>
												<div className="itm-center justify-content-between gap-3 mb-3">
													<div>
														<div className="d-flex align-items-center gap-1">
															<input
																className="form-check-input m-0 mb-2"
																type="checkbox"
																id={`disponible${i}`}
																{...register(
																	`variations.${i}.disponible`
																)}
																defaultChecked={
																	true
																}
															/>
															<FormLabel
																label="Disponible"
																id={`disponible${i}`}
															/>
														</div>
														<FormError
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.disponible
																	?.message
															}
														/>
													</div>
													{i >
														0 && (
														<button
															type="button"
															className="button-remove"
															onClick={() =>
																remove(
																	i
																)
															}
														>
															<IoMdCloseCircleOutline
																color="#ED646E"
																fontSize={
																	24
																}
															/>
														</button>
													)}
												</div>
												<div className="row">
													<div className="col-lg-12 mb-3">
														<Input
															type="file"
															label="Image"
															id={`default_image${i}`}
															placeholder="Image principale"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.image
																	?.message
															}
															accept="image/*"
															onChange={(
																e
															) =>
																handleChangeImage(
																	e,
																	i
																)
															}
														/>
														{/* {(image ||
															product?.default_image) && (
															<img
																src={
																	image ??
																	getImage(
																		product?.default_image
																	)
																}
																style={{
																	width: "100px",
																	height: "100px",
																}}
																className="img-thumbnail"
															/>
														)} */}
													</div>
													<div className="col-md-6">
														<Input
															label="Taille"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.taille`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.taille
																	?.message
															}
														/>
													</div>
													<div className="col-md-6">
														<Input
															type="number"
															label="Quantité"
															id="quantite"
															placeholder="Quantité"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.stock`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.stock
																	?.message
															}
														/>
													</div>

													<div className="col-lg-6 mb-3">
														<Input
															type="number"
															label="Prix(F CFA)"
															id="prix"
															placeholder="Prix(F CFA)"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.prix`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.prix
																	?.message
															}
														/>
													</div>
													<div className="col-lg-6 mb-3">
														<Input
															type="number"
															label="Marge de pourcentage pour les professionnel"
															id="marge"
															placeholder="Marge de pourcentage pour les professionnel"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.marge_pourcentage_pro`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.marge_pourcentage_pro
																	?.message
															}
														/>
													</div>
													<div className="col-lg-6 mb-3">
														<Input
															type="text"
															label="Coupon"
															id="coupon"
															placeholder="Coupon"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.code_promo`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.code_promo
																	?.message
															}
														/>
													</div>
													<div className="col-lg-6 mb-3">
														<Input
															type="date"
															label="Date de debut"
															id="date_debut"
															placeholder="Date de debut"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.date_debut`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.date_debut
																	?.message
															}
														/>
													</div>
													<div className="col-lg-6 mb-3">
														<Input
															type="date"
															label="Date de fin"
															id="date_fin"
															placeholder="Date de fin"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.date_fin`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.date_fin
																	?.message
															}
														/>
													</div>
													<div className="col-md-6">
														<Input
															type="number"
															label="Pourcentage"
															id="pourcentage"
															placeholder="Pourcentage"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.discount_percentage`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.discount_percentage
																	?.message
															}
														/>
													</div>
												</div>
												{/* <div className="col-md-6">
														<Input
															type="text"
															label="Option"
															id="option"
															placeholder="Option"
															customClassLabel={
																"input-produit-label-form-add"
															}
															customClassInput="input-produit-form-add"
															{...register(
																`variations.${i}.option`
															)}
															error={
																errors?.variations &&
																errors
																	?.variations[
																	i
																]
																	?.option
																	?.message
															}
														/>
													</div> */}
												<div className="add-produit-custom-divider" />
											</div>
										)
									)}
									<div className="flex-r">
										<button
											className="btn add-produit-custom-btn-add-variation"
											type="button"
											onClick={() =>
												append(
													variationDefaultValue
												)
											}
										>
											Ajouter une
											autre
											variation{" "}
											<AiOutlinePlusCircle />
										</button>
									</div>

									{/* <div className="add-produit-custom-subtitle mb-4">
										Promo
									</div> */}

									<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
										<button
											className="btn btn-cancel-admin"
											type="button"
										>
											Annuler
										</button>
										<BtnSubmit
											className="btn btn-theme-admin w-100"
											label="Enrégistrer"
											isLoading={
												isLoading
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default AddOrUpdateProduit;
