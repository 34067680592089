import { Article, TLikeArticle } from "../../../utils/api/article/article.type";
import { useAppSelector } from "../../../redux/hook";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import {
	// useDeleteLikeArticleMutation,
	useLikeArticleMutation,
} from "../../../utils/api/article/article.api";
import { useLocation, useNavigate } from "react-router-dom";

export function useToggleLikearticle(article: Article) {
	const { user } = useAppSelector((s) => s?.user);
	const [addLike] = useLikeArticleMutation();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const toggleLike = async () => {
		if (user) {
			const data: TLikeArticle = {
				user: user?.id,
				forum: article?.id,
			};
			const res = await addLike(data);
			if ("data" in res) {
				alert("success");
			} else if ("error" in res) {
				alert("error");
			}
		} else {
			Swal.fire({
				icon: "info",
				title: "Vous devez être connecté pour pouvoir effectuer cette action",
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "Se connecter",
				cancelButtonText: "Fermer",
				confirmButtonColor: Color.success,
				timer: 5000,
			}).then((result) => {
				if (result?.isConfirmed) {
					navigate("/connexion", {
						state: { from: pathname },
					});
				}
			});
		}
	};

	return {
		// isFavorite,
		toggleLike,
		navigate,
	};
}
