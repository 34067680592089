import React from 'react'
import { Input } from '../../../common/Input'

const InfosFacturation = () => {
  return (
    <div className='form'>
        <form action="">
            <div className="row row-form">
                <div className="col-md-6">
                    <Input 
                        label="Noms"
                        type="text"
                        placeholder='Noms'
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Email"
                        type="email"
                        placeholder='Email'
                    />
                </div>
                <div className="col-md-12">
                    <Input 
                        label="Adresse"
                        type="text"
                        placeholder='Adresse'
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Ville"
                        type="text"
                        placeholder='Ville'
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Code postal"
                        type="text"
                        placeholder='Code postal'
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Pays"
                        type="text"
                        placeholder='Pays'
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Téléphone"
                        type="text"
                        placeholder='Téléphone'
                    />
                </div>
                <div className="col-md-8 mt-3">
                    <div className="radio_check_content">
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="flexRadioDefault" 
                                id="flexRadioDefault1" 
                                checked
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Utilisez la même adresse pour la livraison
                            </label>
                        </div>
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" name="flexRadioDefault" 
                                id="flexRadioDefault2"  
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Ajouter une autre adresse de livraison
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  )
}

export default InfosFacturation