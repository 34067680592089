import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./Actualite.css";
import articleIcon from "../../../assets/icons/article.svg";
import { BsArrowRightShort } from "react-icons/bs";
import backgroundImg from "../../../assets/icons/read.svg";
import { NavLink, Link } from "react-router-dom";
import { useGetArticlesForVisitorsQuery } from "../../../utils/api/article/article.api";
import { useItem, usePagination } from "../../../utils/hooks";
import { AlertInfo } from "../../common/Alert";
import moment from "moment";
import { IconAudio, IconHearts, IconLink, IconVideo } from "../../shared/Icons";
import {
  createMarkup,
  getImage,
  getName,
  showModal,
  deleteBaliseHtml
} from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import ViewDocModal from "./ViewDocModal";
import { Article } from "../../../utils/api/article/article.type";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useTranslation } from "react-i18next";

export function ActualiteItem({
  item,
  getItem,
}: {
  item: Article;
  getItem?: (item: Article) => void;
}) {
  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
  const { t } = useTranslation();

  return (
    <div className="page-actu-card-item w-100" id="card-actu-front-item">
      {item?.content_type !== "text" && (
        <>
          <div className="container-type-actu-card-item-front">
            <div className="page-actu-card-item-content-infos-actu-container-content_type-lien mb-3">
              <div className="page-actu-card-item-content-infos-actu-user-container">
                <div className="page-actu-card-item-content-infos-actu-user-type-lien">
                  {item?.content_type === "link"
                    ? t("links")
                    : item?.content_type === "audio"
                    ? "Podcasts"
                    : item?.content_type === "video"
                    ? t("video")
                    : "Article"}
                </div>
                <div className="page-actu-card-item-content-infos-actu-date-content_type-lien">
                  {moment(item?.created_at).format("DD MMM YYYY")}
                </div>
              </div>
              {/* <div className="page-actu-card-item-content-infos-actu-like">
              <IconHearts />
              <span>{item?.likes_count}</span>
            </div> */}
            </div>
            <div className="page-actu-card-item-content-infos-actu-container-content_type-lien-icon-container">
              {item?.content_type === "link" ? (
                <IconLink />
              ) : item?.content_type === "audio" ? (
                <NavLink
                  to={`/actualite-audio/${item?.slug}`}
                  className="no-link"
                  state={item}
                >
                  <span>
                    <IconAudio />
                  </span>
                </NavLink>
              ) : item?.content_type === "video" ? (
                <NavLink
                  to={`/actualite-video/${item?.slug}`}
                  className="no-link"
                  state={item}
                >
                  <span>
                    <IconVideo />
                  </span>
                </NavLink>
              ) : (
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                  }}
                  onClick={() => {
                    getItem && getItem(item);
                    showModal("ViewDocModal");
                  }}
                >
                  <img src={articleIcon} alt="icon article" />
                </button>
              )}
            </div>
          </div>
          <div className="page-actu-card-item-content-infos-actu-like p-2">
            <IconHearts />
            <span>{item?.likes_count}</span>
          </div>
        </>
      )}
      {item?.content_type === "text" && (
        <img
          src={getImage(item?.backgound_image)}
          alt="cover image"
          className="page-actu-card-item-img"
        />
      )}
      <div className="page-actu-card-item-content-container">
        {item?.content_type === "text" && (
          <div className="page-actu-card-item-content-infos-actu-container mb-3">
            <div className="page-actu-card-item-content-infos-actu-user-container">
              <div className="page-actu-card-item-content-infos-actu-user">
                {getName(item?.author)}
              </div>
              <div className="page-actu-card-item-content-infos-actu-date">
                {moment(item?.created_at).format("DD MMM YYYY")}
              </div>
            </div>
            <div className="page-actu-card-item-content-infos-actu-like">
              <IconHearts />
              <span>{item?.likes_count}</span>
            </div>
          </div>
        )}
        <div className={`page-actu-card-item-content-title  mb-3`}>
          {locale == 'fr' ? item?.titre : item?.titre_en }
        </div>
        <div
          className="page-actu-card-item-content-description mb-3"
          dangerouslySetInnerHTML={createMarkup( (locale == 'fr' ? item?.content : item?.content_en )?.slice(0, 120))}
        />

        {item?.content_type === "text" ? (
          <NavLink
            to={`/actualite/${item?.slug}`}
            className="page-actu-card-item-content-btn no-link"
            state={item}
          >
            {t("read_all")} <BsArrowRightShort />
          </NavLink>
        ) : item?.content_type === "link" ? (
          <a
            href={item?.link}
            className="page-actu-card-item-content-link"
            target="_blank"
          >
            {item?.link}
          </a>
        ) : (
          <div className="page-actu-card-item-content-link">{item?.duree}</div>
        )}
      </div>
    </div>
  );
}

export function ActualiteCardItemSkeleton() {
  return (
    <div className="col-md-4 mb-3">
      <div className="section-blog-card-item">
        <div className="section-blog-card-item-img-container">
          <VolkenoSkeleton variant="rect" height={200} width="100%" />
        </div>
        <div className="section-blog-card-item-description-container mb-3">
          <VolkenoSkeleton variant="rect" height={10} width="100%" />
          <VolkenoSkeleton variant="rect" height={10} width="100%" />
        </div>
      </div>
    </div>
  );
}

function Actualite() {
  const style = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 100%), url(${backgroundImg}), lightgray 50% / cover no-repeat`,
  };
  const { limit, page, onChange } = usePagination(12);
  const { data, isLoading } = useGetArticlesForVisitorsQuery({
    limit,
    page,
  });
  const { item, getItem } = useItem<Article>();
  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
  const { t } = useTranslation();

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pb-5">
        <div className="casadeliz-section-hero-container mb-4" style={style}>
          <div className="casadeliz-section-hero-page-title">{t("our_news")}</div>
        </div>
        <div className="container pt-4">
          <div className="row custom-section-divider">
            {!isLoading ? (
              data && data?.results?.length > 0 ? (
                <>
                  {data?.results?.map((item) => (
                    // <div className="col-md-4 mb-4 d-flex" key={item?.slug}>
                    //   <ActualiteItem item={item} getItem={getItem} />
                    // </div>
                    <div className="col-md-4 mb-3" key={item?.slug}>
                      <Link
                        to={
                          item?.content_type === "audio" ? 
                          `/actualite-audio/${item?.slug}`
                          :
                          item?.content_type === "video" ?
                          `/actualite-video/${item?.slug}`
                          :
                          item?.content_type === "link" ?
                          `/actualite/${item?.slug}`
                          :
                          `/actualite/${item?.slug}`
                        }
                        className="section-blog-card-item"
                        state={item}
                      >
                        <div className="section-blog-card-item-img-container">
                          <img
                            src={getImage(item?.backgound_image)}
                            alt="blog"
                            className="section-blog-card-item-img"
                          />
                        </div>
                        <div className="section-blog-card-item-description-container mb-3">
                          <div className="section-blog-card-item-description mb-3">
                          {(item?.content && item?.content_en) ? deleteBaliseHtml(locale == 'fr' ? item?.content?.slice(0, 100) : item?.content_en?.slice(0, 100)) : deleteBaliseHtml(item?.content?.slice(0, 100))}

                          </div>
                          <div className="section-blog-card-item-author">
                            {getName(item?.author)}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <AlertInfo message="Aucune publication trouvée" />
              )
            ) : (
              <>
                {[...Array(12)].map((item, i) => (
                  <ActualiteCardItemSkeleton key={i} />
                ))}
              </>
            )}
          </div>
          {data && data?.results?.length > limit && (
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={onChange}
                perPage={limit}
              />
            </div>
          )}
        </div>
      </div>
      <ViewDocModal item={item} />
      <FrontFooter />
    </div>
  );
}

export default Actualite;
