import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { venteGraphData } from "../../../../utils/api/admin/admin.type";
import { useTranslation } from "react-i18next";
import moment from "moment";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
function Incomming({ ventesGraph }: { ventesGraph?: venteGraphData[] }) {
	const { t } = useTranslation();

	// const options = {
	//   responsive: true,
	//   plugins: {
	//     legend: {
	//       position: "top" as const,
	//       display: false,
	//     },
	//   },
	// };

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
				display: false,
			},
			title: {
				display: false,
				text: "Chart.js Bar Chart",
			},
		},
	};

	const labels = ventesGraph
		? ventesGraph?.map((item) =>
				moment(item?.date).format("MMM").toUpperCase()
		  )
		: [
				"Jan",
				"Fev",
				"Mar",
				"Avr",
				"Mai",
				"Juin",
				"Juil",
				"Aou",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
		  ];

	const data = {
		labels,
		datasets: [
			{
				label: "CFA",
				backgroundColor: "#ED646E",
				data: ventesGraph
					? ventesGraph?.map((item) =>
							Number(item?.sum_montant)
					  )
					: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
		],
	};

	return (
		<div className="chart-item-container">
			<h2 className="chart-item-admin-subtitle mb-4">{t("sales")}</h2>

			<Bar options={options} data={data} />
		</div>
	);
}

export default Incomming;
