import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, User } from "../user/user.type";
import {
	CodeConfirmation,
	ForgetPasswordFormData,
	LoginFormData,
	LoginResult,
	RegisterFormData,
	ResendCode,
	ResetPasswordFormData,
	SocialRegisterFormData,
} from "./auth.type";

export const AuthApi = createApi({
	reducerPath: "auth",
	tagTypes: ["auth", "me"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/auth/`,
	}),
	endpoints: (build) => ({
		me: build.query<User, void>({
			providesTags: ["me"],
			query: () => {
				return "me/";
			},
		}),
		// register user mutation
		registerUser: build.mutation<
			AuthState["user"],
			RegisterFormData | FormData
		>({
			query: (data) => ({
				url: "register/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
			transformResponse: ({ data }) => data,
		}),
		// login user mutation
		loginUser: build.mutation<LoginResult, LoginFormData>({
			query: (data) => ({
				url: "login/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		// request to reset user password mutation
		forgetPassword: build.mutation<User, ForgetPasswordFormData>({
			query: (data) => ({
				url: `request-password-reset/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		//reset user password mutation
		resetUserPassword: build.mutation<User, ResetPasswordFormData>({
			query: (data) => ({
				url: `reset-password/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		// Confirmation code otp
		confirmPhoneOtp: build.mutation<User, CodeConfirmation>({
			query: (data) => ({
				url: `confirm-phone-number/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		resendCode: build.mutation<User, ResendCode>({
			query: (data) => ({
				url: `resend-phone-verification-code/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		loginUserWithSocialMedia: build.mutation<
			LoginResult,
			SocialRegisterFormData
		>({
			query: (data) => ({
				url: "socialregister/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
	}),
});

export const {
	useRegisterUserMutation,
	useLoginUserMutation,
	useForgetPasswordMutation,
	useResetUserPasswordMutation,
	useConfirmPhoneOtpMutation,
	useResendCodeMutation,
	useMeQuery,
	useLazyMeQuery,
	useLoginUserWithSocialMediaMutation,
} = AuthApi;
