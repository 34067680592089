import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useAddOrEditOrderMutation } from "../../../../utils/api/order/order.api";
import { Order, OrderStatut } from "../../../../utils/api/order/order.type";

type OrderStatutData = { statut: OrderStatut };

function ChangeStatusOrderModal({ item }: { item?: Order }) {
	const validationSchema = yup.object().shape({
		statut: yup.string().required().label("Statut"),
	});
	const [changeStatus, { isLoading }] = useAddOrEditOrderMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm<OrderStatutData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: OrderStatutData) => {
		console.log({ data });
		const { statut } = data;
		const res = await changeStatus({
			slug: item?.slug,
			data: {
				statut: statut,
			},
		});
		if ("data" in res) {
			reset();
			closeModal("ChangeStatusOrderModal");
			Swal.fire({
				icon: "success",
				title: `Commande ${
					statut === "annulee"
						? "annulée"
						: statut === "confirmee"
						? "confirmée"
						: statut === "livree"
						? "livrée"
						: "mis en cours"
				} avec  succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		} else if ("error" in res) {
			console.log("error", res.error);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			if (
				err?.data?.motif &&
				err?.data?.motif[0]?.includes(
					"This field may not be blank."
				)
			) {
				return Swal.fire({
					icon: "error",
					title: `Motif est un champ obligatoire`,
					showConfirmButton: false,
					timer: 2000,
				});
			}
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return (
		<div
			className="modal fade"
			id="ChangeStatusOrderModal"
			aria-labelledby="ChangeStatusOrderModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusOrderModal"
						>
							Changer le statut de la commande
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form
							className="w-100"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register(
												"statut"
											)}
										>
											<option value="en_cours">
												En cours
											</option>
											<option value="confirmee">
												Confirmée
											</option>
											<option value="livree">
												Livrée
											</option>
											<option value="annulee">
												Annulée
											</option>
										</select>
										<FormError
											error={
												errors?.statut
											}
										/>
									</div>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
									className="btn btn-theme-admin w-100"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusOrderModal;
