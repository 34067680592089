import React from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { useDelete, usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import {
	useDeleteCartItemMutation,
	useGetCartsQuery,
} from "../../../../utils/api/product/product.api";
import { formatCurrency, formatDate, getName } from "../../../../utils/Utils";
import { User } from "../../../../utils/api/user/user.type";
import { Cart } from "../../../../utils/api/product/product.type";
import { ButtonDelete } from "../../../common/Button";
import { useRelaunchCustomer } from "./useRelaunchCustomer";
import { Order } from "../../../../utils/api/order/order.type";

function DeleteCart({ item }: { item: Cart }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteCartItemMutation();
	const onDelete = useDelete<Cart>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `ce panier?`,
		successMessage: `Le panier a été supprimé`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

export function RelauchCustomerBtn({ order }: { order: Cart }) {
	const { onRelaunch, isLoading } = useRelaunchCustomer(order);

	return (
		<>
			{!isLoading ? (
				<button
					className="btn btn-action-relancer-client"
					onClick={onRelaunch}
				>
					Relancer
				</button>
			) : (
				<button
					className="btn btn-action-relancer-client"
					disabled
				>
					<span
						className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
						role="status"
					></span>
					<span>En cours...</span>
				</button>
			)}
		</>
	);
}

function PanierAttenteTable() {
	const { limit, page, onChange } = usePagination(10);

	const { data, isLoading } = useGetCartsQuery({
		limit,
		page,
	});

	const optionFormatter = (cell: any, row: any) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<DeleteCart item={row} />
					</div>
				</div>
			</>
		);
	};
	const commandNumberFormatter = (cell: string) => {
		return <p className="fw-bold m-0">#{cell}</p>;
	};
	const totalFormatter = (cell: string) => {
		return <p className="fw-bold m-0">{formatCurrency(cell)}</p>;
	};
	const actionFormatter = (cell: User, row: Order) => {
		return <RelauchCustomerBtn order={row} />;
	};

	const columns = [
		{
			dataField: "id",
			text: `N°`,
			formatter: commandNumberFormatter,
		},
		{
			dataField: "user",
			text: `Client`,
			formatter: (cell: User) => getName(cell),
		},
		{
			dataField: "user",
			text: `Email client`,
			formatter: (cell: User) => cell?.email,
		},
		{
			dataField: "user",
			text: `Téléphon client`,
			formatter: (cell: User) => cell?.telephone,
		},
		{
			dataField: "updated_at",
			text: "Date",
			formatter: (cell) => formatDate(cell),
		},
		{
			dataField: "total",
			text: "Total",
			formatter: totalFormatter,
		},
		{
			dataField: "action",
			text: "Actions",
			formatter: actionFormatter,
		},
		// {
		// 	dataField: "id",
		// 	text: "Options",
		// 	formatter: optionFormatter,
		// },
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default PanierAttenteTable;
