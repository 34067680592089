import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { QueryError } from "../../../utils/type";
import { cleannerError } from "../../../utils/Utils";
export function useForgetPassword() {
	const [forgetPassword, { error, isSuccess, isLoading, originalArgs }] =
		useForgetPasswordMutation();
	const navigate = useNavigate();
	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup.string().email().required().label("Email"),
			}),
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
	} = useForm<ForgetPasswordFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (error) {
			const err = error as QueryError;
			const { message = "Une erreur a survenue lors de l'envoi" } =
				err.data || {};
			setError("email", { message: message });
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Un mail vous a été envoyé, vérifiez s'il vous plaît!",
				timer: 4000,
			}).then(() =>
				navigate("/reset-password", {
					replace: true,
					state: { email: originalArgs?.email },
				})
			);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = (data: ForgetPasswordFormData) => {
		forgetPassword(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		clearErrors,
		isLoading,
	};
}
