import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import file_cover from "../../../../assets/appImages/fiche.png";
import { ApiBaseUrl } from "../../../../utils/http";

function Documents({ product }) {
	return (
		<div className="custom-content">
			<div className="row pt-5 all-docs-row">
				<div className="col-md-4 custom-wrapper">
					<a
						target="_blank"
						href={`${
							ApiBaseUrl + product?.fiche_technique
						}`}
						className="document-card"
					>
						<picture className="cover-wrapper position-relative">
							<img src={file_cover} alt="cover" />
						</picture>
						<div className="title_content">
							<span>
								<FaRegFilePdf
									color="#DD2025"
									size={14}
								/>
							</span>
							<span className="sm_title">
								Fiche technique
							</span>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Documents;
