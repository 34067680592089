import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { ChangePasswordData } from "../../../../utils/api/auth/auth.type";
import { useChangePasswordMutation } from "../../../../utils/api/user/user.api";

import { Color } from "../../../../utils/theme";
import { cleannerError, validatePassword } from "../../../../utils/Utils";

function UseEditPassordForm() {
	const [editPassword, { error, isSuccess, isLoading, data }] = useChangePasswordMutation();

	const [haveMinUppercase, setHaveMinUppercase] = useState(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState(false);
	const [haveMinNumber, setHaveMinNumber] = useState(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				old_password: yup.string().required().label("Le mot de passe actuel"),
				new_password: validatePassword(yup),
				confirm_password: yup
					.string()
					.oneOf([yup.ref("new_password"), ""], "Les mots de passe doivent correspondre")
					.required()
					.label("La confirmation du mot de passe"),
			}),
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
		setValue,
		reset,
	} = useForm<ChangePasswordData>({
		resolver: yupResolver(validationSchema),
	});

	const countUppercase = (str: string) => {
		if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str: string) => {
		if (str.replace(/[^a-z]/g, "")?.length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str: string) => {
		if (str.replace(/[^0-9]/g, "")?.length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str: string) => {
		if (str?.length >= 8) {
			return true;
		}
		return false;
	};

	const countSpecial = (str: string) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str?.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		// return count;
		if (count >= 1) {
			return true;
		}
		return false;
	};

	const passwordHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
		var password = e.target.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}

		setValue("new_password", e.target.value);
	};

	const passwordHandleReset = () => {
		reset();
		setHaveMinUppercase(false);
		setHaveMinLowercase(false);
		setHaveMinNumber(false);
		setHaveMinCharacter(false);
		setHaveMinSpecialCharacter(false);
	};
	useEffect(() => {
		if (error) {
			console.log("error", error);
			const err = error as any;
			const { message = "Une erreur a survenue" } = err || {};
			if (err?.data?.old_password[0]?.includes("Wrong password")) {
				return setError("old_password", { message: "Mot de passe incorrect" });
			}

			setError("new_password", { message: message });
			console.log("mess err", message);
		}
	}, [error]);

	useEffect(() => {
		console.log("data", data);
		if (isSuccess) {
			passwordHandleReset();
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Mot de passe modifié avec succès !",
				showConfirmButton: false,
				timer: 3000,
			});
		} else {
			setError("new_password", { message: data?.message });
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: ChangePasswordData) => {
		// console.log("data", data);

		await editPassword(data);
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		clearErrors,
		isLoading,
		reset,
		passwordHandleChange,
		haveMinCharacter,
		haveMinLowercase,
		haveMinNumber,
		haveMinSpecialCharacter,
		haveMinUppercase,
		passwordHandleReset,
	};
}

export default UseEditPassordForm;
