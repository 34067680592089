import { formatCurrency, formatDate, getImage, showModal } from "../../../../utils/Utils";
import { Variation } from "../../../../utils/api/product/product.type";
import { AlertInfo } from "../../../common/Alert";
import { ButtonEdit } from "../../../common/Button";
import TableSkeleton from "../../../common/TableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import EditVariationModal from "./EditVariationModal";
import { useItem } from "../../../../utils/hooks";

function DetailsProduitAdminTable({ variations, isLoading, isEdit }) {
	const { getItem, item, resetItem } = useItem<Variation>();
	const tailleFormatter = (cell: string, row: Variation) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img src={getImage(row?.image)} alt="Profil" className="img-profil-itemt-table" />
				</div>
				<p className="name-profil-patient-table mb-0">{cell}</p>
			</div>
		);
	};
	const statutFormatter = (cell: boolean) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				{cell === true ? (
					<span className="badge rounded-pill custom-statut-active">Disponible</span>
				) : (
					<span className="badge rounded-pill bg-secondary">Indisponible</span>
				)}
			</div>
		);
	};
	const actionFormatter = (cell: string, row: Variation) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<div className="container-btn-action-icon-modal">
					<ButtonEdit
						onClick={() => {
							getItem(row), showModal("EditVariationModal");
						}}
					/>
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "taille",
			text: `Taille`,
			formatter: tailleFormatter,
		},

		{
			dataField: "stock",
			text: "Quantité",
		},
		{
			dataField: "prix",
			text: "Prix",
			formatter: (cell: string) => formatCurrency(cell),
		},
		{
			dataField: "discount_percentage",
			text: "Pourcentage",
			formatter: (cell: string) => Number(cell) + "%",
		},
		{
			dataField: "marge_pourcentage_pro",
			text: "Marge",
			formatter: (cell: string) => Number(cell) + "%",
		},
		{
			dataField: "prix_pro",
			text: "Prix professionnel",
			formatter: (cell: string) => formatCurrency(cell),
		},
		{
			dataField: "code_promo",
			text: "Coupon",
		},
		{
			dataField: "date_debut",
			text: "Date de début",
			formatter: (cell: string) => cell ? formatDate(cell) : null,
		},
		{
			dataField: "date_fin",
			text: "Date de fin",
			formatter: (cell: string) => cell ? formatDate(cell) : null,
		},
		{
			dataField: "disponible",
			text: "Disponibilité",
			formatter: statutFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
		isEdit && {
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={variations}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
					/>
				</>
			)}
			<EditVariationModal resetItem={resetItem} item={item} />
		</>
	);
}

export default DetailsProduitAdminTable;
