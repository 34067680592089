import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../utils/theme";
import { QueryError } from "../../../utils/type";
import { useAppSelector } from "../../../redux/hook";
import { useSendMessageContactMutation } from "../../../utils/api/contact/contact.api";
import { ContactFormData } from "../../../utils/api/contact/contact.type";
import { cleannerError, getName } from "../../../utils/Utils";

const useContactForm = () => {
	const validationSchema = yup.object().shape({
		nom_complet: yup.string().required().label("Nom"),
		email: yup.string().email().required().label("Email"),
		message: yup.string().required().label("Message"),
		subject: yup.string().required().label("Sujet"),
	});
	const { user: authUser } = useAppSelector((s) => s.user);

	const [sendMessage, { isLoading }] = useSendMessageContactMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<ContactFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (authUser) {
			setValue("email", authUser?.email);
			setValue("nom_complet", getName(authUser));
		}
	}, [authUser]);

	const onsubmit = async (data: ContactFormData) => {
		// console.log(data);
		const newData = { ...data, user: authUser?.id };
		const res = await sendMessage(newData);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succés!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
			});
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${
							err?.status || "inconnue"
					  } est survenue`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		authUser,
	};
};

export default useContactForm;
