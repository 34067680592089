import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
	Condition,
	ConditionFormData,
	conditionType,
} from "../../../../utils/api/config/config.type";
import { useAddOrEditConditionMutation } from "../../../../utils/api/config/config.api";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useNavigate } from "react-router-dom";

function UseAddOrEditConditionForm(content?: Condition) {
	const [text, setText] = React.useState<string>("");
	const [textFr, setTextFr] = React.useState<string>("");

	const validationSchema = yup.object().shape({
		text_fr: yup.string().required("La description est requise."),
		type: yup.string().required(),
	});

	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm<ConditionFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [editData, { isLoading }] = useAddOrEditConditionMutation();
	const navigate = useNavigate();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (content) {
			setText(content?.text_en);
			setTextFr(content?.text_fr);
			setValue("slug", content?.slug);
			setValue("text_fr", content?.text_fr);
			setValue("text_en", content?.text_en);
		}
	}, [content]);

	const handleChangeEn = (value) => {
		setText(value);
		setValue("text_en", value);
	};
	const handleChangeTextFr = (value) => {
		setTextFr(value);
		setValue("text_fr", value);
	};

	const onSubmit = async (data: ConditionFormData) => {
		// console.log(data);
		const res = await editData({ slug: content?.slug, data: data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Modifiés avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
			navigate("/admin/parametres", {
				state: {
					index:
						content?.type === conditionType.cgu ? 1 : 2,
				},
			});
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return {
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		text,
		handleChangeEn,
		handleChangeTextFr,
		textFr,
	};
}

export default UseAddOrEditConditionForm;
