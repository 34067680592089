import React, { useState } from "react";
import { FormError, Input, RequiredText } from "../../../common/Input";
import ReactQuill from "react-quill";
import { AiOutlinePlus } from "react-icons/ai";
import MyDropzone from "../../../common/MyDropzone";
import useCrudContact from "../hooks/useCrudContact";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PreviewImage } from "../../../common/MyDropzone";
import { getImage } from "../../../../utils/Utils";

function OptionContactAdmin() {
  const [pictures, setPictures] = useState<File | null>();

  const {
		register,
		errors,
		isLoading,
		onSubmit,
    handleChangeImage,
    handleChangeDescriptionContact,
		handleChangeDescriptionContactEn,
		handleChangeAdresseDescription,
		handleChangeAdresseDescriptionEn,
		descriptionContact,
		descriptionContactEn,
		adresseDescription,
		adresseDescriptionEn,
    contact
	} = useCrudContact();

  return (
    <div className="container-fluid">
      <div className="form-add-container">
      <form className="mt-4" onSubmit={onSubmit}>
					<div className="row row-add-student my-3">
						
						<section className="row mb-5">
							{/* <div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Notre histoire
								</div>
							</div> */}
              				<div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Background titre"
									id="background_titre"
									placeholder="Background titre"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("background_titre")}
									error={
										errors?.background_titre
									}
									required
								/>
							</div>
              				<div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Background titre"
									id="background_titre_en"
									placeholder="Background titre"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("background_titre_en")}
									error={
										errors?.background_titre_en
									}
									required
								/>
							</div>
							<div className="col-lg-12">
								<Input
									type="file"
									label="Background image"
									id="image"
									required
									onChange={
										handleChangeImage
									}
								/>
								<FormError
									error={
										errors?.background_image
									}
								/>
							</div>
							<div className="col-lg-12 mb-4">
                <PreviewImage
                  source={getImage(
                    contact?.background_image
                  )}
                />
              </div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Titre contact(fr)"
									id="title_contact"
									placeholder="Titre contact(fr)"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("titre_contact")}
									error={
										errors?.titre_contact
									}
									required
								/>
							</div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Titre contact(en)"
									id="title_contact_en"
									placeholder="Titre contact(en)"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("title_contact_en")}
									error={
										errors?.title_contact_en
									}
									required
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_contact"
									className="form-label input-produit-label-form-add"
								>
									Description(Fr)
									<RequiredText />
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={descriptionContact}
									onChange={
										handleChangeDescriptionContact
									}
								/>
								<FormError
									error={
										errors?.description_contact
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_En"
									className="form-label input-produit-label-form-add"
								>
									Description(En)
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={descriptionContactEn}
									onChange={
										handleChangeDescriptionContactEn
									}
								/>
								<FormError
									error={
										errors?.description_contact_en
									}
								/>
							</div>
              <div className="col-lg-12 mb-3">
								<Input
									type="text"
									label="Email"
									id="email"
									placeholder="Email"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("email")}
									error={
										errors?.email
									}
									required
								/>
							</div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Téléphone"
									id="telephone_1"
									placeholder="Téléphone 1"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("telephone_1")}
									error={
										errors?.telephone_1
									}
									required
								/>
							</div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Téléphone"
									id="telephone_2"
									placeholder="Téléphone 2"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("telephone_2")}
									error={
										errors?.telephone_2
									}
									required
								/>
							</div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Titre adresse(fr)"
									id="adresse_titre"
									placeholder="Titre adresse"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("adresse_titre")}
									error={
										errors?.adresse_titre
									}
									required
								/>
							</div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Titre adresse(en)"
									id="adresse_titre_en"
									placeholder="Titre adresse"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("address_titre_en")}
									error={
										errors?.address_titre_en
									}
									required
								/>
							</div>
              <div className="col-lg-12 mb-3">
								<label
									htmlFor="description_contact"
									className="form-label input-produit-label-form-add"
								>
									Description adresse(Fr)
									<RequiredText />
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={adresseDescription}
									onChange={
										handleChangeAdresseDescription
									}
								/>
								<FormError
									error={
										errors?.adresse_description
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_En"
									className="form-label input-produit-label-form-add"
								>
									Description adresse(En)
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={adresseDescriptionEn}
									onChange={
										handleChangeAdresseDescriptionEn
									}
								/>
								<FormError
									error={
										errors?.adresse_description_en
									}
								/>
							</div>
              <div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Adresse"
									id="adresse"
									placeholder="Adresse"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("adresse")}
									error={
										errors?.adresse
									}
									required
								/>
							</div>
							<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5 mb-5 ">
								<button
									className="btn btn-cancel-admin"
									type="button"
								>
									Annuler
								</button>
								<button
									className="btn btn-theme-admin w-100"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<span
												className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
												role="status"
											></span>
											<span>
												Enrégistrement...
											</span>
										</>
									) : (
										"Modifier"
									)}
								</button>
							</div>
						</section>
						
					</div>
				</form>
      </div>
    </div>
  );
}

export default OptionContactAdmin;
