import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
	onSetUserToken,
	onSetUserTokenOnLocalStorage,
} from "../../../redux/slice/User.slice";
import {
	useLoginUserMutation,
	useLoginUserWithSocialMediaMutation,
} from "../../../utils/api/auth/auth.api";
import {
	LoginFormData,
	SocialRegisterFormData,
} from "../../../utils/api/auth/auth.type";
import { UserType } from "../../../utils/api/user/user.type";
import { AppLocalStorage, AppStorage } from "../../../utils/storage";
import { Color } from "../../../utils/theme";
import { useAppDispatch ,useAppSelector} from "../../../redux/hook";
import { cleannerError, convertToLowerExceptFirst } from "../../../utils/Utils";
import { IResolveParams } from "reactjs-social-login";
import { useLazyMeAdminQuery } from "../../../utils/api/admin/admin.api";
import { isParticulier, isProfessionnel } from "../../../routes/routerUtils";
import {
	removeCart
} from "../../../redux/slice/useCartSlice";
import {
	useAddMultipleToCartMutation
} from "../../../utils/api/product/product.api";

function useLogin() {
	const { cartItems, totalAmount } = useAppSelector((state) => state.cart);
	const [addMultipleToCart] = useAddMultipleToCartMutation();

	const validationSchema = yup.object().shape({
		email: yup.string().email().required().label("Email"),
		password: yup.string().required().label("Mot de passe"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<LoginFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();

	const [loginUser, { isLoading }] = useLoginUserMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const sendLocalCartToBackend = async (user) => {
		if (cartItems?.length > 0 && user?.id) {
			const cart = cartItems?.map((item) => {
				return {
					variation: item?.variation?.id,
					quantity: item?.quantite,
				};
			});
			const fd = new FormData();
			fd.append("item_list", JSON.stringify(cart));
			let cartLocal = {"item_list": cart}
			const res = await addMultipleToCart(cartLocal);
			if ("data" in res) {
				return dispatch(removeCart());
			}
			if ("error" in res) {
				console.log(res.error);
			}
		}
	};

	const onSubmit = async (data: LoginFormData) => {
		const { remember_me, ...rest } = data;
		const res = await loginUser(rest);
		if ("data" in res) {
			const { data } = res;
			if (remember_me) {
				AppLocalStorage.setItem(
					"remember",
					remember_me ? 1 : 0
				);
				dispatch(
					onSetUserTokenOnLocalStorage({
						user: res.data.data,
						token: res.data.token,
					})
				);
			} else {
				dispatch(
					onSetUserToken({
						user: res.data.data,
						token: res.data.token,
					})
				);
			}
			if (isParticulier(data?.data) || isProfessionnel(data?.data)) {
				sendLocalCartToBackend(data?.data);
			}
			Swal.fire({
				icon: "success",
				title: "Connexion reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				
				if (data?.data?.user_type !== UserType.admin) {
					dispatch(
						onSetUserToken({
							user: data?.data,
							token: data?.token,
						})
					);
				}
			});
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log("err", err.data);
			const {
				message = "Une erreur a survenue lors de la connexion",
			} = err.data || {};
			setError("email", { message: message });
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export function socialLogin() {
	const [socialLoginUser] = useLoginUserWithSocialMediaMutation();
	const dispatch = useAppDispatch();
	const [fetchMe] = useLazyMeAdminQuery();
	const onsocialLogin = async (data: SocialRegisterFormData) => {
		console.log({ data });
		const res = await socialLoginUser(data);
		if ("data" in res) {
			const { data } = res;
			AppStorage.setItem("user", {
				token: data?.token,
			});
			if (data?.data?.user_type === UserType.admin) {
				const res = await fetchMe();
				if ("data" in res) {
					const { data: adminData } = res;
				
					const user = AppStorage.getItem("user") as any;
					dispatch(
						onSetUserToken({
							user: adminData,
							token: user?.token,
						})
					);
				}
			}
			Swal.fire({
				icon: "success",
				title: "Connexion reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				if (data?.data?.user_type !== UserType.admin) {
					dispatch(
						onSetUserToken({
							user: data?.data,
							token: data?.token,
						})
					);
				}
			});
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log("err", err.data);
			const {
				message = "Une erreur a survenue lors de la connexion",
			} = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
	};

	return onsocialLogin;
}

export function useSocialLogin() {
	const onSocialLogin = socialLogin();

	const loginWithGoogle = ({ data }: IResolveParams) => {
		const authUserData: SocialRegisterFormData = {
			email: data?.email as string,
			prenom: data?.given_name,
			nom: data?.family_name,
		};
		console.log({ authUserData, data });
		if (authUserData?.email) {
			onSocialLogin(authUserData);
		} else {
			Swal.fire({
				icon: "info",
				title: "Email non retourné !",
				showConfirmButton: false,
				timer: 1200,
			});
		}
	};

	return {
		loginWithGoogle,
	};
}

export default useLogin;
