import React from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { useLocation } from "react-router-dom";
import { Order, OrderStatut } from "../../../../utils/api/order/order.type";
import {
	useDeleteOrderMutation,
	useGetListOrderAdminQuery,
	useOrderByParticulierQuery
} from "../../../../utils/api/order/order.api";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import {
	IconCancel,
	IconDelivered,
	IconInProgress,
	IconInRoad,
} from "../../../shared/Icons";
import { ButtonDelete, ButtonViewLink } from "../../../common/Button";
import {
	formatCurrency,
	formatDate,
	isAdmin,
	showModal,
} from "../../../../utils/Utils";
import ChangeStatusOrderModal from "../tabs/ChangeStatusOrderModal";
import { useAppSelector } from "../../../../redux/hook";
import { useTranslation } from "react-i18next";



function DeleteOrder({ item }: { item: Order }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteOrderMutation();
	const onDelete = useDelete<Order>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cette commande?`,
		successMessage: `La commande a été supprimée`,
	});
	return <ButtonDelete onClick={onDelete} />;
}
function CommandTable({ statut }: { statut?: OrderStatut }) {
	const { t } = useTranslation();

	const { user } = useAppSelector((s) => s?.user);
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = user?.user_type == "professionnel" ?
	useOrderByParticulierQuery({
		limit,
		page,
		slug: user?.slug,
		statut
	})
	:
	useGetListOrderAdminQuery({
		limit,
		page,
		statut,
	});
	
	let location = useLocation();
	const { item, getItem } = useItem<Order>();
	const is_Admin = isAdmin(user);

	const actionFormatter = (cell: string, row: Order) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<ButtonViewLink
							to={
								location?.pathname?.startsWith(
									"/professionel/commandes"
								)
									? `/professionel/commandes/${row.slug}`
									: `/admin/commandes/${row?.slug}/details-commande`
							}
							state={row}
						/>
					</div>
					<div className="container-btn-action-icon-modal">
						<DeleteOrder item={row} />
					</div>
				</div>
			</>
		);
	};
	const statusFormatter = (cell: OrderStatut, row: Order) => {
		return (
			<div
				className="justify-content-center"
				role="button"
				onClick={() => {
					if (is_Admin) {
						showModal("ChangeStatusOrderModal");
						getItem(row);
					}
				}}
			>
				{cell === "en_cours" && (
					<span className="status-en-cours">
						<span className="pe-2">
							<IconInProgress />
						</span>
						<span>{t("progress")}</span>
					</span>
				)}
				{cell === "confirmee" && (
					<span className="status-en-chemin">
						<span className="pe-2">
							<IconInRoad />
						</span>
						<span>{t("confirm")}</span>
					</span>
				)}
				{cell === "livree" && (
					<span className="status-livre">
						<span className="pe-2">
							<IconDelivered />
						</span>
						<span>{t('delivered')}</span>
					</span>
				)}

				{cell === "annulee" && (
					<span className="status-annule">
						<span className="pe-2">
							<IconCancel />
						</span>
						<span>{t('cancelled')}</span>
					</span>
				)}
			</div>
		);
	};
	const commandNumberFormatter = (cell: string) => {
		return <p className="fw-bold m-0">#{cell}</p>;
	};
	const totalFormatter = (cell: string) => {
		return <p className="fw-bold m-0">{formatCurrency(cell)}</p>;
	};
	const paiementFormatter = (cell: string) => {
		return <p>{cell === "CASH" ? "À la livraison" : "En ligne"}</p>;
	};
	const columns = [
		{
			dataField: "code_commande",
			text: t("orders"),
			formatter: commandNumberFormatter,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "total",
			text: "Total",
			formatter: totalFormatter,
		},
		{
			dataField: "moyen_paiement",
			text: t("method_payment"),
			formatter: paiementFormatter,
		},
		{
			dataField: "statut",
			text: t("status"),
			formatter: statusFormatter,
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
		},
	];
	const selectRow = {
		mode: "checkbox",
	};

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
			<ChangeStatusOrderModal item={item} />
		</>
	);
}

export default CommandTable;
