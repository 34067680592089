/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import {
	Category,
	CategoryFormData,
} from "../../../../utils/api/category/category.type";
import { useAddOrEditCategoryMutation } from "../../../../utils/api/category/category.api";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";

function useCrudCategory(item?: Category, resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<CategoryFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrEdit, { isLoading }] = useAddOrEditCategoryMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
		console.log(errors);
	}, [errors]);

	useEffect(() => {
		if (item?.slug) {
			setValue("nom", item?.nom);
		}
	}, [item]);

	const onSubmit = async (data: CategoryFormData) => {
		console.log("data", data);

		const res = await addOrEdit({
			slug: item?.slug,
			data: data,
		});
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: !item
					? "Catégorie ajoutée avec succès !"
					: "La catégorie a été modifiée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
			closeModal("AddCategoryModal");
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						item ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}

export default useCrudCategory;
