import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { Notification, NotificationFormData } from "./notification.type";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hook";

export const NotificationApi = createApi({
	reducerPath: "notificationApi",
	tagTypes: ["notification", "notificationList"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		// readNotification: builder.mutation<Notification, string>({
		// 	query: (id) => ({
		// 		url: `notification/read/${id}/`,
		// 		method: "GET",
		// 	}),
		// 	invalidatesTags: ["notificationList"],
		// }),
		getNotifByUser: builder.query<
			PaginationResults<Notification>,
			string
		>({
			query: (slug) => `user/${slug}/notifications/`,
			providesTags: ["notification"],
		}),
		readNotification: builder.mutation<
			Notification,
			NotificationFormData
		>({
			query: ({ slug, ...data }) => ({
				url: `notifications/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: ["notification"],
		}),
		deleteNotification: builder.mutation<
			Notification,
			NotificationFormData
		>({
			query: (slug) => ({
				url: `notifications/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["notification"],
		}),
	}),
});

export const { 
	useReadNotificationMutation, 
	useGetNotifByUserQuery 
} = NotificationApi;

export function useNotification() {
	const [count, setCount] = useState(0);
	const user = useAppSelector((s) => s.user.user);
	const { data, isLoading } = useGetNotifByUserQuery(user?.slug);
	const [readMessage] = useReadNotificationMutation();

	const functionCountunreadNotif = useCallback(() => {
		let count = 0;
		data?.results?.map((notif) => {
			if (!notif?.read) {
				count++;
			}
			return count;
		});
		setCount(count);
	}, [data]);

	useEffect(() => {
		functionCountunreadNotif();
	});

	const readAll = () => {
		data?.results?.map((item) => {
			return readMessage({ slug: item?.slug, read: true });
		});
	};
	const readNotif = (slug: string) => {
		return readMessage({ slug: slug, read: true });
	};

	return {
		count,
		data: data?.results,
		isLoading,
		readAll,
		readNotif,
	};
}
