import React,{useEffect} from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { getAvatar, getName } from "../../../utils/Utils";
import PanierEnAttente from "./Tabs/PanierEnAttente";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import DétailsCommandesPanier from "./Tabs/DétailsCommandesPanier";
import moment from "moment";
import ToutesCommandesAdminTable from "./ClientTable/ToutesCommandesAdminTable";
import { useUserFromLocation } from "../../../utils/api/user/user.api";


const steps = [
	{ id: "toute-les-commandes", Component: ToutesCommandesAdminTable },
	{ id: "commandes-completes", Component: ToutesCommandesAdminTable },
	{ id: "commandes-livre", Component: ToutesCommandesAdminTable },
	{ id: "commandes-annuler", Component: ToutesCommandesAdminTable },
	{ id: "panier-en-attente", Component: DétailsCommandesPanier },
	{ id: "details-commande", Component: PanierEnAttente },
];

const tabs = [
	"Toutes les commandes",
	"Commandes confirmées",
	"Commandes livrées",
	"Commandes annulées",
	"Panier en attente",
];

function DetailsClientadmin() {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });

	const { Component } = step;
	const [item] = useUserFromLocation();

	const props = {
		go,
		client:item,
		index,
	};

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Clients" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title mb-4">
						Détail du client
					</div>
					<div className="row mb-3">
						<div className="col-lg-4 mb-3">
							<div className="container-fluid">
								<div className="details-pro-admin-user-infos-container mb-5">
									<div className="details-pro-admin-user-avatar-container">
										<img
											src={getAvatar(
												""
											)}
											alt="user avatar"
											className="details-pro-admin-user-avatar"
										/>
									</div>
									<div className="details-pro-admin-username-container">
										<div className="details-pro-admin-username">
											{getName(
												item
											)}
										</div>
										<div className="details-pro-admin-user-email">
											{item?.email}
										</div>
									</div>
								</div>
								{/* <div className='details-pro-admin-btn-actions-container'>
                        <button className='btn details-pro-admin-btn-rejeter'>Rejeter</button>
                        <button className='btn details-pro-admin-btn-accepter'>Accepter la demande</button>
                    </div> */}
							</div>
						</div>
						<div className="col-lg-4 mb-3">
							<div className="container-fluid custom-border-x">
								<div className="details-pro-admin-subtitle mb-3">
									Information personnelle
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Téléphone
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{item?.telephone}
									</div>
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Genre
									</div>
									<div className="details-pro-admin-autres-infos-value">
										F
									</div>
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Membre depuis
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{moment(
											item?.created_at
										).format(
											"DD MMM YYYY"
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mb-3">
							<div className="container-fluid">
								<div className="details-pro-admin-subtitle mb-3">
									Adresse de livraison
								</div>
								<div className="details-pro-admin-text-message mb-3">
									{item?.adresse}
								</div>
								<div className="details-client-admin-adress-livraison">
									<div className="details-client-admin-adress-livraison-value-container">
										<div className="details-client-admin-adress-livraison-value">
											{item?.orders}
										</div>
										<div className="details-client-admin-adress-livraison-label">
											Commandes
										</div>
									</div>
									<div className="details-client-admin-adress-livraison-value-container">
										<div className="details-client-admin-adress-livraison-value">
											{
												item?.orders_confirmed
											}
										</div>
										<div className="details-client-admin-adress-livraison-label">
											Complétée
										</div>
									</div>
									<div className="details-client-admin-adress-livraison-value-container">
										<div className="details-client-admin-adress-livraison-value">
											{
												item?.orders_canceled
											}
										</div>
										<div className="details-client-admin-adress-livraison-label">
											Annulée
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row mb-3">
						<div className="col-lg-12">
							<div className="content-graph-admin medsain-page-title-container mb-2">
								<ul className="tabs-container tabs-container-admin mb-0 pb-2 ps-0">
									{tabs.map(
										(
											tab: string,
											key: number
										) => (
											<li
												className={`tab-item  ${
													index ===
														key &&
													"tab-active-item-admin"
												}`}
												key={
													key
												}
												onClick={() =>
													go(
														key
													)
												}
												role="button"
											>
												{tab}
											</li>
										)
									)}
								</ul>
							</div>
							<div className="row">
								<div className="col-md-12">
									{/* {index !== 5 ? (
										<div className="row country-and-search-bar-content pb-4">
											<div className="col-md-3 country-selection">
												<Select
												// options={options || []}
												/>
											</div>
											<div className="col-md-4 search-bar-container d-flex align-items-center gap-2">
												<input
													type="search"
													placeholder="Chercher"
													className="search-bar-conseil"
													onChange={(
														e
													) =>
														setQuery(
															e
																?.target
																?.value
														)
													}
												/>
												<span className="icon-container">
													<HiOutlineBars3BottomRight />
												</span>
											</div>
										</div>
									) : null} */}

									<div className="hippocrate-dash-admin-page-content-container mb-1">
										<div className="hippocrate-tabs-locataire-container">
											<div className="tabs-component-container">
												<Component
													{...props}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailsClientadmin;
