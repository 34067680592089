import React from "react";
import AuthWrapper from "./AuthWrapper";
import { Input } from "../common/Input";
import { BtnSubmit } from "../common/Button";
import { useResetPassword } from "./hooks/useResetPassword";
import { PassWordRuleView } from "./PasswordRuleView";
import { usePasswordRule } from "./hooks/useRegister";

function ResetPassword() {
	const { register, errors, onSubmit, isLoading, setValue } =
		useResetPassword();
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	} = usePasswordRule(setValue, "new_password");

	const props = {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	};

	return (
		<AuthWrapper>
			<h2 className="top-title text-center">
				Réinitialiser votre mot de passe
			</h2>
			<div className="form py-4">
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-12 input-wrapper">
							<Input
								label="Code"
								type="number"
								{...register("code")}
								error={errors?.code}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label="Nouveau mot de passe"
								type="password"
								placeholder="********"
								onChange={handleChangePassword}
								onFocus={showPasswordRule}
								onBlur={showPasswordRule}
								error={errors?.new_password}
							/>
						</div>
						{isShowPasswordRules && (
							<PassWordRuleView {...props} />
						)}

						<div className="col-md-12 input-wrapper">
							<Input
								label="Confirmer mot de passe"
								type="password"
								placeholder="********"
								{...register(
									"new_password_confirm"
								)}
								error={
									errors?.new_password_confirm
								}
							/>
						</div>
					</div>
					<div className="btn-content pt-3">
						<BtnSubmit
							label="Réinitialiser"
							isLoading={isLoading}
							className="btn btn-auth w-100"
						/>
					</div>
				</form>
			</div>
		</AuthWrapper>
	);
}

export default ResetPassword;
