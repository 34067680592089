import React from "react";
import { ValueCard, ValueItem } from "../../../front/About/About";
import { ValueCardData } from "../../../front/About/data";
import { useTranslation } from "react-i18next";
import { useGetVisitorAboutQuery } from "../../../../utils/api/config/config.api";
import { createMarkup } from "../../../../utils/Utils";



function Apropos() {
  
	const { t } = useTranslation();
  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
  const { data: about, isLoading: isLoad } = useGetVisitorAboutQuery();

  return (
    <div className="particulier-details-produit-section-tabs-a-propos">
      <div className="particulier-details-produit-section-tabs-a-propos-titre mb-3">
        {t("our_story")}
      </div>
      <div className="particulier-details-produit-section-tabs-a-propos-text mb-3"
            dangerouslySetInnerHTML={createMarkup(
                locale == 'fr' ? about?.description_notre_histoire : about?.description_notre_histoire_en
              )}
      />
      {/* <div className="particulier-details-produit-section-tabs-a-propos-titre mb-5">
        Lorem ipsum dolor  
      </div>
      <div className="particulier-details-produit-section-tabs-a-propos-text mb-3">
      Lorem ipsum dolor sit amet consectetur. Eu aliquam arcu proin nec molestie aenean ultrices. Amet egestas sed orci imperdiet in. Sed eget vitae justo ac. Id a.
      </div>
      <div className="apropos-custom-section">
        <div className="row">
            <div className="col-lg-6 mb-3">
            <ValueItem
                value={"100% Naturel"}
                color={"#ED646E"}
                description="Lorem ipsum dolor sit amet consectetur. A mauris in ut enim suspendisse hendrerit. Non quis eu felis quis."
            />
            </div>
            <div className="col-lg-6 mb-3">
            <ValueItem
                value={"Augmente la résistance"}
                color={"#C5135F"}
                description="Lorem ipsum dolor sit amet consectetur. A mauris in ut enim suspendisse hendrerit. "
            />
            </div>
        </div>
        <div className="row">
            {!!ValueCardData &&
                ValueCardData.map((card, key) => {
                return(
                    <div className="col-lg-6 mb-3">
                        <ValueCard 
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                        />
                    </div>
                )
            })}
        </div>
      </div> */}
    </div>
  );
}

export default Apropos;
