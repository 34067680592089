import React from "react";
import "./FrontFooter.css";
import Logo from "../../../assets/appImages/logo.svg";
import { NavLink, Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube , FaInstagram} from "react-icons/fa";
import Select, { components, SingleValueProps } from "react-select";
import ImgLangFr from "../../../assets/lang/fr.svg";
import ImgLangEn from "../../../assets/lang/en.svg";
import { useTranslation } from "react-i18next";


const countries = [
  { value: "Fr", icon: ImgLangFr, label: "Fr" },
  { value: "En", icon: ImgLangEn, label: "En" },
];
const Option = (props: any) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);


const FrontFooter = () => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = React.useState(countries[0]);

  const handleChange = (value: any) => {
    setSelectedCountry(value);
  };
  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      <img src={selectedCountry.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  return (
    <footer className="footer-component">
      <div className="container">
        <div className="row">
          <div className="col-md-3 pb-5 footer-grid">
            <div className="content-logo-app-footer pt-5">
              <NavLink to="/">
                <img src={Logo} alt="logo" className="logo-app-footer" />
              </NavLink>
            </div>
            <div className="social-links-content d-flex align-items-center gap-3">
              <Link
                to="https://www.facebook.com/casadeliztm/"
                className="icon-wrapper"
              >
                <FaFacebookF />
              </Link>
              <Link
                to="https://twitter.com/"
                className="icon-wrapper"
              >
                <FaTwitter />
              </Link>
              <Link
                to="https://sn.linkedin.com/company/casadeliz"
                className="icon-wrapper"
              >
                <FaLinkedinIn />
              </Link>
              <Link
                to="https://www.instagram.com/casadeliz_tm/?hl=fr"
                className="icon-wrapper"
              >
                <FaInstagram />
              </Link>
            </div>
          </div>
          <div className="col-md-3 mb-4 footer-grid">
            <p className="link-footer-title custom-footer-title pb-4">
              {t("about_us")}
            </p>
            <NavLink 
              className="link-footer custom-footer-link-value trans-0-2 d-block" 
              to="/a-propos"
            >
              {t("about")}
            </NavLink>
            <NavLink
              className="link-footer custom-footer-link-value trans-0-2 d-block"
              to="/faq"
            >
              {t("frequently_question")}
            </NavLink>
            {/* <NavLink 
              className="link-footer custom-footer-link-value trans-0-2 d-block" 
              to="/#"
            >
              {t("points_sale")}
            </NavLink> */}
          </div>
          <div className="col-md-3 mb-4 footer-grid">
            <p className="link-footer-title custom-footer-title pb-4">
              {t("page_important")}
            </p>
            <NavLink className="link-footer custom-footer-link-value trans-0-2 d-block" to="/#">
              {t("home")}
            </NavLink>
            <NavLink
              className="link-footer custom-footer-link-value trans-0-2 d-block"
              to="/boutique"
            >
              {t("our_products")}
            </NavLink>
            {/* <NavLink className="link-footer custom-footer-link-value trans-0-2 d-block" to="/#">
              {t("job_offers")}
            </NavLink> */}
            <NavLink
              className="link-footer custom-footer-link-value trans-0-2 d-block"
              to="/particulier/mon-compte"
            >
              {t("my_account")}
            </NavLink>
            {/* <NavLink
              className="link-footer custom-footer-link-value trans-0-2 d-block"
              to="/#"
            >
              {t("professional_website")}
            </NavLink> */}
          </div>
          <div className="col-md-3 mb-4 footer-grid">
            <p className="link-footer custom-footer-title pb-4">Assistance {t("professional_website")}</p>
              <NavLink
                className="link-footer custom-footer-link-value trans-0-2 d-block"
                to="/connexion"
              >
                {t("connection")}
              </NavLink>
             <NavLink
                className="link-footer custom-footer-link-value trans-0-2 d-block"
                to="/inscription"
              >
                {t("registration")}
              </NavLink>
              {/* <NavLink
                className="link-footer custom-footer-link-value trans-0-2 d-block"
                to="/#"
              >
                {t("settings")}
              </NavLink> */}
              <NavLink
                className="link-footer custom-footer-link-value trans-0-2 d-block"
                to="/particulier/mon-compte"
              >
                {t("orders")}
              </NavLink>
          </div>
        </div>
      </div>
      <div className="copyright-content mt-5">
        <div className="container d-flex align-items-center justify-content-between">
          <span className="copyright">© 2023 Casadeliz.</span>
          <div className="content-select-lang">
            {/* <Select
              value={selectedCountry}
              options={countries}
              onChange={handleChange}
              styles={{
                singleValue: (base) => ({
                  ...base,
                  display: "flex",
                  alignItems: "center",
                }),
              }}
              components={{
                Option,
                SingleValue,
              }}
            /> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
