import React from "react";
import "./FooterParticulier.css";
import Logo from "../../../assets/appImages/logo.svg";
import { NavLink, Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { useTranslation } from "react-i18next";


const FooterParticulier = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer-component footer-particulier">
      <div className="container p-4">
        <div className="row">
            <div className="col-lg-4 mb-4 footer-grid">
            <p className="link-footer-title custom-footer-title-particulier pb-4 text-right">
              {t("contact_us")}
            </p>
            <div className="text-info-contact-footer-particulier mb-3">
                <p className="title-info-contact-footer-particulier mb-1">Email</p>
                <a
                className="link-footer custom-footer-link-value-particulier trans-0-2 d-block"
                >
                    contacts@casadeliz.com
                </a>
            </div>
            <div className="text-info-contact-footer-particulier mb-3">
                <p className="title-info-contact-footer-particulier mb-1">{t("phone")}</p>
                <a
                className="link-footer custom-footer-link-value-particulier trans-0-2 d-block"
                >
                    +221 77 720 79 80 / +221 33 847 02 63
                </a>
            </div>
            <div className="text-info-contact-footer-particulier mb-3">
                <p className="title-info-contact-footer-particulier mb-1">{t("address")}</p>
                <p className="title-info-contact-footer-particulier mb-1">Baconding, SENEGAL:</p>
                <p
                className="link-footer custom-footer-link-value-particulier trans-0-2 d-block"
                >
                    Km 50, RN6, Baconding-Goudomp
                </p>
            </div>
          </div>
          <div className="col-lg-4 pb-5 footer-grid border-bloc-footer">
            <div className="content-logo-app-footer pt-5 text-center">
              <NavLink to="/">
                <img src={Logo} alt="logo" className="logo-app-footer" />
              </NavLink>
            </div>
            <div className="social-links-content flex-c align-items-center gap-3">
              <Link
                to="https://twitter.com/"
                className="icon-wrapper"
              >
                <AiOutlineInstagram />
              </Link>
              <Link
                to="https://twitter.com/"
                className="icon-wrapper"
              >
                <FaFacebookF />
              </Link>
              <Link
                to="https://twitter.com/"
                className="icon-wrapper"
              >
                <FaTwitter />
              </Link>
              <Link
                to="https://twitter.com/"
                className="icon-wrapper"
              >
                <FaLinkedinIn />
              </Link>
              <Link
                to="https://twitter.com/"
                className="icon-wrapper"
              >
                <FaYoutube />
              </Link>
            </div>
          </div>
          
          <div className="col-lg-4 mb-4 footer-grid">
            <p className="link-footer-title custom-footer-title-particulier pb-4">
              {t("useful_pages")}
            </p>
            <NavLink className="link-footer custom-footer-link-value-item trans-0-2 d-block" to="/contact">
              {t("contact")} 
            </NavLink>
            <NavLink
              className="link-footer custom-footer-link-value-item trans-0-2 d-block"
              to="/#"
            >
              404
            </NavLink>
            <Link className="link-footer custom-footer-link-value-item trans-0-2 d-block" to="/particulier/mon-compte" state={{ active: 1 }}>
              {t("password")}
            </Link>
            <Link
              className="link-footer custom-footer-link-value-item trans-0-2 d-block"
              to="/particulier/mon-compte"
              state={{ active: 1 }}
            >
              Modifications
            </Link>
          </div>
        </div>
      </div>
      <div className="copyright-content mt-4">
        <div className="container text-center">
          <p className="copyright">Copyright © <span style={{  fontWeight: 700 }}>Casadeliz</span> | 2023</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterParticulier;
