/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdDelete, MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getImage } from "../../utils/Utils";
import ErrorMessage from "./ErrorMessage";
import { useDeleteProductImageMutation } from "../../utils/api/product/product.api";

function MyDropzone({
	setImages,
	label,
	maxFile,
	acceptFile,
	color,
	background,
}: {
	setImages: any;
	label?: string | any;
	maxFile?: number;
	acceptFile?: any;
	color?: any;
	background?: any;
}) {
	const [maxFileError, setMaxFileError] = useState<string>("");
	const onDrop = useCallback(
		(acceptedFiles: any, fileRejections: any[]) => {
			if (acceptedFiles) {
				setImages(acceptedFiles);
				// console.log(acceptedFiles);
			}
			fileRejections.forEach((file: { errors: any[] }) => {
				file.errors.forEach((err: { code: string }) => {
					if (err.code === "too-many-files") {
						setMaxFileError("Maximum 4 images");
					}
					if (err.code === "file-invalid-type") {
						setMaxFileError(
							"Types de fichiers non prises en charge"
						);
					}
				});
			});
		},
		[]
	);

	let acceptF = acceptFile
		? acceptFile
		: {
				"image/jpeg": [],
				"image/png": [],
				"image/jpg": [],
				"image/gif": [],
		  };
	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		onDrop,
		maxFiles: maxFile,
		multiple: true,
		maxSize: 209715200,
		accept: acceptF,
		// maxSize: 1000,
	});

	const fileRejectionItems = fileRejections.map(
		({ errors }: { errors: any }, index: any) => {
			return (
				<div key={index}>
					{/* {file.size} bytes */}
					<ul className="p-0">
						{errors.map((e: any) => (
							<li
								key={e.code}
								style={{ listStyle: "none" }}
								className="text-danger"
							>
								{e.code === "file-too-large" &&
									`L'image ${
										index + 1
									} est trop grande`}
							</li>
						))}
					</ul>
				</div>
			);
		}
	);

	useEffect(() => {
		setTimeout(() => setMaxFileError(""), 3000);
	});
	return (
		<section className="dropzone">
			{!!label && (
				<label
					htmlFor="pro-images"
					className="form-label form-label-modal-custom mb-2"
				>
					{label}
				</label>
			)}
			<label
				{...getRootProps()}
				style={{
					overflow: "hidden",
					opacity: 1,
					display: "flex",
					justifyContent: "center",
					padding: "3rem 15px",
					background: `${background ? background : "#FFF"}`,
					fontFamily: "Poppins",
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "14px",
					lineHeight: "21px",
					textAlign: "center",
					color: `${color ? color : "#D9D9D9"}`,

					borderRadius: "6px",
					border: `1px dashed ${color ? color : "#D9D9D9"}`,
				}}
				htmlFor="pro-images"
			>
				<input
					{...getInputProps()}
					type="file"
					id="pro-images"
					name="pro-images"
					accept="image/*"
				/>
				<div className="start-label-input-choose-file">
					<div className="flex-c">
						<MdOutlineAddPhotoAlternate
							style={{
								color: `${
									color ? color : "#D9D9D9"
								}`,
								fontSize: "30px",
							}}
						/>
					</div>
					<span className="first-label-input-choose-file">
						Déposez maximum {maxFile} photo
						{maxFile && maxFile > 1 ? "s" : ""} ici ou
						cliquez pour télécharger.
					</span>
					<br />
					{/* <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span> */}
				</div>
			</label>
			{maxFileError && <ErrorMessage message={maxFileError} />}
			<div className="row pt-4">
				{/* {acceptedFileItems} */}
				{!maxFileError && fileRejectionItems}
			</div>
		</section>
	);
}

export default MyDropzone;

type TImageGrid = {
	images: File[] | [];
	deleteFile?: (i: number, file: any) => void;
};

export const ImageGrid = ({ images, deleteFile }: TImageGrid) => {
	return (
		<>
			{images?.length
				? images.map((file: any, idx: number) => (
						<div
							className="drop-img-card position-relative"
							key={idx}
						>
							<button
								className="no-style-btn del-drop-img"
								title="Supprimer"
								type="button"
								onClick={() => {
									if (deleteFile) {
										deleteFile(
											idx,
											file
										);
									}
								}}
							>
								<MdDelete
									color="#fff"
									fontSize={24}
								/>
							</button>
							<img
								src={
									file?.picture
										? getImage(
												file?.picture
										  )
										: URL.createObjectURL(
												file
										  )
								}
								alt="drop-grid-img"
								className="drop-grid-img img-thumbnail"
							/>
						</div>
				  ))
				: ""}
		</>
	);
};

export const ImagesView = ({ images }: { images: any }) => {
	const [deleteImage] = useDeleteProductImageMutation();
	return (
		<>
			{images.length
				? images.map((file: any, idx: number) => (
						<div
							className="drop-img-card position-relative"
							key={idx}
						>
							<button
								className="no-style-btn del-drop-img"
								title="Supprimer"
								type="button"
								onClick={() => {
									console.log({ file });
									deleteImage(file?.slug);
								}}
							>
								<MdDelete
									color="#fff"
									fontSize={22}
								/>
							</button>
							<img
								src={
									file?.picture
										? getImage(
												file?.picture
										  )
										: URL.createObjectURL(
												file
										  )
								}
								alt="drop-grid-img"
								className="drop-grid-img img-thumbnail"
							/>
						</div>
				  ))
				: ""}
		</>
	);
};

export function PreviewImage({ source }: { source?: string }) {
	if (source) {
		return (
			<img
				src={source}
				alt="drop-grid-img"
				className="drop-grid-img img-thumbnail"
				style={{ width: "100px", height: "100px" }}
			/>
		);
	}
	return null;
}
