import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { BtnSubmit } from "../../common/Button";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { cleannerError, closeModal } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import { useChangeStockMutation } from "../../../utils/api/product/product.api";
import { Product } from "../../../utils/api/product/product.type";
import { FormError, Input } from "../../common/Input";
import { useTranslation } from "react-i18next";

type stockData = { 
	quantity: number ,
	stock: string
};


function ChangeStockModal({ item }: { item?: any }) {
	// console.log({ item });
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { t } = useTranslation();
	const validationSchema = yup.object().shape({
		quantity: yup.string().required().label("Stock"),
	});
	const [changeStatus, { isLoading }] = useChangeStockMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm<stockData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: stockData) => {
		// console.log({ data });
		const { quantity } = data;
		const res = await changeStatus({
				quantity: quantity,
				stock: item?.id
		});
		if ("data" in res) {
			reset();
			closeModal("ChangeStockModal");
			Swal.fire({
				icon: "success",
				title: `Stock modifier avec  succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		} else if ("error" in res) {
			console.log("error", res.error);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			if (
				err?.data?.motif &&
				err?.data?.motif[0]?.includes(
					"This field may not be blank."
				)
			) {
				return Swal.fire({
					icon: "error",
					title: `Motif est un champ obligatoire`,
					showConfirmButton: false,
					timer: 2000,
				});
			}
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return (
		<div
			className="modal fade"
			id="ChangeStockModal"
			aria-labelledby="ChangeStockModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStockModal"
						>
							{t('modify_stock')}
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form
							className="w-100"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="row">
								<div className="col-md-12 mb-3">
									<Input
										type="number"
										label={t("add_quantity_sold")}
										id="quantity"
										placeholder={t("add_quantity_sold")}
										customClassLabel={
											"input-parametre-label-form-add"
										}
										customClassInput="input-parametre-form-add"
										{...register("quantity")}
										error={errors.quantity}
										defaultValue={
											item?.quantity
										}
										required
									/>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
									className="btn btn-theme-admin w-100"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStockModal;
