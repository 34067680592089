import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { closeModal } from "../../../utils/Utils";
import { FormError } from "../../common/Input";
import { Color } from "../../../utils/theme";

type Props = {
	isLoading: boolean;
	onCheckStatus: () => void;
	disabled: boolean;
	seconds: number;
};

function CheckStatusPaymentModal(props: Props) {
	const { isLoading, onCheckStatus, disabled, seconds } = props;
	return (
		<div
			className="modal fade"
			id="statusPaymentModal"
			tabIndex={-1}
			aria-labelledby="statusPaymentModalLabel"
			aria-hidden="true"
			data-bs-backdrop="static"
		>
			<div className="modal-dialog">
				<div className="modal-content p-3">
					<div className="d-flex justify-content-between p-1">
						<h5
							className="modal-title check-status-title"
							id="statusPaymentModalLabel"
						>
							Statut
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => {
								closeModal(
									"statusPaymentModal"
								);
							}}
						></button>
					</div>
					<div className="modal-payment-body">
						<div className="my-5 flex-col itm-center">
							<BsFillInfoCircleFill
								className="mb-4"
								fontSize={30}
								color={Color.themeColor}
							/>
							<p className="text-payment-modal mb-4">
								Veuillez vérifier le statut de
								votre paiement!
							</p>
						</div>
					</div>
					<div>
						<div className="flex-c">
							<button
								type="button"
								className="btn particulier-panier-btn-retour"
								onClick={() => onCheckStatus()}
								disabled={
									isLoading ||
									disabled ||
									(seconds > 0 &&
										seconds < 20)
								}
							>
								{isLoading ? (
									<>
										<span
											className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
											role="status"
										></span>
										<span>
											Vérification...
										</span>
									</>
								) : (
									"Vérifier le paiement"
								)}
							</button>
						</div>
						{seconds <= 0 || seconds === 20 ? null : (
							<div className="col-12">
								<FormError
									error={`Veuillez revérifier dans ${seconds} secondes`}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default CheckStatusPaymentModal;
