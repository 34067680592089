import React from "react";
import "./ParticulierLayout.css";
import HeaderParticulier from "../HeaderParticulier/HeaderParticulier";
import FooterParticulier from "../FooterParticulier/FooterParticulier";
import { Outlet } from "react-router-dom";

const ParticulierLayout = () => {
	return (
		<div className="particulier-layout-component">
			<HeaderParticulier />
			<div className="content-page custom-page-content my-5">
				<Outlet />
			</div>
			<FooterParticulier />
		</div>
	);
};

export default ParticulierLayout;
