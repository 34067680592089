import React from "react";
import { FormError, Input, RequiredText } from "../../../common/Input";
import ReactQuill from "react-quill";
import { AiOutlinePlus } from "react-icons/ai";
import useCrudAbout from "../hooks/useCrudAbout";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PreviewImage } from "../../../common/MyDropzone";
import { getImage } from "../../../../utils/Utils";

function OptionAProposAdmin() {
	const {
		register,
		errors,
		isLoading,
		onSubmit,
		handleChangeImage,
		handleChangeImageHistoire,
		handleChangeBanner,
		handleChangeDescription,
		handleChangeDescriptionEn,
		description,
		descriptionEn,
		raisons,
		handleAddRaison,
		removeRaison,
		removeAvantage,
		handleAddAdvantange,
		avantages,
		about,
	} = useCrudAbout();

	return (
		<div className="container-fluid">
			<div className="form-add-container">
				<form className="mt-4" onSubmit={onSubmit}>
					<div className="row row-add-student my-3">
						<div className="col-lg-12">
							<Input
								type="file"
								label="Bannière"
								id="banniere"
								required
								error={errors.banniere}
								onChange={handleChangeBanner}
							/>
							<FormError error={errors?.banniere} />
						</div>
						<div className="col-lg-12 mb-4">
							<PreviewImage
								source={getImage(
									about?.banniere
								)}
							/>
						</div>
						<section className="row mb-5">
							<div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Notre histoire
								</div>
							</div>
							<div className="col-lg-12">
								<Input
									type="file"
									label="Image"
									id="image"
									required
									onChange={
										handleChangeImageHistoire
									}
								/>
								<FormError
									error={
										errors?.image_histoire
									}
								/>
							</div>
							{/* <div className="col-lg-12 mb-3">
								<PreviewImage
									source={getImage(
										about?.image_a_propos
									)}
								/>
							</div> */}
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_fr"
									className="form-label input-produit-label-form-add"
								>
									Description(Fr)
									<RequiredText />
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={description}
									onChange={
										handleChangeDescription
									}
								/>
								<FormError
									error={
										errors?.description_notre_histoire
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_fr"
									className="form-label input-produit-label-form-add"
								>
									Description(En)
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={descriptionEn}
									onChange={
										handleChangeDescriptionEn
									}
								/>
								<FormError
									error={
										errors?.description_notre_histoire_en
									}
								/>
							</div>
						</section>
						<section className="mb-5">
							<div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Notre Equipe
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Titre(Fr)"
										id="titre"
										placeholder="Titre"
										customClassLabel={
											"input-parametre-label-form-add"
										}
										customClassInput="input-parametre-form-add"
										{...register(
											"equipe_titre"
										)}
										error={
											errors?.equipe_titre
										}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Titre(En)"
										id="equipe_titre_en"
										placeholder="Titre"
										customClassLabel={
											"input-parametre-label-form-add"
										}
										customClassInput="input-parametre-form-add"
										{...register(
											"equipe_titre_en"
										)}
										error={
											errors?.equipe_titre_en
										}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<label
										htmlFor="equipe_description"
										className="form-label form-label-modal-custom"
									>
										Description(Fr){" "}
									</label>
									<textarea
										className="form-control form-control-modal mb-2"
										id="equipe_description"
										{...register(
											"equipe_description"
										)}
									/>
								</div>
								<div className="col-md-6 mb-3">
									<label
										htmlFor="equipe_description"
										className="form-label form-label-modal-custom"
									>
										Description(En){" "}
									</label>
									<textarea
										className="form-control form-control-modal mb-2"
										id="equipe_description_en"
										{...register(
											"equipe_description_en"
										)}
									/>
								</div>
							</div>
						</section>
						<section className="mb-5">
							<div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Pourquoi nous choisir?
								</div>
							</div>
							<div className="col-lg-12 mb-3">
								<Input
									type="text"
									label="Titre(Fr)"
									id="titre"
									placeholder="Titre"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register(
										"titre_pourquoi_nous_choisir"
									)}
									error={
										errors?.titre_pourquoi_nous_choisir
									}
									required
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<Input
									type="text"
									label="Titre(En)"
									id="titre"
									placeholder="Titre"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register(
										"titre_pourquoi_nous_choisir_en"
									)}
									error={
										errors?.titre_pourquoi_nous_choisir_en
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<Input
									type="text"
									label="Sous-titre(Fr)"
									id="sous-titre"
									placeholder="Sous-titre"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									required
									customClassInput="input-parametre-form-add"
									{...register(
										"description_pourquoi_nous_choisir"
									)}
									error={
										errors?.description_pourquoi_nous_choisir
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<Input
									type="text"
									label="Sous-titre(En)"
									id="sous-titre"
									placeholder="Sous-titre"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register(
										"description_pourquoi_nous_choisir_en"
									)}
									error={
										errors?.description_pourquoi_nous_choisir_en
									}
								/>
							</div>
						</section>
						<section className="row mb-5">
							<div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Les raisons
								</div>
							</div>
							<div className="col-lg-12 mb-3">
								<Input
									type="file"
									label="Image"
									id="image"
									required
									onChange={
										handleChangeImage
									}
								/>
								<FormError
									error={
										errors?.image_raison
									}
								/>
							</div>
							{raisons?.map((r, i) => (
								<div
									key={r?.slug}
									className="d-flex align-items-start gap-3 shadow-sm mb-3 pt-3"
								>
									<div className="row flex-fill">
										<div className="col-md-6 mb-3">
											<Input
												type="text"
												label={`Titre raison(Fr) ${
													i +
													1
												}`}
												id="titre"
												placeholder={`Titre raison ${
													i +
													1
												}`}
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													`raisons.${i}.titre`
												)}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<Input
												type="text"
												label={`Titre raison(En) ${
													i +
													1
												}`}
												id="titre"
												placeholder={`Titre raison ${
													i +
													1
												}`}
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													`raisons.${i}.titre_en`
												)}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label
												htmlFor="description_fr"
												className="orm-label form-label-modal-custom"
											>
												Texte
												raison(Fr){" "}
												{i + 1}
											</label>
											<textarea
												className="form-control form-control-modal mb-2"
												{...register(
													`raisons.${i}.description`
												)}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label
												htmlFor="description_fr"
												className="orm-label form-label-modal-custom"
											>
												Texte
												raison(En){" "}
												{i + 1}
											</label>
											<textarea
												className="form-control form-control-modal mb-2"
												{...register(
													`raisons.${i}.description_en`
												)}
											/>
										</div>
									</div>
									{i > 0 && (
										<button
											type="button"
											className="button-remove"
											onClick={() =>
												removeRaison(
													i
												)
											}
										>
											<IoMdCloseCircleOutline
												color="#ED646E"
												fontSize={
													24
												}
											/>
										</button>
									)}
								</div>
							))}

							<div className="col-lg-12 mb-3">
								<button
									className="btn btn-add-more-raison"
									type="button"
									onClick={handleAddRaison}
								>
									<AiOutlinePlus /> Ajouter
									une autre raison
								</button>
							</div>
						</section>

						<section className="row">
							<div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Les avantages
								</div>
							</div>
							<div className="col-lg-12">
								<Input
									type="file"
									label="Image"
									id="image"
									required
									onChange={
										handleChangeImage
									}
								/>
								<FormError
									error={
										errors?.image_raison
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<PreviewImage
									source={getImage(
										about?.image_raison
									)}
								/>
							</div>
							{avantages?.map((r, i) => (
								<div
									key={r?.slug}
									className="d-flex align-items-start gap-3 shadow-sm mb-3 pt-3"
								>
									<div className="row flex-fill">
										<div className="col-md-6 mb-3">
											<Input
												type="text"
												label={`Titre avantage(Fr) ${
													i +
													1
												}`}
												id="titre"
												placeholder={`Titre avantage ${
													i +
													1
												}`}
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													`avantages.${i}.titre`
												)}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<Input
												type="text"
												label={`Titre avantage(En) ${
													i +
													1
												}`}
												id="titre"
												placeholder={`Titre avantage ${
													i +
													1
												}`}
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register(
													`avantages.${i}.titre_en`
												)}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label
												htmlFor="description_fr"
												className="orm-label form-label-modal-custom"
											>
												Texte
												avantage(Fr){" "}
												{i + 1}
											</label>
											<textarea
												className="form-control form-control-modal mb-2"
												{...register(
													`avantages.${i}.description`
												)}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label
												htmlFor="description_fr"
												className="orm-label form-label-modal-custom"
											>
												Texte
												avantage(En){" "}
												{i + 1}
											</label>
											<textarea
												className="form-control form-control-modal mb-2"
												{...register(
													`avantages.${i}.description_en`
												)}
											/>
										</div>
									</div>
									{i > 0 && (
										<button
											type="button"
											className="button-remove"
											onClick={() =>
												removeAvantage(
													i
												)
											}
										>
											<IoMdCloseCircleOutline
												color="#ED646E"
												fontSize={
													24
												}
											/>
										</button>
									)}
								</div>
							))}

							<div className="col-lg-12 mb-3">
								<button
									className="btn btn-add-more-raison"
									type="button"
									onClick={
										handleAddAdvantange
									}
								>
									<AiOutlinePlus /> Ajouter
									un autre avantage
								</button>
							</div>
						</section>

						<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
							<button
								className="btn btn-cancel-admin"
								type="button"
							>
								Annuler
							</button>
							<button
								className="btn btn-theme-admin w-100"
								type="submit"
								disabled={isLoading}
							>
								{isLoading ? (
									<>
										<span
											className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
											role="status"
										></span>
										<span>
											Enrégistrement...
										</span>
									</>
								) : (
									"Modifier"
								)}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default OptionAProposAdmin;
