import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { QueryError } from "../../../../utils/type";
import { Testimonial, TestimonialFormData } from "../../../../utils/api/config/config.type";
import { useAddOrUpdateTestimonialMutation } from "../../../../utils/api/config/config.api";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useCrudTestimonial(resetItem: any, go?: any, item?: Testimonial) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prénom"),
		message: yup.string().required().label("Message"),
		fonction: yup.string().required().label("Fonction"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError,
	} = useForm<TestimonialFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [image, setImage] = useState<string>();
	const [message, setMessage] = useState<string>();

	const [sendData, { isLoading }] = useAddOrUpdateTestimonialMutation();

	useEffect(() => {
		console.log(errors);
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.slug) {
			setValue("nom", item?.nom);
			setValue("prenom", item?.prenom);
			setValue("message", item?.message);
			setValue("fonction", item?.fonction);
			setMessage(item?.message);
		}
	}, [item]);

	const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			setImage(URL.createObjectURL(file));
			setValue("avatar", file);
		}
	};
	const onChangeMessage = (value: string) => {
		setValue("message", value);
		setMessage(value);
	};

	const onSubmit = async (data: TestimonialFormData) => {
		if (!item && !data?.avatar) {
			return setError("avatar", { message: "La Photo est obligatoire" });
		}
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await sendData({ slug: item?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Témoignage ${!item ? "ajouté" : "modifié"} avec succès !`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1000,
			}).then(() => {
				reset();
				setImage(undefined);
				resetItem();
				setMessage("");
				go && go("testimonial");
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		image,
		handleChangeImage,
		onChangeMessage,
		message,
	};
}

export default useCrudTestimonial;
