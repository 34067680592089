import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { Product } from "../product/product.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Favorie, FavorieFormData } from "./favorie.type";

export const FavoriteApi = createApi({
	reducerPath: "favoriteApi",
	tagTypes: ["favorite", "favoriteList"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getListFavoriteAdmin: builder.query<
			PaginationResults<Favorie>,
			TypeQuery
		>({
			providesTags: ["favoriteList"],
			query: (query) => QueryUrl("favorite/paginate", query),
		}),
		getFavoriteList: builder.query<Product[], TypeQuery>({
			providesTags: ["favorite"],
			query: (query) => QueryUrl("favorite/me", query),
		}),
		addOrDeleteFavorite: builder.mutation<Favorie, FavorieFormData>({
			onCacheEntryAdded(arg, { dispatch }) {
				setTimeout(() => {
					dispatch(
						FavoriteApi.util.invalidateTags([
							"favoriteList",
							"favorite",
						])
					);
				}, 1000);
			},
			query: (data) => {
				return {
					url: `favorite/toggle/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useAddOrDeleteFavoriteMutation,
	useGetFavoriteListQuery,
	useGetListFavoriteAdminQuery,
	useLazyGetFavoriteListQuery,
} = FavoriteApi;
