import React from "react";
import { FormError } from "../../common/Input";
import UseAddOrUpdateOrderForm from "../../particulier/MonCompteParticulier/requestForm/UseAddOrUpdateOrderForm";
import Form from "react-bootstrap/Form";
import PWave from "../../../assets/appImages/paymentWave.svg";
// import PMasterCard from "../../../assets/appImages/paymentMC.svg";
// import PVisa from "../../../assets/appImages/paymentVisa.svg";
import { User } from "../../../utils/api/user/user.type";
import { formatCurrency, showModal } from "../../../utils/Utils";
import { CartItem } from "../../../utils/api/product/product.type";
import Orange from "../../../assets/icons/money.png";
import Free from "../../../assets/icons/free.png";
import CheckStatusPaymentModal from "../../particulier/PanierParticulier/CheckStatusPaymentModal";
import { useTranslation } from "react-i18next";


type Props = {
  totalAmount?: number;
  user: User;
  isSelectedItem?: boolean;
  cartToOrder: CartItem[];
};

const FormOrder = ({ totalAmount, isSelectedItem, cartToOrder }: Props) => {
	const { t } = useTranslation();
  const {
    onSubmit,
    isLoading,
    errors,
    moyenPaiement,
    handleChangeMoyenPaiement,
    onLoading,
    disabled,
    onGetTransactionStatus,
    seconds,
    user,
  } = UseAddOrUpdateOrderForm(cartToOrder, totalAmount);

  return (
    <form onSubmit={onSubmit}>
      {/* <div className="particulier-panier-infos-bon-container mb-3">
				<label className="particulier-panier-infos-bon-label mb-3">
					Avez-vous un bon?
				</label>
				<div className="input-group mb-2">
					<input
						type="text"
						className="form-control particulier-panier-infos-bon-form-control"
						placeholder="Ajouter le code"
						aria-label="Recipient's username"
						aria-describedby="button-addon2"
					/>
					<button
						className="btn particulier-panier-infos-bon-btn-add mt-2"
						type="button"
						id="button-addon2"
					>
						Ajouter
					</button>
				</div>
			</div> */}
      <div className="particulier-panier-infos-bon-container">
        <div className="particulier-panier-infos-paiement-label-container mb-3">
          <div className="particulier-panier-infos-paiement-label">
            {t('total_price')}
          </div>
          <div className="particulier-panier-infos-paiement-value">
            {formatCurrency(totalAmount)}
          </div>
        </div>
        <div className="particulier-panier-infos-paiement-label-container mb-3">
          <div className="particulier-panier-infos-paiement-label">{t('discount')}</div>
          <div className="particulier-panier-infos-paiement-value-2">0</div>
        </div>
        <div className="particulier-panier-infos-paiement-label-container mb-3">
          <div className="particulier-panier-infos-paiement-label">
		  	{t('delivery')}
          </div>
          <div className="particulier-panier-infos-paiement-value-3">0</div>
        </div>
        <div className="particulier-panier-infos-divider mb-3"></div>

				<div className="particulier-panier-infos-form-check">
					<Form.Check
						type="radio"
						id={`default-radio-1`}
						name="flexRadioDefault"
						label={t('online_payment')}
						className="form-check-input custom-form-check-input mb-4"
						value={"online"}
						onChange={handleChangeMoyenPaiement}
					/>
					<div
						style={{
							display:
								moyenPaiement === "online"
									? "block"
									: "none",
						}}
						className="custom-check-payment-method"
					>
						<ul>
							<li>
								<input
									onChange={
										handleChangeMoyenPaiement
									}
									name="moyen_paiement"
									value="WAVE"
									type="radio"
									id="wave"
								/>
								<label htmlFor="wave">
									<img
										src={PWave}
										alt="Image 1"
									/>
								</label>
							</li>
							{/* <li>
								<input
									onChange={
										handleChangeMoyenPaiement
									}
									name="moyen_paiement"
									value="ORANGE_MONEY"
									type="radio"
									id="orange_money"
								/>
								<label htmlFor="orange_money">
									<img
										src={Orange}
										alt="Orange Money"
									/>
								</label>
							</li>
							<li>
								<input
									onChange={
										handleChangeMoyenPaiement
									}
									name="moyen_paiement"
									value="FREE_MONEY"
									type="radio"
									id="free_money"
								/>
								<label htmlFor="free_money">
									<img
										src={Free}
										alt="Free Money"
									/>
								</label>
							</li> */}
							{/* <li>
								<input
									onChange={
										handleChangeMoyenPaiement
									}
									name="moyen_paiement"
									value="CARTE_BANCAIRE"
									type="radio"
									id="cb2"
								/>
								<label htmlFor="cb2">
									<img
										src={PMasterCard}
										alt="Master Card"
									/>
								</label>
							</li>
							<li>
								<input
									onChange={
										handleChangeMoyenPaiement
									}
									name="moyen_paiement"
									value="CARTE_BANCAIRE"
									type="radio"
									id="cb4"
								/>
								<label htmlFor="cb4">
									<img
										src={PVisa}
										alt="Image 4"
									/>
								</label>
							</li> */}
            </ul>
          </div>

          <Form.Check // prettier-ignore
            type="radio"
            id={`default-radio-2`}
            name="flexRadioDefault"
            label={t('payment_on_delivery')}
            className="form-check-input custom-form-check-input mb-4"
            value={"delivery"}
            onChange={handleChangeMoyenPaiement}
          />

          <FormError
            error={errors?.moyen_paiement && errors?.moyen_paiement?.message}
          />
        </div>
        <div className="particulier-panier-infos-label-total-container mb-3">
          <div className="particulier-panier-infos-label-total">Total:</div>
          <div className="particulier-panier-infos-value-total">
            {formatCurrency(totalAmount)}
          </div>
        </div>
        <div className="particulier-panier-infos-paiement-btn-paiement-container">
          <button
            type={user?.id ? "submit" : "button"}
            className="btn particulier-panier-infos-paiement-btn-paiement"
            disabled={!isSelectedItem}
            style={{
              backgroundColor: isSelectedItem ? "#ED646E" : "#FEFBF9",
              color: isSelectedItem ? "#FFF" : "#ED646E",
            }}
            onClick={() => {
              if (isSelectedItem && !user?.id) {
                showModal("PanierRegisterModal");
              }
            }}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>{t('payment')}...</span>
              </>
            ) : (
              t('pay')
            )}
          </button>
        </div>
      </div>
      <CheckStatusPaymentModal
        isLoading={onLoading}
        onCheckStatus={onGetTransactionStatus}
        seconds={seconds}
        disabled={disabled}
      />
    </form>
  );
};

export default FormOrder;
