import React , {useState , useEffect} from "react";
import { formatDateHour , isAdmin ,showModal} from "../../../../utils/Utils";
import ShowNotificationModal from "../modal/ShowNotificationModal";
import { useAppSelector } from "../../../../redux/hook";
import { useGetNotifByUserQuery } from "../../../../utils/api/notification/notification.api";
import { useNotification } from "../../../../utils/api/notification/notification.api";


function Notification(props) {
	const user = useAppSelector((s) => s.user.user);
  const { data, isLoading } = useGetNotifByUserQuery(user?.slug);
	const [notification, setNotification] = useState();
  const {  readNotif } = useNotification();


  function openModalNotification(item : any) {
    setNotification(item);
    readNotif(item?.slug)
    showModal("DetailsNotificationModal");
  }

  return (
    <div className="medsain-profil-tab-item">
      <div className="medsain-profil-tab-title">Notifications</div>

      <div className="list-group mt-4">
      {(data?.results?.length == 0 && isLoading == false) && (
					"Il n'y a aucune notification ."
				) }
        {data?.results?.map((data) => (
          <button
            type="button"
            onClick={(e) => openModalNotification(data)}
            className={`list-group-item list-group-item-action mb-2 ${
              !data?.read ? "non-lue" : ""
            }`}
            key={data?.slug}
          >
            <div className="row">
              <div className="col-md-7">
                <div className="custom-list-group-item">
                  <span>{data?.user?.prenom} {data?.user?.nom}</span> vient d’ajouter une {" "}
                  {data?.notif_type}
                </div>
              </div>
              <div className="col-md-3">
                <div className="list-group-item-heure pt-1">{formatDateHour(data?.created_at)}</div>
              </div>
              <div className="col-md-2">
                {!data?.read && (
                  <span className="list-group-item-badge">Non lue</span>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
      <ShowNotificationModal notification={notification}
        />
    </div>
  );
}

export default Notification;

const Datas = [
  {
    id: 1,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
  {
    id: 2,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
  {
    id: 3,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 4,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 5,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 6,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 7,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
];
