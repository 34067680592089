import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
	Article,
	ArticleFormData,
	ArticleType,
} from "../../../../utils/api/article/article.type";
import { cleannerError } from "../../../../utils/Utils";
import { useAddOrUpdateArticleMutation } from "../../../../utils/api/article/article.api";
import { useAppSelector } from "../../../../redux/hook";
import { Color } from "../../../../utils/theme";
import { QueryError } from "../../../../utils/type";
import * as yup from "yup";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCrudArticle(resetArticle:any,go?: any, item?: Article) {
	const [type, setType] = useState<ArticleType>("text");
	const [description, setDescription] = useState("");
	const [descriptionEn, setDescriptionEn] = useState("");
	const [imageCouverture, setImageCouverture] = useState<string>();
	const [fichier, setFichier] = useState<File>();

	// const item = useLocationState<Article>(undefined);

	const validationSchema = yup.object().shape({
		type: yup.string().default(type),
		item: yup.string().default(item?.slug),
		titre: yup.string().required().label("Titre(Fr)"),
		content: yup.string().label("Description(Fr)").required(),
		titre_en: yup.string().required().label("Titre(En)"),
		content_en: yup.string().label("Description(En)").required(),
		// backgound_image: yup.mixed().required().label("Bannière"),
		backgound_image: yup.mixed().when("item", {
			is: (item) => item === undefined,
			then: () => yup.mixed().required().label("Bannière"),
			otherwise: () => yup.mixed().notRequired(),
		}),
		link: yup.string().when(["type", "item"], {
			is: (type, item) =>
				item === undefined &&
				(type === "video" || type === "link"),
			then: () => yup.string().required().url().label("Lien"),
			otherwise: () => yup.string().notRequired().label("Lien"),
		}),
		fichier: yup.mixed().when("type", {
			is: (type) => type === "document" || type === "audio",
			then: () => yup.mixed().required().label("Fichier"),
			otherwise: () => yup.mixed().notRequired(),
		}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ArticleFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			slug: item?.slug,
		},
	});

	const [addOrUpdate, { isLoading }] = useAddOrUpdateArticleMutation();
	const { user: authUser } = useAppSelector((s) => s?.user);

	const onChangeDescription = (value: string) => {
		setDescription(value);
		setValue("content", value);
	};

	const onChangeDescriptionEn = (value: string) => {
		setDescriptionEn(value);
		setValue("content_en", value);
	};

	const onChangeType = (e: React.FormEvent<HTMLSelectElement>) => {
		if (e.currentTarget.value) {
			setType(e.currentTarget.value as ArticleType);
		}
	};


	const handleChangeImageCouverture = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const file = e.currentTarget.files
				? e.currentTarget.files[0]
				: null;
			if (file) {
				setImageCouverture(URL.createObjectURL(file));
				setValue("backgound_image", file);
			}
		},
		[]
	);

	const handleChangeFichier = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const file = e.currentTarget.files
				? e.currentTarget.files[0]
				: null;
			if (file) {
				setFichier(file);
				setValue("fichier", file);
			}
		},
		[]
	);

	useEffect(() => {
		console.log({ item });
		if (item) {
			setValue("titre", item?.titre);
			setValue("titre_en", item?.titre_en);
			setValue("content_type", item?.content_type);
			setValue("link", item?.link);
			setValue("content", item?.content);
			setValue("content_en", item?.content_en);
			setDescription(item?.content);
			setDescriptionEn(item?.content_en);
			setType(item?.content_type);
		}
	}, [item]);

	useEffect(() => {
		// console.log({ errors });
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: ArticleFormData) => {
		const newData: ArticleFormData = { ...data, author: authUser?.id };
		const fd = new FormData();
		for (let key of Object.keys(newData)) {
			let value = newData[key as keyof ArticleFormData];
			fd.append(key, value as keyof ArticleFormData);
		}
		const res = await addOrUpdate({ slug: data?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Actualité ${
					item ? "modifié" : "ajouté"
				} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				reset();
				resetArticle();
				go("notre-actu");
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		description,
		onChangeDescription,
		descriptionEn,
		onChangeDescriptionEn,
		handleChangeImageCouverture,
		imageCouverture,
		item,
		onChangeType,
		type,
		handleChangeFichier,
		fichier,
	};
}
