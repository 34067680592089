import React from "react";
import { createMarkup, formatCurrency } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";


function Description({ product }) {
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { t } = useTranslation();
	
	return (
		<div className="particulier-details-product-section-tabs-description">
			<div
				className="particulier-details-product-section-tabs-description-text"
				dangerouslySetInnerHTML={createMarkup(
					locale == "fr" ? product?.description : product?.description_en 
				)}
			/>
			<div className="particulier-details-product-section-tabs-description-table-container my-5">
				<table className="table">
					<tbody>
						{product?.variations?.map((v) => (
							<tr key={v?.slug}>
								<th scope="row">{v?.taille}</th>
								<td>
									{formatCurrency(v?.prix)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Description;
