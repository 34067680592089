import React from "react";
import AuthWrapper from "./AuthWrapper";
import { FormError, Input, RequiredText } from "../common/Input";
import { BtnSubmit } from "../common/Button";
import BecomeProModal from "./modals/BecomeProModal";
import useBecomeProfessionnal from "./hooks/useBecomeProfessionnal";
import FormLabel from "../common/Label";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../common/GoogleInput";
import { useTranslation } from "react-i18next";


function BecomeProfessional() {
	const { t } = useTranslation();
	const [showModal, setShowModal] = React.useState<boolean>();
	const toggle = () => {
		setShowModal((showModal) => !showModal);
	};
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		onChangeVille,
		handleChangePhone,
		ville,
		phone,
	} = useBecomeProfessionnal(toggle);

	return (
		<AuthWrapper>
			<h2 className="top-title text-center">
				{t("becoming_professional")}
			</h2>
			<p className="custom-top-description text-center pt-5">
				{t("becoming_professional_description")}
			</p>
			<div className="form pt-4">
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-12 input-wrapper">
							<Input
								label={t('company')}
								type="text"
								placeholder={t('company')}
								id="societe"
								{...register("societe")}
								error={errors?.societe}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label="NINEA"
								type="text"
								placeholder="NINEA"
								id="ninea"
								{...register("ninea")}
								error={errors?.ninea}
								required
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label={t("first_name")}
								type="text"
								placeholder={t("first_name")}
								required
								id="prenom"
								{...register("prenom")}
								error={errors?.prenom}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label={t("last_name")}
								type="text"
								placeholder={t("last_name")}
								id="nom"
								{...register("nom")}
								error={errors?.nom}
								required
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label="Email"
								type="email"
								placeholder="Email"
								required
								id="email"
								{...register("email")}
								error={errors?.email}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<FormLabel
								label={t("phone")}
								required
								customClassLabel={
									"input-parametre-label-form-add"
								}
							/>
							<div className="input-group mb-3">
								<PhoneInput
									defaultMask={
										".. ... .. .."
									}
									containerClass="react-custom-tel-input mb-3 p-0"
									inputClass=" form-control form-control-modal-custom input-parametre-form-add"
									country={"sn"}
									inputProps={{
										name: "telephone",
										required: true,
										autoFocus: true,
									}}
									countryCodeEditable={
										false
									}
									enableAreaCodes={true}
									disableDropdown={true}
									prefix="+"
									value={phone}
									onChange={(
										phone,
										country: any
									) =>
										handleChangePhone(
											phone,
											country
										)
									}
									inputStyle={{
										width: "100%",
										paddingBottom:
											"22px",
										borderRadius:
											"10px",
										height: 50,
									}}
								/>
								<FormError
									error={errors?.telephone}
								/>
							</div>
						</div>
						<div className="col-md-12 input-wrapper mb-3">
							<FormLabel
								label={t("city")}
								required
								customClassLabel={
									"input-parametre-label-form-add"
								}
							/>
							<GoogleInput
								className="form-control form-control-modal-custom input-parametre-form-add"
								value={ville}
								onChange={onChangeVille}
								placeholder={t("city")}
							/>
							<FormError error={errors?.ville} />
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label={t("activity_sector")}
								type="text"
								placeholder={t("activity_sector")}
								id="secteur"
								{...register("secteur")}
								error={errors?.secteur}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<label
								htmlFor="message_"
								className={`form-label form-label-modal-custom`}
							>
								Message
								<RequiredText />
							</label>
							<textarea
								id="message_"
								rows={8}
								className="form-control form-control-modal-custom"
								placeholder="Message"
								{...register("message")}
							></textarea>
							<FormError error={errors?.message} />
						</div>
					</div>
					<div className="btn-content pt-5">
						<BtnSubmit
							label={t("send")}
							isLoading={isLoading}
							className="btn btn-auth w-100"
						/>
						<BecomeProModal
							show={showModal}
							setShow={setShowModal}
						/>
					</div>
				</form>
			</div>
		</AuthWrapper>
	);
}

export default BecomeProfessional;
