import React from "react";
import "./StockProfessionnel.css";
import Select from 'react-select';
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import StockTable from "./tables/StockTable";
import { useTranslation } from "react-i18next";


const StockProfessionnel = () => {
	const { t } = useTranslation();

  return (
		<div className="dashbord-admin-component colgate_wrapper p-3">
			<div className="dash-admin-page-content-container mb-3">
				<div className="d-flex top_title__container mb-4">
					<h2 className="chart-item-admin-title mb-0">
						Stock
					</h2>
				</div>
				<div className="row flex-r pb-4 pt-2">
					<div className="col-md-4 search-bar-container d-flex align-items-center gap-2">
						<input
							type="search"
							placeholder={t("search")}
							className="search-bar-conseil"
						/>
						<span className="icon-container">
							<HiOutlineBars3BottomRight />
						</span>
					</div>
				</div>
				<div className="content-graph-admin">
					<div className="stock-table-container">
						<StockTable />
					</div>
				</div>
			</div>
		</div>
  );
};

export default StockProfessionnel;
