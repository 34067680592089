import { NavLink } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import PanierRegisterModal from "./Modals/PanierRegisterModal";
import { AlertInfo } from "../../common/Alert";
import { formatCurrency, getImage, showModal } from "../../../utils/Utils";
import FormOrder from "./FormOrder";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useCart } from "./useCart";
import { isProfessionnel } from "../../../routes/routerUtils";
import { useTranslation } from "react-i18next";


function StepOfDelivery() {
  const { t } = useTranslation();
  
  return (
    <div className="row">
      <div className="col-lg-4 mb-3">
        <div className="panier-particulier-infos-service-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <circle cx="24" cy="24" r="24" fill="#DEE2E7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30 20H29V18C29 15.24 26.76 13 24 13C21.24 13 19 15.24 19 18V20H18C16.9 20 16 20.9 16 22V32C16 33.1 16.9 34 18 34H30C31.1 34 32 33.1 32 32V22C32 20.9 31.1 20 30 20ZM24 29C22.9 29 22 28.1 22 27C22 25.9 22.9 25 24 25C25.1 25 26 25.9 26 27C26 28.1 25.1 29 24 29ZM21 20V18C21 16.34 22.34 15 24 15C25.66 15 27 16.34 27 18V20H21Z"
              fill="#8B96A5"
            />
          </svg>
          <div className="panier-particulier-infos-service-title-container">
            <div className="panier-particulier-infos-service-title">
              {t("secure_payment")}
            </div>
            {/* <div className="panier-particulier-infos-service-texte">
              Lorem ipsum dolor sit
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-lg-4 mb-3">
        <div className="panier-particulier-infos-service-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <circle cx="24" cy="24" r="24" fill="#DEE2E7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M32 15H16C14.9 15 14.01 15.9 14.01 17L14 35L18 31H32C33.1 31 34 30.1 34 29V17C34 15.9 33.1 15 32 15ZM19 22H29C29.55 22 30 22.45 30 23C30 23.55 29.55 24 29 24H19C18.45 24 18 23.55 18 23C18 22.45 18.45 22 19 22ZM25 27H19C18.45 27 18 26.55 18 26C18 25.45 18.45 25 19 25H25C25.55 25 26 25.45 26 26C26 26.55 25.55 27 25 27ZM29 21H19C18.45 21 18 20.55 18 20C18 19.45 18.45 19 19 19H29C29.55 19 30 19.45 30 20C30 20.55 29.55 21 29 21Z"
              fill="#8B96A5"
            />
          </svg>
          <div className="panier-particulier-infos-service-title-container">
            <div className="panier-particulier-infos-service-title">
              {t("customer_service")}
            </div>
            {/* <div className="panier-particulier-infos-service-texte">
              Lorem ipsum dolor sit
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-lg-4 mb-3">
        <div className="panier-particulier-infos-service-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <circle cx="24" cy="24" r="24" fill="#DEE2E7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.5 20H29V18C29 16.9 28.1 16 27 16H15C13.9 16 13 16.9 13 18V27C13 28.1 13.9 29 15 29C15 30.66 16.34 32 18 32C19.66 32 21 30.66 21 29H27C27 30.66 28.34 32 30 32C31.66 32 33 30.66 33 29H34C34.55 29 35 28.55 35 28V24.67C35 24.24 34.86 23.82 34.6 23.47L32.3 20.4C32.11 20.15 31.81 20 31.5 20ZM18 30C17.45 30 17 29.55 17 29C17 28.45 17.45 28 18 28C18.55 28 19 28.45 19 29C19 29.55 18.55 30 18 30ZM31.5 21.5L33.46 24H29V21.5H31.5ZM30 30C29.45 30 29 29.55 29 29C29 28.45 29.45 28 30 28C30.55 28 31 28.45 31 29C31 29.55 30.55 30 30 30Z"
              fill="#8B96A5"
            />
          </svg>
          <div className="panier-particulier-infos-service-title-container">
            <div className="panier-particulier-infos-service-title">
              {t("delivery_at_your_expense")}
            </div>
            {/* <div className="panier-particulier-infos-service-texte">
              Lorem ipsum dolor sit
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const Panier = () => {
  const { t } = useTranslation();
  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';

  const {
    panier,
    isItemInTheCard,
    incrementQuantite,
    decrementQuantite,
    handleDeleteProductToCart,
    onSelectProduit,
    onSelectAll,
    onClearCart,
    cartToOrder,
    user,
    cartItems,
    isLoading,
    totalMontant,
    setCodePromo,
    sendCodePromo,
    addToCartDirectiled,
  } = useCart();

  const isPro = isProfessionnel(user);
  const routeShop = isProfessionnel(user)
    ? "/professionel/catalogue"
    : "/boutique";

  const onChangeCodePromo = (e) => {
    setCodePromo(e.target.value);
  };


  return (
    <div className={!isPro ? "page-content pt-5-content-page" : ""}>
      <div className="container-casadeliz pt-5 mb-5">
        <section className="section-boutique custom-section-divider">
          <div className="row">
            <div className="col-lg-9 mb-3">
              <div className="panier-particulier-list-item-container mb-5">
                <div className="list-group mb-3">
                  {!isLoading ? (
                    panier?.length > 0 ? (
                      panier?.map((item, i) => (
                        <div key={item?.variation?.slug + i}>
                          <div className="list-group-item particulier-list-group-item pb-0">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value={item?.slug}
                              onChange={(e) => onSelectProduit(e)}
                              checked={cartToOrder.some(
                                (elem) => elem?.slug === item?.slug
                              )}
                            />
                            <div className="row w-100">
                              <div className="col-lg-6 mb-3">
                                <div className="particulier-panier-item-infos-container">
                                  <div className="particulier-panier-image-item-container">
                                    <img
                                      src={getImage(item?.variation?.image)}
                                      alt="image produit casadeliz"
                                      className="particulier-panier-image-item"
                                    />
                                  </div>
                                  <div className="particulier-panier-titre-item-container">
                                    <div className="particulier-panier-titre-item mb-2">
                                      { locale == "fr" ? item?.variation?.item?.nom : item?.variation?.item?.nom_en} {item?.variation?.taille}
                                    </div>
                                    <div className="btn particulier-panier-btn-annuler-item-container">
                                      <button
                                        className="btn particulier-panier-btn-annuler-item"
                                        onClick={() =>
                                          handleDeleteProductToCart(item)
                                        }
                                      >
                                        {t("remove")}
                                      </button>
                                      <button className="btn particulier-panier-btn-annuler-item">
                                        {t('add_to_favorites')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 mb-3">
                                <input
                                  type="text"
                                  className="form-control particulier-panier-infos-bon-form-control"
                                  placeholder={t("apply_code")}
                                  aria-label="Recipient's username"
                                  aria-describedby="button-addon2"
                                  onChange={(e) => onChangeCodePromo(e)}
                                />
                                <button
                                  className="btn particulier-panier-btn-annuler-item mt-2"
                                  type="button"
                                  id="button-addon2"
                                  onClick={() =>
                                    !user?.id
                                      ? showModal("PanierRegisterModal")
                                      : sendCodePromo(item)
                                  }
                                >
                                  {t("apply")}
                                </button>
                              </div>
                              <div className="col-lg-3 mb-3">
                                <div className="particulier-panier-prix-item mb-2">
                                  {formatCurrency(item?.prix_total)}
                                </div>
                                <div className="particulier-panier-quantite-item-container">
                                  {/* <input className="form-control input-quantite-panier" onChange={(e) => addToCartDirectiled(e,item)}  defaultValue={item?.quantite} min={1} type="number" /> */}

                                  <div className="pb-3 d-flex align-items-center gap-5 qat_panier">
                                    <div className="m-0 custom_sm__title product__number btn_logiques">
                                      <button
                                        onClick={() => decrementQuantite(item)}
                                        disabled={item?.quantite === 1}
                                      >
                                        {" "}
                                        -{" "}
                                      </button>
                                      <span className="px-4">
                                        {item?.quantite}
                                      </span>
                                      <button
                                        onClick={() => incrementQuantite(item)}
                                        disabled={item?.quantite  == item?.variation?.stock}
                                      >
                                        {" "}
                                        +{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {(item?.quantite  == item?.variation?.stock && (
                                  <p className="message-stock-atteint">{t("total_stock_has_been_reached")}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="row">
                        {cartItems?.length > 0 ? (
                          <AlertInfo message={t("your_cart_not_empty")} />
                        ) : (
                          <AlertInfo message={t("your_cart_empty")} />
                        )}
                      </div>
                    )
                  ) : (
                    <>
                      {[...Array(3)].map((item, i) => (
                        <PanierItemSkeleton key={i} />
                      ))}
                    </>
                  )}
                  {isItemInTheCard && (
                    <label className="list-group-item particulier-list-group-item align-items-center pb-2">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        onChange={onSelectAll}
                      />
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="particulier-panier-label-select-all">
                            {t('all')}
                          </div>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
                <div className="particulier-panier-btn-retour-container">
                  <NavLink
                    to={routeShop}
                    className="btn particulier-panier-btn-retour"
                  >
                    <BsArrowLeftShort /> {t('back_to_store')}
                  </NavLink>
                  {panier?.length > 1 && (
                    <button
                      className="btn particulier-panier-btn-tout-enlever"
                      onClick={() => onClearCart()}
                    >
                      {t('remove_all')}
                    </button>
                  )}
                </div>
              </div>
              <StepOfDelivery />
            </div>
            <div className="col-lg-3 mb-3">
              <FormOrder
                user={user}
                isSelectedItem={cartToOrder?.length > 0}
                totalAmount={totalMontant}
                cartToOrder={cartToOrder}
              />
            </div>
          </div>
        </section>
      </div>
      <PanierRegisterModal />
    </div>
  );
};

function PanierItemSkeleton() {
  return (
    <div className="list-group-item particulier-list-group-item pb-0">
      <VolkenoSkeleton height={30} width={30} variant="rect" borderRadius={5} />
      <div className="row w-100">
        <div className="col-lg-8 mb-3">
          <div className="particulier-panier-item-infos-container">
            <div className="particulier-panier-image-item-container">
              <VolkenoSkeleton
                height={10}
                width={10}
                variant="rect"
                className="particulier-panier-image-item"
              />
            </div>
            <div className="particulier-panier-titre-item-container">
              <div className="particulier-panier-titre-item mb-2">
                <VolkenoSkeleton height={10} width={200} variant="rect" />
              </div>
              <div className="btn particulier-panier-btn-annuler-item-container">
                <VolkenoSkeleton height={20} width={60} variant="rect" />

                <VolkenoSkeleton height={20} width={60} variant="rect" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="particulier-panier-prix-item mb-2">
            <VolkenoSkeleton height={10} width={100} variant="rect" />
          </div>
          <div className="particulier-panier-quantite-item-container">
            <VolkenoSkeleton height={40} width={100} variant="rect" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panier;
