import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { FaqFormData, Faq } from "./faq.type";
import { QueryUrl } from "../../Utils";

export const FaqApi = createApi({
	reducerPath: "faqAPi",
	tagTypes: ["faq", "faq_by_slug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),

	endpoints: (builder) => ({
		getFaq: builder.query<PaginationResults<Faq>, TypeQuery>({
			query: (query) => QueryUrl(`faqs/`, query),
			providesTags: ["faq"],
		}),
		getFaqForVisitors: builder.query<PaginationResults<Faq>, TypeQuery>(
			{
				query: (query) => QueryUrl(`visiteur/faqs/`, query),
				providesTags: ["faq"],
			}
		),
		getFaqBySlug: builder.query<Faq, string>({
			query: (slug) => `faqs/${slug}/`,
			providesTags: ["faq_by_slug"],
		}),
		addOrUpdateFaq: builder.mutation<
			Faq,
			{ slug?: string; data: FaqFormData | FormData }
		>({
			invalidatesTags: ["faq"],

			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `faqs/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `faqs/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editFaq: builder.mutation<
			Faq,
			{ slug?: string; data: FaqFormData | FormData }
		>({
			invalidatesTags: ["faq"],
			query: ({ slug, data }) => {
				return {
					url: `faqs/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteFaq: builder.mutation<Faq, string>({
			query: (slug) => ({
				url: `faqs/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["faq"],
		}),
	}),
});

export const {
	useGetFaqQuery,
	useGetFaqBySlugQuery,
	useEditFaqMutation,
	useLazyGetFaqQuery,
	useAddOrUpdateFaqMutation,
	useDeleteFaqMutation,
	useGetFaqForVisitorsQuery,
} = FaqApi;
