import React from "react";
import { Article } from "../../utils/api/article/article.type";
import FormPostComment from "./FormPostComment";
import { useAppSelector } from "../../redux/hook";
import { AlertInfo } from "./Alert";
import CommentItem from "../front/actualite/CommentItem";
import { useTranslation } from "react-i18next";

function Forum({ item }: { item?: Article }) {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	
	const { user } = useAppSelector((s) => s?.user);
	return (
		<div className="casadeliz-commentaire-item-container">
			{item?.comments?.map((comment) => (
				<CommentItem
					key={comment?.slug}
					comment={comment}
					user={user}
				/>
			))}

			{!!user ? (
				<div>
					<div className="custom-forum-titre-reponse my-4">
						{t('post_comment')}
					</div>
					<FormPostComment item={item} />
				</div>
			) : (
				<AlertInfo message={t('please_log_to_join_discussion')} />
			)}
		</div>
	);
}

export default Forum;
