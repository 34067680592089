import React from "react";
import "./MonCompteParticulier.css";
import MonCompte from "./Tabs/MonCompte";
import MonMotDePasse from "./Tabs/MonMotDePasse";
import MesCommande from "./Tabs/MesCommande";
import MesAnnulations from "./Tabs/MesAnnulations";
import MesFavoris from "./Tabs/MesFavoris";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { onlogout } from "../../../redux/slice/User.slice";
import { useLocation, useNavigate } from "react-router-dom";
import DetailCommandeParticulier from "./Tabs/DetailCommandeParticulier";
import { useItem } from "../../../utils/hooks";
import { Order } from "../../../utils/api/order/order.type";
import { useGetFavoriteProductByUserQuery } from "../../../utils/api/product/product.api";
import { useTranslation } from "react-i18next";



function MonCompteParticulier() {
	const { t } = useTranslation();

const steps = [
	{ id: "mon-compte", Component: MonCompte },
	{ id: "mot-de-passe", Component: MonMotDePasse },
	// { id: "mes-options", Component: MesOptionsPaiement },
	{ id: "mes-commandes", Component: MesCommande },
	{ id: "mes-annulations", Component: MesAnnulations },
	{ id: "mes-favoris", Component: MesFavoris },
	{ id: "detailCommande", Component: DetailCommandeParticulier },
];

const tabs = [
	{
		title: t("manage_my_account"),
		subTabs: [
			{ titre: t("my_profile"), index: 0 },
			{ titre: t("my_password"), index: 1 },
			// { titre: "Mes options de paiement", index: 2 },
		],
	},
	{
		title: t("my_order"),
		subTabs: [
			{ titre: t("my_order"), index: 2 },
			{ titre: t("my_cancellations"), index: 3 },
		],
	},
	{
		title: t("my_favorites"),
		subTabs: [{ titre: t("my_favorites"), index: 4 }],
	},
];
	const { user } = useAppSelector((s) => s?.user);
	const location = useLocation();

	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({
		initialStep: location?.state?.active ?? 0,
		steps,
	});
	const { Component } = step;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, getItem } = useItem<Order>();

	const handleLogout = () => {
		dispatch(onlogout());
		navigate("/", { replace: true });
	};
	const { data = [] } = useGetFavoriteProductByUserQuery({
		slug: user?.slug,
	});
	return (
		<div className="page-content mt-0  pt-5">
			<div className="container-casadeliz mb-5">
				<section className="section-boutique custom-section-divider">
					<div className="detail-livraison-titre mb-3">
						<span>{t("home")} /</span> {t("my_account")}
					</div>
					<div className="row">
						<div className="col-lg-3 mb-3">
							<ul className="tabs-container tabs-container-particulier mb-0 pb-2 ps-0">
								{tabs.map(
									(tab: any, key: any) => (
										<div key={key}>
											<div className="tab-items-title">
												{
													tab?.title
												}
											</div>
											{tab?.subTabs?.map(
												(
													subtab: any,
													subkey: number
												) => {
													return (
														<li
															className={`tab-item ${
																key +
																	key +
																	subkey ===
																	index &&
																"tab-active-item-particulier"
															}`}
															key={
																subtab?.index
															}
															onClick={() => {
																go(
																	subtab?.index
																);
															}}
															role="button"
														>
															<span className="relative">
																{
																	subtab?.titre
																}
																{subtab?.titre ===
																	"Mes favoris" && (
																	<span className="counter-notif-panier">
																		{
																			data?.length
																		}
																	</span>
																)}
															</span>
														</li>
													);
												}
											)}
										</div>
									)
								)}
								<li>
									<button
										className="btn auth-submit-btn"
										onClick={() =>
											handleLogout()
										}
									>
										{t("logout")}
									</button>
								</li>
							</ul>
						</div>
						<div className="col-lg-9 mb-3">
							<div className="tabs-component-container">
								<Component
									user={user}
									item={item}
									getItem={getItem}
									go={go}
								/>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default MonCompteParticulier;
