import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Team, TeamFormData } from "../../../../utils/api/team/team.type";
import { useAddOrUpdateTeamMutation } from "../../../../utils/api/team/team.api";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { QueryError } from "../../../../utils/type";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useCrudTeam(resetItem:any,go?: any, item?: Team) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prénom"),
		x: yup.string().url().label("Le lien"),
		facebook: yup.string().url().label("Le lien"),
		fonction: yup.string().required().label("Fonction"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError,
	} = useForm<TeamFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [image, setImage] = useState<string>();

	const [addOrEditMember, { isLoading }] = useAddOrUpdateTeamMutation();

	useEffect(() => {
		console.log(errors);
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
			setValue("prenom", item?.prenom);
			setValue("x", item?.x);
			setValue("facebook", item?.facebook);
			setValue("fonction", item?.fonction);
		}
	}, [item]);

	const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			setImage(URL.createObjectURL(file));
			setValue("avatar", file);
		}
	};

	const onSubmit = async (data: TeamFormData) => {
		if (!item && !data?.avatar) {
			return setError("avatar", { message: "La Photo est obligatoire" });
		}
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await addOrEditMember({ slug: item?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Membre ${!item ? "ajouté" : "modifié"} avec succès !`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1000,
			}).then(() => {
				reset();
				setImage(undefined);
				resetItem();
				go && go("equipe");
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		image,
		handleChangeImage,
	};
}

export default useCrudTeam;
