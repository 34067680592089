import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import {
	CommentProductData,
	Product,
} from "../../../../utils/api/product/product.type";
import { usePostCommentProductMutation } from "../../../../utils/api/product/product.api";
import { useAppSelector } from "../../../../redux/hook";
import { cleannerError } from "../../../../utils/Utils";
import { QueryError } from "../../../../utils/type";

export function usePostCommentProduct(item?: Product) {
	const validationSchema = yup.object().shape({
		content: yup.string().required().label("Commentaire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm<CommentProductData>({
		resolver: yupResolver(validationSchema),
	});
	const [postComment, { isLoading }] = usePostCommentProductMutation();
	const { user: authUser } = useAppSelector((s) => s?.user);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: CommentProductData) => {
		const newData: CommentProductData = {
			...data,
			produit: item?.id as number,
			user: authUser?.id,
		};
		console.log({ data, newData });
		const res = await postComment(newData);
		if ("data" in res) {
			setValue("content", "");
		} else if ("error" in res) {
			const err = res?.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		authUser,
	};
}
