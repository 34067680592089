import React, { useState } from "react";
import MyDropzone from "../../../common/MyDropzone";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import articleIcon from "../../../../assets/icons/article.svg";
import { usePagination } from "../../../../utils/hooks";
import { useGetArticlesQuery } from "../../../../utils/api/article/article.api";
import { AlertInfo } from "../../../common/Alert";
import moment from "moment";
import {
	IconAudio,
	IconHearts,
	IconLink,
	IconVideo,
} from "../../../shared/Icons";
import { createMarkup, getImage, getName } from "../../../../utils/Utils";
import { CustomPagination } from "../../../common/CustomPagination";

function OptionNotreActuAdmin({ go, getArticle }) {
	const [pictures, setPictures] = useState<File | null>();
	const { limit, page, onChange } = usePagination(8);
	const { data, isLoading } = useGetArticlesQuery({ limit, page });
	return (
		<div className="container-fluid">
			<div className="form-add-container">
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="container-display-title-theme">
						<button
							className="btn btn-theme-admin"
							onClick={() => go("ajout-actu")}
						>
							<span>
								Ajouter une actualité
								<AiOutlinePlusCircle />
							</span>
						</button>
					</div>
				</div>
				<form className="mt-4">
					<div className="row row-add-student my-3">
						<div className="col-lg-12 mb-3">
							<label
								className={`form-label form-label-modal-custom input-parametre-label-form-add`}
							>
								Bannière
							</label>
							<MyDropzone
								setImages={setPictures}
								maxFile={10}
							/>
						</div>

						<div className="container pt-4">
							<div className="row custom-section-divider">
								{!isLoading ? (
									data &&
									data?.results?.length >
										0 ? (
										<>
											{data?.results?.map(
												(
													item
												) => (
													<div
														className="col-xl-6 mb-4"
														key={
															item?.slug
														}
													>
														<div
															onClick={() => {
																getArticle(
																	item
																);
																go(
																	"details-actu"
																);
															}}
															className="no-link"
															style={{
																cursor: "pointer",
															}}
														>
															<div className="page-actu-card-item custom-page-actu-card-item-option row">
																<div className="col-lg-5 p-0">
																	{item?.content_type !==
																		"text" && (
																		<>
																			<div
																				className={`page-actu-card-item-content-infos-actu-container-type-lien mb-3 custom-flex-direction`}
																			>
																				<div
																					className={`page-actu-card-item-content-infos-actu-user-container custom-flex-direction`}
																				>
																					<div className="page-actu-card-item-content-infos-actu-user-type-lien">
																						{item?.content_type ===
																						"link"
																							? "Liens"
																							: item?.content_type ===
																							  "audio"
																							? "Podcasts"
																							: item?.content_type ===
																							  "video"
																							? "Vidéos"
																							: "Article"}
																					</div>
																					<div className="page-actu-card-item-content-infos-actu-date-type-lien">
																						{moment(
																							item?.created_at
																						).format(
																							"DD MMM YYYY"
																						)}
																					</div>
																				</div>
																				<div className="page-actu-card-item-content-infos-actu-like-type-lien">
																					<IconHearts />
																					<span>
																						{
																							item?.likes_count
																						}
																					</span>
																				</div>
																			</div>
																			<div className="page-actu-card-item-content-infos-actu-container-type-lien-icon-container">
																				{item?.content_type ===
																				"link" ? (
																					<IconLink />
																				) : item?.content_type ===
																				  "audio" ? (
																					<IconAudio />
																				) : item?.content_type ===
																				  "video" ? (
																					<IconVideo />
																				) : (
																					<img
																						src={
																							articleIcon
																						}
																						alt="icon article"
																					/>
																				)}
																			</div>
																		</>
																	)}
																	{item?.content_type ===
																		"text" && (
																		<img
																			src={getImage(
																				item?.backgound_image
																			)}
																			alt="cover image"
																			className="page-actu-card-item-img custom-page-actu-card-item-img-option"
																		/>
																	)}
																</div>
																<div className="col-lg-7 mb-3">
																	<div className="page-actu-card-item-content-container">
																		{item?.content_type ===
																			"text" && (
																			<div className="page-actu-card-item-content-infos-actu-container mb-3">
																				<div className="page-actu-card-item-content-infos-actu-user-container">
																					<div className="page-actu-card-item-content-infos-actu-user">
																						{getName(
																							item?.author
																						)}
																					</div>
																					<div className="page-actu-card-item-content-infos-actu-date">
																						{moment(
																							item?.created_at
																						).format(
																							"DD MMM YYYY"
																						)}
																					</div>
																				</div>
																				<div className="page-actu-card-item-content-infos-actu-like">
																					<IconHearts />
																					<span>
																						{
																							item?.likes_count
																						}
																					</span>
																				</div>
																			</div>
																		)}
																		<div
																			className={`page-actu-card-item-content-title mb-3`}
																		>
																			{
																				item?.titre
																			}
																		</div>
																		<div
																			className="page-actu-card-item-content-description mb-3"
																			dangerouslySetInnerHTML={createMarkup(
																				item?.content?.slice(
																					0,
																					50
																				)
																			)}
																		/>

																		{item?.content_type ===
																			"link" && (
																			<Link
																				to={
																					"#"
																				}
																				className="page-actu-card-item-content-link"
																			>
																				{
																					item?.link
																				}
																			</Link>
																		)}
																		{item?.content_type ===
																			"video" ||
																			(item?.content_type ===
																				"audio" && (
																				<div className="page-actu-card-item-content-link">
																					{
																						item?.duree
																					}
																				</div>
																			))}
																	</div>
																</div>
															</div>
														</div>
													</div>
												)
											)}
										</>
									) : (
										<AlertInfo message="Auccune donnée trouvée" />
									)
								) : (
									<></>
								)}
								{data &&
									data?.results?.length >
										limit && (
										<div className="custom-pagination-container">
											<CustomPagination
												nbPages={
													data?.count
												}
												page={
													page
												}
												onChange={
													onChange
												}
												perPage={
													limit
												}
											/>
										</div>
									)}
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default OptionNotreActuAdmin;
