import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import {
	AdminQuery,
	Admin,
	AdminFormData,
	AdminType,
	IAdminDashboardData,
} from "./admin.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const AdminApi = createApi({
	reducerPath: "adminApi",
	tagTypes: ["admin", "dashboard"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		meAdmin: builder.query<Admin, void>({
			query: () => "auth/me-admin/",
			providesTags: ["admin"],
		}),
		getAdminsList: builder.query<PaginationResults<Admin>, AdminQuery>({
			query: (query) => QueryUrl("user/admins/", query),
			providesTags: ["admin"],
		}),
		addOrUpdateAdmin: builder.mutation<Admin,{slug?: string;user_type?: AdminType.admin | AdminType.superadmin;data: AdminFormData | FormData;}>({
			invalidatesTags: ["admin"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `user/admins/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `user/admins/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editAdmin: builder.mutation<
			Admin,
			{
				slug?: string;
				user_type?: AdminType;
				data: AdminFormData | FormData;
			}
		>({
			query: ({ slug, user_type, data }) => {
				return {
					url: `${
						user_type === AdminType.admin
							? `user/admins/${slug}/`
							: `users/${slug}/`
					}`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["admin"],
		}),
		deleteAdmin: builder.mutation<Admin, string>({
			query: (slug) => ({
				url: `user/admins/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["admin"],
		}),
		getDashboardData: builder.query<
			IAdminDashboardData,
			{ date?: string; q?: string }
		>({
			query: ({ date, q }) => ({
				url: "dashboard/",
				params: { date, q },
			}),
			providesTags: ["admin", "dashboard"],
		}),
	}),
});
export const {
	useMeAdminQuery,
	useAddOrUpdateAdminMutation,
	useEditAdminMutation,
	useLazyMeAdminQuery,
	useGetAdminsListQuery,
	useDeleteAdminMutation,
	useGetDashboardDataQuery,
} = AdminApi;
