import React from "react";
import { usePagination } from "../../../../utils/hooks";
import { useAppSelector } from "../../../../redux/hook";
import CommandParticulierTable from "./CommandParticulierTable";
import { useGetCanceledOrderByUserQuery } from "../../../../utils/api/order/order.api";

function MesAnnulations({ getItem, go }) {
	const { user } = useAppSelector((s) => s?.user);
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetCanceledOrderByUserQuery({
		limit,
		page,
		slug: user?.slug,
	});

	return (
		<div className="page-content mt-0">
			<div className="container-casadeliz mb-5">
				<div className="mon-compte-titre-editer mb-3">
					Mes commandes
				</div>
				<CommandParticulierTable
					getItem={getItem}
					go={go}
					data={data}
					isLoading={isLoading}
					onChange={onChange}
					page={page}
					limit={limit}
				/>
			</div>
		</div>
	);
}

export default MesAnnulations;
