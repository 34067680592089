import { Contact } from './../../../../utils/api/config/config.type';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
	ContactFormData,
	UploadFileResult,
} from "../../../../utils/api/config/config.type";
import {
	useAddOrUpdateContactMutation,
	useGetContactQuery,
} from "../../../../utils/api/config/config.api";
import { UPLOAD_FILE_END_POINT } from "../../../../utils/constante";


function useCrudContact() {
	const validationSchema = yup.object().shape({
		background_titre : yup.string().required().label("Titre(fr)"),
		background_titre_en : yup.string().required().label("Titre(en)"),
		background_image: yup.string().required().label("Image"),
		titre_contact : yup.string().required().label("Titre contact(fr)"),
		title_contact_en: yup.string().required().label("Titre contact(en)"),
		description_contact: yup
			.string()
			.required()
			.label("Description(fr)"),
		description_contact_en: yup.string().label("Description(en)"),
		email: yup
			.string()
			.required()
			.label("Email"),
		telephone_1: yup
			.string()
			.required()
			.label("Telephone"),
		telephone_2: yup
			.string()
			.required()
			.label("Telephone"),
		adresse_titre: yup
			.string()
			.required()
			.label("Adresse(fr)"),
		address_titre_en: yup
			.string()
			.required()
			.label("Adresse(en)"),
		adresse_description: yup
			.string()
			.required()
			.label("Description Adresse"),
		adresse_description_en: yup.string().required().label("Description Adresse"),
		adresse: yup.string().required().label("Adresse"),
		
	});
	const { data: contact } = useGetContactQuery();
	const [edit, { isLoading }] = useAddOrUpdateContactMutation();

	const [descriptionContact, setDescriptionContact] = useState<string>();
	const [descriptionContactEn, setDescriptionContactEn] = useState<string>();
	const [adresseDescription, setAdresseDescription] = useState<string>();
	const [adresseDescriptionEn, setAdresseDescriptionEn] = useState<string>();


	const [dataForm, setDataForm] = useState<ContactFormData>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		control,
	} = useForm<ContactFormData>({
		resolver: yupResolver(validationSchema)
	});


	useEffect(() => {
		if (contact) {
			let fields: (keyof ContactFormData)[] = [	
				"background_titre",	
				"background_image",
				"titre_contact",	
				"title_contact_en",	
				"description_contact",	
				"description_contact_en",	
				"email"	,
				"telephone_1",	
				"telephone_2",	
				"adresse_titre",	
				"address_titre_en",	
				"adresse_description",	
				"adresse_description_en",	
				"adresse",
			];

			for (let field of fields) {
				register(field);
				setValue(field, contact[field]);
			}
			setDescriptionContact(contact.description_contact);
			setDescriptionContactEn(contact.description_contact_en);
			setAdresseDescription(contact.adresse_description);
			setAdresseDescriptionEn(contact.adresse_description_en);
		}
	}, [contact]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
		console.log({ errors });
	}, [errors]);

	const updateImage = async (image, key) => {
		let result;
		try {
			let fd = new FormData();
			let files = image;
			fd.append("project_name", "casadeliz");
			fd.append("media_type", "image");
			fd.append("media", files);

			const response = await fetch(UPLOAD_FILE_END_POINT, {
				method: "POST",
				body: fd,
			});
			
			if (response.ok) {
				const res: UploadFileResult = await response.json();
				if ("success" in res) {
					setValue("background_image", res.file_url);
					console.log('response',res.file_url)
				}
			}
		} catch (error) {
			console.log(error);
		}
		
	};

	const handleChangeImage = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			updateImage(file, "contact");
		}
	};


	const handleChange = (
		propertyName: keyof ContactFormData,
		value: string
	) => {
		setDataForm({ ...dataForm, [propertyName]: value });
		setValue(propertyName, value);
	};
	const handleChangeDescriptionContact = (value: string) => {
		setDescriptionContact(value);
		setValue("description_contact", value);
	};
	const handleChangeDescriptionContactEn = (value: string) => {
		setDescriptionContactEn(value);
		setValue("description_contact_en", value);
	};

	const handleChangeAdresseDescription = (value: string) => {
		setAdresseDescription(value);
		setValue("adresse_description", value);
	};
	const handleChangeAdresseDescriptionEn = (value: string) => {
		setAdresseDescriptionEn(value);
		setValue("adresse_description_en", value);
	};


	const onSubmit = async (data: ContactFormData) => {

		let result = await edit({data: data });

		if ("data" in result) {
			Swal.fire({
				icon: "success",
				title: "Modifié avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in result) {
			const err = result.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						contact ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		dataForm,
		handleChange,
		handleChangeImage,
		handleChangeDescriptionContact,
		handleChangeDescriptionContactEn,
		handleChangeAdresseDescription,
		handleChangeAdresseDescriptionEn,
		descriptionContact,
		descriptionContactEn,
		adresseDescription,
		adresseDescriptionEn,
		contact
	};
}

export default useCrudContact;
