import React, { useState } from "react";
import { Input } from "../../../common/Input";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import useChangePassword from "./useChangePassword";

function ChangerPassword() {
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const { register, errors, isLoading, onSubmit } = useChangePassword();

	return (
		<div className="medsain-profil-tab-item">
			<div className="medsain-profil-tab-title">
				Changer mot de passe
			</div>
			<form className="mt-4" onSubmit={onSubmit}>
				<div className="row row-add-student my-3">
					<div className="col-md-4 position-relative">
						<Input
							type={
								showPassword
									? "text"
									: "password"
							}
							label="Ancien mot de passe"
							id="old_password"
							placeholder="********"
							{...register("old_password")}
							error={errors?.old_password}
						/>
						<span
							className="icon-edit-info-profil"
							onClick={() => {
								setShowPassword(!showPassword);
							}}
						>
							{showPassword ? (
								<AiFillEyeInvisible />
							) : (
								<AiFillEye />
							)}
						</span>
					</div>
					<div className="col-md-4 position-relative">
						<Input
							type={
								showNewPassword
									? "text"
									: "password"
							}
							label="Nouveau mot de passe"
							id="new_password"
							placeholder="********"
							{...register("new_password")}
							error={errors?.new_password}
						/>
						<span
							className="icon-edit-info-profil"
							onClick={() => {
								setShowNewPassword(
									!showNewPassword
								);
							}}
						>
							{showNewPassword ? (
								<AiFillEyeInvisible />
							) : (
								<AiFillEye />
							)}
						</span>
					</div>

					<div className="col-md-4 position-relative">
						<Input
							type={
								showConfirmPassword
									? "text"
									: "password"
							}
							label="Confirmer mot de passe"
							id="confirm_password"
							placeholder="********"
							{...register("confirm_password")}
							error={errors?.confirm_password}
						/>
						<span
							className="icon-edit-info-profil"
							onClick={() => {
								setShowConfirmPassword(
									!showConfirmPassword
								);
							}}
						>
							{showConfirmPassword ? (
								<AiFillEyeInvisible />
							) : (
								<AiFillEye />
							)}
						</span>
					</div>
					<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
						<button
							className="btn btn-cancel-admin"
							type="button"
						>
							Annuler
						</button>
						<button
							className="btn btn-theme-admin w-100"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									<span
										className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
										role="status"
									></span>
									<span>
										Enrégistrement...
									</span>
								</>
							) : (
								"Enrégistrer"
							)}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ChangerPassword;
