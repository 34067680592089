import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./Boutique.css";
import Carousel from "react-multi-carousel";
import ReactStars from "react-rating-stars-component";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import Empty from "../../../assets/appImages/category.png";
import { createMarkup, formatCurrency, getImage } from "../../../utils/Utils";
import { useProduct, useToggleFavoriteProduct } from "./hooks/useProduct";
import { AlertInfo } from "../../common/Alert";
import FormPostCommentProduct from "./FormPostComment";
import Comment from "./Comment";
import { useTranslation } from "react-i18next";


export const ProductCarousselItem = ({
  images,
  imgClass,
}: {
  images: any;
  imgClass?: string;
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      itemsToScroll: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      itemsToScroll: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      itemsToScroll: 1,
    },
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const { index, active } = rest;

    return (
      <button
        className={`btn custom-btn-caroussel ${active ? "active" : "inactive"}`}
        onClick={() => onClick()}
      >
        {
          React.Children.toArray(
            images?.map((item, i) => (
              <img
                src={item?.picture ? getImage(item?.picture) : Empty}
                alt="image produit"
                className="w-100"
                key={i}
              />
            ))
          )[index]
        }
      </button>
    );
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      showDots={true}
      renderButtonGroupOutside={true}
      customDot={<CustomDot onClick={() => console.log("clicked")} />}
      autoPlay={false}
      arrows={false}
      className="custom-caroussel-details-produit"
    >
      {React.Children.toArray(
        images?.map((item, i) => (
          <div className="logo-partenaire-casadeliz-container mb-4" key={i}>
            <img
              src={item?.picture ? getImage(item?.picture) : Empty}
              className={`${imgClass}`}
            />
          </div>
        ))
      )}
    </Carousel>
  );
};

function DetailBoutique() {
  const {
    product,
    productNumber,
    setProductNumber,
    handleAddToCart,
    variation,
    handleChangeCurrentVariation,
    user,
    handleRate,
  } = useProduct();

  const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	

  const { toggleFavorite } = useToggleFavoriteProduct(product);

  console.log({product})

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pt-5-content-page">
        <div className="container pt-5 pb-5">
          <section className="section__shop_detail">
            <div className="row mb-5">
              <div className="col-md-5">
                <div className="image-content">
                  <img
                    src={getImage(variation?.image)}
                    alt="image du produit"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div>
                  <div className="custom_product_title mb-3">
                  { locale == "fr" ? product?.nom : product?.nom_en} {(variation?.taille ? variation?.taille : product?.variations[0]?.taille)}
                  </div>
                  {/* <div className="particulier-details-produit-notes-container">
                    <ReactStars
                      count={5}
                      size={14}
                      emptyIcon={<i className="fa-duotone fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#FFC600"
                      edit={!!user}
                      value={variation?.rate}
                      onChange={handleRate}
                    />
                  </div> */}
                  <div className="product_price mb-3">
                    {formatCurrency(variation?.prix)}
                  </div>
                  <h5 className="m-0 pt-3 pb-2 detail_title">{t("details")}</h5>
                  <div
                    className="product_details mb-3"
                    dangerouslySetInnerHTML={createMarkup(locale == 'fr' ? product?.description : product?.description_en )}
                  />
                  <div className="quantity_and_total">
                    <div className="pb-3 d-flex align-items-center gap-5 qat_wrapper">
                      <span className="custom_sm__title">Quantité</span>
                      <p className="m-0 custom_sm__title product__number">
                        <button
                          onClick={() => setProductNumber(productNumber - 1) }
                          disabled={productNumber === 1}
                        >
                          {" "}
                          -{" "}
                        </button>
                        <span className="px-4">{productNumber}</span>
                        <button
                          onClick={() => (variation?.stock ? variation?.stock : product?.variations[0]?.stock) > productNumber ? setProductNumber(productNumber + 1) : setProductNumber(productNumber)}
                        >
                          {" "}
                          +{" "}
                        </button>
                      </p>
                      {((variation?.stock ? variation?.stock : product?.variations[0]?.stock) == productNumber && (
                        <p className="message-stock-atteint">{t("total_stock_has_been_reached")}</p>
                      ))}
                    </div>
                    {/* <div className="d-flex align-items-center gap-5 qat_wrapper mb-3">
											<span className="custom_sm__title">
												Total
											</span>
											<span className="custom_sm__title t_value">
												{formatCurrency(
													Number(
														variation?.stock
													) *
														Number(
															variation?.prix
														)
												)}
											</span>
										</div> */}
                    {product?.variations?.length > 1 && (
                      <div className="d-flex align-items-center gap-5 qat_wrapper">
                        <span className="custom_sm__title">Format</span>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          {product?.variations?.map((item) => (
                            <div
                              key={item?.slug}
                              className={`variation-item ${
                                variation?.id === item?.id ? "active" : ""
                              }`}
                              role="button"
                              onClick={() => handleChangeCurrentVariation(item)}
                            >
                              {item?.taille}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="custom_btn_container pt-5">
                    <button
                      className={`btn btn_add_to_fav mb-3 `}
                      onClick={toggleFavorite}
                    >
                      {t("favourites")}
                      {product?.is_favorite ? (
                        <AiFillHeart size={22} />
                      ) : (
                        <AiOutlineHeart size={22} />
                      )}
                    </button>
                    <button
                      className="btn btn_add_to_cart mb-3"
                      onClick={() => handleAddToCart(variation,product)}
                    >
                      {t("add_cart")} <AiOutlineShoppingCart size={22} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-description-and-comments pt-5">
              <div className="col-md-6">
                <div className="description_and_comments d-flex flex-column gap-5">
                  <Comment product={product} />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <ProductCarousselItem images={product?.images} />
              </div>
            </div>

            {!!user ? (
              <div className="post_response_form pt-5">
                <h3 className="m-0 pb-4 custom__title">
                   {t("post_comment")}
                </h3>
                <FormPostCommentProduct item={product} />
              </div>
            ) : (
              <AlertInfo message={t("message_to_connect")} />
            )}
          </section>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default DetailBoutique;
