export enum ApiCashOut {
	CARD = "BANK_CARD_API_CASH_OUT",
	WAVE = "WAVE",
	ORANGE = "ORANGE_SN_API_CASH_OUT",
	FREE = "FREE_SN_WALLET_CASH_OUT",
}

export interface BankCardData {
	customerFirstName: string;
	customerLastName: string;
	cardNumber: string;
	cardExpireMonth: string;
	cardExpireYear: string;
	cardExpireDate: string;
	cardCVC: string;
	cardType: string;
	cardPhoneNumber: string;
	[key: string]: string;
}

export type PaiementData = {
	apiCash?: string;
	telephone: string;
	amount?: number;
	transactionId?: string | number;
};
export type IntechData = Partial<BankCardData> &
	Partial<{
		amount: number;
		phone: string;
		codeService: string;
		externalTransactionId: string | number;
		callbackUrl: string;
		apiKey: string;
		data: { env: string };
		sender: string;
		merchantName: string;
		operationDescription: string;
		successRedirectUrl: string;
		errorRedirectUrl: string;
	}>;
