import React from "react";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import backgroundImg from "../../../assets/icons/backgroundFaq.svg";
import callIcon from "../../../assets/icons/callIcon.svg";
import chatIcon from "../../../assets/icons/chatIcon.svg";
import "./Faq.css";
import { Accordion } from "react-bootstrap";
import { useGetFaqForVisitorsQuery } from "../../../utils/api/faq/faq.api";
import { usePagination } from "../../../utils/hooks";
import { CustomPagination } from "../../common/CustomPagination";
import { AlertInfo } from "../../common/Alert";
import { createMarkup } from "../../../utils/Utils";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useTranslation } from "react-i18next";


const style = {
	background: `linear-gradient(0deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 100%), url(${backgroundImg}), lightgray 50% / cover no-repeat`,
};

function Faq() {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetFaqForVisitorsQuery({ limit, page });

	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content pb-5">
				<div
					className="casadeliz-section-hero-container mb-4"
					style={style}
				>
					<div className="casadeliz-section-hero-page-title">
						{t("faq_title")}
					</div>
				</div>
				<div className="container pt-4">
					<div className="row custom-section-divider">
						<div className="col-md-12">
							<div className="page-faq-sub-title">
								{t("Do_you_have_questions")} ?
							</div>
							<h3 className="page-faq-title mb-5">
								{t("faq_title")}
							</h3>
							<div className="page-faq-faq-container mb-4">
								{!isLoading ? (
									data &&
									data?.results?.length >
										0 ? (
										<>
											{data?.results?.map(
												(
													item,
													i
												) => (
													<Accordion
														defaultActiveKey="0"
														flush
														key={
															item?.slug
														}
														className="mb-4"
													>
														<Accordion.Item
															// @ts-ignore
															eventKey={
																i
															}
														>
															<Accordion.Header>
																{
																	locale == 'fr' ? item?.titre : item?.titre_en 
																}
															</Accordion.Header>
															<Accordion.Body>
																<div
																	dangerouslySetInnerHTML={createMarkup(
																		locale == 'fr' ? item?.description : item?.description_en
																	)}
																	className="faq-details"
																/>
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>
												)
											)}
										</>
									) : (
										<AlertInfo message="Aucune donnée trouvée" />
									)
								) : (
									<>
										{[...Array(5)].map(
											(item, i) => (
												<div
													className="d-flex flex-column flex-sm-row justify-content-between gap-4"
													key={
														i
													}
												>
													<div className="flex-fill">
														<VolkenoSkeleton
															variant="rect"
															width="100%"
															height={
																20
															}
														/>
													</div>
													<VolkenoSkeleton
														variant="rect"
														width={
															100
														}
														height={
															20
														}
													/>
												</div>
											)
										)}
									</>
								)}
								{data &&
									data?.results?.length >
										limit && (
										<div className="custom-pagination-container">
											<CustomPagination
												nbPages={
													data?.count
												}
												page={
													page
												}
												onChange={
													onChange
												}
												perPage={
													limit
												}
											/>
										</div>
									)}
							</div>
							<div className="page-faq-besoin-aide">
								{t("still_need_help")} ?
							</div>
							<div className="page-faq-besoin-aide-text">
								{t("still_need_help_description")}
							</div>
							<div className="row">
								<div className="col-md-4 mb-3">
									<div className="faq-card-info-aide p-3">
										<div className="d-flex justify-content-center mb-3">
											<img
												src={
													callIcon
												}
												alt="call icon"
											/>
										</div>
										<div className="faq-card-info-aide-title mb-3">
											Assistance 
										</div>
										<div className="faq-card-info-aide-subtitle">
											+221 77 720 79
											80
										</div>
										<div className="faq-card-info-aide-subtitle">
											+221 33 847 02
											63
										</div>
									</div>
								</div>
								<div className="col-md-4 mb-3">
									<div className="faq-card-info-aide p-3">
										<div className="d-flex justify-content-center mb-3">
											<img
												src={
													chatIcon
												}
												alt="chat icon"
											/>
										</div>
										<div className="faq-card-info-aide-title mb-3">
											{t("send_us_message")}
										</div>
										<div className="faq-card-info-aide-link">
											contacts@casadeliz.com
										</div>
									</div>
								</div>
								<div className="col-md-4 mb-3">
									<div className="faq-card-info-aide p-3">
										<div className="d-flex justify-content-center mb-3">
											<img
												src={
													callIcon
												}
												alt="call icon"
											/>
										</div>
										<div className="faq-card-info-aide-title mb-3">
											{t("address")}
										</div>
										<div className="text-center">
											<div className="faq-card-info-aide-subtitle">
												Km 50,
												Route
												Nationale
												6,
											</div>
											<div className="faq-card-info-aide-subtitle">
												Baconding
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
}

export default Faq;
