import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { useLocationState } from "../../../utils/Utils";
import FormCondition from "./FormCondition";
import {
	Condition,
	conditionType,
} from "../../../utils/api/config/config.type";

function AddOrUpdatePolitiques() {
	const condition = useLocationState<Condition>(undefined);

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Paramètres" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title">
						Ajouter politiques de confidentialités
					</div>
					<div className="form-add-container">
						<FormCondition
							type={conditionType.pc}
							condition={condition}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddOrUpdatePolitiques;
