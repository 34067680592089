import React from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { Order } from "../../../../utils/api/order/order.type";
import { CustomPagination } from "../../../common/CustomPagination";
import {
	IconCancel,
	IconDelivered,
	IconInProgress,
	IconInRoad,
} from "../../../shared/Icons";
import { ButtonView } from "../../../common/Button";
import { formatCurrency, formatDate } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";


function CommandParticulierTable({
	getItem,
	go,
	data,
	isLoading,
	page,
	onChange,
	limit,
}) {
	const { t } = useTranslation();

	const actionFormatter = (cell: string, row: Order) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<ButtonView
							onClick={() => {
								getItem(row);
								go("detailCommande");
							}}
						/>
					</div>
				</div>
			</>
		);
	};
	const statusFormatter = (cell: string) => {
		if (cell === "en_cours") {
			return (
				<div className="justify-content-center">
					<span className="status-en-cours">
						<span className="pe-2">
							<IconInProgress />
						</span>
						<span>{t("progress")}</span>
					</span>
				</div>
			);
		} else if (cell === "confirmee") {
			return (
				<div className="justify-content-center">
					<span className="status-en-chemin">
						<span className="pe-2">
							<IconInRoad />
						</span>
						<span>Confirmée{t("progress")}</span>
					</span>
				</div>
			);
		} else if (cell === "livree") {
			return (
				<div className="justify-content-center">
					<span className="status-livre">
						<span className="pe-2">
							<IconDelivered />
						</span>
						<span>{t("delivered")}</span>
					</span>
				</div>
			);
		} else {
			return (
				<div className="justify-content-center">
					<span className="status-annule">
						<span className="pe-2">
							<IconCancel />
						</span>
						<span>{t("cancelled")}</span>
					</span>
				</div>
			);
		}
	};
	const commandNumberFormatter = (cell: string) => {
		return <p className="fw-bold m-0">#{cell}</p>;
	};
	const totalFormatter = (cell: string) => {
		return <p className="fw-bold m-0">{formatCurrency(cell)}</p>;
	};
	const paiementFormatter = (cell: string) => {
		return <p>{cell === "CASH" ? "À la livraison" : "En ligne"}</p>;
	};
	const columns = [
		{
			dataField: "code_commande",
			text: t("orders"),
			formatter: commandNumberFormatter,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell) => formatDate(cell),
		},
		{
			dataField: "total",
			text: "Total",
			formatter: totalFormatter,
		},
		{
			dataField: "moyen_paiement",
			text: t("payment"),
			formatter: paiementFormatter,
		},
		{
			dataField: "moyen_paiement",
			text: t("method_payment"),
		},
		{
			dataField: "statut",
			text: t("status"),
			formatter: statusFormatter,
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					{data && data?.count > limit && (
						<div className="custom-pagination-container">
							<CustomPagination
								nbPages={data?.count}
								page={page}
								onChange={onChange}
								perPage={limit}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
}

export default CommandParticulierTable;
