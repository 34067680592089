/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../utils/Utils";
import { DemandeFormData } from "../../../utils/api/professionnel/professionnel.type";
import { useSendDemandeMutation } from "../../../utils/api/professionnel/professionnel.api";
import { GoogleAdresseType } from "../../../utils/type";

function useBecomeProfessionnal(toggle: () => void) {
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Prenom"),
		nom: yup.string().required().label("Nom"),
		email: yup
			.string()
			.required()
			.email("email invalide")
			.label("Email"),
		telephone: yup.string().required().label("Téléphone"),
		ville: yup.string().required().label("Ville"),
		secteur: yup.string().required().label("Secteur"),
		ninea: yup.string().required().label("Ninea"),
		message: yup.string().required().label("Message"),
		societe: yup.string().required().label("Société"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<DemandeFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [ville, setVille] = useState("");
	const [phone, setPhone] = useState<string | null>(null);
	const [registerPro, { isLoading }] = useSendDemandeMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 6000);
		}
	}, [errors]);

	const onChangeVille = (val: GoogleAdresseType) => {
		setVille(val?.ville);
		if (val?.ville) {
			setValue(`ville`, val?.ville);
		}
	};

	const handleChangePhone = (item: string, country: any) => {
		setPhone(item);
		if (parseInt(item) !== parseInt(country?.dialCode)) {
			setValue("telephone", item);
		} else {
			setValue("telephone", "");
		}
	};

	const onSubmit = async (data: DemandeFormData) => {
		console.log({ data });
		const res = await registerPro(data);
		if ("data" in res) {
			toggle();
			reset();
			setVille("");
			setPhone("");
		}
		if ("error" in res) {
			const err = res.error as any;
			if (
				err?.data?.email?.includes(
					"user with this email already exists."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de l'envoie de la demande",
					showConfirmButton: false,
					timer: 2000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		onChangeVille,
		handleChangePhone,
		ville,
		phone,
	};
}

export default useBecomeProfessionnal;
