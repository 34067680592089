import BreadCrumb from "../../shared/BreadCrumb";
import { NavLink } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import MesProduitsAdminTable from "./MesProduitsAdminTable/MesProduitsAdminTable";
import "./MesProduitsAdmin.css";
import { useSearch } from "../../../utils/hooks";


function MesProduitsAdmin() {
	const { search, handleSearch } = useSearch();

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Mes produits" />
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className="row country-and-search-bar-content pb-4">
							<div className="offset-md-8 col-md-4 search-bar-container d-flex align-items-center gap-2">
								<input
									type="search"
									placeholder="Chercher"
									className="search-bar-conseil"
									onChange={handleSearch}
								/>
								<span className="icon-container">
									<HiOutlineBars3BottomRight />
								</span>
							</div>
						</div>
						<div className="content-graph-admin medsain-page-title-container d-flex justify-content-between gap-3 flex-wrap mb-2">
							<div className="container-display-title-theme">
								<NavLink
									to="/admin/ajouter-un-produit"
									className="btn btn-theme-admin"
								>
									<span>
										Ajouter un produit
										<AiOutlinePlusCircle />
									</span>
								</NavLink>
							</div>
							<div className="container-display-title-theme">
								<NavLink
									to="categories"
									className="btn btn-theme-admin"
								>
									<span>
										Gestion des
										catégories
									</span>
								</NavLink>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="hippocrate-tabs-locataire-container">
								<div className="tabs-component-container">
									<MesProduitsAdminTable
										q={search}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MesProduitsAdmin;
