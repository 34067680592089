import { Order } from "../order/order.type";
import { Variation } from "../product/product.type";
import { TypeQuery, User } from "../user/user.type";

export enum AdminType {
	admin = "admin",
	superadmin = "superadmin",
}

export type Admin = Pick<
	User,
	| "nom"
	| "prenom"
	| "email"
	| "telephone"
	| "is_active"
	| "is_archive"
	| "avatar"
	| "created_at"
	| "adresse"
	| "user_type"
	| "slug"
	| "id"
	| "longitude"
	| "latitude"
	| "secteur"
> & {
	admin_type: AdminType;
	parent: number;
	dashboard: boolean;
	produits: boolean;
	commandes: boolean;
	particuliers: boolean;
	professionnels: boolean;
	options: boolean;
	messages: boolean;
	parametres: boolean;
};

export type AdminFormData = Partial<Admin>;

export interface IWeekData {
	day: string;
	nb_user: number;
}

export interface venteGraphData {
	date: string;
	sum_montant: number;
}
export interface IMonthData {
	start_day: string;
	end_day: string;
	users_count: number;
}

export interface IAdminDashboardData {
	product_in_current_cart_count: number;
	ca: number;
	product_with_0_stock_count: number;
	recent_orders: Order[];
	subscribers_nl_count: number;
	top_ventes: Variation[];
	ventesGraph: venteGraphData[];
}

export type AdminQuery = TypeQuery & {
	nom?: string;
	type?: string;
	prenom?: string;
	telephone?: string;
	email?: string;
	adresse?: string;
};
