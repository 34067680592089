import React from "react";
import { Form } from "react-bootstrap";
import { usePostComment } from "./hooks/usePostComment";
import { Article } from "../../utils/api/article/article.type";
import { getName } from "../../utils/Utils";
import { FormError } from "./Input";
import { BtnSubmit } from "./Button";

const FormPostComment = ({ item }: { item?: Article }) => {
	const { register, authUser, errors, onSubmit, isLoading } = usePostComment(item);
	return (
		<form onSubmit={onSubmit}>
			<div className="row">
				<div className="col-md-6 col-form-register-tabs mb-5">
					<div className="content-col-form-register-tabs">
						<input
							className="form-control form-control-register-forum"
							type="text"
							placeholder="Noms"
							defaultValue={getName(authUser)}
							disabled
						/>
					</div>
				</div>
				<div className="col-md-6 col-form-register-tabs mb-5">
					<div className="content-col-form-register-tabs">
						<input
							className="form-control form-control-register-forum"
							type="email"
							placeholder="Email"
							defaultValue={authUser?.email}
							disabled
						/>
					</div>
				</div>
				<div className="col-md-12 col-form-register-tabs mb-5">
					<div className="content-col-form-register-tabs">
						<textarea
							id="comment"
							className={`form-control form-control-register-forum`}
							cols={30}
							rows={5}
							placeholder="Text"
							{...register("content")}
						/>
						<FormError error={errors?.content} />
					</div>
				</div>
				<div className="col-md-12">
					<div className="form-reponse-btn-container">
						<Form.Check
							type={"checkbox"}
							id={`default-checkbox`}
							label="M'avertir quand quelqu'un répond"
							className="form-reponse-check-label"
							{...register("averti_moi")}
						/>
						<FormError error={errors?.averti_moi} />
						<BtnSubmit
							className="btn form-reponse-btn"
							label="Commenter"
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default FormPostComment;
