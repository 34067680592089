import React, { ReactNode } from 'react';
import Logo from "../../assets/appImages/logo.svg";
import "./Auth.css";
import { NavLink } from 'react-router-dom';


function AuthWrapper({children}: {children: ReactNode}) {
  return (
    <div className='auth-wrapper bg_blur'>
      <div className="logo-content d-flex justify-content-start">
        <NavLink to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
      </div>
      <div className="half_content">
        <div className="auth-card">
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthWrapper;