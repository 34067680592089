import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatDate, getAvatar } from "../../../../utils/Utils";
import { usePagination } from "../../../../utils/hooks";
import { useGetAdminsListQuery } from "../../../../utils/api/admin/admin.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { Admin } from "../../../../utils/api/admin/admin.type";
import { ButtonEditLink, ButtonViewLink } from "../../../common/Button";
import { useToggleArchiveAdmin } from "../hooks/useCrudAdmin";
import { FaLock, FaUnlock } from "react-icons/fa";
import { Color } from "../../../../utils/theme";

function ToggleArchiveAdmin({ item }: { item: Admin }) {
	const toggleArchive = useToggleArchiveAdmin(item);

	return (
		<button
			className={`btn btn-action-filter-icon with-tooltip ${
				item?.is_archive ? "tooltip-red" : ""
			}`}
			data-tooltip-content={
				item?.is_archive ? "Débloquer" : "Bloquer"
			}
			onClick={toggleArchive}
			style={{
				color: !item?.is_archive ? Color.vert : Color.danger,
			}}
		>
			{!item?.is_archive ? <FaUnlock /> : <FaLock />}
		</button>
	);
}

function SousAdminTable({ q }: { q?: string }) {
	const { limit, page, onChange } = usePagination(10);

	const { data, isLoading } = useGetAdminsListQuery({
		limit,
		page,
		q,
	});

	const actionFormatter = (cell: string, row: Admin) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<ButtonViewLink
							to={`${row?.slug}/detail-sous-admin`}
							state={row}
						/>
					</div>
					<div className="container-btn-action-icon-modal">
						<ButtonEditLink
							to={`${row?.slug}/modifier-sous-admin`}
							state={row}
						/>
					</div>
					<div className="container-btn-action-icon-modal">
						<ToggleArchiveAdmin item={row} />
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: Admin) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getAvatar(row?.avatar)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">{cell}</p>
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom",
			text: `Nom`,
			formatter: nameFormatter,
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "telephone",
			text: "Téléphone",
		},
		{
			dataField: "adresse",
			text: "Adresse",
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default SousAdminTable;
