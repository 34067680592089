import React from "react";
import { Input } from "../../common/Input";
import "./MonCompteParticulier.css";

function DetailsLivraisonParticulier() {
  return (
    <div className="page-content mt-0  pt-5">
      <div className="container-casadeliz mb-5">
        <section className="section-boutique custom-section-divider">
          <div className="detail-livraison-titre">Détail de la livraison</div>
          <form action="">
            <div className="row row-add-student my-3">
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Prénom"
                  id="prenom"
                  placeholder="Prénom"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Nom"
                  id="nom"
                  placeholder="Nom"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Adresse"
                  id="adresse"
                  placeholder="Adresse"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Adresse exacte (rue, quartier...) *"
                  id="rue"
                  placeholder="Adresse exacte (rue, quartier...) *"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Ville"
                  id="ville"
                  placeholder="Ville"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Téléphone"
                  id="telephone"
                  placeholder="Téléphone"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Email"
                  id="email"
                  placeholder="Email"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                />
              </div>
              <div className="col-lg-12 mb-3">
                <span className="remind-me d-flex align-items-center gap-1">
                  <input
                    className="custom-detail-form-check form-check-input m-0"
                    type="checkbox"
                    value=""
                    id="remind_me_check"
                  />
                  <label
                    className="custom-detail-form-label form-check-label"
                    htmlFor="remind_me_check"
                  >
                    Enregistrez ces informations pour un paiement plus rapide la
                    prochaine fois
                  </label>
                </span>
              </div>
              <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                <button className="btn custom-btn-confirm w-100" type="submit">
                  Confirmer
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default DetailsLivraisonParticulier;
