import React from "react";
import AuthWrapper from "./AuthWrapper";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Input } from "../common/Input";
import { NavLink } from "react-router-dom";
import { BtnSubmit } from "../common/Button";
import useLogin, { useSocialLogin } from "./hooks/useLogin";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleClientId } from "../../utils/http";
import { useTranslation } from "react-i18next";


function Login() {
	const { t } = useTranslation();
	const { register, onSubmit, errors, isLoading } = useLogin();
	const { loginWithGoogle } = useSocialLogin();
	return (
		<AuthWrapper>
			<h2 className="top-title text-center">{t("login_us")}</h2>
			<div className="social-connect-content d-flex align-items-center justify-content-center gap-3 py-4">
				<button
					type="button"
					className="btn btn-social-connect d-flex align-items-center gap-2"
				>
					<FaFacebookF color="#337FFF" size={20} />
					{t("login_facebook")}
				</button>
				<LoginSocialGoogle
					client_id={GoogleClientId}
					isOnlyGetToken={false}
					onLoginStart={() => console.log("login")}
					onResolve={loginWithGoogle}
					onReject={(err) => {
						console.log(err);
					}}
					scope="email profile"
				>
					<button
						type="button"
						className="btn btn-social-connect d-flex align-items-center gap-2"
					>
						<FcGoogle size={20} />
						{t("login_google")}
					</button>
				</LoginSocialGoogle>
			</div>
			<div className="or-content text-center">
				<span>{t("or")}</span>
			</div>
			<div className="form pt-4">
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-12 input-wrapper">
							<Input
								label="Email"
								type="email"
								placeholder="Email"
								{...register("email")}
								error={errors?.email}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label={t("password")}
								placeholder="********"
								{...register("password")}
								error={errors?.password}
								type="password"
							/>
						</div>
					</div>
					<div className="btn-content pt-3">
						<BtnSubmit
							label={t("login")}
							isLoading={isLoading}
							className="btn btn-auth w-100"
						/>
						{/* <NavLink 
                            to={"/admin/dashboard"}
                            className="btn btn-auth w-100 mb-3"
                        >Connexion admin</NavLink>
                        <NavLink 
                            to={"/professionel/dashboard"}
                            className="btn btn-auth w-100 mb-3"
                        >Connexion professionel</NavLink>
                        <NavLink 
                            to={"/particulier/accueil"}
                            className="btn btn-auth w-100 mb-3"
                        >Connexion particulier</NavLink> */}
					</div>
					<div className="d-flex align-items-center justify-content-between pt-2">
						<span className="remind-me d-flex align-items-center gap-1">
							<input
								className="form-check-input m-0"
								type="checkbox"
								id="remind_me_check"
								{...register("remember_me")}
							/>
							<label
								className="form-check-label"
								htmlFor="remind_me_check"
							>
								{t("remember_me")}
							</label>
						</span>
						<NavLink
							className="forgot-password"
							to={`/forgot-password`}
						>
							{t("forget_password")}
						</NavLink>
					</div>
					<p className="redirection text-center pt-5">
						{t("no_account_yet")}?&nbsp;
						<NavLink
							to={`/inscription`}
							className="go-to-page"
						>
							{t("register")}
						</NavLink>
					</p>
				</form>
			</div>
		</AuthWrapper>
	);
}

export default Login;
