import React from "react";
import "./CommandProfessionnel.css";
import { MdArrowBack, MdOutlineEdit, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import InfosFacturation from "./payment/InfosFacturation";
import ModePaiement from "./payment/ModePaiement";
import Recap from "./payment/Recap";
import RecapCommandTable from "./tables/RecapCommandTable";


const steps = [
    { id: "infos-facturation", Component: InfosFacturation },
    { id: "payment-mode", Component: ModePaiement },
    { id: "recap", Component: Recap }
]
const tabs = [
    "Informations de facturation",
    "Mode de paiement",
    "Récapitulatif"
]
  

const PaymentProcess = () => {
    const {
        step,
        navigation: {previous, go, next},
        index
    }: useStepType = useStep({ initialStep: 0, steps });
    const {Component} = step;
    function stepNumber(k: number) {
        let num: number = 1
        switch (k) {
            case 0:
                num = 1
                break;
            case 1:
                num = 2
                break;
            case 2:
                num = 3
                break;
            default:
                    break;
        }
        return num
    }
    
  return (
    <>
        <div className="dashbord-admin-component colgate_wrapper p-3 mb-3">
            <div className="dash-admin-page-content-container">
                <div className="d-flex top_title__container pb-4">
                    <h2 className="chart-item-admin-title mb-0">
                        Commandes
                    </h2>
                    <div className="right-tabs-content mt-3">
                        <ul className="tabs-container">
                            <li 
                                role="button"
                                className={`tab-item `}
                            >
                                Commandes
                            </li>
                            <li 
                                role="button"
                                className={`tab-item tab-item-active`}
                            >
                                Panier en attente
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="custom_content">
                    <span className="command_status csd_color">Finaliser la commande</span>
                </div>
            </div>
        </div>
        <div className="custom-content">
            <div className="row row-content">
                <div className="col-md-8 mb-3">
                    <div className="colgate-card finalize_steps colgate_wrapper p-3">
                        <ul className="tabs-container">
                        {tabs.map((tab, key) => (
                            <li 
                                role="button"
                                className={`
                                    tab-item
                                    ${index === key ? 'tab-item-active' : ''}
                                `}
                                onClick={() => go(key)}
                            >
                                <span>{stepNumber(key)}</span>&nbsp;
                                {tab}
                            </li>
                        ))}
                        </ul>
                        <Component />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="colgate-card colgate_wrapper">
                        <div className="p-3 d-flex align-items-center justify-content-between">
                            <p className="m-0 card__title">
                                Récapitulatif de la commande <br />
                                <span>2 articles</span>
                            </p>
                            <span className="edit_icon" title="Modifier">
                                <MdOutlineEdit size={22} />
                            </span>
                        </div>
                        <div className="separator"></div>
                        <div className="detail-command-table pt-4">
                            <RecapCommandTable />
                        </div>
                        <div className="card__recap_info p-3">
                            <p className="d-flex align-items-center justify-content-between m-0 pb-3">
                                <span className="sm__value">Montant</span>
                                <span className="sm__value">$90.00</span>
                            </p>
                            <p className="d-flex align-items-center justify-content-between m-0 pb-5">
                                <span className="sm__value">Livraison</span>
                                <span className="sm__value">$0.00</span>
                            </p>
                            <p className="d-flex align-items-center justify-content-between m-0">
                                <span className="sm__value fw-bold">Total</span>
                                <span className="sm__value fw-bold">$90.00</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="options-container d-flex align-items-center pt-2">
            <button
                role="button"
                className="btn btn-theme-go-back"
                onClick={previous}
                disabled={index === 0}
            >
                <MdArrowBack />&nbsp;
                Annuler
            </button>
            <button
                role="button"
                className="btn btn-theme-admin"
                onClick={next}
            >
                Suivant &nbsp;
                <MdOutlineKeyboardArrowRight size={22} />
            </button>
        </div>
    </>
  );
};

export default PaymentProcess;
