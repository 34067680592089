import React, { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./SuccessPaiement.css";
import { runFireworks } from "./runCanvaFirework";
import { useSearchParams } from "react-router-dom";
import BigLoader from "../../../common/LoaderSuspense";
import { IoMdClose } from "react-icons/io";


const SuccessPaiement = () => {
	const [isLoading, seIsLoading] = useState<boolean>(true);
	const [success, setSuccess] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	

	useEffect(() => {
		let paiementStatus = searchParams.get("status")
		if(paiementStatus == 'success'){
			setSuccess(true)
			seIsLoading(false)
			//annimation
			runFireworks();
			let interaval = setInterval(() => {
				runFireworks();
			}, 8000);
			return () => clearInterval(interaval);
		}else{
			setSuccess(false)
			seIsLoading(false)
		}
	}, [searchParams]);

	return (
		<>
		{isLoading &&(
			<BigLoader/>
		)}
		{(!isLoading && success ) && (	
		<div className="success-wrapper">
			<div
				className="container"
				style={{
					minHeight: "100vh",
					paddingTop: "20vh",
				}}
			>
				<div className="row flex-fill">
					<div className="col-md-8 col-xl-6 mx-auto mt-5">
						<div className="payment">
							<div className="payment_header">
								<div className="check">
									<BsCheckLg />
								</div>
							</div>
							<div className="success-content">
								<h1>
									Paiement effectué avec
									succès!
								</h1>
								<p>
									La livraison sera
									effectuée dès que
									possible. Merci de votre
									confiance.
								</p>
								<div className="d-flex flex-column align-items-center flex-md-row justify-content-center gap-3 ">
									<Link to="/boutique">
										Revenir à la
										boutique
									</Link>
									<Link to="/particulier/mon-compte">
										Voir mes commandes
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		)}
		{(!isLoading && !success ) && (	
		<div className="error-payment-wrapper">
			<div
			className="container"
			style={{
				minHeight: "100vh",
				paddingTop: "20vh",
			}}
			>
			<div className="row flex-fill">
				<div className="col-md-8 col-xl-6 mx-auto mt-5">
				<div className="payment">
					<div className="error-payment_header">
					<div className="close icon-statut-payment">
						<IoMdClose />
					</div>
					</div>
					<div className="error-payment-content">
					<h1>Paiement non effectué !</h1>
					<p>Veuillez reessayer plutard.</p>
					<div className="d-flex flex-column align-items-center flex-md-row justify-content-center gap-3 pt-4">
						<Link to="/boutique">Revenir à la boutique</Link>
						<Link to="/panier">Voir mon panier</Link>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div> 
		)}
		</>
	);
};

export default SuccessPaiement;
