import React,{useState} from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrash } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink, Link } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import imgProd from "../../../../assets/appImages/imgProduit.svg";
import { useStockByProQuery } from "../../../../utils/api/product/product.api";
import { usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import { useAppSelector } from "../../../../redux/hook";
import { Variation } from "../../../../utils/api/product/product.type";
import { formatCurrency, formatDate, getImage, showModal } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import ChangeStockModal from "../ChangeStockModal";


function StockTable() {
	const { t } = useTranslation();

	const { limit, page, onChange } = usePagination(10);
	const { user } = useAppSelector((s) => s?.user);
	const [item, setItem] = useState<any>();
	const { data = { results: [] }, isLoading } = useStockByProQuery({
		limit,
		page,
		slug: user?.slug,
	});

	const actionFormatter = (cell: any, row: any) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/professionel/stock/produit/${row.slug}`}
							className="btn btn-action-filter-icon with-tooltip"
							data-tooltip-content="Voir"
							state={row}
						>
							<AiFillEye />
						</NavLink>
					</div>
				</div>

			</>
		);
	};
	const nameFormatter = (cell: Variation, row: any) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getImage(cell?.image)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">
				{cell?.item?.nom} {cell?.taille}
				</p>
			</div>
		);
	};
	const montantFormatter = (cell: Variation, row: any) => {
		return (
			<p className="fw-bold m-0">
				{formatCurrency(Number(cell?.prix_pro) * row?.quantity)}
			</p>
		);
	};

	const quantiteFormatter = (cell: number, row: any) => {
		return (
			<Link
				to="#"
				className="justify-content-center btn btn-action-filter-icon with-tooltip"
				data-tooltip-content={t("click_to_edit")}
				onClick={() => {
					setItem(row);
					showModal("ChangeStockModal");
				}}
			>
			
				{cell > 3 && (
					<span className="status-livre py-2 px-4">
						<span>{cell}</span>
					</span>
				)}

				{cell <= 3 && (
					<span className="status-annule  py-2 px-4">
						<span>{cell}</span>
					</span>
				)}
			</Link>
		);
	};

	const columns = [
		{
			dataField: "variation",
			text: t("products"),
			formatter: nameFormatter,
			headerStyle: () => {
				return { width: "200px", whiteSpace: "normal" };
			},
		},
		{
			dataField: "variation",
			text: t("price"),
			formatter: (cell) => formatCurrency(cell?.prix_pro),
		},
		{
			dataField: "created_at",
			text: t("date_add"),
			formatter: (cell) => formatDate(cell),
		},
		{
			dataField: "quantity",
			text: t("quantity"),
			formatter: (cell,row) => quantiteFormatter(cell,row)
		},
		{
			dataField: "variation",
			text: t("amount"),
			formatter: montantFormatter,
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
		},
	];
	const selectRow = {
		mode: "checkbox",
	};

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
			<ChangeStockModal item={item} />
		</>
	);
}

export default StockTable;
