import React from "react";
import { CardProduitParticulier } from "../../ProduitDisponibleParticulier/ProduitDisponibleParticulier";
import { usePagination } from "../../../../utils/hooks";
import { useAppSelector } from "../../../../redux/hook";
import { AlertInfo } from "../../../common/Alert";
import { useGetFavoriteProductByUserQuery } from "../../../../utils/api/product/product.api";
import { useTranslation } from "react-i18next";


function MesFavoris() {
	const { t } = useTranslation();

	const { user } = useAppSelector((s) => s?.user);
	const { limit, page } = usePagination(10);
	const { data = [], isLoading } = useGetFavoriteProductByUserQuery({
		limit,
		page,
		slug: user?.slug,
	});
	return (
		<div className="page-content mt-0">
			<div className="container-casadeliz mb-5">
				<div className="mon-compte-titre-editer mb-3">
					{t("my_favorites")}
				</div>
				{!isLoading ? (
					<>
						{data?.length > 0 ? (
							data?.map((item) => (
								<CardProduitParticulier
									key={item?.slug}
									item={item?.produit}
									showIconsFavoris={true}
								/>
							))
						) : (
							<AlertInfo message="Aucun produit ajouté comme favoris" />
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default MesFavoris;
