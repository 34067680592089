import React from "react";
import "./StockProfessionnel.css";
import { NavLink } from "react-router-dom";
import { BsFileRuled } from "react-icons/bs";
import {
	createMarkup,
	formatCurrency,
	useLocationState,
} from "../../../utils/Utils";
import { useTranslation } from "react-i18next";


const StockProductDetail = () => {
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const product = useLocationState<any>(undefined);

	return (
		<div className="dashbord-admin-component colgate_wrapper">
			<div className="dash-admin-page-content-container mb-3">
				<div className="d-flex top_title__container mb-4 p-3">
					<h2 className="chart-item-admin-title mb-0">
						Stock
					</h2>
				</div>
			</div>
			<div className="custom-action-btn-content p-3">
				<span className="detail-product-span">
					{ t('product_details') }
				</span>
				<NavLink to="#" className="btn btn-theme-admin">
					<span>
						{ t('technical_data') }
						<BsFileRuled />
					</span>
				</NavLink>
			</div>
			<div className="stock_product_details">
				<div className="row">
					<div className="col-md-4">
						<div className="colgate_wrapper py-3 details">
							<h4 className="m-0 p-3 card__title">
								{ t('details') }
							</h4>
							<div className="product-view-content"></div>
							<div className="details-content bg_pink_blur">
								<p className="m-0 pb-3">
									<span className="sm-title">
										{ t('status') }:
									</span>
									&nbsp;
									<span className="sm-value">
										{product?.quantity >
										0
											? t("available_from_stock")
											: t("out_of_stock")}
									</span>
								</p>
								<p className="m-0 pb-3">
									<span className="sm-title">
										{ t('size') }:
									</span>
									&nbsp;
									<span className="sm-value">
										{
											product
												?.variation
												?.taille
										}
									</span>
								</p>
								<p className="m-0 pb-3">
									<span className="sm-title">
										Tags:
									</span>
									&nbsp;
									<span className="sm-value">
										{product
												?.variation
												?.item
												?.tags?.length > 0 ? 
												
												JSON.parse(
													product
														?.variation
														?.item
														?.tags
												)?.map(
													(tag) => tag
												)
												:
												[]
										}
									
									</span>
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="colgate_wrapper py-3 general-infos">
							<h4 className="m-0 p-3 card__title">
								{ t('general_information') }
							</h4>
							<div className="details-content bg_pink_blur">
								<p className="m-0 pb-3">
									<span className="sm-title">
										{ t('name_product') }:
									</span>
									&nbsp;
									<span className="sm-value">
										{
											product
												?.variation
												?.variation
												?.taille
										}
									</span>
								</p>
								<p className="m-0 pb-3">
									<span className="sm-title">
										{ t('price') }:
									</span>
									&nbsp;
									<span className="sm-value">
										{formatCurrency(
											product
												?.variation
												?.prix
										)}
									</span>
								</p>
								<p className="m-0 pb-3">
									<span className="sm-title">
										Stock:
									</span>
									&nbsp;
									<span className="sm-value">
										{product?.quantity}
									</span>
								</p>
								<div className="m-0 pb-3">
									<span className="sm-title">
										Description:
									</span>
									&nbsp;
									<div
										className="sm-description-value"
										dangerouslySetInnerHTML={createMarkup(
											product
												?.variation
												?.item
												?.description
										)}
									/>
								</div>
							</div>
							{/* <div className="pt-4"></div>
							<h4 className="m-0 p-3 card__title">
								Variations
							</h4>
							<div className="pt-2 varition-table">
								<DetailVariationTable />
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StockProductDetail;
