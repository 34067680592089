import ProfessionnelAdminTable from "./ProfessionnelAdminTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { useSearch } from "../../../../utils/hooks";

const ListePro = () => {
	const { search, handleSearch } = useSearch();

	return (
		<>
			<div className="row country-and-search-bar-content pb-4">
				<div className="offset-md-8 col-md-4 search-bar-container d-flex align-items-center gap-2">
					<input
						type="search"
						placeholder="Chercher"
						className="search-bar-conseil"
						onChange={handleSearch}
					/>
					<span className="icon-container">
						<HiOutlineBars3BottomRight />
					</span>
				</div>
			</div>
			<div className="content-graph-admin medsain-page-title-container mb-2">
				<div className="container-display-title-theme">
					<NavLink
						to="/admin/ajouter-un-professionnel"
						className="btn btn-theme-admin"
					>
						<span>
							Ajouter un professionnel
							<AiOutlinePlusCircle />
						</span>
					</NavLink>
				</div>
			</div>
			<ProfessionnelAdminTable q={search} />
		</>
	);
};

export default ListePro;
