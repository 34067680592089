/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	Contact,
	ContactFormData,
	ResponseContact,
	ResponseContactFormData,
} from "./contact.type";

export const ContactApi = createApi({
	reducerPath: "contactApi",
	tagTypes: ["contactList", "contactById", "responseByContact"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getListContactAdmin: builder.query<
			PaginationResults<Contact>,
			TypeQuery
		>({
			providesTags: ["contactList"],
			query: (query) => QueryUrl("contacts/", query),
		}),
		sendMessageContact: builder.mutation<
			ResponseContact,
			ContactFormData
		>({
			query: (data) => ({
				url: "visiteur/contacts/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["contactList"],
		}),

		deleteContact: builder.mutation<Contact, string>({
			query: (id) => ({
				url: `contacts/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["contactList"],
		}),
		contactById: builder.query<Contact | any, string>({
			query: (id) => `contacts/${id}/`,
			providesTags: ["contactById"],
		}),
		sendResponseContact: builder.mutation<
			ResponseContact,
			ResponseContactFormData
		>({
			query: (data) => ({
				url: "contact/responses/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["contactList"],
		}),
		updateContact: builder.mutation<
			Contact,
			{ slug?: string; data: ContactFormData }
		>({
			invalidatesTags: ["contactList"],
			query: ({ slug, data }) => {
				return {
					url: `contacts/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetListContactAdminQuery,
	useSendResponseContactMutation,
	useSendMessageContactMutation,
	useDeleteContactMutation,
	useUpdateContactMutation,
} = ContactApi;
