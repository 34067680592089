import React from "react";
import { NavLink } from "react-router-dom";
import "./FrontHeader.css";
import Logo from "../../../assets/appImages/logo.svg";
import FirstHeader from "./FirstHeader";
import { useAppSelector } from "../../../redux/hook";
import { getAuthUserDefaultRoute } from "../../../utils/Utils";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";


const FrontHeader = () => {
  const { user } = useAppSelector((s) => s?.user);
  const { t } = useTranslation();

  return (
    <div className="frontheader-component headering fixed-top">
      <FirstHeader />
      <Navbar expand="lg" className="header-rendu-front">
        <Navbar.Brand href="/">
          <img src={Logo} alt="Casadeliz" className="app-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="header-navbar-collapse"
        >
          <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link menu-link" to={`/`}>
                {t("home")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link menu-link" to={`/boutique`}>
                {t("shop")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link menu-link" to={`/a-propos`}>
              {t("about")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link menu-link" to={`/faq`}>
                {t("faq_title")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link menu-link" to={`/actualite`}>
                {t("our_news")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link menu-link" to={`/contact`}>
                {t("contact")}
              </NavLink>
            </li>
          </ul>
          <div className="d-flex align-items-center gap-1">
            <div className="content-btn-cta">
              <NavLink
                to={`/become-professional`}
                className="btn btn-become-pro"
              >
                {t("become_pro")} 
              </NavLink>
            </div>
            <div className="content-btn-cta">
              {user ? (
                <NavLink
                  to={getAuthUserDefaultRoute(user)}
                  className="btn btn-theme"
                  role="button"
                >
                  {user?.prenom + " " + user?.nom}
                </NavLink>
              ) : (
                <NavLink
                  to={`/connexion`}
                  className="btn btn-theme"
                  role="button"
                >
                  {t("login")} 
                </NavLink>
              )}
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default FrontHeader;
