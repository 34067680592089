import React from "react";
import HomePage from "../components/HomePage/HomePage";
import Boutique from "../components/front/boutique/Boutique";
import DetailBoutique from "../components/front/boutique/DetailBoutique";
import About from "../components/front/About/About";
import Contact from "../components/front/contact/Contact";
import Actualite from "../components/front/actualite/Actualite";
import DetailsActualite from "../components/front/actualite/DetailsActualite";
import Faq from "../components/front/faq/Faq";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import ForgetPassword from "../components/auth/ForgetPassword";
import ValidateCode from "../components/auth/ValidateCode";
import ResetPassword from "../components/auth/ResetPassword";
import BecomeProfessional from "../components/auth/BecomeProfessional";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import ProfilAdmin from "../components/admin/ProfilAdmin/ProfilAdmin";
import DashboardProfessionnel from "../components/professionnel/DashboardProfessionnel/DashboardProfessionnel";
import CommandProfessionnel from "../components/professionnel/CommandProfessionnel/CommandProfessionnel";
import DetailsCommand from "../components/professionnel/CommandProfessionnel/DetailsCommand";
import DetailsPanier from "../components/professionnel/CommandProfessionnel/DetailsPanier";
import PaymentProcess from "../components/professionnel/CommandProfessionnel/PaymentProcess";
import CatalogueProfessionnel from "../components/professionnel/CatalogueProfessionnel/CatalogueProfessionnel";
import CatalogDetailProduct from "../components/professionnel/CatalogueProfessionnel/CatalogDetailProduct";
import StockProfessionnel from "../components/professionnel/StockProfessionnel/StockProfessionnel";
import StockProductDetail from "../components/professionnel/StockProfessionnel/StockProductDetail";
import ProfilProfessionnel from "../components/professionnel/ProfilProfessionnel/ProfilProfessionnel";
import ParametreAdmin from "../components/admin/ParametreAdmin/ParametreAdmin";
import AddOrUpdateAdmin from "../components/admin/ParametreAdmin/AddOrUpdateAdmin";
import AddOrUpdateConditions from "../components/admin/ParametreAdmin/AddOrUpdateConditions";
import AddOrUpdatePolitiques from "../components/admin/ParametreAdmin/AddOrUpdatePolitiques";
import ClientAdmin from "../components/admin/ClientAdmin/ClientAdmin";
import AddOrUpdateClient from "../components/admin/ClientAdmin/AddOrUpdateClient";
import AddOrUpdateProfessionnel from "../components/admin/ProfessionnelAdmin/AddOrUpdateProfessionnel";
import UtilisateurAdmin from "../components/admin/UtilisateurAdmin/UtilisateurAdmin";
import MesProduitsAdmin from "../components/admin/MesProduitsAdmin/MesProduitsAdmin";
import AddOrUpdateProduit from "../components/admin/MesProduitsAdmin/AddOrUpdateProduit";
import DetailsProduit from "../components/admin/MesProduitsAdmin/DetailsProduit";
import DetailsProfessionnelAdmin from "../components/admin/ProfessionnelAdmin/DetailsProfessionnelAdmin";
import DetailsClientadmin from "../components/admin/ClientAdmin/DetailsClientadmin";
import RelancerClientForm from "../components/admin/ClientAdmin/RelancerClientForm";
import OptionAdmin from "../components/admin/OptionAdmin/OptionAdmin";
import ParticulierLayout from "../components/particulier/ParticulierLayout/ParticulierLayout";
import AccueilParticulier from "../components/particulier/AccueilParticulier/AccueilParticulier";
import ProduitDisponibleParticulier from "../components/particulier/ProduitDisponibleParticulier/ProduitDisponibleParticulier";
import DetailsProduitParticulier from "../components/particulier/DetailsProduitParticulier/DetailsProduitParticulier";
import MonCompteParticulier from "../components/particulier/MonCompteParticulier/MonCompteParticulier";
import DetailsLivraisonParticulier from "../components/particulier/MonCompteParticulier/DetailsLivraisonParticulier";
import DetailsActuVideo from "../components/front/actualite/DetailsActuVideo";
import DetailsArticleAudio from "../components/front/actualite/DetailsArticleAudio";
import {
	IsAdminAllow,
	IsParticulierAllow,
	ProtectedRoutes,
	RedirectAuthRoute,
} from "./routerUtils";
import { UserType } from "../utils/api/user/user.type";
import ProfessionnelAdmin from "../components/admin/ProfessionnelAdmin/ProfessionnelAdmin";
import Categories from "../components/admin/MesProduitsAdmin/categories/Categories";
import PanierVisiteur from "../components/front/panier/PanierVisiteur";
import MessagerieAdmin from "../components/admin/Messages/MessagerieAdmin";
import MessagesContact from "../components/admin/Messages/MessagesContact";
import UpdateProduit from "../components/admin/MesProduitsAdmin/UpdateProduit";
import { Page404 } from "../components/shared/ErrorsPage/Errors";
import PanierParticulier from "../components/particulier/PanierParticulier/PanierParticulier";
import EditProfilPro from "../components/professionnel/ProfilProfessionnel/EditProfilPro";
import SuccessPaiement from "../components/particulier/PanierParticulier/paiement/SuccessPaiement";

export const AppRoutes = [
	{
		path: "/connexion",
		element: (
			<RedirectAuthRoute>
				<Login />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/inscription",
		element: (
			<RedirectAuthRoute>
				<Register />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/forgot-password",
		element: (
			<RedirectAuthRoute>
				<ForgetPassword />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/validate-code",
		element: <ValidateCode />,
	},
	{
		path: "/reset-password",
		element: <ResetPassword />,
	},
	{
		path: "/become-professional",
		element: <BecomeProfessional />,
	},
	{
		path: "/",
		element: <HomePage />,
	},
	{
		path: "/boutique",
		element: <Boutique />,
	},
	{
		path: "/boutique/produit/:slug",
		element: <DetailBoutique />,
	},
	{
		path: "/a-propos",
		element: <About />,
	},
	{
		path: "/contact",
		element: <Contact />,
	},
	{
		path: "/actualite",
		element: <Actualite />,
	},
	{
		path: "/actualite/:slug",
		element: <DetailsActualite />,
	},
	{
		path: "/actualite-video/:slug",
		element: <DetailsActuVideo />,
	},
	{
		path: "/actualite-audio/:slug",
		element: <DetailsArticleAudio />,
	},
	{
		path: "/faq",
		element: <Faq />,
	},
	{
		path: "/panier",
		element: <PanierVisiteur />,
	},
	{
		path: "/paiement",
		element: <SuccessPaiement />,
	},
	{
		path: "admin/",
		element: (
			<ProtectedRoutes
				userType={[UserType.admin, UserType.superadmin]}
			>
				<AdminLayout />
			</ProtectedRoutes>
		),
		children: [
			{
				element: <Navigate to="/admin/dashboard" replace />,
				index: true,
			},
			{
				path: "dashboard",
				element: (
					<IsAdminAllow field="dashboard">
						<DashboardAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "profil",
				element: <ProfilAdmin />,
			},
			{
				path: "parametres",
				element: (
					<IsAdminAllow field="parametres">
						<ParametreAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "ajouter-sous-admin",
				element: (
					<IsAdminAllow field="parametres">
						<AddOrUpdateAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "parametres/:slug/modifier-sous-admin",
				element: (
					<IsAdminAllow field="parametres">
						<AddOrUpdateAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "parametres/:slug/detail-sous-admin",
				element: (
					<IsAdminAllow field="parametres">
						<AddOrUpdateAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "ajouter-conditions-utilisations",
				element: (
					<IsAdminAllow field="options">
						<AddOrUpdateConditions />
					</IsAdminAllow>
				),
			},
			{
				path: "ajouter-politique-confidentialite",
				element: (
					<IsAdminAllow field="options">
						<AddOrUpdatePolitiques />
					</IsAdminAllow>
				),
			},
			{
				path: "clients",
				element: (
					<IsAdminAllow field="particuliers">
						<ClientAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "clients/:slug",
				element: (
					<IsAdminAllow field="particuliers">
						<DetailsClientadmin />
					</IsAdminAllow>
				),
			},
			{
				path: "client/:slug/relancer",
				element: (
					<IsAdminAllow field="particuliers">
						<RelancerClientForm />
					</IsAdminAllow>
				),
			},
			{
				path: "clients/:slug/details-commande",
				element: (
					<IsAdminAllow field="particuliers">
						<DetailsCommand />
					</IsAdminAllow>
				),
			},
			{
				path: "ajouter-un-client",
				element: (
					<IsAdminAllow field="particuliers">
						<AddOrUpdateClient />
					</IsAdminAllow>
				),
			},
			{
				path: "clients/:slug/modifier",
				element: (
					<IsAdminAllow field="particuliers">
						<AddOrUpdateClient />
					</IsAdminAllow>
				),
			},
			{
				path: "professionnels",
				element: (
					<IsAdminAllow field="professionnels">
						<ProfessionnelAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "professionnels/:slug",
				element: (
					<IsAdminAllow field="professionnels">
						<DetailsProfessionnelAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "professionnels/:slug/commandes/:slug",
				element: (
					<IsAdminAllow field="professionnels">
						<DetailsCommand />
					</IsAdminAllow>
				),
			},
			{
				path: "ajouter-un-professionnel",
				element: (
					<IsAdminAllow field="professionnels">
						<AddOrUpdateProfessionnel />
					</IsAdminAllow>
				),
			},
			{
				path: "professionnels/:slug/modifier",
				element: (
					<IsAdminAllow field="professionnels">
						<AddOrUpdateProfessionnel />
					</IsAdminAllow>
				),
			},
			{
				path: "utilisateurs",
				element: <UtilisateurAdmin />,
			},
			{
				path: "commandes/:slug/details-commande",
				element: (
					<IsAdminAllow field="commandes">
						<DetailsCommand />
					</IsAdminAllow>
				),
			},
			{
				path: "commandes/panier/:slug",
				element: <DetailsPanier />,
			},
			{
				path: "mes-produits",
				element: (
					<IsAdminAllow field="produits">
						<MesProduitsAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "mes-produits/categories",
				element: (
					<IsAdminAllow field="produits">
						<Categories />
					</IsAdminAllow>
				),
			},
			{
				path: "ajouter-un-produit",
				element: (
					<IsAdminAllow field="produits">
						<AddOrUpdateProduit />
					</IsAdminAllow>
				),
			},
			{
				path: "mes-produits/:slug",
				element: (
					<IsAdminAllow field="produits">
						<DetailsProduit />
					</IsAdminAllow>
				),
			},
			{
				path: "mes-produits/:slug/modifier",
				element: (
					<IsAdminAllow field="produits">
						<UpdateProduit />
					</IsAdminAllow>
				),
			},
			{
				path: "commandes",
				element: (
					<IsAdminAllow field="commandes">
						<CommandProfessionnel />
					</IsAdminAllow>
				),
			},
			{
				path: "options",
				element: (
					<IsAdminAllow field="options">
						<OptionAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "messagerie",
				element: (
					<IsAdminAllow field="messages">
						<MessagerieAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "messages",
				element: (
					<IsAdminAllow field="messages">
						<MessagesContact />
					</IsAdminAllow>
				),
			},
		],
	},
	{
		path: "professionel/",
		element: (
			<ProtectedRoutes userType={[UserType.professionnel]}>
				<AdminLayout />
			</ProtectedRoutes>
		),
		children: [
			{
				element: (
					<Navigate to="/professionel/dashboard" replace />
				),
				index: true,
			},
			{
				path: "dashboard",
				element: <DashboardProfessionnel />,
			},
			{
				path: "commandes",
				element: <CommandProfessionnel />,
			},
			{
				path: "commandes/:slug",
				element: <DetailsCommand />,
			},
			{
				path: "commandes/panier/:slug",
				element: <DetailsPanier />,
			},
			{
				path: "commandes/panier/payer",
				element: <PaymentProcess />,
			},
			{
				path: "catalogue",
				element: <CatalogueProfessionnel />,
			},
			{
				path: "catalogue/produit/:slug",
				element: <CatalogDetailProduct />,
			},
			{
				path: "stock",
				element: <StockProfessionnel />,
			},
			{
				path: "stock/produit/:slug",
				element: <StockProductDetail />,
			},
			{
				path: "profil",
				element: <ProfilProfessionnel />,
			},
			{
				path: "modifier-mon-profil",
				element: <EditProfilPro />,
			},
			{
				path: "panier",
				element: <PanierParticulier />,
			},
		],
	},
	{
		path: "particulier/",
		element: (
			<IsParticulierAllow>
				<ParticulierLayout />
			</IsParticulierAllow>
		),
		children: [
			{
				element: <Navigate to="/particulier/accueil" replace />,
				index: true,
			},
			{
				path: "accueil",
				element: <AccueilParticulier />,
			},
			{
				path: "produits-disponible",
				element: <ProduitDisponibleParticulier />,
			},
			{
				path: "produit/:slug",
				element: <DetailsProduitParticulier />,
			},
			{
				path: "details-livraison",
				element: <DetailsLivraisonParticulier />,
			},
			{
				path: "mon-compte",
				element: <MonCompteParticulier />,
			},
			{
				path: "panier",
				element: <PanierParticulier />,
			},
		],
	},
	{
		path: "*",
		element: <Page404 />,
	},
];
