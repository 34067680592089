/* eslint-disable @typescript-eslint/no-explicit-any */
import { Admin } from "../admin/admin.type";

export enum conditionType {
	cgu = "CGU",
	ml = "ML",
	pc = "PMC",
}
export interface Condition {
	slug: string;
	text_en: string;
	text_fr: string;
	type: conditionType;
}

export type ConditionFormData = Partial<Condition>;
export interface ConfigApp {
	_id: string;
	cgu: string;
	ptc: string;
	instagram: string;
	facebook: string;
	linkedin: string;
	tiktok: string;
	twitter: string;
	androidAppUrl: string;
	iosAppUrl: string;
	type: string;
}

export type ConfigAppFormData = Partial<ConfigApp>;
export type Newsletter = {
	email: string;
};
export type NewsletterData = Partial<Newsletter>;

export interface Testimonial {
	avatar: string;
	slug: string;
	nom: string;
	prenom: string;
	message: string;
	message_en: string;
	fonction: string;
	user: Admin;
}
export type TestimonialFormData = {
	avatar: File;
	nom: string;
	prenom: string;
	message: string;
	message_en: string;
	fonction: string;
	user: number;
};

export type Raison = {
	id?: string;
	slug?: string;
	titre: string;
	titre_en: string;
	description: string;
	description_en: string;
};

export type Avantage = {
	id?: string;
	slug?: string;
	titre: string;
	titre_en: string;
	description: string;
	description_en: string;
};

export interface Partner {
	slug: string;
	nom: string;
	logo: string;
}

export type PartnerFormData = { nom: string; logo: File };

export interface About {
	raisons: Raison[];
	avantages: Avantage[];
	banniere: string;
	slug: string;
	titre_a_propos: string;
	titre_a_propos_en: string;
	image_histoire: any;
	titre_notre_histoire: string;
	titre_notre_histoire_en: string;
	description_notre_histoire: string;
	description_notre_histoire_en: string;
	titre_pourquoi_nous_choisir: string;
	titre_pourquoi_nous_choisir_en: string;
	description_pourquoi_nous_choisir: string;
	description_pourquoi_nous_choisir_en: string;
	image_raison: any;
	equipe_titre: string;
	equipe_titre_en: string;
	equipe_description: string;
	equipe_description_en: string;
	created_at: string;
}

export type AboutFormData = Partial<About>;

export interface UploadFileResult {
	success: boolean;
	file_url: string;
}

export interface Catalogue {
	slug: string;
	description: string;
	description_en: string;
	pourcentage: string;
	pourcentage_text: string;
	pourcentage_text_en: string;
	catalogue: string;
}

export type CatalogueFormData = Partial<Catalogue>;

export interface Contact {
	slug: string;
	background_titre: string;
	background_titre_en: string;	
	background_image: string;	
	titre_contact: string;	
	title_contact_en: string;	
	description_contact	: string;
	description_contact_en: string;	
	email: string;	
	telephone_1: string;	
	telephone_2: string;	
	adresse_titre: string;	
	address_titre_en: string;	
	adresse_description: string;	
	adresse_description_en: string;	
	adresse: string;
}

export type ContactFormData = Partial<Contact>;

export interface Accueil {
	slug: string;
	titre: string;
	titre_en: string;	
	description	: string;
	description_en: string;	
	image: string;
}

export type AccueilFormData = Partial<Accueil>;

