import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./Contact.css";
import backgroundImg from "../../../assets/icons/contact.svg";
import { IconContact, IconMessage } from "../../shared/Icons";
import useContactForm from "./useContactForm";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/Input";
import { getName,deleteBaliseHtml } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";
import {
	useGetContactQuery,
} from "../../../utils/api/config/config.api";

function ContactForm() {
	const { t } = useTranslation();

	const { register, isLoading, errors, onSubmit, authUser } =
		useContactForm();

	return (
		<form onSubmit={onSubmit}>
			<div className="row">
				<div className="col-md-6 col-form-register-tabs mb-3">
					<div className="content-col-form-register-tabs">
						<label className="form-label form-label-register-tabs">
						{t("nams")}* 
						</label>
						<input
							className="form-control form-control-register-tabs"
							type="text"
							placeholder={t("nams")}
							{...register("nom_complet")}
							defaultValue={getName(authUser)}
							disabled={!!authUser}
						/>
						<FormError error={errors?.nom_complet} />
					</div>
				</div>
				<div className="col-md-6 col-form-register-tabs mb-3">
					<div className="content-col-form-register-tabs">
						<label className="form-label form-label-register-tabs">
							{t("email")}*
						</label>
						<input
							className="form-control form-control-register-tabs"
							type="email"
							placeholder={t("email")}
							{...register("email")}
							defaultValue={authUser?.email}
							disabled={!!authUser}
						/>
						<FormError error={errors?.email} />
					</div>
				</div>
				<div className="col-12 col-form-register-tabs mb-3">
					<div className="content-col-form-register-tabs">
						<label className="form-label form-label-register-tabs">
							{t("subject")}*
						</label>
						<input
							className="form-control form-control-register-tabs"
							type="text"
							placeholder={t("subject")}
							{...register("subject")}
						/>
						<FormError error={errors?.subject} />
					</div>
				</div>
				<div className="col-md-12 col-form-register-tabs mb-4">
					<div className="content-col-form-register-tabs">
						<label className="form-label form-label-register-tabs">
							{t("message")}*
						</label>
						<textarea
							id="comment"
							className={`form-control form-control-register-tabs`}
							cols={30}
							rows={5}
							{...register("message")}
						/>
						<FormError error={errors?.message} />
					</div>
				</div>
				<div className="col-md-12">
					<BtnSubmit
						className="btn custom-btn-send-contact"
						label={t("send_message")}
						isLoading={isLoading}
						textLoading="En cours"
					/>
				</div>
			</div>
		</form>
	);
}

function Contact() {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { data: contact } = useGetContactQuery();

	const style = {
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 100%), url(${backgroundImg}), lightgray 50% / cover no-repeat`,
	};
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content pb-5">
				<div className="casadeliz-section-hero-container mb-4" style={style}>
					<div className="casadeliz-section-hero-page-title">{locale == 'fr' ? contact?.background_titre : contact?.background_titre_en }</div>
				</div>
				<div className="container">
					<div className="row custom-section-divider">
						<div className="col-md-7">
							<div className="px-3">
								<div className="page-contact-subtitle mb-3">
									{locale == 'fr' ? contact?.titre_contact : contact?.title_contact_en}
								</div>
								<div className="page-contact-text-message mb-3">
								 	{locale == 'fr' ? deleteBaliseHtml(contact?.description_contact) : deleteBaliseHtml(contact?.description_contact_en)}
								</div>
								<div className="page-contact-card-item mb-3">
									<div className="page-contact-card-item-icon">
										<IconMessage />
									</div>
									<div>
										<div className="page-contact-card-item-title">Message</div>
										<div className="page-contact-card-item-email">
											{contact?.email}
										</div>
									</div>
								</div>
								<div className="page-contact-card-item mb-3">
									<div className="page-contact-card-item-icon">
										<IconContact />
									</div>
									<div>
										<div className="page-contact-card-item-title">
											{t("contact_us")}
										</div>
										<div className="page-contact-card-item-value">
											{contact?.telephone_1}
										</div>
										<div className="page-contact-card-item-value">
											{contact?.telephone_2}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-5">
							<div className="px-3">
								<div className="page-contact-titre-localisation">{t("location")}</div>
								<div className="page-contact-titre-adresse mb-3">{locale == 'fr' ? contact?.adresse_titre : contact?.address_titre_en}</div>
								<div className="page-contact-text-message mb-3">
									{locale == 'fr' ? deleteBaliseHtml(contact?.adresse_description) : deleteBaliseHtml(contact?.adresse_description_en)}
								</div>
								<div className="page-contact-card-localisation">
									<div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="50"
											height="50"
											viewBox="0 0 50 50"
											fill="none"
										>
											<path
												d="M25 27.0835C28.4518 27.0835 31.25 24.2853 31.25 20.8335C31.25 17.3817 28.4518 14.5835 25 14.5835C21.5482 14.5835 18.75 17.3817 18.75 20.8335C18.75 24.2853 21.5482 27.0835 25 27.0835Z"
												stroke="#ED646E"
												strokeWidth="1.33333"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M24.9997 4.16675C20.5794 4.16675 16.3402 5.9227 13.2146 9.0483C10.089 12.1739 8.33301 16.4131 8.33301 20.8334C8.33301 24.7751 9.17051 27.3542 11.458 30.2084L24.9997 45.8334L38.5413 30.2084C40.8288 27.3542 41.6663 24.7751 41.6663 20.8334C41.6663 16.4131 39.9104 12.1739 36.7848 9.0483C33.6592 5.9227 29.42 4.16675 24.9997 4.16675Z"
												stroke="#ED646E"
												strokeWidth="1.33333"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
									<div>
										{/* <div className="page-contact-card-localisation-titre">
											Baconding, SENEGAL
										</div> */}
										<div className="page-contact-card-localisation-adresse">
											{contact?.adresse}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row custom-section-divider">
						<div className="col-md-12">
							<ContactForm />
						</div>
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
}

export default Contact;
