import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../utils/Utils";
import { FormError } from "../common/Input";
import { useSubscribeNewsletterMutation } from "../../utils/api/config/config.api";
import { Newsletter } from "../../utils/api/config/config.type";
import Swal from "sweetalert2";
import { Color } from "../../utils/theme";

const NewsLetterForm = () => {
	const validationSchema = yup.object().shape({
		email: yup.string().required().email().label("L'email"),
	});
	const [subscribeNewsletter, { isLoading }] =
		useSubscribeNewsletterMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		reset,
		clearErrors,
	} = useForm<Newsletter>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 6000);
		}
	}, [errors]);

	const onSubmit = async (data) => {
		console.log(data);
		const res = await subscribeNewsletter(data);

		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Souscription reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
			reset();
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log(err.data);
			if (
				err?.data?.email?.includes(
					"newsletter with this email already exists."
				)
			) {
				setError("email", {
					message: "Cet email existe déjà!",
				});
			} else {
				const {
					message = "Une erreur inconnue s'est produite",
				} = err.data || {};
				setError("email", { message: message });
			}
		}
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="input-group custom-section-contact-input-group mb-3">
				<input
					type="text"
					className="form-control custom-section-contact-form-control"
					placeholder="Email"
					aria-label="Recipient's username"
					aria-describedby="basic-addon2"
					{...register("email")}
				/>
				<button
					className="input-group-text custom-section-contact-input-group-text"
					id="basic-addon2"
					type="submit"
				>
					{isLoading ? (
						<span
							className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
							role="status"
						></span>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34873 18.9426 4.80688 17.0679 2.93215C15.1931 1.05742 12.6513 0.0029116 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.154 14.1544 15.6542 15.6542C14.1545 17.154 12.121 17.9976 10 18Z"
								fill="white"
							/>
							<path
								d="M9.293 6.70697L11.586 8.99997H6V11H11.586L9.293 13.293L10.707 14.707L15.414 9.99997L10.707 5.29297L9.293 6.70697Z"
								fill="white"
							/>
						</svg>
					)}
				</button>
			</div>
			<FormError error={errors.email} />
		</form>
	);
};

export default NewsLetterForm;
