import UseAddOrEditConditionForm from "./hooks/UseAddOrEditConditionForm";
import {
	Condition,
	conditionType,
} from "../../../utils/api/config/config.type";
import ReactQuill from "react-quill";
import { useEffect } from "react";
import { FormError } from "../../common/Input";

export type ConditionProps = {
	condition?: Condition;
	type: conditionType;
};

const FormCondition = ({ condition, type }: ConditionProps) => {
	const {
		errors,
		onSubmit,
		setValue,
		isLoading,
		text,
		handleChangeEn,
		handleChangeTextFr,
		textFr,
	} = UseAddOrEditConditionForm(condition);

	useEffect(() => {
		if (type) {
			setValue("type", type);
		}
	}, [type]);

	return (
		<form className="mt-4" onSubmit={onSubmit}>
			<div className="row row-add-student my-3">
				<div className="col-md-12 mb-3">
					<label
						htmlFor="description"
						className="form-label form-label-modal-custom"
						aria-labelledby="description"
					>
						Description(Fr)
					</label>
					<ReactQuill
						className="editor-cgu"
						theme="snow"
						value={textFr}
						onChange={handleChangeTextFr}
					/>
					<FormError error={errors?.text_fr} />
				</div>
				<div className="col-md-12 mb-3">
					<label
						htmlFor="description"
						className="form-label form-label-modal-custom"
						aria-labelledby="description"
					>
						Description(En)
					</label>
					<ReactQuill
						className="editor-cgu"
						theme="snow"
						value={text}
						onChange={handleChangeEn}
					/>
					<FormError error={errors?.text_en} />
				</div>

				<div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
					<button
						className="btn btn-theme-admin w-100"
						disabled={isLoading}
						type="submit"
					>
						{isLoading ? (
							<>
								<span
									className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
									role="status"
								></span>
								<span>
									{condition
										? "Modification..."
										: "Ajout..."}
								</span>
							</>
						) : condition ? (
							"Modifer"
						) : (
							"Ajouter"
						)}
					</button>
				</div>
			</div>
		</form>
	);
};

export default FormCondition;
