import React from "react";
import { BsEmojiWink } from "react-icons/bs";
import { FiPaperclip } from "react-icons/fi";
import { RiSendPlaneFill } from "react-icons/ri";
import Avatar from "../../../assets/appImages/userAvat.svg";
import Avatar2 from "../../../assets/appImages/userAvat2.svg";

function DetailsMessagerieAdmin() {
  return (
    <div className="dtails-messages-tabs-component">
      <div className="d-flex gap-3 align-items-center border-bottom p-3">
        <div className="">
          <img
            src={Avatar}
            className="img-received-msg"
            alt="profil detail message"
            style={{ width: "60", height: "60" }}
          />
        </div>
        <div className="">
          <div className="msg-user-infos-container">
            <div className="d-flex align-items-center msg-user-name">
              <p className="profil-detail-message mb-0">Esther Howard</p>
            </div>
            <div className="bloc-user-disconnect-time msg-user-lastonline">
              <p className="text-disconnect-time mb-0">
                Online - Last seen, 2.02pm
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bloc-details pb-5">
        <Message />
        <Response />
      </div>
      <ChatInput />
    </div>
  );
}

export default DetailsMessagerieAdmin;

function Message() {
  return (
    <div className="received-msg-item">
      <div className="conatiner-received-msg-item">
        <div className="content-img-received-msg-item">
          <img
            src={Avatar2}
            className="img-received-msg"
            alt="image profil contact"
          />
        </div>
        <div className="container-text-message-recu">
          <div className="bloc-message-recu mb-2">
            <div className="text-message-recu">
              Creation Ipsum is simply dummy text of the printing and
              typesetting industry.{" "}
            </div>
          </div>
          <span className="time-detail-message">09:04 PM</span>
        </div>
      </div>
    </div>
  );
}

function Response() {
  return (
    <div className="bloc-reponse">
      <div className="sending-msg-item">
        <div className="bloc-message-envoyer">
          <div className="text-message-envoyer mb-2">
            Creation Ipsum is simply dummy text of the printing and typesetting
            industry.{" "}
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <span className="time-detail-message">09:04 PM</span>
        </div>
      </div>
    </div>
  );
}

function ChatInput() {
  return (
    <div className="p-3 border-top">
      <form>
        <div className="left-footer">
          <div className="left-footer-container">
            <div className="input-group">
              <div className="input-container">
                <div className="container-display-input-message">
                  <div className="share">
                    <FiPaperclip className="img-icon-chat" />
                  </div>
                  <div className="inp w-100">
                    <textarea
                      className="textarrea-message-custom-chat form-control"
                      rows={1}
                      name="reponse"
                      placeholder="Type your message here..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-container custom-btn-chat-container">
              <div className="emoji">
                <BsEmojiWink className="img-icon-chat" />
              </div>
              <button type="submit" className="btn btn-send-message-tabs">
                <RiSendPlaneFill />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
