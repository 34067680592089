import React from 'react'
import { Input } from '../../../common/Input'
import UseEditPassordForm from "../requestForm/UseEditPassordForm";
import { useTranslation } from "react-i18next";


function MonMotDePasse() {
  const { t } = useTranslation();


  const {
		register,
		onSubmit,
		isLoading,
		errors,
		passwordHandleChange,
		haveMinCharacter,
		haveMinLowercase,
		haveMinNumber,
		haveMinSpecialCharacter,
		haveMinUppercase,
  } = UseEditPassordForm();

  return (
    <div className="page-content mt-0">
      <div className="container-casadeliz mb-5">
          <div className="mon-compte-titre-editer">{t("edit_your_profile")}</div>
          <form onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="password"
                  label={t("current_password")}
                  id="mot_de_passe"
                  placeholder="********"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                  {...register("old_password")}
                  error={errors?.old_password?.message}
                />
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="password"
                  label={t("new_password")}
                  id="nouveau"
                  placeholder="********"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                  onChange={(e) => passwordHandleChange(e)}
                  error={errors?.new_password?.message}
                />
                
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="password"
                  label={t("password_confirm")}
                  id="confirmer"
                  placeholder="********"
                  customClassLabel={"input-details-livraison-label-form-add"}
                  customClassInput="input-details-livraison-form-add"
                  {...register("confirm_password")}
                  error={errors?.confirm_password?.message}
                />
                <div className="col-md-12">
                  <div className="input-group my-2 password-validation-terms-row">
                    <div className="password-validation-terms-container">
                      <h4 className="password-validation-terms-title">
                        {t("your_password_must_contain")}:{" "}
                      </h4>
                      <ul className="password-validation-terms-ul p-l-14 text-start">
                        <li
                          className={
                            "password-rule-item " +
                            (haveMinCharacter && "text-success")
                          }
                        >
                          {t("minimum_8_characters")}
                        </li>
                        <li
                          className={
                            "password-rule-item " +
                            (haveMinUppercase && "text-success")
                          }
                        >
                          {t("at_least_1_capital_letter")}
                        </li>
                        <li
                          className={
                            "password-rule-item " +
                            (haveMinLowercase && "text-success")
                          }
                        >
                          {t("minimum_1_lowercase_letter")}
                        </li>
                        <li
                          className={
                            "password-rule-item " +
                            (haveMinNumber && "text-success")
                          }
                        >
                          {t("at_least_1_digit")}
                        </li>
                        <li
                          className={
                            "password-rule-item " +
                            (haveMinSpecialCharacter && "text-success")
                          }
                        >
                          {t("at_least_1_special_character")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                <button className="btn custom-btn-confirm w-100" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Enrégistrer...</span>
                    </>
                  ) : (
                    "Enrégistrer"
                  )}
                  
                </button>
              </div>
              
            </div>
          </form>
      </div>
    </div>
  )
}

export default MonMotDePasse