import { AiFillEye } from "react-icons/ai";
import { HiTrash } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { MdDelete, MdEdit } from "react-icons/md";
import { Link, LinkProps } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export enum ButtonState {
	Primary = "Primary",
	Loading = "Loading",
}

type BtnPropsType = {
	label: string;
	type?: "submit" | "button" | "reset";
	onClick?: (e?: any) => void;
	isLoading?: boolean;
	textLoading?: string;
};

type BtnPropsTypeGoBack = {
	navigation: any;
};

type BtnLoadingType = {
	isLoading: boolean;
};

type ButtonProps = React.HtmlHTMLAttributes<HTMLButtonElement>;

export function BtnNext({ label, type, isLoading }: BtnPropsType) {
	return (
		<button className="m-btn m-btn-next" type={type || "button"}>
			{!isLoading ? (
				`${label}`
			) : (
				<>
					<span
						className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
						role="status"
					></span>
					<span>Chargement...</span>
				</>
			)}
		</button>
	);
}
export function BtnBack({
	label,
	navigation,
}: BtnPropsType & BtnPropsTypeGoBack) {
	return (
		<button
			type="button"
			className="btn auth-submit-annuler"
			onClick={() => navigation.previous()}
		>
			{label}
		</button>
	);
}
export function BtnSkip({ label }: BtnPropsType) {
	return <button className="m-btn m-btn-back">{label}</button>;
}

export function GoBackButton({ label = "Annuler" }: { label?: string }) {
	const navigate = useNavigate();
	return (
		<button
			className="auth-submit-annuler"
			onClick={() => navigate(-1)}
		>
			{label}
		</button>
	);
}

export function BtnCloseModal({ label, onClick }: BtnPropsType) {
	return (
		<button
			className="close-modal auth-submit-annuler"
			data-bs-dismiss="modal"
			type="button"
			onClick={onClick}
		>
			{label ? label : <IoClose />}
		</button>
	);
}

export function BtnSubmit({
	isLoading,
	label,
	className,
	textLoading,
	...rest
}: BtnPropsType &
	BtnLoadingType &
	React.HtmlHTMLAttributes<HTMLButtonElement>) {
	return !isLoading ? (
		<button
			type="submit"
			className={className ?? "btn auth-submit-btn"}
			{...rest}
			disabled={isLoading}
		>
			{label}
		</button>
	) : (
		<button
			type="submit"
			className={className ?? "btn auth-submit-btn"}
			{...rest}
		>
			<span
				className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
				role="status"
			></span>
			<span>{textLoading ?? "Chargement"}...</span>
		</button>
	);
}
export function BtnUnsubscribe({
	isLoading,
	label,
	...rest
}: BtnPropsType &
	BtnLoadingType &
	React.HtmlHTMLAttributes<HTMLButtonElement>) {
	return !isLoading ? (
		<button
			type="submit"
			className="btn unsubscribe-submit-btn"
			{...rest}
		>
			{label}
		</button>
	) : (
		<button
			type="submit"
			className="btn unsubscribe-submit-btn"
			{...rest}
			disabled={isLoading}
		>
			<span
				className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
				role="status"
			></span>
			<span>Chargement...</span>
		</button>
	);
}

export function BtnDeleteAll({ label, onClick }: BtnPropsType) {
	return (
		<button
			className="btn-delete-all btn-add-delete flex-m"
			onClick={onClick}
		>
			<MdDelete style={{ fontSize: 18 }} />
			<span className="ms-2">{label}</span>
		</button>
	);
}

export const ButtonEditLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link
			{...rest}
			className="btn btn-action-icon-edit with-tooltip"
			data-tooltip-content="Modifier"
		>
			<MdEdit />
		</Link>
	);
};
export const ButtonViewLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link
			className="btn btn-action-filter-icon with-tooltip"
			data-tooltip-content="Voir"
			{...rest}
		>
			<AiFillEye />
		</Link>
	);
};

export const ButtonView: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn btn-action-filter-icon with-tooltip"
			data-tooltip-content="Voir"
			type="button"
			{...rest}
		>
			<AiFillEye />
		</button>
	);
};

export const ButtonEdit: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn btn-action-icon-edit with-tooltip"
			data-tooltip-content="Modifier"
			{...rest}
		>
			<MdEdit />
		</button>
	);
};

export const ButtonDelete: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn with-tooltip btn-action-icon-delete"
			data-tooltip-content="Supprimer"
			type="button"
			{...rest}
		>
			<HiTrash />
		</button>
	);
};

export function Spinner() {
	return (
		<>
			<span
				className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
				role="status"
			></span>
			<span>Chargement...</span>
		</>
	);
}
