/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Product, ProductFormData, Variation, VariationFormData } from "../../../../utils/api/product/product.type";
import {
	useAddOrEditProductMutation,
	useEditVariationMutation,
	useProductFromLocation,
} from "../../../../utils/api/product/product.api";
import { Color } from "../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { useGetCategoriesQuery } from "../../../../utils/api/category/category.api";
import { cleannerError, closeModal } from "../../../../utils/Utils";

export const variationDefaultValue = {
	option: "",
	quantite: "",
	disponible: true,
	code_promo: "",
	date_fin: "",
	date_debut: "",
	prix: "",
	stock: "",
	taille: "",
	discount_percentage: ""
};

function useCrudProduct() {
	const [product] = useProductFromLocation();
	const validationSchema = yup.object().shape({
		slug: yup.string().default(product?.slug),
		nom: yup.string().required().label("Le nom"),
		nom_en: yup.string().label("Le nom"),
		description: yup.string().required().label("La description"),
		description_en: yup.string().label("La description"),
		categorie: yup.string().required().label("La catégorie").nullable(),
		tags: yup.array().of(yup.string()).nullable(),
		variations: yup.array().of(
			yup.object({
				disponible: yup.boolean().required("Disponible"),
				taille: yup.string().required().label("Taille"),
				code_promo: yup.string().nullable().label("Code promo"),
				image: yup.mixed().when("slug", {
					is: (val: string) => !val,
					then: () => yup.mixed().required().label("Image"),
					otherwise: () => yup.mixed().label("Image").nullable(),
				}),
				marge_pourcentage_pro: yup
					.number()
					.required()
					.label("La Marge")
					.typeError("La Marge est un champ obligatoire"),
				prix: yup
					.number()
					.required()
					.label("Le prix")
					.transform((value) => (isNaN(value) ? null : value)),
				stock: yup
					.number()
					.label("Stock")
					.transform((value) => (isNaN(value) ? null : value)),
				discount_percentage: yup.mixed().when("code_promo", {
						is: (val: string) =>  val ,
						then: () => yup.number().label("Le pourcentage").required()
						.transform((value) => (isNaN(value) ? null : value)),
					}),
				date_debut: yup.string().when("code_promo", {
					is: (val: string) =>  val,
					then: () => yup.string().label("Le date de début").required(),
				}).nullable(),
				date_fin: yup.string().when("code_promo", {
					is: (val: string) =>  val,
					then: () => yup.string().label("Le date de fin").required(),
				}).nullable(),
			})
		),
		images: yup.array().when("slug", {
			is: (val: string) => !val,
			then: () =>
				yup
					.array()
					.of(yup.mixed().required("Veuillez uploader au moins une image").label("Image"))
					.min(1, "Veuillez uploader au moins une image"),
		}),
		fiche_technique: yup.mixed().when("slug", {
			is: (val: string) => !val,
			then: () => yup.mixed().required().label("Image"),
			otherwise: () => yup.mixed().label("Image"),
		}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		control,
	} = useForm<ProductFormData>({
		//@ts-ignore
		resolver: yupResolver(validationSchema),
		defaultValues: {
			variations: [variationDefaultValue],
		},
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "variations",
	});
	const {
		fields: tailles,
		append: addTaille,
		remove: removeTaille,
	} = useFieldArray({
		control,
		name: "tailles",
	});

	const [sendData, { isLoading }] = useAddOrEditProductMutation();
	const navigate = useNavigate();
	const { data } = useGetCategoriesQuery({});
	const [description, setDescription] = useState("");
	const [descriptionEn, setDescriptionEn] = useState<string>("");
	const [image] = useState<any>();
	const [images, setImages] = useState<any>([]);
	const [categorie, setCategorie] = useState<any>(null);
	const [status, setStatus] = useState<boolean>(true);
	const [tags, setTags] = useState<{ id: string; text: string }[]>([]);

	const handleDelete = (i) => {
		setTags(tags.filter((tag, index) => index !== i));
	};

	const handleAddition = (tag) => {
		setTags([...tags, tag]);
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();
		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);
		setTags(newTags);
	};

	const onChangeDescription = (value: string) => {
		setValue("description", value);
		setDescription(value);
	};
	const onChangeDescriptionEn = (value: string) => {
		setValue("description_en", value);
		setDescriptionEn(value);
	};

	const onChangeCategory = (item: any) => {
		setValue("categorie", item?.value);
		setCategorie(item);
	};
	const onChangeStatus = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value === "1") {
			setValue("disponible", true);
			setStatus(true);
		} else {
			setValue("disponible", false);
			setStatus(false);
		}
	};

	const deleteImage = (index) => {
		const newImages = images.slice(0, index).concat(images.slice(index + 1));
		setImages(newImages);
	};

	const handleChangeFiche = (e: any) => {
		const file = e.target.files;
		if (file && file?.length > 0) {
			setValue("fiche_technique", file[0]);
		}
	};
	const handleChangeImage = (e: any, i: number) => {
		const file = e.target.files;
		if (file && file?.length > 0) {
			setValue(`variations.${i}.image`, file[0]);
			// setImage(URL.createObjectURL(file[0]));
		}
	};

	useEffect(() => {
		// console.log({ errors });
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	useEffect(() => {
		if (product?.id) {
			console.log({ product });
			const fields: (keyof ProductFormData)[] = [
				"id",
				"slug",
				"nom",
				"nom_en",
				"description",
				"disponible",
				"prix",
				"stock",
				"marge_pourcentage_pro",
				"code_promo",
				"date_debut",
				"date_fin",
				"description",
				"description_en",
				"discount_percentage",
				"mise_en_avant"
			];
			for (let field of fields) {
				register(field);
				setValue(field, product[field]);
			}
			setDescription(product?.description);
			setDescriptionEn(product?.description_en);
		}
		if (product?.tags) {
			const newTags = Array.isArray(product?.tags) ? 
				product?.tags?.length > 0 ?
					product?.tags?.map((tag) => {
						return {
							id: tag,
							text: tag,
						};
					})
				:
				[]
			:
			(product?.tags?.length > 0 ?
				JSON.parse(product?.tags)?.map((tag) => {
					return {
						id: tag,
						text: tag,
					};
				})
				:
				[]
			);
			

			setTags(newTags);
		}
		if (product?.disponible) {
			setStatus(product?.disponible);
		}
		if (product?.tailles) {
			setValue("tailles", JSON.parse(product?.tailles));
		}
		if (product?.variations) {
			setValue(
				"variations",
				product?.variations?.map((item) => {
					return {
						taille: item?.taille,
						disponible: item?.disponible,
						prix: item?.prix,
						code_promo: item?.code_promo,
						marge_pourcentage_pro: item?.marge_pourcentage_pro,
						stock: item?.stock,
						date_debut: item?.date_debut,
						date_fin: item?.date_fin,
						discount_percentage: item?.discount_percentage
					};
				})
			);
		}
		// console.log(JSON.parse(product.tailles));
	}, [product]);
	useEffect(() => {
		if (data && product?.categorie) {
			const cat = data?.results?.find((item) => item?.id === product?.categorie?.id);
			setCategorie({ label: cat?.nom, value: cat?.id });
			setValue("categorie", product?.categorie?.id);
		}
	}, [product, data]);

	const onSubmit = async (data: ProductFormData) => {
	
		const fd = new FormData();
		if (images?.length > 0) {
			images?.forEach((item: string) => {
				fd.append("images", item);
			});
		}
		if (tags?.length > 0) {
			fd.append("tags", JSON.stringify(tags?.map((tag) => tag?.text)));
		}
		for (let key of Object.keys(data)) {
			if (key === "variations") {
				if (data[key] && data[key]?.length) {
					fd.append(
						key,
						JSON.stringify(
							data["variations"]?.map((item) => {
								return {
									taille: item?.taille,
									prix: item?.prix,
									code_promo: item?.code_promo,
									marge_pourcentage_pro: item?.marge_pourcentage_pro,
									stock: item?.stock,
									date_debut: item?.date_debut ,
									date_fin: item?.date_fin ,
									disponible: item?.disponible ? "True" : "False",
									discount_percentage: item?.discount_percentage,
								};
							})
						)
					);
					data[key]?.forEach((element, i) => {
						console.log({ i, image: element?.image });
						fd.append(`variations[${i}].image`, element?.image);
					});
				}
			} else if (key === "tailles") {
				if (data[key] && data[key]?.length) {
					fd.append(key, JSON.stringify(data["tailles"]));
				}
			} else {
				fd.append(key, data[key]);
			}
		}

		const res = await sendData({ id: data?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: product ? "Produit modifié avec succès !" : "Produit ajouté avec succès !",
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				navigate("/admin/mes-produits");
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		images,
		control,
		Controller,
		setImages,
		categories: data?.results?.map((item) => {
			return {
				label: item?.nom,
				value: item?.id,
			};
		}),
		categorie,
		fields,
		append,
		remove,
		description,
		onChangeDescription,
		descriptionEn,
		onChangeDescriptionEn,
		handleChangeFiche,
		onChangeCategory,
		onChangeStatus,
		status,
		tags,
		handleAddition,
		handleDelete,
		handleDrag,
		tailles,
		addTaille,
		removeTaille,
		deleteImage,
		image,
		handleChangeImage,
		product,
	};
}

export function useEditProduct() {
	const [product] = useProductFromLocation();
	const validationSchema = yup.object().shape({
		slug: yup.string().default(product?.slug),
		nom: yup.string().required().label("Le nom"),
		nom_en: yup.string().label("Le nom"),
		description: yup.string().required().label("La description"),
		description_en: yup.string().label("La description"),
		categorie: yup.string().required().label("La catégorie").nullable(),
		tags: yup.array().of(yup.string()).nullable(),
		images: yup.array().of(yup.mixed().label("Image")),
		fiche_technique: yup.mixed().label("Image"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		control,
	} = useForm<ProductFormData>({
		//@ts-ignore
		resolver: yupResolver(validationSchema),
		defaultValues: {},
	});

	const [sendData, { isLoading }] = useAddOrEditProductMutation();
	const { data } = useGetCategoriesQuery({});
	const [description, setDescription] = useState("");
	const [descriptionEn, setDescriptionEn] = useState<string>("");
	const [image] = useState<any>();
	const [images, setImages] = useState<any>([]);
	const [categorie, setCategorie] = useState<any>(null);
	const [status, setStatus] = useState<boolean>(true);
	const [tags, setTags] = useState<{ id: string; text: string }[]>([]);

	const handleDelete = (i) => {
		setTags(tags.filter((tag, index) => index !== i));
	};

	const handleAddition = (tag) => {
		setTags([...tags, tag]);
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();
		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);
		setTags(newTags);
	};

	const onChangeDescription = (value: string) => {
		setValue("description", value);
		setDescription(value);
	};
	const onChangeDescriptionEn = (value: string) => {
		setValue("description_en", value);
		setDescriptionEn(value);
	};

	const onChangeCategory = (item: any) => {
		setValue("categorie", item?.value);
		setCategorie(item);
	};
	const onChangeStatus = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value === "1") {
			setValue("disponible", true);
			setStatus(true);
		} else {
			setValue("disponible", false);
			setStatus(false);
		}
	};

	const deleteImage = (index) => {
		const newImages = images.slice(0, index).concat(images.slice(index + 1));
		setImages(newImages);
	};

	const handleChangeFiche = (e: any) => {
		const file = e.target.files;
		if (file && file?.length > 0) {
			setValue("fiche_technique", file[0]);
		}
	};
	const handleChangeImage = (e: any, i: number) => {
		const file = e.target.files;
		if (file && file?.length > 0) {
			setValue(`variations.${i}.image`, file[0]);
			// setImage(URL.createObjectURL(file[0]));
		}
	};

	useEffect(() => {
		// console.log({ errors });
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	useEffect(() => {
		if (product?.id) {
			console.log({ product });
			const fields: (keyof ProductFormData)[] = [
				"id",
				"slug",
				"nom",
				"nom_en",
				"description",
				"disponible",
				"prix",
				"stock",
				"marge_pourcentage_pro",
				"code_promo",
				"date_debut",
				"date_fin",
				"description",
				"description_en",
				"discount_percentage",
				"mise_en_avant"
			];
			for (let field of fields) {
				register(field);
				setValue(field, product[field]);
			}
			setDescription(product?.description);
			setDescriptionEn(product?.description_en);
		}
		if (product?.tags) {
			const newTags = Array.isArray(product?.tags) ? 
			product?.tags?.length > 0 ?
					product?.tags?.map((tag) => {
						return {
							id: tag,
							text: tag,
						};
					})
				:
					[]
			:
			(product?.tags?.length > 0 ?
				JSON.parse(product?.tags)?.map((tag) => {
					return {
						id: tag,
						text: tag,
					};
				})
				:
				[]
			);
			setTags(newTags);
		}
		if (product?.disponible) {
			setStatus(product?.disponible);
		}
		if (product?.tailles) {
			setValue("tailles", JSON.parse(product?.tailles));
		}
	}, [product]);
	useEffect(() => {
		if (data && product?.categorie) {
			const cat = data?.results?.find((item) => item?.id === product?.categorie?.id);
			setCategorie({ label: cat?.nom, value: cat?.id });
			setValue("categorie", product?.categorie?.id);
		}
	}, [product, data]);

	const onSubmit = async (data: ProductFormData) => {
		
		const fd = new FormData();
		if (images?.length > 0) {
			images?.forEach((item: string) => {
				fd.append("images", item);
			});
		}
		if (tags?.length > 0) {
			fd.append("tags", JSON.stringify(tags?.map((tag) => tag?.text)));
		}
		for (let key of Object.keys(data)) {
			fd.append(key, data[key]);
		}

		const res = await sendData({ id: data?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Produit modifié avec succès !",
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {});
		}
		if ("error" in res) {
			const err = res.error as any;
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		images,
		control,
		Controller,
		setImages,
		categories: data?.results?.map((item) => {
			return {
				label: item?.nom,
				value: item?.id,
			};
		}),
		categorie,
		description,
		onChangeDescription,
		descriptionEn,
		onChangeDescriptionEn,
		handleChangeFiche,
		onChangeCategory,
		onChangeStatus,
		status,
		tags,
		handleAddition,
		handleDelete,
		handleDrag,
		deleteImage,
		image,
		handleChangeImage,
		product,
	};
}

export function useMiseEnAvant(produit: Product) {

	const fd = new FormData();

	for (let key of Object.keys(produit)) {
		if (key === "variations") {
			if (produit[key] && produit[key]?.length) {
				fd.append(
					key,
					JSON.stringify(
						produit["variations"]?.map((item) => {
							return {
								taille: item?.taille,
								prix: item?.prix,
								code_promo: item?.code_promo,
								marge_pourcentage_pro: item?.marge_pourcentage_pro,
								stock: item?.stock,
								date_debut: item?.date_debut ,
								date_fin: item?.date_fin ,
								disponible: item?.disponible ? "True" : "False",
								discount_percentage: item?.discount_percentage,
							};
						})
					)
				);
				produit[key]?.forEach((element, i) => {
					console.log({ i, image: element?.image });
					fd.append(`variations[${i}].image`, element?.image);
				});
			}
		} else if (key === "tailles") {
			if (produit[key] && produit[key]?.length) {
				fd.append(key, JSON.stringify(produit["tailles"]));
			}
		}
		 else {
			fd.append(key, produit[key]);
		}
	}

	fd.append('mise_en_avant', produit.mise_en_avant ? 'true' : 'false' );

	const [editData] = useAddOrEditProductMutation();
	const onMiseEnAvant = async () => {
		// let data: ProductFormData = {
		// 	mise_en_avant: !produit.mise_en_avant,
		// };
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				produit?.mise_en_avant === true ? "Métre en avant" : "Enlever "
			} ce produit ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: produit?.mise_en_avant ? Color.success : Color.danger,
			confirmButtonColor: produit?.mise_en_avant
				? Color.success
				: Color.danger,
			preConfirm: () => {
				return editData({
					id: produit.slug,
					data: fd,
				});
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Produit ${
						result?.value?.data?.is_archive
							? "mise en avant"
							: "non mise en avant"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1200,
				});
			}
		});
	};
	return onMiseEnAvant;
}

export function useEditVariation(item?: Variation) {
	const validationSchema = yup.object().shape({
		disponible: yup.boolean().required("Disponible"),
		taille: yup.string().required().label("Taille"),
		code_promo: yup.string().nullable().label("Code promo"),
		image: yup.mixed().label("Image").nullable(),
		marge_pourcentage_pro: yup.string().when("code_promo", {
				is: (val: string) =>  val,
				then: () => yup.number()
				.required()
				.label("La Marge")
				.typeError("La Marge est un champ obligatoire"),
			}),
		prix: yup
			.number()
			.required()
			.label("Le prix")
			.transform((value) => (isNaN(value) ? null : value)),
		stock: yup
			.number()
			.label("Stock")
			.transform((value) => (isNaN(value) ? null : value)),
		discount_percentage: yup.string().when("code_promo", {
				is: (val: string) =>  val,
				then: () => yup.number().label("Le pourcentage").required()
				.transform((value) => (isNaN(value) ? null : value)),
			}),
		date_debut: yup.string().when("code_promo", {
			is: (val: string) =>  val,
			then: () => yup.string().label("Le date de début").required(),
		}).nullable(),
		date_fin: yup.string().when("code_promo", {
			is: (val: string) =>  val,
			then: () => yup.string().label("Le date de fin").required(),
		}).nullable(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<VariationFormData>({
		//@ts-ignore
		resolver: yupResolver(validationSchema),
	});

	const [sendData, { isLoading }] = useEditVariationMutation();
	// const navigate = useNavigate();
	const [image, setImage] = useState<any>();

	const handleChangeImage = (e: any) => {
		const file = e.target.files;
		if (file && file?.length > 0) {
			setValue(`image`, file[0]);
			setImage(URL.createObjectURL(file[0]));
		}
	};

	useEffect(() => {
		// console.log({ errors });
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	useEffect(() => {
		if (item?.id) {
			// console.log({ product });
			const fields: (keyof VariationFormData)[] = [
				"slug",
				"taille",
				"stock",
				"prix",
				"disponible",
				"prix",
				"marge_pourcentage_pro",
				"code_promo",
				"date_debut",
				"date_fin",
				"discount_percentage"
			];
			for (let field of fields) {
				register(field);
				setValue(field, item[field]);
			}
		}
	}, [item]);

	const onSubmit = async (data: VariationFormData) => {
		console.log({ data });
		
		const fd = new FormData();

		for (let key of Object.keys(data)) {
			if (key === "variations") {
				if (data[key] && data[key]?.length) {
					fd.append(
						key,
						JSON.stringify(
							data["variations"]?.map((item) => {
								return {
									taille: item?.taille,
									prix: item?.prix,
									code_promo: item?.code_promo,
									marge_pourcentage_pro: item?.marge_pourcentage_pro,
									stock: item?.stock,
									date_debut: item?.date_debut,
									date_fin: item?.date_fin ,
									disponible: item?.disponible ? "True" : "False",
									discount_percentage: item?.discount_percentage ,
								};
							})
						)
					);
					data[key]?.forEach((element, i) => {
						console.log({ i, image: element?.image });
						fd.append(`variations[${i}].image`, element?.image);
					});
				}
			} else if (key === "tailles") {
				if (data[key] && data[key]?.length) {
					fd.append(key, JSON.stringify(data["tailles"]));
				}
			} else if (key === "date_debut" || key === "date_fin") {
				if (data[key] && data[key]?.length) {
					fd.append(key, data[key]);
				}
			}
			 else {
				fd.append(key, data[key]);
			}
		}

		const res = await sendData({ slug: data?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: item ? "Produit modifié avec succès !" : "Produit ajouté avec succès !",
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				closeModal("EditVariationModal");
				// navigate("/admin/mes-produits");
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		image,
		handleChangeImage,
		reset,
	};
}

export default useCrudProduct;
