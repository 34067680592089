import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { getAvatar, getName } from "../../../../utils/Utils";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { usePagination } from "../../../../utils/hooks";
import { useGetListUserQuery } from "../../../../utils/api/user/user.api";
import {
	ButtonDelete,
	ButtonEditLink,
	ButtonViewLink,
} from "../../../common/Button";
import { User } from "../../../../utils/api/user/user.type";

function UtilisateurAdminTable({ q }: { q?: string }) {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetListUserQuery({
		limit,
		page,
		q,
	});

	const actionFormatter = (cell: string, row: User) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<ButtonViewLink to={``} />
					</div>
					<div className="container-btn-action-icon-modal">
						<ButtonEditLink to={``} />
					</div>
					<div className="container-btn-action-icon-modal">
						<ButtonDelete />
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: User) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getAvatar(row?.avatar)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">
					{getName(row)}
				</p>
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: `Nom`,
			formatter: nameFormatter,
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
		},
		{
			dataField: "civilite",
			text: "Genre",
		},
		{
			dataField: "user_type",
			text: "Type",
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default UtilisateurAdminTable;
