import React from "react";
import "./ProfilProfessionnel.css";
import { NavLink } from "react-router-dom";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { AiOutlineFlag, AiOutlineShop } from "react-icons/ai";
import { useAppSelector } from "../../../redux/hook";
import { getAvatar, getName } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";


const ProfilProfessionnel = () => {
	const { t } = useTranslation();

	const { user } = useAppSelector((s) => s?.user);
	return (
		<div className="dashbord-admin-component colgate_wrapper p-3">
			<div className="dash-admin-page-content-container mb-3">
				<div className="d-flex top_title__container mb-4">
					<h2 className="chart-item-admin-title mb-0">
						{t('profile')}
					</h2>
				</div>
				<div className="custom-profil-content">
					<div className="profil_pink_banner position-relative">
						<NavLink
							to={`/professionel/modifier-mon-profil`}
							role="button"
							className="btn pro_edit_btn"
						>
							{t('edit_profile')}
						</NavLink>
					</div>
				</div>
				<div className="pro-custom-infos p-4">
					<div className="row row-pro-infos">
						<div className="col-md-4">
							<div className="pro_profil_card">
								<p className="m-0 image-wrapper pb-3">
									<img
										src={getAvatar(
											user?.avatar
										)}
										alt="photo"
									/>
								</p>
								<p className="m-0 name pb-4">
									{getName(user)}
								</p>
								<p className="m-0 sm-title d-flex align-items-center gap-3 pb-3 shop">
									<AiOutlineShop className="icon" />
									<span>
										{user?.societe}
									</span>
								</p>
								<p className="m-0 sm-title d-flex align-items-center gap-3 pb-3 flag">
									<AiOutlineFlag className="icon" />
									<span>{user?.ville}</span>
								</p>
								<p className="m-0 sm-title d-flex align-items-center gap-3 pb-3 location">
									<HiOutlineLocationMarker className="icon" />
									<span>
										{user?.adresse}
									</span>
								</p>
								<p className="m-0 sm-title d-flex align-items-center gap-3 email">
									<HiOutlineMail className="icon" />
									<span>{user?.email}</span>
								</p>
							</div>
						</div>
						<div className="col-md-8">
							<div className="pro_right_card">
								<div className="row">
									<div className="col-md-6 pb-5">
										<p className="m-0 pb-3 sm-title">
											NINEA
										</p>
										<p className="m-0 sm-value">
											{user?.ninea}
										</p>
									</div>
									<div className="col-md-6 pb-5">
										<p className="m-0 pb-3 sm-title">
											{t('website')}
										</p>
										<p className="m-0 sm-value">
											{t('website')}
										</p>
									</div>
									<div className="col-md-6 pb-5">
										<p className="m-0 pb-3 sm-title">
											{t('contact')}
										</p>
										<p className="sm-value">
											+
											{
												user?.telephone
											}
										</p>
									</div>
									<div className="col-md-6">
										<p className="m-0 pb-3 sm-title">
											{t('activity_sector')}
										</p>
										<p className="m-0 sm-value">
											{
												user?.secteur
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilProfessionnel;
