import React from "react";
import { CardVariationParticulier } from "../../ProduitDisponibleParticulier/ProduitDisponibleParticulier";

function DetailCommandeParticulier({ item: order }) {
	return (
		<div className="page-content mt-0">
			<div className="container-casadeliz mb-5">
				<div className="mon-compte-titre-editer mb-3">
					#{order?.code_commande}
				</div>
				{order?.items.map((item) => (
					<CardVariationParticulier
						key={item?.slug}
						item={item}
						showIconsAdd={false}
						showIconsFavoris={false}
					/>
				))}
			</div>
		</div>
	);
}

export default DetailCommandeParticulier;
