import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import useCrudAdmin from "./hooks/useCrudAdmin";
import FormLabel from "../../common/Label";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { BtnSubmit } from "../../common/Button";
import { Authorisations } from "../../../utils/constante";
import { useLocation } from "react-router-dom";

function AddOrUpdateAdmin() {
	const {
		register,
		errors,
		isLoading,
		onSubmit,
		phone,
		onChangePhone,
		adresse,
		handleChangeAdress,
		user,
	} = useCrudAdmin();
	const { pathname } = useLocation();
	const isView = pathname?.includes("detail-sous-admin");

	console.log('user',user)
	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Paramètres" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title">
						{user
							? isView
								? "Détail"
								: "Modifier le"
							: "Ajouter un"}{" "}
						sous-admin
					</div>
					<div className="form-add-container">
						<form className="mt-4" onSubmit={onSubmit}>
							<div className="row row-add-student my-3">
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Nom"
										id="nom"
										placeholder="Nom"
										customClassLabel={
											"input-parametre-label-form-add"
										}
										customClassInput="input-parametre-form-add"
										{...register("nom")}
										error={errors.nom}
										disabled={isView}
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Prénom"
										id="prenom"
										placeholder="Prénom"
										customClassLabel={
											"input-parametre-label-form-add"
										}
										customClassInput="input-parametre-form-add"
										{...register(
											"prenom"
										)}
										error={
											errors.prenom
										}
										disabled={isView}
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="email"
										label="Email"
										id="email"
										placeholder="Email"
										customClassLabel={
											"input-parametre-label-form-add"
										}
										customClassInput="input-parametre-form-add"
										{...register(
											"email"
										)}
										error={errors.email}
										disabled={isView}
									/>
								</div>
								<div className="col-md-6 mb-3">
									<FormLabel
										label="N° Téléphone"
										required
										customClassLabel={
											"input-parametre-label-form-add"
										}
									/>
									<div className="input-group mb-3">
										<PhoneInput
											defaultMask={
												".. ... .. .."
											}
											containerClass="react-custom-tel-input mb-3 p-0"
											inputClass=" form-control form-control-modal-custom input-parametre-form-add"
											country={"sn"}
											inputProps={{
												name: "telephone",
												required: true,
												autoFocus:
													true,
											}}
											countryCodeEditable={
												false
											}
											enableAreaCodes={
												true
											}
											disableDropdown={
												true
											}
											prefix="+"
											value={phone}
											onChange={(
												phone
											) =>
												onChangePhone(
													phone
												)
											}
											inputStyle={{
												width: "100%",
												paddingBottom:
													"22px",
												borderRadius:
													"10px",
												height: 50,
											}}
											disabled={
												isView
											}
										/>
										<FormError
											error={
												errors?.telephone
											}
										/>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<FormLabel
										label="Adresse"
										required
										customClassLabel={
											"input-parametre-label-form-add"
										}
									/>
									<GoogleInput
										className="form-control form-control-modal-custom input-parametre-form-add"
										value={adresse}
										onChange={
											handleChangeAdress
										}
										placeholder="Adresse"
										disabled={isView}
									/>
									<FormError
										error={
											errors?.adresse
										}
									/>
								</div>
								<div className="bg-white p-3 filter-and-table-container">
									<div className="content-title-section-detail-admin">
										<p className="title-permissions">
											Permissions
										</p>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group crud-form-group">
												<div className="container-radio-input">
													<div className="form-radio-input radio-input">
														{Authorisations?.map(
															(
																item
															) => (
																<label
																	className="ml-0 mb-3"
																	key={
																		item?.field
																	}
																>
																	<input
																		type="checkbox"
																		id="dashbboard"
																		{...register(
																			item?.field
																		)}
																		disabled={
																			isView
																		}
																	/>
																	<span>
																		{
																			item?.label
																		}
																	</span>
																</label>
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{!isView && (
									<div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
										<BtnSubmit
											label={
												user
													? "Modifier"
													: "Ajouter"
											}
											type="submit"
											isLoading={
												isLoading
											}
											className="btn btn-theme-admin w-100"
										/>
									</div>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddOrUpdateAdmin;
