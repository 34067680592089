import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/hook";
import { User, UserType } from "../utils/api/user/user.type";
import { isAdmin } from "../utils/Utils";
import { Authorisations, FieldType } from "../utils/constante";
import { Admin } from "../utils/api/admin/admin.type";
import { Page403 } from "../components/shared/ErrorsPage/Errors";
import { AppStorage } from "../utils/storage";

interface ProtectedRoutesProps {
	children: ReactElement;
	userType: UserType[];
}

export const isParticulier = (user: User) => {
	return user?.user_type === UserType.particulier;
};

export const isProfessionnel = (user: User) => {
	return user?.user_type === UserType.professionnel;
};

export function isSuperAdmin(user?: User | Admin) {
	return user && user.user_type === UserType.superadmin;
}

export const ProtectedRoutes = ({
	children,
	userType,
}: ProtectedRoutesProps) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);

	const isAccess = userType?.includes(user?.user_type);

	return isAccess && isAccess !== undefined ? (
		children
	) : (
		<Navigate to="/" state={{ from: location }} replace />
	);
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
	const { token, user } = useAppSelector((state) => state.user);
	const from = AppStorage.getItem("from");
	console.log({ from });

	let route = "/panier";
	if (isParticulier(user)) {
		route = "/particulier/panier";
	} else if (isProfessionnel(user)) {
		route = "/professionel/panier";
	}

	const { state } = useLocation();
	if (state?.from && token) {
		return <Navigate to={route} replace />;
	}
	if (from && token) {
		return <Navigate to={route} replace />;
	}
	let path: string = "/";

	if (isParticulier(user)) {
		path = "/particulier/accueil";
	} else if (isAdmin(user)) {
		path = "/admin/dashboard";
		if (user?.user_type === UserType.admin) {
			for (let index = 0; index < Authorisations.length; index++) {
				const element = Authorisations[index];
				if (user[`${element?.field}`] === true) {
					path = element.path;
					break;
				}
			}
		}
	} else if (isProfessionnel(user)) {
		path = "/professionel/dashboard";
	}

	return !token && !user?.id ? children : <Navigate to={path} replace />;
};

// UserRoutes
export const IsParticulierAllow = ({
	children,
}: {
	children: ReactElement;
}) => {
	const { user, token } = useAppSelector((state) => state.user);
	let path: string = "/";
	if (token) {
		if (isParticulier(user)) {
			path = "/particulier/accueil";
		}
	}

	return !token || !isParticulier(user) ? (
		<Navigate to={path} replace />
	) : (
		children
	);
};

export const IsAdminAllow = ({
	children,
	field,
}: {
	children: ReactElement;
	field: FieldType;
}) => {
	const { user, token } = useAppSelector((state) => state.user);
	let path: string = "/connexion";
	if (token) {
		if (isParticulier(user)) {
			path = "/particulier/accueil";
		}

		if (isProfessionnel(user)) {
			path = "/professionel/dashboard";
		}
		if (isAdmin(user)) {
			path = "/admin/dashboard";
		}
	}
	const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
	if (!user[field] && !is_SuperAdmin) {
		return <Page403 />;
	}

	// return children;
	return !token || !isAdmin(user) ? (
		<Navigate to={path} replace />
	) : (
		children
	);
};
