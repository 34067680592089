import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { useDelete, usePagination } from "../../../../utils/hooks";
import {
	useDeleteProductMutation,
	useGetListProductAdminQuery,
} from "../../../../utils/api/product/product.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { Product } from "../../../../utils/api/product/product.type";
import {
	ButtonDelete,
	ButtonEditLink,
	ButtonViewLink,
} from "../../../common/Button";
import { createMarkup, formatDate, getImage } from "../../../../utils/Utils";


function DeleteProduct({ item }: { item: Product }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteProductMutation();
	const onDelete = useDelete<Product>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `ce produit?`,
		successMessage: `Le produit a été supprimé`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

function MesProduitsAdminTable({ q }: { q?: string }) {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetListProductAdminQuery({
		limit,
		page,
		q,
	});

	const actionFormatter = (cell: string, row: Product) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<div className="container-btn-action-icon-modal">
					<ButtonViewLink to={row?.slug} state={row} />
				</div>
				<div className="container-btn-action-icon-modal">
					<ButtonEditLink to={`${row?.slug}/modifier`} state={row} />
				</div>
				<div className="container-btn-action-icon-modal">
					<DeleteProduct item={row} />
				</div>
			</div>
		);
	};
	const nameFormatter = (cell: string, row: Product) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getImage(row?.images[0]?.picture)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">{cell}</p>
			</div>
		);
	};
	const stockFormatter = (cell: string, row: Product) => {
		if (row?.stock > 3) 
		{
			return (
				<div className="justify-content-center">
					<span className="status-livre py-2 px-4">
						<span>{row?.stock}</span>
					</span>
				</div>
			);
		} else {
			return (
				<div className="justify-content-center">
					<span className="status-annule py-2 px-4">
						<span>{row?.stock}</span>
					</span>
				</div>
			);
		}
	};
	const columns = [
		{
			dataField: "nom",
			text: `Produits`,
			formatter: nameFormatter,
		},
		{
			dataField: "categorie.nom",
			text: "Catégorie",
		},
		{
			dataField: "description",
			text: "Description",
			formatter: (cell: string) => <div dangerouslySetInnerHTML={createMarkup(cell?.slice(0, 100))} />,
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "id",
			text: "Stock",
			formatter: stockFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
					/>
					<div className="custom-pagination-container">
						<div className="custom-pagination-container">
							<CustomPagination
								nbPages={data?.count}
								page={page}
								onChange={onChange}
								perPage={limit}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default MesProduitsAdminTable;
