import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { formatDate, getAvatar, getName } from "../../../../utils/Utils";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { useDelete, usePagination } from "../../../../utils/hooks";
import {
	useDeleteDemandeMutation,
	useGetDemandeListQuery,
} from "../../../../utils/api/professionnel/professionnel.api";
import { ButtonDelete, ButtonViewLink, Spinner } from "../../../common/Button";
import { CustomPagination } from "../../../common/CustomPagination";
import {
	Professionnel,
	StatusDemande,
} from "../../../../utils/api/professionnel/professionnel.type";
import ChangeStatusProfessionelModal from "./ChangeStatusProfessionelModal";
import { useValidDemande } from "../hooks/useCrudPro";

function DeleteDemande({ item }: { item: Professionnel }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteDemandeMutation();
	const onDelete = useDelete<Professionnel>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cette demande?`,
		successMessage: `La demande a été supprimée`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ValideDemande({ item }: { item: Professionnel }) {
	const { onValid, isLoading } = useValidDemande(item);

	return (
		<>
			{isLoading ? (
				<button
					disabled
					className="btn details-pro-admin-btn-accepter px-2 py-1"
				>
					<Spinner />
				</button>
			) : (
				<button
					className="btn details-pro-admin-btn-accepter px-2 py-1"
					onClick={onValid}
					disabled={item?.status === "acceptee"}
				>
					Accepter
				</button>
			)}
		</>
	);
}

function DemandesProfessionnelAdminTable() {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetDemandeListQuery({ limit, page });

	const actionFormatter = (cell: string, row: Professionnel) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<ValideDemande item={row} />

				<div className="container-btn-action-icon-modal">
					<ButtonViewLink to={row?.slug} state={row} />
				</div>
				<div className="container-btn-action-icon-modal">
					<DeleteDemande item={row} />
				</div>
			</div>
		);
	};
	const nameFormatter = (cell: string, row: Professionnel) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img
						src={getAvatar(row?.avatar)}
						alt="Profil"
						className="img-profil-itemt-table"
					/>
				</div>
				<p className="name-profil-patient-table mb-0">
					{getName(row)}
				</p>
			</div>
		);
	};
	const statutFormatter = (cell: StatusDemande) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				{cell === "acceptee" ? (
					<span className="badge rounded-pill custom-statut-active">
						{cell}
					</span>
				) : cell === "refusee" ? (
					<span className="badge rounded-pill bg-danger">
						{cell}
					</span>
				) : (
					<span className="badge rounded-pill bg-secondary">
						{cell}
					</span>
				)}
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: `Nom`,
			formatter: nameFormatter,
			headerStyle: () => {},
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "civilite",
			text: "Genre",
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "status",
			text: "Statut",
			formatter: statutFormatter,
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
			<ChangeStatusProfessionelModal />
		</>
	);
}

export default DemandesProfessionnelAdminTable;
