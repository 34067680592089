import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { useLazyRelaunchCustomerQuery } from "../../../../utils/api/user/user.api";
import { QueryError } from "../../../../utils/type";
import { Order } from "../../../../utils/api/order/order.type";

export const useRelaunchCustomer = (order: any) => {
	
	const [relaunch, { isLoading }] = useLazyRelaunchCustomerQuery();

	const onRelaunch = async () => {
		const res = await relaunch({
			user: order?.user?.slug,
			order: order?.slug,
		});
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Client relancé avec succès !`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur inconnue a survenue !",
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};

	return { onRelaunch, isLoading };
};
