import { useLocationState } from "../../../utils/Utils";
import {
	Condition,
	conditionType,
} from "../../../utils/api/config/config.type";
import BreadCrumb from "../../shared/BreadCrumb";
import FormCondition from "./FormCondition";

function AddOrUpdateConditions() {
	const condition = useLocationState<Condition>(undefined);

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Paramètres" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title">
						{!condition ? "Ajouter" : "Modifier"}{" "}
						conditions d'utilisation
					</div>
					<div className="form-add-container">
						<FormCondition
							type={conditionType.cgu}
							condition={condition}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddOrUpdateConditions;
