import React from "react";
import moment from "moment";
import { getAvatar, getName } from "../../../utils/Utils";
import { Product } from "../../../utils/api/product/product.type";
import FormPostCommentProduct from "./FormPostComment";
import { useTranslation } from "react-i18next";


type Props = {
	avatar?: string;
	name?: string;
	date?: string;
	comment?: string;
};

const CommentItem = ({ avatar, name, date, comment }: Props) => {
	
	return (
		<div className="py-3 d-flex align-items-center gap-3 comment__item">
			<div className="avatar__wrapper">
				<img src={avatar} alt="avatar" />
			</div>
			<div className="comment_info__wrapper">
				<div className="m-0 pb-3 d-flex align-items-center gap-2 name_and_stars">
					<span className="name">{name}</span>
					{/* <span className="stars">
						<ReactStars
							count={5}
							size={14}
							emptyIcon={
								<i className="fa-duotone fa-star"></i>
							}
							halfIcon={
								<i className="fa fa-star-half-alt"></i>
							}
							fullIcon={
								<i className="fa fa-star"></i>
							}
							activeColor="#FFC600"
							edit={false}
							value={4}
						/>
					</span> */}
				</div>
				<p className="m-0 pb-2 date">
					{moment(date).format("DD MMMM YYYY à HH:MM")}2
				</p>
				<p className="m-0 comment_short">{comment}</p>
			</div>
		</div>
	);
};

const Comment = ({
	product,
	isShowForm,
}: {
	product: Product;
	isShowForm?: boolean;
}) => {
	
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	
	return (
		<div className="col_comments">
			<h4 className="custom__title">{t('comments')}</h4>
			<div className="pertinents-comments">
				{product?.comments?.nbre_comments > 0 &&
					product?.comments?.comments?.map((comment) => {
						return (
							<CommentItem
								avatar={getAvatar(
									comment.user?.avatar
								)}
								name={getName(comment?.user)}
								date={comment.created_at}
								comment={comment.content}
								key={comment?.slug}
							/>
						);
					})}
				{isShowForm && (
					<div className="post_response_form pt-5">
						<h3 className="m-0 pb-4 custom__title">
							{t('post_comment')}
						</h3>
						<FormPostCommentProduct item={product} />
					</div>
				)}
			</div>
		</div>
	);
};

export default Comment;
