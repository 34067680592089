import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import MyDropzone from "../../../common/MyDropzone";
import { createMarkup } from "../../../../utils/Utils";
import { useDeleteFaqMutation, useGetFaqQuery } from "../../../../utils/api/faq/faq.api";
import { useDelete, usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import { BiEditAlt } from "react-icons/bi";
import { Faq } from "../../../../utils/api/faq/faq.type";
import { ButtonDelete } from "../../../common/Button";

export function DeleteFaq({ item }: { item?: Faq }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteFaqMutation();
	const onDelete = useDelete<Faq>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cette question!`,
		successMessage: `Supprimée`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

function OptionFaqAdmin({ go, getItem }) {
	const [pictures, setPictures] = useState<File | null>();
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetFaqQuery({ limit, page });

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row">
					<div className="col-md-12">
						<div className="content-graph-admin medsain-page-title-container mb-2">
							<div className="container-display-title-theme">
								<button className="btn btn-theme-admin" onClick={() => go("maj-faq")}>
									<span>
										Ajouter
										<AiOutlinePlusCircle />
									</span>
								</button>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="form-add-container">
								<div className="row row-add-student my-3">
									<div className="col-lg-12 mb-3">
										<label
											className={`form-label form-label-modal-custom input-parametre-label-form-add`}
										>
											Bannière
										</label>
										<MyDropzone setImages={setPictures} maxFile={10} />
									</div>
									<div className="col-lg-12 mb-3">
										<div className="content-graph-admin">
											<ol>
												{!isLoading ? (
													data && data?.results?.length > 0 ? (
														<>
															{data?.results?.map(
																(item) => (
																	<li
																		key={
																			item?.id
																		}
																		className="mb-5 option-faq-ol-custom-li"
																	>
																		<span>
																			{
																				item?.titre
																			}
																		</span>
																		<div className="cond-utilisation-ol-item-container">
																			<p
																				className="text-cgu-admin pb-2"
																				dangerouslySetInnerHTML={createMarkup(
																					item?.description
																				)}
																			/>
																		</div>
																		<div className="eglise-cond-utilisation-ol-btn-container">
																			<button
																				// to={`/admin/faqs/${item?.slug}/modifier`}
																				className="btn eglise-cond-utilisation-ol-btn-edit"
																				onClick={() => {
																					go(
																						"maj-faq"
																					);
																					getItem(
																						item
																					);
																				}}
																			>
																				<BiEditAlt />
																			</button>
																			<DeleteFaq
																				item={
																					item
																				}
																			/>
																		</div>
																	</li>
																)
															)}
														</>
													) : (
														<AlertInfo message="Aucune donnée trouvée" />
													)
												) : (
													<></>
												)}
											</ol>
										</div>
										{data && data?.results?.length > limit && (
											<div className="custom-pagination-container">
												<CustomPagination
													nbPages={data?.count}
													page={page}
													onChange={onChange}
													perPage={limit}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OptionFaqAdmin;
