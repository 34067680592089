import React from "react";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import ListePro from "./ProfessionnelAdminTable/ListePro";
import "./ProfessionnelAdmin.css";
import DemandesProfessionnelAdminTable from "./ProfessionnelAdminTable/DemandesProfessionnelAdminTable";

const steps = [
	{ id: "pro-listes", Component: ListePro },
	{ id: "demandes", Component: DemandesProfessionnelAdminTable },
];
const tabs = ["Confirmés", "Demandes"];

function ProfessionnelAdmin() {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;
	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="d-flex justify-content-between align-items-center top_title__container mb-4">
					<h2 className="breadcrumb-current-page undefined">
						Professionnels
					</h2>
					<div className="right-tabs-content mt-3">
						<ul className="tabs-container">
							{tabs.map((tab, key) => (
								<li
									role="button"
									key={key}
									className={`
                        tab-item 
                        ${index === key ? "tab-item-active" : ""}
						le
                    `}
									onClick={() => go(key)}
								>
									{tab}
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="tabs-content">
					<div className="admin-table pt-3">
						<Component />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProfessionnelAdmin;
