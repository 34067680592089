import React from "react";
import InProgressCommand from "./InProgressCommand";
import ConfirmedCommand from "./ConfirmedCommand";
import DeliveredCommand from "./DeliveredCommand";
import CanceledCommand from "./CanceledCommand";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import { useTranslation } from "react-i18next";

const steps = [
	{ id: "in-progress-command", Component: InProgressCommand },
	{ id: "delivered-command", Component: ConfirmedCommand },
	{ id: "confirmed-command", Component: DeliveredCommand },
	{ id: "canceled-command", Component: CanceledCommand },
];

function Commands() {
	const { t } = useTranslation();

	const tabs = [t('progress'), t('confirms'), t('delivereds'), t('cancelled')];

	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;
	return (
		<>
			<div className="commands-content pt-3">
				<ul className="tabs-container with_border_bottom">
					{tabs.map((tab, key) => (
						<li
							role="button"
							className={`
                        tab-item 
                        ${index === key ? "tab-item-active" : ""}
                    `}
							onClick={() => go(key)}
						>
							{tab}
						</li>
					))}
				</ul>
				<div className="tabs-content">
					<div className="admin-table pt-3">
						<Component />
					</div>
				</div>
			</div>
		</>
	);
}

export default Commands;
