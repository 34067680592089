import React from "react";
import ReactStars from "react-rating-stars-component";
import { Input } from "../../common/Input";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import Documents from "./tabs/Documents";
import otherItem from "../../../assets/appImages/otherItem.svg";
import { ProductCarousselItem } from "../../front/boutique/DetailBoutique";
import { useProduct } from "../../front/boutique/hooks/useProduct";
import { createMarkup, formatCurrency } from "../../../utils/Utils";
import Description from "../../particulier/DetailsProduitParticulier/Tabs/Description";
import Commentaire from "../../particulier/DetailsProduitParticulier/Tabs/Commentaire";

const steps = [
	{ id: "description", Component: Description },
	{ id: "commentaire", Component: Commentaire },
	{ id: "documents", Component: Documents },
];

const tabs = ["Description", "Commentaires", "Documents"];

function CatalogDetailProduct() {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });

	const { Component } = step;

	const {
		product,
		setProductNumber,
		handleAddToCart,
		variation,
		handleChangeCurrentVariation,
		user,
		handleRate,
	} = useProduct();

	return (
		<div className="page-content mt-0">
			<div className="container-casadeliz mb-5">
				<section className="section-detail-catalog-product">
					<div className="row mb-5">
						<div className="col-md-5">
							<ProductCarousselItem
								images={product?.images}
							/>
						</div>
						<div className="col-md-7">
							<div>
								<div className="particulier-details-produit-titre mb-3">
									{product?.nom}
								</div>
								<div className="particulier-details-produit-notes-container">
									<ReactStars
										count={5}
										size={14}
										emptyIcon={
											<i className="fa-duotone fa-star"></i>
										}
										halfIcon={
											<i className="fa fa-star-half-alt"></i>
										}
										fullIcon={
											<i className="fa fa-star"></i>
										}
										activeColor="#FFC600"
										edit={!!user}
										value={
											variation?.rate
										}
										onChange={
											handleRate
										}
									/>
								</div>

								<div className="particulier-details-produit-prix mb-3">
									{formatCurrency(
										variation?.prix_pro
									)}
								</div>
								<div
									className="particulier-details-produit-texte mb-3"
									dangerouslySetInnerHTML={createMarkup(
										product?.description
									)}
								/>
								{product?.variations?.length >
									1 && (
									<div className="d-flex align-items-center gap-5 qat_wrapper mb-4">
										<span className="custom_sm__title">
											Format
										</span>
										<div className="d-flex align-items-center flex-wrap gap-3">
											{product?.variations?.map(
												(
													item
												) => (
													<div
														key={
															item?.slug
														}
														className={`variation-item ${
															variation?.id ===
															item?.id
																? "active"
																: ""
														}`}
														role="button"
														onClick={() =>
															handleChangeCurrentVariation(
																item
															)
														}
													>
														{
															item?.taille
														}
													</div>
												)
											)}
										</div>
									</div>
								)}
								<div className="particulier-details-produit-btn-container">
									<div className="particulier-details-produit-titre-quantite mb-3">
										Quantité :
									</div>
									<Input
										type="number"
										label="Min"
										id="min"
										placeholder="0"
										defaultValue={1}
										hideLabel={true}
										customClassLabel={
											"input-particulier-details-produit-label-form"
										}
										customClassInput="input-particulier-details-produit-form-add"
										onChange={(e) => {
											setProductNumber(
												Number(
													e
														.currentTarget
														.value
												)
											);
										}}
									/>
									<button
										className="btn particulier-details-produit-btn-ajout-panier mb-3"
										onClick={() =>
											handleAddToCart(
												variation,
												product
											)
										}
									>
										Ajouter au panier{" "}
										<BsFillArrowRightCircleFill />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="particulier-details-produit-section-tabs-container">
								<div className="particulier-details-produit-section-tabs-list-container">
									<ul className="tabs-container tabs-container-admin mb-0 pb-2 ps-0">
										{tabs.map(
											(
												tab: string,
												key: number
											) => (
												<li
													className={`tab-item  ${
														index ===
															key &&
														"tab-active-item-admin"
													}`}
													key={
														key
													}
													onClick={() =>
														go(
															key
														)
													}
													role="button"
												>
													{
														tab
													}
												</li>
											)
										)}
									</ul>
								</div>
								<div className="tabs-component-container">
									<Component
										product={product}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="particulier-details-produit-section-tabs-container py-3 px-2">
								<div className="particulier-details-produit-titre-other-items mb-3">
									Vous pourriez aimer
								</div>
								{Array(5)
									.fill(5)
									.map((i) => (
										<div
											className="particulier-details-produit-titre-other-items-item mb-1"
											key={i}
										>
											<div className="particulier-details-produit-titre-other-items-img-container">
												<img
													src={
														otherItem
													}
													alt="image produit casadeliz"
													className="particulier-details-produit-titre-other-items-img"
												/>
											</div>
											<div>
												<div className="particulier-details-produit-titre-other-items-titre">
													Pack(x6)
													Nectar
													de
													pomme
													de
													cajou
													-
													1L
													-
													PET
												</div>
												<div className="particulier-details-produit-titre-other-items-prix">
													6
													000,00
													CFA
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default CatalogDetailProduct;
