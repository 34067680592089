/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { cleannerError } from "../../../../utils/Utils";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";
import { AdminFormData } from "../../../../utils/api/admin/admin.type";
import { ProFormData } from "../../../../utils/api/professionnel/professionnel.type";
import { useAddOrEditProMutation } from "../../../../utils/api/professionnel/professionnel.api";

function useEditProfil() {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prénom"),
		telephone: yup.string().required().label("Téléphone"),
		email: yup.string().email().required().label("Email"),
		adresse: yup
			.string()
			.required()
			.label("Adresse")
			.typeError("Adresse est un champ obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ProFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();
	const [phone, setPhone] = useState<string>();
	const [adresse, setAdresse] = useState("");

	const [editAdmin, { isLoading }] = useAddOrEditProMutation();

	const { user } = useAppSelector((s) => s.user);

	const onChangePhone = (phone: string) => {
		setPhone(phone);
		setValue("telephone", `+${phone}`);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (user?.id) {
			let fields: (keyof ProFormData)[] = [
				"nom",
				"prenom",
				"telephone",
				"email",
				"adresse",
				"secteur",
				"societe",
				"civilite",
				"ninea",
				"ville",
			];
			for (let field of fields) {
				register(field);
				setValue(field, user[field]);
			}
			setPhone(user?.telephone);
			setAdresse(user?.adresse);
		}
	}, [user]);

	const handleChangeAdress = (value) => {
		// console.log(value);
		setValue("adresse", value?.rue);
		setAdresse(value?.rue);
	};

	const onSubmit = async (data: AdminFormData) => {
		console.log("data", data);
		const res = await editAdmin({
			slug: user?.slug,
			data: data,
		});
		if ("data" in res) {
			dispatch(onSetUser(res.data));
			reset();
			setPhone("");
			Swal.fire({
				icon: "success",
				title: `Informations modifiées avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			if (
				err?.data?.email?.includes(
					"user with this email already exists."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Un utilisateur avec cet email exite déjà!",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				let message = err?.data?.message
					? err?.data?.message
					: err?.data?.email
					? "Un utilisateur avec cet email existe déjà"
					: err?.data?.telephone
					? "Un utilisateur avec ce numero de téléphone existe déjà"
					: "Une erreur inconnue a survenue lors de la modification";
				Swal.fire({
					icon: "error",
					title: message,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		phone,
		onChangePhone,
		setPhone,
		user,
		handleChangeAdress,
		adresse,
	};
}

export default useEditProfil;
