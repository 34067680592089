import React, { useState,useEffect } from "react";
import Modal from "react-modal";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import ReactQuill from "react-quill";
import { closeModal, createMarkup, getName,showModal } from "../../../../utils/Utils";


const ShowNotificationModal = (
  {
    notification 
  }:
  {
   notification?: any;
  }
) => {
  const [value, setValue] = useState("");



  return (
    <div
			className="modal fade"
			id="DetailsNotificationModal"
			aria-labelledby="DetailNotificationModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header b-b-0">
            <h5 className="modal-title" id="DetailNotificationModalLabel">
              Detail d'une notification
            </h5>
            <CloseModalBtn onClick={() => closeModal("DetailsNotificationModal") } />
          </div>
          <div className="p-3">
              <div className="row">
                {/* <div className="col-md-12 mb-3">
                  <label
                    htmlFor="description"
                    className="form-label form-label-modal-custom"
                    aria-labelledby="description"
                  >
                    Type
                  </label>
                  {notification?.notif_type}
                </div> */}
                <div className="col-md-12">
                  <label
                      htmlFor="content"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="description"
                    >
                    Description
                  </label>
                  {notification?.content}
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default ShowNotificationModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    zIndex: 99999,
  },
};
