import Swal from "sweetalert2";
import {
	useAddMultipleToCartMutation,
	useAddProductCartMutation,
	useClearCartMutation,
	useDeleteProductCartMutation,
	useLazyGetCartByUserQuery,
	useEditCartitemsMutation,
} from "../../../utils/api/product/product.api";
import { Color } from "../../../utils/theme";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useEffect, useState } from "react";
import {
	decrement,
	getCartCount,
	getTotalAmount,
	increment,
	removeCart,
	removeCartItem,
	editCartProduct,
} from "../../../redux/slice/useCartSlice";
import { isParticulier, isProfessionnel } from "../../../routes/routerUtils";

export function useCart() {
	const [panier, setPanier] = useState<any>([]);
	const [cartToOrder, setCartToOrder] = useState<any>([]);
	const [codePromo, setCodePromo] = useState<any>();
	const { user } = useAppSelector((s) => s?.user);
	const [getCart, { data, isLoading }] = useLazyGetCartByUserQuery();
	const [addToCart] = useAddProductCartMutation();
	const [addMultipleToCart] = useAddMultipleToCartMutation();

	const [deleteProduct] = useDeleteProductCartMutation();
	const [clearCart] = useClearCartMutation();
	const isItemInTheCard = !isLoading && data && data?.items?.length > 0;
	const { cartItems, totalAmount } = useAppSelector((state) => state.cart);
	const dispatch = useAppDispatch();
	// console.log("panier", { data });

	const [addCodePromo] = useEditCartitemsMutation();

	const getCartItems = () => {
		dispatch(getCartCount());
		dispatch(getTotalAmount());
	};

	useEffect(() => {
		if (user?.id) {
			getCart(user?.slug);
		}
	}, []);

	useEffect(() => {
		if (user && data) {
			setPanier([...data?.items]);
			setCartToOrder([...data?.items]);
		} else {
			setPanier(cartItems);
			setCartToOrder(cartItems);
		}
	}, [data, cartItems]);

	const sendCodePromo = async (item) => {

		const fd = new FormData();
	
		fd.append('user_code_promo', codePromo);

		const res = await addCodePromo({ slug: item?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Code promos ajouté avec succès !",
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				// navigate("/admin/mes-produits");
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}


	const sendLocalCartToBackend = async () => {
		if (cartItems?.length > 0 && user?.id) {
			const cart = cartItems?.map((item) => {
				return {
					variation: item?.variation?.id,
					quantity: item?.quantite,
				};
			});
			const fd = new FormData();
			fd.append("item_list", JSON.stringify(cart));
			let cartLocal = {"item_list": cart}
			const res = await addMultipleToCart(cartLocal);
			if ("data" in res) {
				return dispatch(removeCart());
			}
			if ("error" in res) {
				console.log(res.error);
			}
		}
	};
	// useEffect(() => {
	// 	if (isParticulier(user) || isProfessionnel(user)) {
	// 		sendLocalCartToBackend();
	// 	}
	// }, [cartItems, user]);

	const incrementQuantite = (item) => {

		if (user) {
			addToCart({ quantite: 1, variation: item?.variation?.id });
		} else {
			if( (item?.quantite + 1) <= item?.variation?.stock)
			{
				dispatch(increment(item));
				getCartItems();
			}
		}
	};

	const decrementQuantite = (item) => {
		if (user) {
			addToCart({ quantite: -1, variation: item?.variation?.id });
		} else {
			dispatch(decrement(item));
			getCartItems();
		}
	};

	const addToCartDirectiled = (e,item) => {
		let quantite = e.target.value


		if (user) {
			addToCart({ quantite: parseInt(quantite) , variation: item?.variation?.id });
		} else {

			let produit = {
				produit: item?.produit,
				prix_total: item?.variation?.prix && ( parseInt(item?.variation?.prix) * parseInt(quantite) ) ,
				quantite: parseInt(quantite),
				variation: item?.variation,
			};

			dispatch(editCartProduct(produit));
			getCartItems();
		}
	};

	const handleDeleteProductToCart = (item) => {
		Swal.fire({
			title: "Êtes-vous sure de vouloir supprimer ce produit du panier",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.success,
			preConfirm: () => {
				if (user) {
					deleteProduct(item?.slug);
					dispatch(removeCartItem(item))
				} else {
					return dispatch(removeCartItem(item));
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result.isConfirmed) {
				getCartItems();
				Swal.fire({
					icon: "success",
					title: `Produit suprimer du panier avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const onSelectProduit = (e) => {
		if (e?.target.checked) {
			let cart = [
				...cartToOrder,
				data?.items?.find((x) => x.slug == e.target.value),
			];
			setCartToOrder(cart);
		} else {
			let cart = cartToOrder?.filter(
				(x) => x.slug != e.target.value
			);
			setCartToOrder(cart);
		}
	};

	const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.checked) {
			setCartToOrder(data?.items);
		} else {
			setCartToOrder([]);
		}
	};

	const onClearCart = async () => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir vider le panier`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.danger,
			confirmButtonColor: Color.danger,
			preConfirm: async () => {
				if (user) {
					const res = await clearCart(data?.slug as string);
					if ("data" in res){
						dispatch(removeCart());
					} 
					else if ("error" in res) console.log(res.error);
				} else {
					return dispatch(removeCart());
				}
			},
		});
	};

	return {
		panier,
		isItemInTheCard,
		incrementQuantite,
		decrementQuantite,
		handleDeleteProductToCart,
		onSelectProduit,
		onSelectAll,
		onClearCart,
		isLoading,
		cartToOrder,
		user,
		cartItems,
		totalMontant: data?.total ?? totalAmount,
		panierCount: panier?.length,
		setCodePromo,
		sendCodePromo,
		addToCartDirectiled,
	};
}
