import { useGetTestimonialForVisitorsQuery } from "../../utils/api/config/config.api";
import { CardTemoignage } from "./HomePage";
import { AlertInfo } from "../common/Alert";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/pagination';

const CarouselTestimonail = () => {
	const { data: tesmonials, isLoading } =
		useGetTestimonialForVisitorsQuery();
	return (
		<div className="carousel-testimonial">
			<Swiper
				// slidesPerView={3}
				spaceBetween={20}
				loop={true}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				navigation={true}
				breakpoints={{
					768: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1200: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
				}}
				modules={[
					Pagination, 
					Navigation, 
					Autoplay]}
				className="mySwiper"
				effect="Cards"
				centeredSlides={true}
				slideNextClass={"custom-slide-next"}
			>
				{!isLoading ? (
					<>
						{tesmonials ? (
							<div className="swiper-slide">

									{tesmonials?.map((item) => (
										
										<SwiperSlide>
											{({ isActive }) => (
												<CardTemoignage
													isActive={isActive}
													key={
														item?.slug
													}
													item={item}
												/>
											)}
										</SwiperSlide>
									))}
							</div>
						) : (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					</>
				) : (
					<></>
				)}
			</Swiper>
		</div>
	);
};

export default CarouselTestimonail;
