import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./About.css";
import history_image from "../../../assets/appImages/our_history_image.png";
import { BsFacebook, BsFillRecordCircleFill } from "react-icons/bs";
import { avantagesImages } from "./data";
import { FaTwitter } from "react-icons/fa";
import { useGetTeamForVisitorsQuery } from "../../../utils/api/team/team.api";
import { AlertInfo } from "../../common/Alert";
import { createMarkup, getAvatar, getName } from "../../../utils/Utils";
import { useGetVisitorAboutQuery } from "../../../utils/api/config/config.api";
import { Color } from "../../../utils/theme";
import { useTranslation } from "react-i18next";


export const ValueItem = ({
  value,
  color,
  description,
}: {
  value: string;
  color: string;
  description?: string;
}) => {
  return (
    <div className="value-item mb-4">
      <div className="top-item-value">
        <BsFillRecordCircleFill color={color} />
        {value}
      </div>
      <p className="description m-0 pt-2">{description}</p>
    </div>
  );
};

export const ValueCard = ({
  icon,
  title,
  description,
}: {
  icon: string;
  title: string;
  description: string;
}) => {
  return (
    <div className="value-card text-center">
      <div className="icon-content">
        <img src={icon} className="icon" color="#ED646E" />
      </div>
      <span className="title">{title}</span>
      <span className="description">{description}</span>
    </div>
  );
};

const TeamCard = ({
  name,
  photo,
  role,
  facebook_link,
  twitter_link,
}: {
  name: string;
  photo: string;
  role: string;
  facebook_link: string;
  twitter_link: string;
}) => {
  return (
    <div className="team-card">
      <div className="image-content">
        <img src={getAvatar(photo)} alt="photo" />
      </div>
      <span className="name">{name}</span>
      <div className="d-flex justify-content-between align-items-end px-3 py-4">
        <div className="name-and-role d-flex flex-column gap-2">
          <span className="role">{role}</span>
        </div>
        <div className="socials-link d-flex align-items-center gap-3">
          <a href={facebook_link} target="_blank">
            <BsFacebook
              style={{
                fontSize: 25,
                color: "#3B4758",
              }}
            />
          </a>
          <a href={twitter_link} target="_blank">
            <FaTwitter
              style={{
                fontSize: 25,
                color: "#3B4758",
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

function About() {
  const { data = [], isLoading } = useGetTeamForVisitorsQuery();
  const { data: about, isLoading: isLoad } = useGetVisitorAboutQuery();
  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
  const { t } = useTranslation();

  const style = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${about?.banniere}), lightgray 50% / cover no-repeat`,
  };
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pt-5-content-page">
        <div
          className="about_us_bg text-center"
          style={style}
        >
          <div className="casadeliz-section-hero-page-title">{t("about")}</div>
        </div>
        <div className="our history pt-5 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="image-content">
                  <img
                    src={about?.image_histoire}
                    alt="image"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <p className="custom-small-title m-0">{t("about_us")}</p>
                <h3 className="custom-big-title">{t("our_story")}</h3>
                {!isLoad && (
                  <div
                    className="custom-description"
                    dangerouslySetInnerHTML={createMarkup(
                      locale == 'fr' ? about?.description_notre_histoire : about?.description_notre_histoire_en
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="section_light">
          <div className="container">
            <p className="custom-small-title text-center m-0">
              {t('why_choose_us')} ?
            </p>
            <h3 className="custom-big-title text-center">
              {locale == 'fr' ? about?.titre_pourquoi_nous_choisir : about?.titre_pourquoi_nous_choisir_en}
            </h3>
            <p className="m-0 pt-3 text-center">
              {locale == 'fr' ? about?.description_pourquoi_nous_choisir : about?.description_pourquoi_nous_choisir_en}
            </p>
            <div className="all-values-content pt-4">
              <div className="row">
                <div className="col-md-4">
                  {!!about &&
                    about?.raisons
                      .slice(0, 3)
                      .map((item, key) => (
                        <ValueItem
                          value={locale == 'fr' ? item?.titre : item?.titre_en  }
                          color={Color.themeColor}
                          description={locale == 'fr' ? item.description : item.description_en}
                          key={key}
                        />
                      ))}
                </div>
                <div className="col-md-4 d-flex justify-content-center mb-4">
                  <div className="image-content">
                    <img src={about?.image_raison} alt="image" />
                  </div>
                </div>
                <div className="col-md-4">
                  {!!about &&
                    about?.raisons
                      .slice(3, 6)
                      .map((item, key) => (
                        <ValueItem
                          value={locale == 'fr' ? item?.titre : item?.titre_en}
                          color={Color.themeColor}
                          description={locale == 'fr' ? item.description : item.description_en}
                          key={key}
                        />
                      ))}
                </div>
              </div>
            </div>
            <div className="pt-5 value-cards">
              <div className="row">
                {!!about &&
                  about?.avantages?.map((card, key) => {
                    return (
                      <div className="col-md-3 card-wrapper mb-3">
                        <ValueCard
                          icon={avantagesImages[key]}
                          title={locale == 'fr' ? card.titre : card.titre_en}
                          description={locale == 'fr' ? card.description : card.description_en}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="teams page_section">
          <div className="container">
            <p className="custom-small-title text-center m-0">{t('team')}</p>
            <h3 className="custom-big-title text-center">
              {locale == 'fr' ? about?.equipe_titre : about?.equipe_titre_en}
            </h3>
            <p className="m-0 pt-3 text-center">{locale == 'fr' ? about?.equipe_description : about?.equipe_description_en}</p>
          </div>
          <div className="our-teams py-5 container-fluid">
            <div className="row">
              {!isLoading ? (
                <>
                  {data ? (
                    data?.map((member, key) => (
                      <div className="col-md-4 mb-3" key={key}>
                        <TeamCard
                          photo={member.avatar}
                          name={getName(member)}
                          role={member.fonction}
                          facebook_link={member.facebook}
                          twitter_link={member.x}
                          key={member.slug}
                        />
                      </div>
                    ))
                  ) : (
                    <AlertInfo message={t("no_data_found")} />
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default About;
