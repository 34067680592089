import React from "react";
import CommandParticulierTable from "./CommandParticulierTable";
import { useOrderByParticulierQuery } from "../../../../utils/api/order/order.api";
import { usePagination } from "../../../../utils/hooks";
import { useAppSelector } from "../../../../redux/hook";
import { useTranslation } from "react-i18next";


function MesCommande({ getItem, go }) {
	const { t } = useTranslation();

	const { user } = useAppSelector((s) => s?.user);
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useOrderByParticulierQuery({
		limit,
		page,
		slug: user?.slug,
	});

	return (
		<div className="page-content mt-0">
			<div className="container-casadeliz mb-5">
				<div className="mon-compte-titre-editer mb-3">
					{t("my_order")}
				</div>
				<CommandParticulierTable
					getItem={getItem}
					go={go}
					data={data}
					isLoading={isLoading}
					onChange={onChange}
					page={page}
					limit={limit}
				/>
			</div>
		</div>
	);
}

export default MesCommande;
