import React from "react";
import NewsLetterForm from "./NewsLetterForm";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
	const { t } = useTranslation();

	return (
		<section className="section-contact section-banner-homepage custom-section-divider">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="custom-section-contact-title mb-4">{t("join_newsletter")}</div>
						<div className="custom-section-contact-texte mb-4">
							{t("join_newsletter")}
						</div>
						<NewsLetterForm />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Newsletter;
