import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import Forum from "../../common/Forum";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { createMarkup, getImage } from "../../../utils/Utils";
import { ApiBaseUrl } from "../../../utils/http";
import { useArticleFromLocation } from "../../../utils/api/article/article.api";
import { IconPause, IconPlay } from "../../shared/Icons";
import { ShareArticle } from "./DetailsActuVideo";

function DetailsArticleAudio() {
	const [item] = useArticleFromLocation();

	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content pb-5">
				<div className="container pt-4">
					<div className="col-md-12">
						<div className="container pt-5">
							<div className="row">
								<div className="col-lg-6 mb-3">
									<div className="details-article-video-titre-container">
										<div className="details-article-video-titre mb-5">
											{item?.titre}
										</div>
										<div
											className="details-actu-content mb-3"
											dangerouslySetInnerHTML={createMarkup(
												item?.content
											)}
										/>
									</div>
								</div>
								<div className="col-lg-6 mb-3">
									<div className="custom-video-audio-container position-relative">
										<img
											src={getImage(
												item?.backgound_image
											)}
											alt="image cover"
											className="custom-img-cover-article-audio"
										/>
										<div className="custom-audio-player-container w-100">
											<AudioPlayer
												src={
													ApiBaseUrl +
													"/" +
													item?.fichier
												}
												showJumpControls={
													false
												}
												showDownloadProgress={
													false
												}
												showFilledVolume={
													false
												}
												customIcons={{
													play: (
														<IconPlay />
													),
													pause: (
														<IconPause />
													),
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row custom-section-divider-forum"></div>
						<ShareArticle item={item} />
						<div className="row custom-section-divider-forum"></div>
						<div className="details-article-section-comment-title mb-4">
							Commentaires
						</div>
						<Forum item={item} />
						<div className="row custom-section-divider"></div>
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
}

export default DetailsArticleAudio;
