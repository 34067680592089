interface Props {
	haveMinCharacter: boolean;
	haveMinNumber: boolean;
	haveMinLowercase: boolean;
	haveMinSpecialCharacter: boolean;
	haveMinUppercase: boolean;
}

export function PassWordRuleView(props: Props) {
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	} = props;
	return (
		<div className="col-12">
			<div className="password-validation-terms-container">
				<h4 className="password-validation-terms-title">
					Votre mot de passe doit contenir :{" "}
				</h4>
				<ul className="password-validation-terms-ul px-2">
					<li
						className={"password-rule-item " + (haveMinCharacter && "fas-text-success")}
					>
						Au minimum 8 caractères
					</li>
					<li
						className={"password-rule-item " + (haveMinUppercase && "fas-text-success")}
					>
						Au minimum 1 caractère en majuscule
					</li>
					<li
						className={"password-rule-item " + (haveMinLowercase && "fas-text-success")}
					>
						Au minimum 1 caractère en minuscule
					</li>
					<li className={"password-rule-item " + (haveMinNumber && "fas-text-success")}>
						Au minimum 1 nombre
					</li>
					<li
						className={
							"password-rule-item " + (haveMinSpecialCharacter && "fas-text-success")
						}
					>
						Au minimum 1 caractère spéciale
					</li>
				</ul>
			</div>
		</div>
	);
}
