import React, { useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { CommentArticle } from "../../../utils/api/article/article.type";
import { createMarkup, getAvatar, getName } from "../../../utils/Utils";
import { IconHearts } from "../../shared/Icons";
import { User } from "../../../utils/api/user/user.type";
import { usePostResponseComment } from "../../common/hooks/usePostResponseComment";
import { FormError } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";

function ResponseCommentForum({ item }: { item: CommentArticle }) {
	const { register, errors, onSubmit, authUser, isLoading } = usePostResponseComment(item);

	return (
		<div className="custom-bloc-response-comment">
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-6 col-form-register-tabs mb-4">
						<div className="content-col-form-register-tabs">
							<input
								className="form-control form-control-register-forum"
								type="text"
								placeholder="Noms"
								defaultValue={getName(authUser)}
								disabled
							/>
						</div>
					</div>
					<div className="col-md-6 col-form-register-tabs mb-4">
						<div className="content-col-form-register-tabs">
							<input
								className="form-control form-control-register-forum"
								type="email"
								placeholder="Email"
								defaultValue={authUser?.email}
								disabled
							/>
						</div>
					</div>
					<div className="col-md-12 col-form-register-tabs mb-5">
						<div className="content-col-form-register-tabs">
							<textarea
								id="comment"
								className={`form-control form-control-register-forum`}
								cols={30}
								rows={5}
								placeholder="Text"
								{...register("content")}
							></textarea>
							<FormError error={errors?.content} />
						</div>
					</div>
					<div className="col-md-3 offset-md-9">
						<BtnSubmit
							className="btn form-reponse-btn w-100"
							label="Répondre"
							isLoading={isLoading}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}

const CommentItem = ({ comment, user }: { comment: CommentArticle; user: User }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<div
				className="casadeliz-commentaire-item mb-4"
				key={comment?.slug}
			>
				<div className="casadeliz-commentaire-item-infos-user-container mb-3">
					<div className="casadeliz-section-hero-details-actu-user">
						<img
							src={getAvatar(
								comment?.created_by?.avatar
							)}
							alt="user avatar"
							className="casadeliz-section-hero-details-actu-user-avatar-forum"
						/>

						<div>
							<div className="casadeliz-section-hero-details-actu-username-forum">
								{getName(comment?.created_by)}
							</div>
							<div className="casadeliz-section-hero-details-actu-occupation-forum">
								{moment(
									comment?.created_at
								).fromNow()}
							</div>
						</div>
					</div>
					<div className="page-actu-card-item-content-infos-actu-like">
						<IconHearts />
						<span>10</span>
					</div>
				</div>
				<div
					className="casadeliz-commentaire-item-text mb-3"
					dangerouslySetInnerHTML={createMarkup(
						comment?.content
					)}
				/>

				<Button
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text-comment"
					aria-expanded={open}
					className="casadeliz-commentaire-item-btn-response"
				>
					Répondre
				</Button>
			</div>
			<Collapse in={open}>
				<div className="mb-3">
					<div id="example-collapse-text-comment">
						{comment?.responses?.map((response) => (
							<div
								className="custom-reponse-comment-container"
								key={response?.slug}
							>
								<div className="casadeliz-commentaire-item mb-3">
									<div className="casadeliz-commentaire-item-infos-user-container mb-3">
										<div className="casadeliz-section-hero-details-actu-user">
											<img
												src={getAvatar(
													response
														?.created_by
														?.avatar
												)}
												alt="user avatar"
												className="casadeliz-section-hero-details-actu-user-avatar-forum"
											/>

											<div>
												<div className="casadeliz-section-hero-details-actu-username-forum">
													{getName(
														response?.created_by
													)}
												</div>
												<div className="casadeliz-section-hero-details-actu-occupation-forum">
													{moment(
														comment?.created_at
													).fromNow()}
												</div>
											</div>
										</div>
										<div className="page-actu-card-item-content-infos-actu-like">
											<IconHearts />
											<span>
												10
											</span>
										</div>
									</div>
									<div
										className="casadeliz-commentaire-item-text mb-3"
										dangerouslySetInnerHTML={createMarkup(
											comment?.content
										)}
									/>
								</div>
							</div>
						))}
					</div>
					{!!user && (
						<ResponseCommentForum item={comment} />
					)}
				</div>
			</Collapse>
		</>
	);
};

export default CommentItem;
