import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatDate } from "../../../../utils/Utils";
import { usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import { Admin } from "../../../../utils/api/admin/admin.type";
import { ButtonViewLink } from "../../../common/Button";
import { useGetSubscribersToNewsletterQuery } from "../../../../utils/api/config/config.api";

function SubscribersTable({ q }: { q?: string }) {
	const { limit, page, onChange } = usePagination(10);

	const { data, isLoading } = useGetSubscribersToNewsletterQuery({
		limit,
		page,
		q,
	});

	const actionFormatter = (cell: string, row: Admin) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<div className="container-btn-action-icon-modal">
					<ButtonViewLink
						to={`${row?.slug}/detail-sous-admin`}
						state={row}
					/>
				</div>
			</div>
		);
	};

	const columns = [
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "created_at",
			text: "Date d'abonnement",
			formatter: (cell: string) => formatDate(cell),
		},
		// {
		// 	dataField: "id",
		// 	text: "Action",
		// 	formatter: actionFormatter,
		// 	headerStyle: () => {
		// 		return { width: "100px", whiteSpace: "normal" };
		// 	},
		// },
	];
	// const selectRow = {
	// 	mode: "checkbox",
	// };
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						// selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default SubscribersTable;
