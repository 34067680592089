import React from "react";
import Modal from "react-modal";
import { BsCheckCircle } from "react-icons/bs";
import "../Auth.css";
import { useNavigate } from "react-router-dom";


const BecomeProModal = ({
  show,
  setShow,
}: {
  show: any;
  setShow: any;
}) => {

  const navigate = useNavigate();

  function closeModal() {
    setShow(false);
  }

  return (
		<Modal
			isOpen={show}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="modal-header">
				<div className="w-100 text-center">
					<h5 className="modal_title_pro">
						Demande envoyée
					</h5>
				</div>
			</div>
			<div className="modal_pro_confirm_send d-flex flex-column align-items-center gap-5 pt-3">
				<div className="icon_container">
					<BsCheckCircle color="#ED646E" size={45} />
				</div>
				<p className="m-0-py-2 text-center message-description">
					Votre compte est en cours de validation par un
					administrateur. Un email de confirmation vous sera
					envoyé !
				</p>
				<div className="pt-2 btn-content w-100">
					<button
						className="btn btn-auth w-100"
						onClick={() => {
							setShow(false);
							navigate("/");
						}}
					>
						Ok
					</button>
				</div>
			</div>
		</Modal>
  );
};

export default BecomeProModal;

export const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      zIndex: 99999,
    },
  };
