import React from "react";
import { FormError } from "../../common/Input";
import { usePostCommentProduct } from "./hooks/usePostComment";
import { BtnSubmit } from "../../common/Button";
import { Product } from "../../../utils/api/product/product.type";
import { useTranslation } from "react-i18next";


const FormPostCommentProduct = ({ item }: { item?: Product }) => {
	const { t } = useTranslation();
	
	const { register, errors, isLoading, onSubmit } =
		usePostCommentProduct(item);
	return (
		<form onSubmit={onSubmit}>
			<div className="row row-form">
				{/* <div className="col-md-6">
					<Input label="" type="text" placeholder="Noms" />
				</div>
				<div className="col-md-6">
					<Input
						label=""
						type="email"
						placeholder="Email"
					/>
				</div> */}
				<div className="col-md-12">
					<textarea
						id="recipient_message_poster"
						rows={10}
						placeholder={t("comments")}
						className="form-control form-control-modal-custom w-100"
						{...register("content")}
					/>
					<FormError error={errors?.content} />
				</div>
			</div>
			<div className="d-flex flex-r pt-3">
				<BtnSubmit
					label={t("comment")}
					isLoading={isLoading}
					className="btn btn_post_comment"
				/>
			</div>
		</form>
	);
};

export default FormPostCommentProduct;
