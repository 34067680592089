import React from "react";
import { RiCheckDoubleFill } from "react-icons/ri";
import Avatar from "../../../assets/appImages/userAvat.svg";

function Sommaire() {
  return (
    <>
      <li className="list-group-item list-group-item-meessage linkable active">
        <div>
          <div className="bloc-profil-contact">
            <div className="d-flex align-items-center gap-3 w-100">
              <img
                src={Avatar}
                className="img-messge-contact"
                alt="image profil contact"
              />
              <div className="w-90">
                <div className="container-header-message-list">
                  <p className="nom-contact mb-0 active">Esther Howard</p>
                  <p className="time-message-contact mb-0">8:10 PM</p>
                </div>
                <div className="bloc-message-contact">
                  <div className="w-50">
                    <p
                      className="contenu-message-contact linkable mb-0"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Cursus magna
                      mollis.
                    </p>
                  </div>
                  <div className="">
                    <span className="statut-message-tabs-traite statut-message-tabs-non-traite">
                      <RiCheckDoubleFill />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="list-group-item list-group-item-meessage linkable">
        <div>
          <div className="bloc-profil-contact">
            <div className="d-flex align-items-center gap-3 w-100">
              <img
                src={Avatar}
                className="img-messge-contact"
                alt="image profil contact"
              />
              <div className="w-90">
                <div className="container-header-message-list">
                  <p className="nom-contact mb-0">Esther Howard</p>
                  <p className="time-message-contact mb-0">8:10 PM</p>
                </div>
                <div className="bloc-message-contact">
                  <div className="w-50">
                    <p
                      className="contenu-message-contact linkable mb-0"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Cursus magna
                      mollis.
                    </p>
                  </div>
                  <div className="">
                    <span className="statut-message-tabs-traite statut-message-tabs-non-traite">
                      <RiCheckDoubleFill />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="list-group-item list-group-item-meessage linkable">
        <div>
          <div className="bloc-profil-contact">
            <div className="d-flex align-items-center gap-3 w-100">
              <img
                src={Avatar}
                className="img-messge-contact"
                alt="image profil contact"
              />
              <div className="w-90">
                <div className="container-header-message-list">
                  <p className="nom-contact mb-0">Esther Howard</p>
                  <p className="time-message-contact mb-0">8:10 PM</p>
                </div>
                <div className="bloc-message-contact">
                  <div className="w-50">
                    <p
                      className="contenu-message-contact linkable mb-0"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Cursus magna
                      mollis.
                    </p>
                  </div>
                  <div className="">
                    <span className="statut-message-tabs-traite statut-message-tabs-non-traite">
                      <RiCheckDoubleFill />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default Sommaire;
