import React from "react";
import BannerHomepage from "../../HomePage/BannerHomepage";
import "./AccueilParticulier.css";
import ProductList from "../../front/boutique/ProductList";
import Newsletter from "../../HomePage/Newsletter";

function AccueilParticulier() {
	return (
		<div className="page-content mt-0 pt-0">
			<BannerHomepage />
			<div className="container-casadeliz mb-5">
				<section className="section-boutique custom-section-divider">
					<div className="home-page-titre-boutique mb-5">
						Produits disponibles
					</div>
					<ProductList perPage={30} />
				</section>
				{/* <section className="section-boutique custom-section-divider">
					<div className="particulier-form-devis-fournisseur-container">
						<div className="row">
							<div className="col-lg-6 mb-3">
								<div className="particulier-form-devis-fournisseur-title-container">
									<div className="particulier-form-devis-fournisseur-title mt-4">
										Un moyen simple d'envoyer des demandes à tous les
										fournisseurs
									</div>
									<div className="particulier-form-devis-fournisseur-subtitle">
										Lorem ipsum dolor sit amet, consectetur adipisicing elit,
										sed do eiusmod tempor incididunt.
									</div>
								</div>
							</div>
							<div className="col-lg-6 mb-3">
								<div className="particulier-form-devis-fournisseur-form-container">
									<form action="">
										<div className="row my-2">
											<div className="particulier-form-devis-fournisseur-form-title mb-2">
												Envoyer un devis aux fournisseurs
											</div>
											<div className="col-lg-12 mb-2">
												<Input
													type="text"
													label="De quel produit avez-vous besoin ?"
													hideLabel={true}
													id="nom"
													placeholder="De quel produit avez-vous besoin ?"
													customClassLabel={
														"input-parametre-label-form-add"
													}
													customClassInput="input-parametre-form-add"
												/>
											</div>
											<div className="col-lg-12 mb-2">
												<textarea
													name="message_"
													rows={5}
													className="form-control form-control-custom-devis"
													placeholder="Détails"
												></textarea>
											</div>
											<div className="col-lg-6 mb-2">
												<Input
													type="text"
													label="Quantité"
													hideLabel={true}
													id="nom"
													placeholder="Quantité"
													customClassLabel={
														"input-parametre-label-form-add"
													}
													customClassInput="input-parametre-form-add"
												/>
											</div>
											<div className="col-lg-6 mb-2">
												<SelectInput
													label="Pcs"
													hideLabel={true}
													options={listePcs}
													customClassLabel={
														"input-parametre-label-form-add"
													}
													customClassInput="input-parametre-form-add"
												/>
											</div>
											<div className="col-lg-12 mb-2">
												<button className="btn particulier-form-devis-fournisseur-form-btn">
													Envoyer
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section> */}

				<Newsletter />
			</div>
		</div>
	);
}

export default AccueilParticulier;
