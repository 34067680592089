import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import MyDropzone, { ImageGrid, ImagesView } from "../../common/MyDropzone";
import { WithContext as ReactTags } from "react-tag-input";
import { FormError, Input } from "../../common/Input";
import ReactQuill from "react-quill";
import { useEditProduct } from "./hooks/useCrudProduct";
import { BtnSubmit } from "../../common/Button";
import Select from "react-select";
import FormLabel from "../../common/Label";
import DetailsProduitAdminTable from "./MesProduitAdminTable/DetailsProduitAdminTable";

function UpdateProduit() {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		description,
		onChangeDescription,
		descriptionEn,
		onChangeDescriptionEn,
		categories,
		handleChangeFiche,
		categorie,
		onChangeCategory,
		tags,
		handleAddition,
		handleDelete,
		handleDrag,
		images,
		setImages,
		deleteImage,
		product,
	} = useEditProduct();

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Mes produits" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title mb-4">Ajouter un produit</div>
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-lg-4">
								<div className="add-produit-custom-subtitle mb-4">Détails</div>
								<div className="mb-3">
									<MyDropzone
										maxFile={10}
										color={"#ED646E"}
										background={"#EEF0F4"}
										setImages={setImages}
									/>
									<FormError error={errors?.images} />
									<div className="add-produit-custom-label-indicator">
										Ajoutez {10 - images?.length} photos supplémentaires pour
										compléter la galerie de vos produits !
									</div>
									{product?.images && (
										<div className="grid-images">
											<ImagesView images={product?.images} />
										</div>
									)}
									<div className="grid-images">
										<ImageGrid images={images} deleteFile={deleteImage} />
									</div>
								</div>
								<div className="add-produit-custom-divider" />
								<div className="col-lg-12 mb-3">
									<label className="form-label input-produit-label-form-add mb-2">
										Tags
									</label>
									<ReactTags
										tags={tags}
										handleDelete={handleDelete}
										handleAddition={handleAddition}
										handleDrag={handleDrag}
										inputFieldPosition="top"
										autocomplete
										placeholder="Entrer au moins 9 tags"
										classNames={{
											tags: "tagsClass",
											tagInput: "tagInputClass",
											tagInputField: "tagInputFieldClass",
											selected: "selectedClass",
											tag: "tagClass",
											remove: "removeClass",
										}}
									/>
									<FormError error={errors?.tags} />
								</div>
								<div className="col-lg-12 mb-3">
									<input
											className="custom-detail-form-check form-check-input m-0"
											type="checkbox"
											value=""
											id="mise_en_avant"
											{...register("mise_en_avant")}
											/>
									<label
										htmlFor="description_fr"
										className="form-label input-produit-label-form-add m-l-3 p-b-4"
									>
										
										<strong>Métre en avant</strong> 
									</label>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="add-produit-custom-subtitle mb-4">
									Information générale
								</div>
								<div className="row">
									<div className="col-lg-6 mb-3">
										<Input
											type="text"
											label="Nom du produit(Fr)"
											id="nom_produit"
											placeholder="Nom du produit(Fr)"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register("nom")}
											error={errors.nom}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<Input
											type="text"
											label="Nom du produit(En)"
											id="nom_produit"
											placeholder="Nom du produit(En)"
											customClassLabel={"input-produit-label-form-add"}
											customClassInput="input-produit-form-add"
											{...register("nom_en")}
											error={errors.nom_en}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<label
											htmlFor="description_fr"
											className="form-label input-produit-label-form-add"
										>
											Description(Fr)
										</label>
										<ReactQuill
											className="produit-editor-cgu"
											theme="snow"
											value={description}
											onChange={onChangeDescription}
										/>
										<FormError error={errors?.description} />
									</div>
									<div className="col-lg-6 mb-3">
										<label
											htmlFor="description_en"
											className="form-label input-produit-label-form-add"
										>
											Description(En)
										</label>
										<ReactQuill
											className="produit-editor-cgu"
											theme="snow"
											value={descriptionEn}
											onChange={onChangeDescriptionEn}
										/>
									</div>
									<div className="col-6 mb-3">
										<Input
											type="file"
											label="Modifier la fiche technique du produit"
											id="fiche_technique"
											placeholder="Ajouter la fiche technique du produit"
											customClassLabel={"input-produit-label-form-add"}
											accept="application/pdf"
											customClassInput="input-produit-form-add"
											onChange={handleChangeFiche}
											error={errors.fiche_technique}
										/>
									</div>
									<div className="col-lg-6 mb-3">
										<FormLabel label="Catégories" />
										<Select
											options={categories}
											value={categorie}
											onChange={onChangeCategory}
											placeholder="Sélectionner une catégorie"
											isClearable
											classNamePrefix="custom-select"
										/>
										<FormError error={errors?.categorie} />
									</div>

									<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
										<BtnSubmit
											className="btn btn-theme-admin w-100"
											label="Enrégistrer"
											isLoading={isLoading}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
					<div className="add-produit-custom-divider" />
					<div className="row">
						<div className="add-produit-custom-btn-add-variation-container mb-3">
							<div className="add-produit-custom-subtitle mb-4">Variations</div>
						</div>
						<DetailsProduitAdminTable
							variations={product?.variations}
							isLoading={isLoading}
							isEdit={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateProduit;
