import React from "react";
import { useDelete, usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import { createMarkup, formatDate, getAvatar, getName } from "../../../../utils/Utils";
import TableSkeleton from "../../../common/TableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Testimonial } from "../../../../utils/api/config/config.type";
import { useDeleteTestimonialMutation, useGetTestimonialQuery } from "../../../../utils/api/config/config.api";

export function DeleteTestimonial({ item }: { item?: Testimonial }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteTestimonialMutation();
	const onDelete = useDelete<Testimonial>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `ce témoignage!`,
		successMessage: `Témoignage supprimé`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

function OptionTestimonialAdmin({ go, getItem }) {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetTestimonialQuery({
		limit,
		page,
		q: "",
	});

	console.log({ data });

	const actionFormatter = (cell: string, row: Testimonial) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<ButtonEdit
							onClick={() => {
								go("addTestimonial");
								getItem(row);
							}}
						/>
					</div>
					<div className="container-btn-action-icon-modal">
						<DeleteTestimonial item={row} />
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: Testimonial) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-itemt-table">
					<img src={getAvatar(row?.avatar)} alt="Profil" className="img-profil-itemt-table" />
				</div>
				<p className="name-profil-patient-table mb-0">{getName(row)}</p>
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: `Nom`,
			formatter: nameFormatter,
		},
		{
			dataField: "fonction",
			text: "Fonction",
		},
		{
			dataField: "message",
			text: "Message",
			formatter: (cell) => <div dangerouslySetInnerHTML={createMarkup(cell)} />,
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
			formatter: (cell) => formatDate(cell),
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			<div className="content-graph-admin medsain-page-title-container mb-2">
				<div className="container-display-title-theme">
					<button className="btn btn-theme-admin" onClick={() => go("addTestimonial")}>
						<span>
							Ajouter
							<AiOutlinePlusCircle />
						</span>
					</button>
				</div>
			</div>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && data?.results && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default OptionTestimonialAdmin;
