import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { WithContext as ReactTags } from "react-tag-input";
import { Input } from "../../common/Input";
import ReactQuill from "react-quill";
import DetailsProduitAdminTable from "./MesProduitAdminTable/DetailsProduitAdminTable";
import { BsFileText } from "react-icons/bs";
import { useProductFromLocation } from "../../../utils/api/product/product.api";
import { ApiBaseUrl } from "../../../utils/http";
import { ProductCarousselItem } from "../../front/boutique/DetailBoutique";

function DetailsProduit() {
	const [product, isLoading] = useProductFromLocation();

	const [tags] = Array.isArray(product?.tags) ? 
	React.useState(
		product?.tags?.length > 0 ?
			product?.tags?.map((tag) => {
				return {
					id: tag,
					text: tag,
				};
			})
		:
			[]
		)
	:
	(
	React.useState(
		product?.tags?.length > 0 ?
		JSON.parse(product?.tags)?.map((tag) => {
			return {
				id: tag,
				text: tag,
			};
		})
		:
			[]
		)
	);


	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Mes produits" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title mb-4">Détail du produit</div>
					<div className="row">
						<div className="col-lg-4">
							<div className="add-produit-custom-subtitle mb-4">Détails</div>
							<div className="detail-produit-img-produit-container">
								<ProductCarousselItem
									images={product?.images}
									imgClass="detail-produit-img-produit"
								/>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="add-produit-custom-subtitle mb-4">Information générale</div>
							<div className="row">
								<div className="col-lg-6 mb-3">
									<Input
										type="text"
										label="Nom du produit(Fr)"
										id="nom_produit"
										placeholder="Nom du produit(Fr)"
										customClassLabel={"input-produit-label-form-add"}
										customClassInput="input-produit-form-add"
										value={product?.nom}
									/>
								</div>
								<div className="col-lg-6 mb-3">
									<Input
										type="text"
										label="Nom du produit(En)"
										id="nom_produit"
										placeholder="Nom du produit(En)"
										customClassLabel={"input-produit-label-form-add"}
										customClassInput="input-produit-form-add"
										value={product?.nom_en}
									/>
								</div>
								<div className="col-lg-6 mb-3">
									<label
										htmlFor="description_fr"
										className="form-label input-produit-label-form-add"
									>
										Description(Fr)
									</label>
									<ReactQuill
										className="produit-editor-cgu"
										theme="snow"
										value={product?.description}
									/>
								</div>
								<div className="col-lg-6 mb-3">
									<label
										htmlFor="description_en"
										className="form-label input-produit-label-form-add"
									>
										Description(En)
									</label>
									<ReactQuill
										className="produit-editor-cgu"
										theme="snow"
										value={product?.description_en}
									/>
								</div>
								<div className="col-lg-6 mb-3">
									<Input
										type="text"
										label="Catégorie"
										id="categorie"
										customClassLabel={"input-produit-label-form-add"}
										customClassInput="input-produit-form-add"
										value={product?.categorie?.nom}
									/>
								</div>
								<div className="col-lg-6 mb-3">
									<label className="form-label input-produit-label-form-add mb-2">
										Tags
									</label>
									<ReactTags
										tags={tags}
										inputFieldPosition="top"
										autocomplete
										placeholder="Entrer au moins 9 tags"
										classNames={{
											tags: "tagsClass",
											tagInput: "tagInputClass",
											tagInputField: "tagInputFieldClass",
											selected: "selectedClass",
											tag: "tagClass",
											remove: "removeClass",
										}}
									/>
								</div>
								<div className="itm-center gap-4 mb-4">
									<div className="add-produit-custom-subtitle">Fiche technique</div>
									<div className="col-md- auth-submit-btn-container text-end">
										<a
											className="btn btn-theme-admin"
											href={ApiBaseUrl + product?.fiche_technique}
										>
											<span>
												Voir le catalogue
												<BsFileText />
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="add-produit-custom-divider" />
					<div className="row">
						<div className="add-produit-custom-btn-add-variation-container mb-3">
							<div className="add-produit-custom-subtitle mb-4">Variations</div>
						</div>
						<DetailsProduitAdminTable
							variations={product?.variations}
							isLoading={isLoading}
							isEdit={false}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailsProduit;
