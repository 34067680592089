import React from "react";
import { NavLink } from "react-router-dom";
import { createMarkup } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { BiEditAlt } from "react-icons/bi";
import { DeleteCondition } from "./ConditionsUtilisation";
import {
	Condition,
	conditionType,
} from "../../../../utils/api/config/config.type";

function PolitiquesConfidentialite({ condition }: { condition?: Condition }) {
	return (
		<div className="content-graph-admin">
			{!condition ? (
				<AlertInfo message="Pas de données" />
			) : (
				<div
					key={condition?.slug}
					className="mb-5 cond-utilisation-ol-custom-li"
				>
					<div className="cond-utilisation-ol-item-container">
						<p
							className="text-cgu-admin pb-2"
							dangerouslySetInnerHTML={createMarkup(
								condition?.text_fr
							)}
						/>
					</div>
					<div className="eglise-cond-utilisation-ol-btn-container">
						<NavLink
							to="/admin/ajouter-politique-confidentialite"
							className="btn eglise-cond-utilisation-ol-btn-edit"
							state={condition}
						>
							<BiEditAlt />
						</NavLink>
						<DeleteCondition
							item={condition}
							type={conditionType.pc}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default PolitiquesConfidentialite;
