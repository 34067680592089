import React from "react";
import { Category } from "../../../../utils/api/category/category.type";
import useCrudCategory from "./useCrudCategory";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { BtnSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";

const AddCategoryModal = ({
	item,
	resetItem,
}: {
	item?: Category;
	resetItem: () => void;
}) => {
	const { register, onSubmit, errors, isLoading, reset } = useCrudCategory(
		item,
		resetItem
	);
	const onClose = () => {
		resetItem();
		reset();
	};
	return (
		<div
			className="modal fade"
			id="AddCategoryModal"
			aria-labelledby="AddCategoryModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog  modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header py-2 flex-m">
						<h5 className="admin-modal-title text-start">
							{item ? "Modifier la" : "Ajouter une"}{" "}
							catégorie
						</h5>
						<CloseModalBtn onClick={onClose} />
					</div>
					<div className="modal-body">
						<div className="container-form">
							<form onSubmit={onSubmit}>
								<div className="row">
									<div className="col-12 mb-3">
										<Input
											type="text"
											label="Nom"
											id="nom"
											placeholder="Nom"
											customClassLabel={
												"input-parametre-label-form-add"
											}
											customClassInput="input-parametre-form-add"
											error={
												errors.nom
											}
											defaultValue={
												item?.nom
											}
											{...register(
												"nom"
											)}
											required
										/>
									</div>
								</div>
								<div className="col-md-3 offset-md-9 auth-submit-btn-container pt-5">
									<BtnSubmit
										className="btn btn-theme-admin w-100"
										isLoading={
											isLoading
										}
										type="submit"
										label={
											item
												? "Modifier"
												: "Ajouter"
										}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCategoryModal;
