import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./Boutique.css";
import ShopBackgroundImage from "../../../assets/appImages/shop_cover.png";
import ProductList from "./ProductList";
import { useTranslation } from "react-i18next";


function Boutique() {
  const { t } = useTranslation();
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pt-5-content-page">
        <div
          className="shop_bg text-center"
          style={{
            backgroundImage: `url(${ShopBackgroundImage})`,
          }}
        >
          <div className="casadeliz-section-hero-page-title">{t("shop")}</div>
        </div>
        <div className="container pt-5 pb-5">
          <ProductList perPage={30} />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default Boutique;
