import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import {
	About,
	AboutFormData,
	Condition,
	ConditionFormData,
	ConfigApp,
	ConfigAppFormData,
	Newsletter,
	Partner,
	PartnerFormData,
	Testimonial,
	TestimonialFormData,
	Catalogue,
	CatalogueFormData,
	Contact,
	ContactFormData,
	Accueil,
	AccueilFormData
} from "./config.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const ConfigApi = createApi({
	reducerPath: "configApi",
	tagTypes: [
		"config",
		"conditions",
		"newsletter",
		"temoignages",
		"partenaires",
		"about_page",
		'catalogues',
		'contacts',
		'accueil',
		'produitALaUne'
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getConfigApp: builder.query<ConfigApp, void>({
			providesTags: ["config"],
			query: () => "config/",
		}),
		editConfig: builder.mutation<ConfigApp, ConfigAppFormData>({
			query: (data) => ({
				url: `config/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["config"],
		}),
		getConditions: builder.query<Condition[], void>({
			query: () => `conditions/`,
			transformResponse: ({ results }) => results,
			providesTags: ["conditions"],
		}),
		addOrEditCondition: builder.mutation<
			Condition,
			{ slug?: string; data: ConditionFormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `conditions/${slug}/`,
						method: "PUT",
						body: data,
					};
				} else {
					return {
						url: `conditions/`,
						method: "POST",
						body: data,
					};
				}
			},
			invalidatesTags: ["conditions"],
		}),
		deleteCondition: builder.mutation<Condition, string>({
			query: (slug) => ({
				url: `conditions/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["conditions"],
		}),
		getPc: builder.query<Condition, void>({
			query: () => `mentions_legales/`,
			transformResponse: ({ results }) => results[0],
			providesTags: ["conditions"],
		}),
		getCgu: builder.query<Condition, void>({
			query: () => `condition_generale_utilisations/`,
			providesTags: ["conditions"],
			transformResponse: ({ results }) => results[0],
		}),
		subscribeNewsletter: builder.mutation<Newsletter, Newsletter>({
			query: (data) => ({
				url: "visiteur/newsletters/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["newsletter"],
		}),
		getSubscribersToNewsletter: builder.query<
			PaginationResults<Newsletter>,
			TypeQuery
		>({
			query: (query) => ({
				url: QueryUrl("newsletters/", query),
				method: "GET",
			}),
			providesTags: ["newsletter"],
		}),

		// testimonials
		getTestimonial: builder.query<
			PaginationResults<Testimonial>,
			TypeQuery
		>({
			query: () => `temoignages/`,
			providesTags: ["temoignages"],
		}),
		getTestimonialForVisitors: builder.query<Testimonial[], void>({
			query: () => ({
				url: `visiteur/temoignages/`,
			}),
			providesTags: ["temoignages"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdateTestimonial: builder.mutation<
			Testimonial,
			{ slug?: string; data: TestimonialFormData | FormData }
		>({
			invalidatesTags: ["temoignages"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `temoignages/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `temoignages/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteTestimonial: builder.mutation<Testimonial, string>({
			query: (slug) => ({
				url: `temoignages/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["temoignages"],
		}),

		// partners
		getPartner: builder.query<PaginationResults<Partner>, TypeQuery>({
			query: () => `partenaires/`,
			providesTags: ["partenaires"],
		}),
		getPartnerForVisitors: builder.query<Partner[], void>({
			query: () => ({
				url: `visiteur/partenaires/`,
			}),
			providesTags: ["partenaires"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdatePartner: builder.mutation<
			Partner,
			{ slug?: string; data: PartnerFormData | FormData }
		>({
			invalidatesTags: ["partenaires"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `partenaires/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `partenaires/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deletePartner: builder.mutation<Partner, string>({
			query: (slug) => ({
				url: `partenaires/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["partenaires"],
		}),
		// About
		getAbout: builder.query<About, void>({
			query: () => `about_page/`,
			providesTags: ["about_page"],
		}),
		addOrEditAbout: builder.mutation<About, AboutFormData>({
			query: (data) => {
				return {
					url: `about_page/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["about_page"],
		}),
		getVisitorAbout: builder.query<About, void>({
			query: () => `visiteur/about_page/`,
			providesTags: ["about_page"],
			transformResponse: ({ results }) => results[0],
		}),
		addOrUpdateCatalogue: builder.mutation<Catalogue,{ slug?: string; data: CatalogueFormData | FormData }>({
			invalidatesTags: ["catalogues"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `catalogue_page/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `catalogue_page/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getCatalogue: builder.query<Catalogue, void>({
			query: () => `page/catalogue/`,
			providesTags: ["catalogues"],
		}),
		addOrUpdateContact: builder.mutation<Contact,{ slug?: string; data: ContactFormData | FormData }>({
			invalidatesTags: ["contacts"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `contact_page/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `contact_page/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getContact: builder.query<Contact, void>({
			query: () => `page/contact/`,
			providesTags: ["contacts"],
		}),
		addOrUpdateAccueil: builder.mutation<Accueil,{ slug?: string; data: AccueilFormData | FormData }>({
			invalidatesTags: ["accueil"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `home_page/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `home_page/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getAccueil: builder.query<Accueil, void>({
			query: () => `page/home/`,
			providesTags: ["accueil"],
		}),
		getProduitALaUne: builder.query<any, void>({
			query: () => `produit/a_la_une/`,
			providesTags: ["produitALaUne"],
		})
	}),
});

export const {
	useEditConfigMutation,
	useGetConfigAppQuery,
	useAddOrEditConditionMutation,
	useGetCguQuery,
	useGetPcQuery,
	useGetConditionsQuery,
	useDeleteConditionMutation,
	useSubscribeNewsletterMutation,
	useGetTestimonialQuery,
	useGetTestimonialForVisitorsQuery,
	useAddOrUpdateTestimonialMutation,
	useDeleteTestimonialMutation,
	useGetPartnerForVisitorsQuery,
	useGetPartnerQuery,
	useAddOrUpdatePartnerMutation,
	useDeletePartnerMutation,
	useGetSubscribersToNewsletterQuery,
	useAddOrEditAboutMutation,
	useGetAboutQuery,
	useGetVisitorAboutQuery,
	useAddOrUpdateCatalogueMutation,
	useGetCatalogueQuery,
	useAddOrUpdateContactMutation,
	useGetContactQuery,
	useAddOrUpdateAccueilMutation,
	useGetAccueilQuery,
	useGetProduitALaUneQuery
} = ConfigApi;
