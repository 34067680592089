import React from "react";
import { Input } from "../../../common/Input";
import UseEditInfosForm from "../requestForm/UseEditInfosForm";
import ErrorMessage from "../../../common/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import { getAvatar } from "../../../../utils/Utils";
import { HiOutlineCamera } from "react-icons/hi2";
import { useTranslation } from "react-i18next";


function MonCompte() {
	const { t } = useTranslation();

	const {
		register,
		handelImageChange,
		handleChangePhone,
		phone,
		avatar,
		onSubmit,
		isLoading,
		errors,
		user,
	} = UseEditInfosForm();

	return (
		<div className="page-content mt-0">
			<div className="container-casadeliz mb-5">
				<div className="mon-compte-titre-editer">
					{t("edit_your_profile")}
				</div>
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-3 mb-3">
							<div className="medsain-mon-profil-img-container">
								<div className="medsain-mon-profil-img-title mb-3">
									Photo
								</div>
								<div className="container-img-profil-user mb-4">
									<div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
										<label
											htmlFor="input-test"
											className="input-testt"
										>
											<img
												src={
													avatar ??
													getAvatar(
														user?.avatar
													)
												}
												alt="Profil"
												className="custom-img-profil-user-admin medsain-mon-profil-img"
											/>
											<div className="leader-auto-user-avatar-edit-hover-deux-profil">
												<HiOutlineCamera className="edit-avatar-camera-icon" />
											</div>
										</label>
										<form className="mt-4">
											<input
												type="file"
												className="input-add-avatar"
												id="input-test"
												onChange={
													handelImageChange
												}
											/>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="col-9 row row-add-student my-3">
							<div className="col-md-6 mb-3">
								<Input
									type="text"
									label={t("first_name")}
									id="prenom"
									placeholder={t("first_name")}
									customClassLabel={
										"input-details-livraison-label-form-add"
									}
									customClassInput="input-details-livraison-form-add"
									{...register("prenom")}
								/>
								<ErrorMessage
									message={
										errors?.prenom &&
										errors?.prenom
											?.message
									}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									type="text"
									label={t("last_name")}
									id="nom"
									placeholder={t("last_name")}
									customClassLabel={
										"input-details-livraison-label-form-add"
									}
									customClassInput="input-details-livraison-form-add"
									{...register("nom")}
								/>
								<ErrorMessage
									message={
										errors?.nom &&
										errors?.nom?.message
									}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									type="text"
									label="Email"
									id="email"
									placeholder="Email"
									customClassLabel={
										"input-details-livraison-label-form-add"
									}
									customClassInput="input-details-livraison-form-add"
									{...register("email")}
									disabled={true}
								/>
								<ErrorMessage
									message={
										errors?.email &&
										errors?.email
											?.message
									}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<label
									htmlFor="telephone"
									className="form-label form-label-modal-custom input-details-livraison-label-form-add"
									aria-labelledby="telephone"
								>
									{t("phone")}
								</label>
								<PhoneInput
									defaultMask={
										".. ... .. .."
									}
									containerClass="react-custom-tel-input mb-3 p-0"
									inputClass=" form-control form-control-modal-custom input-parametre-form-add"
									country={"sn"}
									inputProps={{
										name: "telephone",
										required: true,
										autoFocus: true,
									}}
									countryCodeEditable={
										false
									}
									enableAreaCodes={true}
									disableDropdown={true}
									prefix="+"
									value={phone}
									onChange={(
										phone,
										country: any
									) =>
										handleChangePhone(
											phone,
											country
										)
									}
									inputStyle={{
										width: "100%",
										paddingBottom:
											"22px",
										borderRadius:
											"10px",
										height: 50,
									}}
								/>
								<ErrorMessage
									message={
										errors?.telephone &&
										errors?.telephone
											?.message
									}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									type="text"
									label={t("address")}
									id="adresse"
									placeholder={t("address")}
									customClassLabel={
										"input-details-livraison-label-form-add"
									}
									customClassInput="input-details-livraison-form-add"
									{...register("adresse")}
								/>
								<ErrorMessage
									message={
										errors?.adresse &&
										errors?.adresse
											?.message
									}
								/>
							</div>
							{/* <div className="col-md-6 mb-3">
								<Input
									type="file"
									label="Ajouter une photo"
									id="photo"
									placeholder="Ajouter une photo"
									customClassLabel={
										"input-details-livraison-label-form-add"
									}
									customClassInput="input-details-livraison-form-add"
								/>
							</div> */}

							<div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
								<button
									className="btn custom-btn-confirm w-100"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<span
												className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
												role="status"
											></span>
											<span>
												{t("save")}...
											</span>
										</>
									) : (
										t("save")
									)}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default MonCompte;
