import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import * as Yup from "yup";
import { User, UserType } from "./api/user/user.type";
import Avatar from "../assets/appImages/Avatar.png";
import Image from "../assets/appImages/category.png";
import { ApiBaseUrl } from "./http";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { isParticulier, isProfessionnel } from "../routes/routerUtils";
import { OrderMoyenPaiement, OrderStatut } from "./api/order/order.type";
import { ApiCashOut } from "./intech/intech.type";
import { useTranslation } from "react-i18next";


const phoneUtil = PhoneNumberUtil.getInstance();

export function validateNumber(phone?: string, country?: string) {
	if (!phone || !country) return true;
	try {
		const number = phoneUtil.parse(phone, country);
		if (
			phoneUtil.isPossibleNumber(number) &&
			phoneUtil.isValidNumber(number)
		) {
			return true;
		}
	} catch (ex) {
		console.log("error phone", ex);
	}
	return false;
}

export function getInTechPhone(phone: string) {
	const number = phoneUtil.parse(phone, "SN");
	if (
		phoneUtil.isPossibleNumber(number) &&
		phoneUtil.isValidNumber(number)
	) {
		return number.getNationalNumber();
	}
	return phone;
}
export function getInTechTel(phone: string) {
	const number = phoneUtil.parse(phone, "SN");
	if (
		phoneUtil.isPossibleNumber(number) &&
		phoneUtil.isValidNumber(number)
	) {
		return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
	}
	return phone;
}

export function validatePhone(yup: typeof Yup, country: string) {
	return yup
		.string()
		.test("passwords-match", "Numéro téléphone invalide", (val) =>
			validateNumber(val, country)
		)
		.label("Le téléphone")
		.nullable();
}

export function validatePassword(Yup: any) {
	return Yup.string()
		.required()
		.matches(/[a-z]+/, "Au moins une minuscule.")
		.matches(/[A-Z]+/, "Au moins une majuscule.")
		.matches(
			/[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
			"Au moins un caractère spécial"
		)
		.matches(/\d+/, "Au moins un chiffre.")
		.min(8)
		.label("Le mot de passe");
}

export function useLocationState<T>(
	defaultValue: T | any,
	redirectToWhenNull: string | null = null
): T {
	const state = useLocation().state as T;
	const navigate = useNavigate();
	useEffect(() => {
		if (!state && redirectToWhenNull) {
			navigate(redirectToWhenNull);
		}
	}, [state]);
	return state || defaultValue;
}
export function createMarkup(
	text: string | any,
	showDots?: boolean,
	numberSlice?: number
) {
	return {
		__html: `${numberSlice ? text.slice(0, numberSlice) : text}${
			showDots === true ? "..." : ""
		}`,
	};
}
export function readMarkup(
	text: string | any,
	showDots?: boolean,
	numberSlice?: number
) {
	let content = text ? text.replace(/<.+?>/g, "") : text;
	return {
		__html: `${numberSlice ? content.slice(0, numberSlice) : content}${
			showDots === true ? "..." : ""
		}`,
	};
}

export function cleannerError(
	errors: any,
	cleanner: any,
	timeOut: number = 5000
) {
	if (errors) {
		setTimeout(
			() =>
				Object.entries(errors).map(([key]: any) =>
					cleanner(key)
				),
			timeOut
		);
	}
}

export function isRH(user: any) {
	if (!user) return false;
	return ["rh"].includes(user.user_type);
}

export function isPraticien(user: any) {
	if (!user) return false;
	return ["praticien"].includes(user.user_type);
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
	if (additionalSearchQuery) {
		for (let key of Object.keys(additionalSearchQuery)) {
			let val = additionalSearchQuery[key];

			if (Array.isArray(val)) {
				if (val.length > 0) {
					const string = val
						.map((v) => `${key}=${v}`)
						.join("&");
					if (baseUrl.includes("?")) {
						baseUrl += `&${string}`;
					} else {
						baseUrl += `?${string}`;
					}
				}
			} else if (typeof val === "boolean") {
				if (baseUrl.includes("?")) {
					baseUrl += `&${key}=${val}`;
				} else {
					baseUrl += `?${key}=${val}`;
				}
			} else {
				if (val) {
					if (baseUrl.includes("?")) {
						baseUrl += `&${key}=${val}`;
					} else {
						baseUrl += `?${key}=${val}`;
					}
				}
			}
		}
	}
	return baseUrl;
}

export const isUser = (user: User) => {
	return user?.user_type === "user";
};

export const isAdmin = (user: User) => {
	return (
		user?.user_type === UserType.admin ||
		user?.user_type === UserType.superadmin
	);
};

export const getAvatar = (avatar: string | any) => {
	if (avatar && !avatar?.includes("default.png")) {
		if (avatar.indexOf("http") === 0) return avatar;
		return `${ApiBaseUrl}/${avatar}`;
	}
	return Avatar;
};

export const getImage = (image: string | any) => {
	if (image && !image?.includes("default.png")) {
		if (image.indexOf("http") === 0) return image;
		return `${ApiBaseUrl}/${image}`;
	}
	return Image;
};

export function getName(item: any) {
	if (item && item !== undefined) {
		return item?.prenom + " " + item?.nom;
	}
	return "";
}

export const onHide = (modalId) => {
	// console.log("modalId",modalId)
	document.getElementById(modalId)?.classList.remove("show");
	$(`#${modalId}`).hide();
	// $(`#${modalId}`).click();
	$("body").removeClass("modal-open");
	$(".modal-backdrop").remove();
	$(`body`).css({ overflowY: "scroll" });
};

export const onShow = (modalId: string) => {
	document.getElementById(modalId)?.classList.add("show");
	$(`#${modalId}`).show();
};

export const countUppercase = (str: any) => {
	if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
		return true;
	}
	return false;
};

export const countLowercase = (str: any) => {
	if (str.replace(/[^a-z]/g, "")?.length >= 1) {
		return true;
	}
	return false;
};

export const countNumber = (str: any) => {
	if (str.replace(/[^0-9]/g, "")?.length >= 1) {
		return true;
	}
	return false;
};

export const countCharacters = (str: any) => {
	if (str?.length >= 8) {
		return true;
	}
	return false;
};

export const countSpecial = (str: any) => {
	const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
	let count = 0;
	for (let i = 0; i < str?.length; i++) {
		if (!punct.includes(str[i])) {
			continue;
		}
		count++;
	}
	// return count;
	if (count >= 1) {
		return true;
	}
	return false;
};

const CURRENCY_FORMATTER = new Intl.NumberFormat("fr-FR", {
	currency: "XOF",
	style: "currency",
});

export function formatCurrency(number: number | any) {
	return CURRENCY_FORMATTER.format(number);
}

export const formatDateHour = (date) => {
	return moment(date).format("DD/MM/YYYY à HH:mm:ss");
};

export const formatDate = (date) => {
	return moment(date).format("DD/MM/YYYY");
};

export const sortByAlphabetical = (arr) => {
	return [...arr]?.sort(function (a, b) {
		if (a?.name < b?.name) {
			return -1;
		}
		if (a?.name > b?.name) {
			return 1;
		}
		return 0;
	});
};

export const getLabelRate = (note: number) => {
	if (note === 5) {
		return "Très bien";
	}
	if (note === 4) {
		return "Bien";
	}

	if (note === 3) {
		return "Satisfaisant";
	}

	if (note === 2) {
		return "Insuffisant";
	}

	return "Déconseillé";
};

export const showModal = (id: string) => {
	console.log("id", id);
	const element = document.getElementById(id);

	const myModal = new Modal(element);
	myModal.show();
};

export const closeModal = (id: string) => {
	const element = document.getElementById(id);
	const myModal = Modal.getInstance(element);
	$(".modal-backdrop").remove();
	$("body").removeClass("modal-open");
	$("body").css("padding-right", "");
	$("body").css("overflow", "auto");
	myModal?.hide();
};

export function getAuthUserDefaultRoute(user) {
	let path = "/";
	if (isParticulier(user)) {
		path = "/particulier/accueil";
	} else if (isAdmin(user)) {
		path = "/admin/dashboard";
	} else if (isProfessionnel(user)) {
		path = "/professionel/dashboard";
	}
	return path;
}

export const getLabelOrderStatus = (status: OrderStatut) => {
	const { t } = useTranslation();

	if (status === "annulee") {
		return t("cancelled");
	}
	if (status === "confirmee") {
		return t("confirm");
	}

	if (status === "en_cours") {
		return t("progress");
	}

	if (status === "livree") {
		return t("delivered");
	}
	return status;
};

export const getLabelMessageStatus = (status: string) => {
	if (status === "traited") {
		return "Traité";
	}
	if (status === "in_progress") {
		return "En cours";
	}

	if (status === "new") {
		return "Nouveau";
	}
	return status;
};

export function formatBadgeMessage(status: string) {
	if (status === "traited") {
		return "text-bg-success";
	}
	if (status === "in_progress") {
		return "text-bg-secondary";
	}

	if (status === "new") {
		return "text-bg-danger";
	}
	return "text-bg-dark";
}

export const formatCodeService = (moyen_paiement?: OrderMoyenPaiement) => {
	if (moyen_paiement === "WAVE") {
		return ApiCashOut.WAVE;
	}
	if (moyen_paiement === "ORANGE_MONEY") {
		return ApiCashOut.ORANGE;
	}

	if (moyen_paiement === "FREE_MONEY") {
		return ApiCashOut.ORANGE;
	}

	if (moyen_paiement === "CARTE_BANCAIRE") {
		return ApiCashOut.CARD;
	}
	return moyen_paiement;
};


export function findNonStringProperty(obj: {
	[key: string]: any;
}): string | null {
	console.log({ obj });
	for (const key in obj) {
		if (typeof obj[key] !== "string" && obj[key] !== undefined) {
			return key;
		}
	}
	return null;
}
export function convertToLowerExceptFirst(str) {
	if (str.length === 0) {
		return "";
	} else if (str.length === 1) {
		return str.toUpperCase();
	} else {
		const firstChar = str.charAt(0).toUpperCase();
		const restOfString = str.slice(1).toLowerCase();
		return firstChar + restOfString;
	}
}

export function deleteBaliseHtml(str) {
	const regex = /<[^>]*>/g ;
	const toRawText = (text:string) => text.replace(regex, '')
	return toRawText(str ? str : '...')
}

