import React, { useState } from "react";
import ReactQuill from "react-quill";
import BreadCrumb from "../../shared/BreadCrumb";
import { Input } from "../../common/Input";

function RelancerClientForm() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Clients" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">Relancer le client</div>
          <div className="form-add-container">
            <form className="mt-4">
              <div className="row row-add-student my-3">
                <div className="col-md-12">
                  <Input
                    type="email"
                    label="Email du client"
                    id="email"
                    placeholder="Email du client"
                    value={"famas@gmail.com"}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="message"
                    className="form-label form-label-modal-custom"
                    aria-labelledby="message"
                  >
                    Message
                  </label>
                  <ReactQuill className="editor-cgu" theme="snow" />
                </div>
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button className="btn btn-cancel-admin" type="button">
                    Annuler
                  </button>
                  <button
                    className="btn btn-theme-admin w-100"
                    disabled={isLoading}
                    type="submit"
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelancerClientForm;
