import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { useSearch } from "../../../../utils/hooks";
import BreadCrumb from "../../../shared/BreadCrumb";
import CategoriesTable from "./CategoriesTable";

function Categories() {
	const { search, handleSearch } = useSearch();
	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Mes catégories" />
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="row country-and-search-bar-content pb-4">
							<div className="offset-md-8 col-md-4 search-bar-container d-flex align-items-center gap-2">
								<input
									type="search"
									placeholder="Chercher"
									className="search-bar-conseil"
									onChange={handleSearch}
								/>
								<span className="icon-container">
									<HiOutlineBars3BottomRight />
								</span>
							</div>
						</div>
						<CategoriesTable q={search} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Categories;
