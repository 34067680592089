/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
	AboutFormData,
	UploadFileResult,
} from "../../../../utils/api/config/config.type";
import {
	useAddOrEditAboutMutation,
	useGetAboutQuery,
} from "../../../../utils/api/config/config.api";
import { UPLOAD_FILE_END_POINT } from "../../../../utils/constante";

const itemDefaultValue = {
	titre: "",
	titre_en: "",
	description: "",
	description_en: "",
};

const defaultValues = {
	slug: "",
	titre_a_propos: "",
	titre_a_propos_en: "",
	image_a_propos: "",
	titre_notre_histoire: "",
	titre_notre_histoire_en: "",
	description_notre_histoire: "",
	description_notre_histoire_en: "",
	titre_pourquoi_nous_choisir: "",
	titre_pourquoi_nous_choisir_en: "",
	description_pourquoi_nous_choisir: "",
	description_pourquoi_nous_choisir_en: "",
	image_raison: "",
	equipe_titre: "",
	equipe_titre_en: "",
	equipe_description: "",
	equipe_description_en: "",
	created_at: "",
	avantages: [itemDefaultValue],
};

function useCrudAbout() {
	const validationSchema = yup.object().shape({
		// image_a_propos: yup.mixed().required().label("Image"),
		// image_raison: yup.mixed().required().label("Image"),
		description_notre_histoire: yup
			.string()
			.required()
			.label("Description"),
		description_notre_histoire_en: yup.string().label("Description"),
		description_pourquoi_nous_choisir: yup
			.string()
			.required()
			.label("Sous titre"),
		description_pourquoi_nous_choisir_en: yup
			.string()
			.label("Description"),
		titre_pourquoi_nous_choisir: yup.string().required().label("Titre"),
		titre_pourquoi_nous_choisir_en: yup.string().label("Titre"),
		// equipe_titre: yup.string().required().label("Titre"),
		// equipe_titre_en: yup.string().label("Titre"),
		// equipe_description: yup.string().required().label("Description"),
		// equipe_description_en: yup.string().label("Description"),
		raisons: yup.array().of(
			yup.object({
				titre: yup.string().required().label("Titre"),
				titre_en: yup.string().label("Titre").nullable(),
				description: yup
					.string()
					.required()
					.label("Description"),
				description_en: yup
					.string()
					.label("Description")
					.nullable(),
			})
		),
		avantages: yup.array().of(
			yup.object({
				titre: yup.string().required().label("Titre"),
				titre_en: yup.string().label("Titre").nullable(),
				description: yup
					.string()
					.required()
					.label("Description"),
				description_en: yup
					.string()
					.label("Description")
					.nullable(),
			})
		),
	});
	const { data: about } = useGetAboutQuery();
	const [edit, { isLoading }] = useAddOrEditAboutMutation();

	const [description, setDescription] = useState<string>();
	const [descriptionEn, setDescriptionEn] = useState<string>();

	const [dataForm, setDataForm] = useState<AboutFormData>(defaultValues);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		control,
	} = useForm<AboutFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			raisons: [itemDefaultValue],
			avantages: [itemDefaultValue],
		},
	});

	const {
		fields: raisons,
		append: addRaison,
		remove: removeRaison,
	} = useFieldArray({
		control,
		name: "raisons",
	});

	const {
		fields: avantages,
		append: addAvantage,
		remove: removeAvantage,
	} = useFieldArray({
		control,
		name: "avantages",
	});

	useEffect(() => {
		if (about) {
			let fields: (keyof AboutFormData)[] = [
				"avantages",
				"banniere",
				"titre_a_propos",
				"titre_a_propos_en",
				"image_histoire",
				"titre_notre_histoire",
				"titre_notre_histoire_en",
				"description_notre_histoire",
				"description_notre_histoire_en",
				"titre_pourquoi_nous_choisir",
				"titre_pourquoi_nous_choisir_en",
				"description_pourquoi_nous_choisir",
				"description_pourquoi_nous_choisir_en",
				"image_raison",
				"equipe_titre",
				"equipe_titre_en",
				"equipe_description",
				"equipe_description_en",
				"raisons",
			];

			for (let field of fields) {
				register(field);
				setValue(field, about[field]);
			}
			setDescription(about.description_notre_histoire);
			setDescriptionEn(about.description_notre_histoire_en);
		}
	}, [about]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
		console.log({ errors });
	}, [errors]);

	const updateImage = async (image, key) => {
		let result;
		try {
			let fd = new FormData();
			let files = image;
			fd.append("project_name", "casadeliz");
			fd.append("media_type", "image");
			fd.append("media", files);

			const response = await fetch(UPLOAD_FILE_END_POINT, {
				method: "POST",
				body: fd,
			});
			if (response.ok) {
				const res: UploadFileResult = await response.json();
				if ("success" in res) {
					result = await edit({
						...about,
						[key]: res.file_url,
					});
				}
			}
		} catch (error) {
			console.log(error);
		}
		if ("data" in result) {
			Swal.fire({
				icon: "success",
				title: "Image modifiée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in result) {
			const err = result.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						about ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	const handleChangeImage = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			updateImage(file, "image_raison");
			// setValue("image_raison", e.currentTarget.files[0]);
		}
	};
	const handleChangeBanner = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			// setValue("banniere", file);
			updateImage(file, "banniere");
		}
	};
	const handleChangeImageHistoire = (
		e: React.FormEvent<HTMLInputElement>
	) => {
		if (e.currentTarget.files) {
			// let file = e.currentTarget.files[0];
			// setValue("image_histoire", file);
			// setDataForm({
			// 	...dataForm,
			// 	image_histoire: URL.createObjectURL(file),
			// });
			let file = e.currentTarget.files[0];
			updateImage(file, "image_histoire");
		}
	};

	const handleChange = (
		propertyName: keyof AboutFormData,
		value: string
	) => {
		setDataForm({ ...dataForm, [propertyName]: value });
		setValue(propertyName, value);
	};
	const handleChangeDescription = (value: string) => {
		setDescription(value);
		setValue("description_notre_histoire", value);
	};
	const handleChangeDescriptionEn = (value: string) => {
		setDescriptionEn(value);
		setValue("description_notre_histoire_en", value);
	};

	const handleAddRaison = () => {
		addRaison(itemDefaultValue);
	};
	const handleAddAdvantange = () => {
		addAvantage(itemDefaultValue);
	};

	const onSubmit = async (data: AboutFormData) => {
		console.log("data", data);

		// const images = {
		// 	image_histoire: data.image_histoire,
		// 	image_raison: data.image_raison,
		// };
		// console.log({ images });
		// let imageToUploadKey = findNonStringProperty(images);
		// console.log(imageToUploadKey);
		let result = await edit(data);

		if ("data" in result) {
			Swal.fire({
				icon: "success",
				title: "Modifié avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in result) {
			const err = result.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						about ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		dataForm,
		handleChangeBanner,
		handleChangeImage,
		handleChangeImageHistoire,
		handleChange,
		about,
		raisons,
		removeRaison,
		avantages,
		removeAvantage,
		handleAddAdvantange,
		handleAddRaison,
		handleChangeDescription,
		handleChangeDescriptionEn,
		description,
		descriptionEn,
	};
}

export default useCrudAbout;
