/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../utils/api/auth/auth.api";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/Utils";

export function validatePassword(Yup, message) {
	return Yup.string()
		.required(message)
		.min(8)
		.matches(
			/[a-z]+/,
			"Le mot de passe doit contenir au moins une lettre minuscule."
		)
		.matches(
			/[A-Z]+/,
			"Le mot de passe doit contenir au moins une lettre majuscule."
		)
		.matches(
			/[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
			"Le mot de passe doit contenir au moins un caractère spécial."
		)
		.matches(
			/\d+/,
			"Le mot de passe doit contenir au moins un chiffre."
		);
}

function useRegister() {
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Prenom"),
		nom: yup.string().required().label("Nom"),
		email: yup
			.string()
			.required()
			.email("email invalide")
			.label("Email"),
		password: validatePassword(
			yup,
			"Le mot de passe est un champ obligatoire"
		),
		confirmPassword: yup
			.string()
			.oneOf(
				[yup.ref("password"), ""],
				"Veuillez entrer des mots de passe identiques."
			),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<RegisterFormData>({
		resolver: yupResolver(validationSchema),
	});

	const navigate = useNavigate();

	const [registerUser, { isLoading }] = useRegisterUserMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 6000);
		}
	}, [errors]);

	const onSubmit = async (data: RegisterFormData) => {
		console.log({ data });
		const res = await registerUser(data);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Inscription réussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				navigate("/connexion", { replace: true });
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			if (
				err?.data?.email?.includes(
					"user with this email already exists."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de votre inscription",
					showConfirmButton: false,
					timer: 2000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}
export const usePasswordRule = (setValue: any, field?: string) => {
	const [haveMinUppercase, setHaveMinUppercase] = useState<boolean>(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState<boolean>(false);
	const [haveMinNumber, setHaveMinNumber] = useState<boolean>(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState<boolean>(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] =
		useState<boolean>(false);
	const [isShowPasswordRules, setIsShowPasswordRules] =
		useState<boolean>(false);

	const countUppercase = (str: string) => {
		if (str.replace(/[^A-Z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str: string) => {
		if (str.replace(/[^a-z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str: string) => {
		if (str.replace(/[^0-9]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str: string) => {
		if (str.length >= 8) {
			return true;
		}
		return false;
	};
	const countSpecial = (str: string) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		if (count >= 1) {
			return true;
		}
		return false;
	};
	const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
		var password = e.currentTarget.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}
		setValue(field ? field : "password", e.currentTarget.value);
	};
	const showPasswordRule = () => {
		setIsShowPasswordRules(
			(isShowPasswordRules) => !isShowPasswordRules
		);
	};
	return {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	};
};
export default useRegister;
