/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import "./HomePage.css";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import BannerHomepage from "./BannerHomepage";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import StarRatingComponent from "react-star-rating-component";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { FiShoppingCart } from "react-icons/fi";
import { BsCartCheck } from "react-icons/bs";
import { createMarkup, getAvatar, getImage, getName, deleteBaliseHtml } from "../../utils/Utils";
import cartImg from "../../assets/icons/BuyCart.svg";
import bottleImg from "../../assets/icons/bottle.svg";
import { BiChevronRight } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import ProductList from "../front/boutique/ProductList";
import { Link } from "react-router-dom";
import { useGetPartnerForVisitorsQuery } from "../../utils/api/config/config.api";
import { Testimonial } from "../../utils/api/config/config.type";
import Newsletter from "./Newsletter";
import CarouselTestimonail from "./CarouselTestimonail";
import { useGetArticlesForVisitorsQuery } from "../../utils/api/article/article.api";
import { AlertInfo } from "../common/Alert";
import { ActualiteCardItemSkeleton , ActualiteItem } from "../front/actualite/Actualite";
import { useTranslation } from "react-i18next";
import { Article } from "../../utils/api/article/article.type";
import { useItem } from "../../utils/hooks";
import {
	useGetProduitALaUneQuery,
} from "../../utils/api/config/config.api";

export const responsiveI = {
  0: { items: 1 },
  992: { items: 2 },
  1024: { items: 4 },
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    itemsToScroll: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    itemsToScroll: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    itemsToScroll: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    itemsToScroll: 1,
  },
};
const HomePage = () => {
  const { t } = useTranslation();
  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
  const { item, getItem } = useItem<Article>();
  const { data: articles, isLoading } = useGetArticlesForVisitorsQuery({
    limit: 6,
    page: 1,
    // q: "text",
  });

  const { data: partners, isLoading: isLoad } = useGetPartnerForVisitorsQuery();

  const { data } = useGetProduitALaUneQuery();


  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <BannerHomepage />
        <div className="container-casadeliz mb-5">
          <section className="section-boutique custom-section-divider">
            <div className="home-page-titre-boutique mb-5">{t("shop")}</div>
            <ProductList perPage={6} />
          </section>
          <section className="section-nouveau custom-section-divider">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-6 col-left-banner-accueil mb-3">
                  <div className="content-col-left-banner-accueil">
                    <NavLink to="/boutique" className="btn custom-btn-nouveau mb-4">
                      {t("new")}
                    </NavLink>
                    <div className="section-nouveau-titre mb-5">
                      {t("slogan")} {locale == 'fr' ? data?.nom : data?.nom_en}
                    </div>
                    <NavLink to={`/boutique/produit/${data?.slug}`} className="btn custom-btn-commander">
                      {t("order")}
                      <span>
                        <img src={cartImg} alt="cart icon" />
                      </span>
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-6 col-right-banner-accueil mb-3">
                  <div className="content-col-right-banner-accueil">
                    <div className="container-img-col-right-banner-accueil">
                      <img
                        src={data?.images[0]?.picture ? getImage(data?.images[0]?.picture) : bottleImg}
                        alt="bouteille"
                        className="img-col-right-img-bouteille"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-nouveau custom-section-divider">
            <div className="container-fluid">
              <div className="row align-items-center position-relative">
                <div className="col-md-12">
                  <div>
                    <h2 className="custome-titre-temoignage mb-0">
                      {" "}
                      {t("customers_say")}
                    </h2>
                  </div>
                </div>
                <div className="col-md-12 pt-3">
                  <CarouselTestimonail />
                </div>
              </div>
            </div>
          </section>
          <section className="section-blog section-banner-homepage custom-section-divider">
            <div className="row">
              <div className="col-md-12">
                <div className="custom-section-blog-title-container mb-4">
                  <div className="custom-section-blog-title">Blog</div>
                  <Link
                    to="/actualite"
                    className="custom-section-blog-btn-voir"
                  >
                    {t("show_more")}
                    <BiChevronRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              {!isLoading ? (
                articles && articles?.results?.length > 0 ? (
                  <>
                    {articles?.results?.map((item) => (
                      <div className="col-md-4 mb-3" key={item?.slug}>
                        <Link
                          to={
                            item?.content_type === "audio" ? 
                            `/actualite-audio/${item?.slug}`
                            :
                            item?.content_type === "video" ?
                            `/actualite-video/${item?.slug}`
                            :
                            item?.content_type === "link" ?
                            `/actualite/${item?.slug}`
                            :
                            `/actualite/${item?.slug}`
                          }
                          className="section-blog-card-item"
                          state={item}
                        >
                          <div className="section-blog-card-item-img-container">
                            <img
                              src={getImage(item?.backgound_image)}
                              alt="blog"
                              className="section-blog-card-item-img"
                            />
                          </div>
                          <div className="section-blog-card-item-description-container mb-3">
                            <div className="section-blog-card-item-description mb-3">
                              {(item?.content && item?.content_en) ? deleteBaliseHtml(locale == 'fr' ? item?.content?.slice(0, 100) : item?.content_en?.slice(0, 100)) : deleteBaliseHtml(item?.content?.slice(0, 100))}
                            </div>
                            <div className="section-blog-card-item-author">
                              {getName(item?.author)}
                            </div>
                          </div>
                        </Link>
                      </div>
                      // <div className="col-md-4 mb-3 d-flex" key={item?.slug}>
                      //   <ActualiteItem item={item} getItem={getItem} />
                      // </div>
                    ))}
                  </>
                ) : (
                  <AlertInfo message="Aucune article trouvé" />
                )
              ) : (
                <>
                  {[...Array(6)].map((item, i) => (
                    <ActualiteCardItemSkeleton key={i} />
                  ))}
                </>
              )}
            </div>
          </section>
          <section className="section-partenaire section-banner-homepage custom-section-divider">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="custom-section-partenaire-title mb-4">
                  {t("our_partners")}
                </div>
              </div>
              <div className="col-md-12">
                {!isLoad ? (
                  partners && partners?.length > 0 ? (
                    <Carousel
                      responsive={responsive}
                      infinite={true}
                      showDots={false}
                      autoPlay={true}
                      autoPlaySpeed={1000}
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    >
                      {partners?.map((item) => (
                        <div
                          className="logo-partenaire-casadeliz-container mb-3"
                          key={item?.slug}
                        >
                          <img
                            src={getImage(item?.logo)}
                            alt={item?.nom ?? "logo partenaire casadeliz"}
                            className="logo-partenaire-casadeliz"
                          />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <AlertInfo message="Aucune donnée trouvé" />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
          <Newsletter />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default HomePage;

export const ProductItem = ({ item }: { item: any }) => {
  const { t } = useTranslation();

  const [isAddedToCart, setIsAddedToCart] = React.useState(false);

  return (
    <Fade bottom cascade>
      <div className="product-item-container">
        <NavLink
          to={`/produit/${item?.id}`}
          state={item}
          className="no-link w-100"
        >
          {item?.new === true ? (
            <span className="statut-product-item">{t("new")}</span>
          ) : item?.rupture === true ? (
            <span className="statut-product-item-rupture">
              {t("out_of_stock")}
            </span>
          ) : (
            ""
          )}

          <div className="content-img-product-item">
            <img
              src={
                item?.imagesData
                  ? getImage(item?.imagesData[0]?.image)
                  : getImage("/")
              }
              alt={item?.articleName}
              className="img-product-item"
            />
          </div>
          <div className="container-body-product-item">
            <p className="name-product-item my-2">{item?.articleName} </p>
            <div className="content-price-product">
              <p className="price-product-item d-flex">
                {item?.articlePrice} FCFA
              </p>
            </div>
            <div className="container-cta-chat-produit mt-3">
              {item.rupture === false ? (
                <div className="content-btn-achat-produit w-100">
                  <NavLink
                    to={`/produit/${item?.id}`}
                    className="btn btn-theme w-100"
                  >
                    {t("buy")}
                  </NavLink>
                </div>
              ) : (
                <div className="content-btn-achat-produit w-100">
                  <button className="btn btn-theme w-100 disablead">
                  {t("buy")}
                  </button>
                </div>
              )}
              <div className="content-btn-add-to-panier">
                {item.rupture === false ? (
                  <button
                    className={`
                    btn btn-theme-outline ${
                      isAddedToCart ? "added_to_card" : ""
                    }
                  `}
                    onClick={() => setIsAddedToCart(!isAddedToCart)}
                  >
                    {isAddedToCart ? <BsCartCheck /> : <FiShoppingCart />}
                  </button>
                ) : (
                  <button className="btn btn-theme-outline disabled">
                    {isAddedToCart ? <BsCartCheck /> : <FiShoppingCart />}
                  </button>
                )}
              </div>
            </div>
          </div>
        </NavLink>
        <button className="btn icon-fav-product">
          {item?.favorite === true ? (
            <AiFillHeart style={{ color: "#E58924" }} />
          ) : (
            <AiOutlineHeart />
          )}
        </button>
      </div>
    </Fade>
  );
};

export const ProductItemSkeleton = () => {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((item, i) => (
          <div className="col-product-item" key={i}>
            <div
              className="product-item-container"
              style={{
                border: "1px solid #ebebeb",
                textAlign: "center",
              }}
            >
              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  width={`70%`}
                  height={15}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export const CardTemoignage = ({
  item,
  isActive,
}: {
  item: Testimonial;
  isActive: boolean;
}) => {

  const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { t } = useTranslation();

  return (
    <div className="position-relative">
      <img
        src={getAvatar(item?.avatar)}
        alt="user avatar"
        className={`casadeliz-card-temoignage-avatar ${
          isActive ? "active" : ""
        }`}
      />
      <div className={`casadeliz-card-temoignage ${isActive ? "active" : ""}`}>
        <div className="casadeliz-card-temoignage-user-infos-container">
          <div className="casadeliz-card-temoignage-name">{getName(item)}</div>
          <div className="casadeliz-card-temoignage-occupation">
            {item?.fonction}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              d="M7.87563 11.0202C7.42357 11.0218 6.97951 11.1396 6.58619 11.3624C7.13995 9.92909 8.05839 8.66517 9.25063 7.69578C9.31885 7.6396 9.37533 7.57054 9.41685 7.49254C9.45838 7.41453 9.48413 7.32911 9.49264 7.24115C9.50115 7.15319 9.49226 7.06442 9.46646 6.9799C9.44067 6.89538 9.39847 6.81677 9.3423 6.74856C9.28612 6.68034 9.21706 6.62386 9.13905 6.58234C9.06105 6.54081 8.97563 6.51506 8.88767 6.50655C8.79971 6.49803 8.71094 6.50693 8.62642 6.53273C8.5419 6.55852 8.46329 6.60071 8.39508 6.65689C5.95063 8.643 4.86285 11.393 4.86285 13.098C4.86707 13.7009 5.04672 14.2894 5.37988 14.7919C5.71304 15.2943 6.18529 15.6889 6.73897 15.9274C7.0973 16.101 7.48971 16.1929 7.88785 16.1963C8.2383 16.2134 8.58856 16.1591 8.91739 16.0367C9.24622 15.9143 9.54677 15.7264 9.80081 15.4844C10.0549 15.2424 10.2571 14.9513 10.3953 14.6288C10.5335 14.3063 10.6048 13.9591 10.6048 13.6083C10.6048 13.2574 10.5335 12.9102 10.3953 12.5877C10.2571 12.2652 10.0549 11.9741 9.80081 11.7321C9.54677 11.4901 9.24622 11.3023 8.91739 11.1799C8.58856 11.0575 8.2383 11.0032 7.88785 11.0202H7.87563Z"
              fill="#ED646E"
            />
            <path
              d="M14.6834 11.02C14.2313 11.021 13.7871 11.1389 13.394 11.3622C13.9475 9.93038 14.8636 8.66689 16.0523 7.69557C16.1267 7.64194 16.1894 7.57365 16.2364 7.4949C16.2834 7.41615 16.3138 7.32861 16.3258 7.23767C16.3377 7.14673 16.3309 7.05431 16.3058 6.96609C16.2806 6.87788 16.2377 6.79575 16.1797 6.72474C16.1216 6.65373 16.0496 6.59536 15.9682 6.55321C15.8867 6.51106 15.7975 6.48603 15.706 6.47965C15.6145 6.47328 15.5226 6.48569 15.4361 6.51614C15.3496 6.54658 15.2702 6.59441 15.2029 6.65668C12.7584 8.64279 11.6707 11.3928 11.6707 13.0978C11.6735 13.6938 11.848 14.2764 12.1731 14.776C12.4982 15.2756 12.9602 15.671 13.504 15.915C13.8714 16.0937 14.2749 16.1857 14.6834 16.1839C15.0339 16.2009 15.3841 16.1466 15.713 16.0242C16.0418 15.9019 16.3423 15.714 16.5964 15.472C16.8504 15.23 17.0527 14.9389 17.1909 14.6164C17.3291 14.2939 17.4003 13.9467 17.4003 13.5958C17.4003 13.245 17.3291 12.8978 17.1909 12.5753C17.0527 12.2528 16.8504 11.9617 16.5964 11.7197C16.3423 11.4777 16.0418 11.2898 15.713 11.1675C15.3841 11.0451 15.0339 10.9908 14.6834 11.0078V11.02Z"
              fill="#ED646E"
            />
          </svg>
          <div
            className="casadeliz-card-temoignage-text"
            dangerouslySetInnerHTML={createMarkup(locale == "fr" ? item?.message : item?.message_en)}
          />
        </div>
      </div>
    </div>
  );
};
