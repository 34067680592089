/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
	Admin,
	AdminFormData,
	AdminType,
} from "../../../../utils/api/admin/admin.type";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
	useAddOrUpdateAdminMutation,
	useEditAdminMutation,
} from "../../../../utils/api/admin/admin.api";
import { cleannerError, useLocationState } from "../../../../utils/Utils";
import { UserType } from "../../../../utils/api/user/user.type";
import { Authorisations } from "../../../../utils/constante";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";

function useCrudAdmin(resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		nom: yup
			.string()
			.required()
			.min(2, "Nom doit être au minimum 2 lettes")
			.label("Nom"),
		prenom: yup
			.string()
			.required()
			.min(2, "Prénom doit être au minimum 2 lettes")
			.label("Prénom"),
		telephone: yup.string().label("Téléphone"),
		email: yup.string().email().required().label("Email"),
		adresse: yup
			.string()
			.required()
			.label("Adresse")
			.typeError("Adresse est un champ obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<AdminFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			admin_type: AdminType.admin,
		},
	});

	const dispatch = useAppDispatch();
	const [phone, setPhone] = useState<string>();
	const [adresse, setAdresse] = useState<string>("");

	const [addOrUpdateUser, { isLoading }] = useAddOrUpdateAdminMutation();
	const user = useLocationState<Admin>(undefined);
	const { user: authUser } = useAppSelector((s) => s.user);
	const navigate = useNavigate();

	const onChangePhone = (phone: string) => {
		setPhone(phone);
		setValue("telephone", `+${phone}`);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (user?.id) {
			let fields: (keyof AdminFormData)[] = [
				"nom",
				"prenom",
				"telephone",
				"email",
				"adresse",
				"parametres",
				"dashboard",
				"messages",
				"particuliers",
				"messages",
				"options",
				"commandes",
				"parametres",
				"produits",
				"professionnels",
			];
			if (user?.user_type !== UserType.admin) {
				fields = [
					"nom",
					"prenom",
					"telephone",
					"email",
					"adresse",
				];
			}
			for (let field of fields) {
				register(field);
				setValue(field, user[field]);
			}
			setPhone(user?.telephone);
			setAdresse(user?.adresse);
		} else {
			Authorisations.forEach((item) => {
				setValue(item?.field, true);
			});
		}
	}, [user]);

	const handleChangeAdress = (value) => {
		// console.log(value);
		setValue("adresse", value?.rue);
		setValue("longitude", value?.longitude);
		setValue("latitude", value?.latitude);
		setAdresse(value?.rue);
	};

	const onSubmit = async (data: AdminFormData) => {
		if (authUser) data["parent"] = authUser?.id;
		console.log("data", data);
		const res = await addOrUpdateUser({
			slug: user?.slug,
			user_type: AdminType.admin,
			data: data,
		});
		if ("data" in res) {
			if (authUser?.id === res.data?.id) {
				dispatch(onSetUser(res.data));
			}
			Swal.fire({
				icon: "success",
				title: !user
					? "Admin ajouté avec succès"
					: "L'admin a été modifié avec succès",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
			reset();
			navigate("/admin/parametres");
			resetItem && resetItem();
			setPhone("");
			setAdresse("");
		}
		if ("error" in res) {
			const err = res.error as any;
			if (
				err?.data?.email?.includes(
					"user with this email already exists."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Un utilisateur avec cet email exite déjà!",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				let message = err?.data?.message
					? err?.data?.message
					: err?.data?.email
					? "Un utilisateur avec cet email existe déjà"
					: err?.data?.telephone
					? "Un utilisateur avec ce numero de téléphone existe déjà"
					: `Une erreur a survenue lors de ${
							user ? "la modification" : "l'ajout"
					  }`;
				Swal.fire({
					icon: "error",
					title: message,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		authUser,
		phone,
		onChangePhone,
		setPhone,
		handleChangeAdress,
		adresse,
		user,
	};
}

export default useCrudAdmin;

export function useToggleArchiveAdmin(user: Admin) {
	const [editData] = useEditAdminMutation();
	const onArchive = async () => {
		let data: AdminFormData = {
			is_archive: !user.is_archive,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				user?.is_archive === true ? "Débloquer" : "Bloquer"
			} cet admin ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: user?.is_archive ? Color.success : Color.danger,
			confirmButtonColor: user?.is_archive
				? Color.success
				: Color.danger,
			preConfirm: () => {
				return editData({
					slug: user.slug,
					user_type: AdminType.admin,
					data: data,
				});
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Admin ${
						result?.value?.data?.is_archive
							? "bloqué "
							: "débloqué"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1200,
				});
			}
		});
	};
	return onArchive;
}

export const useChangeAvatar = (user: Admin) => {
	const [avatar, setAvatar] = useState<string | undefined>(undefined);
	const [changeAvatar] = useEditAdminMutation();
	const { user: authUser } = useAppSelector((s) => s.user);
	const dispatch = useAppDispatch();

	const handleChangeAvatar = async (e) => {
		setAvatar(URL.createObjectURL(e.target.files[0]));
		const userData = new FormData();
		await userData.append("avatar", e.target.files[0]);
		const res = await changeAvatar({
			slug: user?.slug,
			data: userData,
			user_type:
				user?.user_type === UserType.admin
					? AdminType.admin
					: AdminType.superadmin,
		});

		if ("data" in res) {
			if (authUser?.id === res.data?.id) {
				dispatch(onSetUser(res.data));
			}
			Swal.fire({
				icon: "success",
				title: "Photo  de profil modifée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			let message = err?.data?.message
				? err?.data?.message
				: err?.data?.telephone
				? err?.data?.telephone
				: "Une erreur a survenue lors de la modification";
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	return {
		handleChangeAvatar,
		avatar,
		user,
	};
};
