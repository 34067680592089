import basketIcon from "../../../assets/icons/basket.svg";
import park from "../../../assets/icons/park-outline.svg";
import phoneTime from "../../../assets/icons/phone-time.svg";
import security from "../../../assets/icons/security.svg";

export const avantagesImages = [basketIcon, park, phoneTime, security];

export const ValueCardData = [
	{
		id: 1,
		icon: basketIcon,
		title: "Politique de Retour",
		description: "Lorem ipsum dolor sit amet consectetur. A mauris in",
	},
	{
		id: 2,
		icon: park,
		title: "100% Frais",
		description: "Lorem ipsum dolor sit amet consectetur. A mauris in",
	},
	{
		id: 3,
		icon: phoneTime,
		title: "Assistance 24/7",
		description: "Lorem ipsum dolor sit amet consectetur. A mauris in",
	},
	{
		id: 4,
		icon: security,
		title: "Paiement sécurisé",
		description: "Lorem ipsum dolor sit amet consectetur. A mauris in",
	},
];