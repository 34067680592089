import React from "react";
import "./CatalogueProfessionnel.css";
import casad_promo from "../../../assets/appImages/casad_promo.png";
import CatalogProductCard from "./CatalogProductCard";
import { usePagination, useSearch } from "../../../utils/hooks";
import { useGetListProductQuery } from "../../../utils/api/product/product.api";
import { useGetCatalogueQuery } from "../../../utils/api/config/config.api";
import { closeModal, createMarkup, getName } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import { CustomPagination } from "../../common/CustomPagination";
import { useTranslation } from "react-i18next";
import VoirCatalogueModal from "./VoirCatalogueModal";


const CatalogueProfessionnel = () => {
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';

	const { t } = useTranslation();

	const { limit, page, onChange } = usePagination(12);
	const { search, handleSearch } = useSearch();
	const { data:catalogue } = useGetCatalogueQuery();
	
	const { data = { results: [] }, isLoading } = useGetListProductQuery({
		limit,
		page,
		q: search,
	});

	return (
		<div className="dashbord-admin-component colgate_wrapper p-3">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row align-items-center justify-content-between mb-4">
					<div className="col-md-3">
						<h2 className="chart-item-admin-title mb-0">
							{t("catalog")}
						</h2>
					</div>
					{/* <div className="col-md-3">
						<NavLink
							to="/admin/ajouter-un-produit"
							className="btn btn-theme-admin"
						>
							<span>
								Voir le catalogue
								<BsFileRuled />
							</span>
						</NavLink>
					</div> */}
					<div className="col-md-4 search-bar-container d-flex align-items-center gap-2">
						<input
							type="search"
							placeholder="Réchercher un produit"
							className="search-bar-conseil"
							onChange={handleSearch}
						/>
					</div>
				</div>
				<div className="row catalogue_description py-3">
					<div className="col-md-9">
						{/* <p className="custom-content">
							{ locale == 'fr' ? catalogue?.description : catalogue?.description_en }
						</p> */}
						<p
							className="custom-content"
							dangerouslySetInnerHTML={createMarkup(
								locale == 'fr' ? catalogue?.description : catalogue?.description_en
							)}>
						</p>
					</div>
				</div>
				<div className="row promo_catalogue_offer py-3">
					<div className="col-md-10">
						<div className="promo-offer-card">
							<p className="m-0 offer-description">
								<img
									src={casad_promo}
									alt="img"
								/>
								<span>{catalogue?.pourcentage}</span>&nbsp; 
								{/* { locale == 'fr' ? catalogue?.pourcentage_text : catalogue?.pourcentage_text_en } */}
								<span
									className="custom-content"
									dangerouslySetInnerHTML={createMarkup(
										locale == 'fr' ? catalogue?.pourcentage_text : catalogue?.pourcentage_text_en 
									)}
								/>
							</p>
							<button data-bs-toggle="modal"
									data-bs-target="#VoirCatalogueModal" 
									className="btn btn-see-offer">
								{t("see_catalog")}
							</button>
						</div>
					</div>
				</div>
				<div className="all-catalog-products pt-5">
					<div className="row row-products">
						{!isLoading ? (
							data?.results?.length > 0 ? (
								data?.results?.map(
									(product) => (
										<div
											className="col-md-4 mb-4 product-wrapper"
											key={
												product?.slug
											}
										>
											<CatalogProductCard
												slug={
													product.slug
												}
												number={
													product.id
												}
												product_image={
													product
														.images[0]
														?.picture
												}
												full_name={
													product.nom
												}
												prix_pro={
													product
														?.variations[0]
														?.prix_pro
												}
												prix_promo={
													product
														?.variations[0]
														?.prix_promo
												}
												isInPromotion={
													product
														?.variations[0]
														?.promo_expired
												}
												product={
													product
												}
											/>
										</div>
									)
								)
							) : (
								<AlertInfo
									message={`Aucun produit trouvé}`}
								/>
							)
						) : (
							<></>
						)}
						{data && data?.results?.length > limit && (
							<div className="custom-pagination-container">
								<CustomPagination
									nbPages={data?.count}
									page={page}
									onChange={onChange}
									perPage={limit}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<VoirCatalogueModal item={catalogue} />
		</div>
	);
};

export default CatalogueProfessionnel;
