import React from 'react';
import { Input } from '../../../common/Input';
import visaIcon from "../../../../assets/icons/visa.svg";
import mastercardIcon from "../../../../assets/icons/mastercard.svg";
import skrillIcon from "../../../../assets/icons/skrill.svg";
import googlepayIcon from "../../../../assets/icons/google_pay.svg";
import applepayIcon from "../../../../assets/icons/apple_pay.svg";
import paypalIcon from "../../../../assets/icons/paypal.svg";

const ModePaiement = () => {
  return (
    <div className='form'>
        <form action="">
            <div className="row row-form">
                <div className="col-md-12 mt-3">
                    <div className="radio_check_content d-flex align-items-center justify-content-between">
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="flexRadioDefault" 
                                id="flexRadioDefault1" 
                                checked
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Carte de crédit / débit
                            </label>
                        </div>
                        <div className="cards-type-items d-flex align-items-center gap-2">
                            <div className="item_wrapper">
                                <img src={visaIcon} alt="card" />
                            </div>
                            <div className="item_wrapper">
                                <img src={mastercardIcon} alt="card" />
                            </div>
                            <div className="item_wrapper">
                                <img src={skrillIcon} alt="card" />
                            </div>
                            <div className="item_wrapper">
                                <img src={googlepayIcon} alt="card" />
                            </div>
                            <div className="item_wrapper">
                                <img src={applepayIcon} alt="card" />
                            </div>
                        </div>
                    </div>
                    <p className="m-0 custom_info_check pt-3">
                        Payez en toute sécurité en utilisant votre compte bancaire en utilisant Visa, Mastercard, Skrill, GooglePay et ApplePay.
                    </p>
                </div>
                <div className="col-md-12 pt-4">
                    <Input 
                        label="Numéro de carte"
                        type="numeric"
                        placeholder='xxxx xxxx xxxx xxxx'
                    />
                </div>
                <div className="col-md-12">
                    <Input 
                        label="Nom sur la carte"
                        type="text"
                        placeholder='Nom sur la carte'
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Date d'expiration"
                        type="date"
                    />
                </div>
                <div className="col-md-6">
                    <Input 
                        label="Code CVV"
                        type="numeric"
                        placeholder='XXX'
                    />
                </div>
                <div className="separator"></div>
                <div className="col-md-12 py-3">
                    <div className="radio_check_content">
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                name="principal_card" 
                                id="principal_card" 
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Créer cette carte principale
                            </label>
                        </div>
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                name="principal_card" 
                                id="principal_card"  
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Enregistrez la carte pour les paiements futurs
                            </label>
                        </div>
                    </div>
                </div>
                <div className="separator"></div>
                <div className="col-md-12 py-3">
                    <div className="radio_check_content d-flex align-items-center justify-content-between">
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="principal_card" 
                                id="principal_card" 
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Paypal
                            </label>
                        </div>
                        <div className="item_wrapper">
                            <img src={paypalIcon} alt="card" />
                        </div>
                    </div>
                    <p className="m-0 custom_info_check pt-2">
                        Vous serez redirigé vers le site PayPal pour finaliser votre commande en toute sécurité.
                    </p>
                </div>
                <div className="separator"></div>
                <div className="col-md-12 py-3">
                    <div className="radio_check_content d-flex align-items-center justify-content-between">
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="principal_card" 
                                id="principal_card" 
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Paiement à la livraison
                            </label>
                        </div>
                        <div className="item_wrapper">
                            <img src={paypalIcon} alt="card" />
                        </div>
                    </div>
                    <p className="m-0 custom_info_check pt-2">
                        Payez en espèces lorsque votre commande est livrée.
                    </p>
                </div>
            </div>
        </form>
    </div>
  )
}

export default ModePaiement