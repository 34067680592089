import React from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import paypal from "../../../../assets/icons/paypal.svg"

const Recap = () => {
  return (
    <div className="recap-contents">
      <div className="separator"></div>
      <div className="facturation-infos recap_content position-relative">
          <p className="m-0 card__title pb-4">
            Détails de facturation
          </p>
          <p className="m-0 sm__value pb-3 fw-bold">
            Ngoné Diop
          </p>
          <p className="m-0 sm__value pb-3">
            Dakar Sénégal, <br />
            Grand Yoff No. 28
          </p>
          <p className="m-0 sm__value pb-2">
            <span>Email:</span>&nbsp;
            <span className="csd_color">ngonediop@gmail.com</span>
          </p>
          <p className="m-0 sm__value pb-2">
            <span>Téléphone:</span>&nbsp;
            <span className="csd_color">+221 77 700 00 00</span>
          </p>
          <span className="edit_icon" title="Modifier">
            <MdOutlineEdit size={22} />
          </span>
      </div>
      <div className="separator"></div>
      <div className="livraison-adress recap_content position-relative">
          <p className="m-0 card__title pb-4">
              Adresse de livraison
          </p>
          <p className="m-0 sm__value pb-2">
              Sénégal, Dakar Yeumbeul ben baraque
          </p>
          <span className="edit_icon" title="Modifier">
            <MdOutlineEdit size={22} />
          </span>
      </div>
      <div className="separator"></div>
      <div className="payment-mode recap_content position-relative">
          <p className="m-0 card__title pb-4">
              Mode paiement
          </p>
          <p className="m-0 sm__value pb-2 payment_method">
              <img src={paypal} alt="mode" />
          </p>
          <p className="m-0 sm__value pb-2">
              ramafall@gmail.com
          </p>
          <span className="edit_icon" title="Modifier">
            <MdOutlineEdit size={22} />
          </span>
      </div>
    </div>
  )
}

export default Recap