import React from "react";
import ReactQuill from "react-quill";
import { AlertInfo } from "../../common/Alert";
import { BtnSubmit } from "../../common/Button";
import CloseModalBtn from "../../shared/CloseModalBtn";
import moment from "moment";
import useResponseContactForm from "./useResponseContactForm";
import { Contact } from "../../../utils/api/contact/contact.type";
import { closeModal, createMarkup, getName } from "../../../utils/Utils";
import { FormError } from "../../common/Input";

const DetailsMessageModal = ({ item }: { item?: Contact }) => {
	const { errors, onSubmit, response, handleChangeResponse, isLoading } =
		useResponseContactForm(item);
	return (
		<div
			className="modal fade"
			id="DetailsMessageModal"
			aria-labelledby="ChangeStatusMessageModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header b-b-0">
						<h5
							className="modal-title admin-modal-title text-start"
							id="DetailsMessageModal"
						>
							Réponse à ({item?.nom_complet})
						</h5>
						<CloseModalBtn
							onClick={() =>
								closeModal(
									"DetailsMessageModal"
								)
							}
						/>
					</div>
					<div className="form-container px-3">
						<div>
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
								Sujet:
							</h5>
							<p className="text-detail-message">
								{item?.subject}
							</p>
						</div>
						<div className="row border-bottom pb-2">
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
								Message:
							</h5>
							<p className="text-detail-message">
								{item?.message}
							</p>
						</div>
						<div className="my-2">
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0">
								Réponses
							</h5>
							<div className="responses-container">
								{item?.responses &&
								item?.responses?.length > 0 ? (
									item?.responses?.map(
										(res) => (
											<div
												className="b-t-1 pt-3"
												key={
													res?.slug
												}
											>
												<div
													className="text-detail-message"
													dangerouslySetInnerHTML={createMarkup(
														res?.response
													)}
												/>

												<div
													style={{
														textAlign:
															"left",
													}}
												>
													Par{" "}
													<span>
														{res
															?.user
															?.prenom
															? getName(
																	res?.user
															  )
															: "Admin"}
													</span>{" "}
													le{" "}
													{moment(
														res?.created_at
													)?.format(
														"DD-MM-YYYY"
													)}
												</div>
											</div>
										)
									)
								) : (
									<AlertInfo message="Aucune réponse" />
								)}
							</div>
						</div>
						<form onSubmit={onSubmit}>
							<div className="row mb-4 mt-4">
								<div className="col-md-12 mb-3">
									<div className="mb-4 form-group">
										<label className="form-label label-form-admin">
											Réponse
										</label>
										<ReactQuill
											value={
												response
											}
											onChange={
												handleChangeResponse
											}
											className="produit-editor-cgu"
										/>
										<FormError
											error={
												errors?.response
											}
										/>
									</div>
								</div>
							</div>
							<div className="gap-3 flex-r align-center row my-4">
								<div className="col-md-3">
									<button
										type="button"
										className="btn btn-cancelled"
										data-bs-dismiss="modal"
									>
										Fermer
									</button>
								</div>
								<div className="col-md-3">
									<BtnSubmit
										label="Envoyer"
										isLoading={
											isLoading
										}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailsMessageModal;
