import React from "react";
import AuthWrapper from "./AuthWrapper";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Input } from "../common/Input";
import { BtnSubmit } from "../common/Button";
import { NavLink } from "react-router-dom";
import { PassWordRuleView } from "./PasswordRuleView";
import useRegister, { usePasswordRule } from "./hooks/useRegister";
import useLogin, { useSocialLogin } from "./hooks/useLogin";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleClientId } from "../../utils/http";


function Register() {
	const { t } = useTranslation();
	const { register, onSubmit, errors, isLoading, setValue } = useRegister();
	const { loginWithGoogle } = useSocialLogin();
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	} = usePasswordRule(setValue);

	const props = {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	};

	return (
		<AuthWrapper>
			<h2 className="top-title text-center">{t("register_at")}</h2>
			<div className="social-connect-content d-flex align-items-center justify-content-center gap-3 py-4">
				<button type="button" className="btn btn-social-connect d-flex align-items-center gap-2">
					<FaFacebookF color="#337FFF" size={20} />
					{t("register_facebook")}
				</button>
				<LoginSocialGoogle
					client_id={GoogleClientId}
					isOnlyGetToken={false}
					onLoginStart={() => console.log("login")}
					onResolve={loginWithGoogle}
					onReject={(err) => {
						console.log(err);
					}}
					scope="email profile"
				>
					<button
						type="button"
						className="btn btn-social-connect d-flex align-items-center gap-2"
					>
						<FcGoogle size={20} />
						{t("register_google")}
					</button>
				</LoginSocialGoogle>
			</div>
			<div className="or-content text-center">
				<span>{t("or")}</span>
			</div>
			<div className="form pt-4">
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-12 input-wrapper">
							<Input
								label={t("first_name")}
								type="text"
								placeholder={t("first_name")}
								id="prenom"
								{...register("prenom")}
								error={errors?.prenom}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label={t("last_name")}
								type="text"
								placeholder={t("last_name")}
								id="nom"
								{...register("nom")}
								error={errors?.nom}
							/>
						</div>
						<div className="col-md-12 input-wrapper">
							<Input
								label="Email"
								type="email"
								placeholder="Email"
								id="email"
								{...register("email")}
								error={errors?.email}
							/>
						</div>
						<div className="col-md-12">
							<Input
								label={t("password")}
								placeholder="********"
								id="password"
								onChange={handleChangePassword}
								onFocus={showPasswordRule}
								onBlur={showPasswordRule}
								error={errors?.password}
								type="password"
							/>
						</div>
						{isShowPasswordRules && <PassWordRuleView {...props} />}

						<div className="col-md-12">
							<Input
								label={t("password_confirm")}
								placeholder="********"
								id="confirm_password"
								{...register("confirm_password")}
								error={errors?.confirm_password}
								type="password"
							/>
						</div>
					</div>
					<div className="btn-content pt-3">
						<BtnSubmit
							label={t("register")}
							isLoading={isLoading}
							className="btn btn-auth w-100"
						/>
					</div>
					<p className="redirection text-center pt-5">
						{t("already_registered")}?&nbsp;
						<NavLink to={`/connexion`} className="go-to-page">
							{t("login")}
						</NavLink>
					</p>
				</form>
			</div>
		</AuthWrapper>
	);
}

export default Register;
