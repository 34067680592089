import React from "react";
import { Input } from "../../../common/Input";
import useChangePassword from "../../../admin/ProfilAdmin/Tabs/useChangePassword";

function ChangerPassword() {
	const { register, errors, isLoading, onSubmit } = useChangePassword();

	return (
		<div className="medsain-profil-tab-item">
			<div className="medsain-profil-tab-title">
				Changer mot de passe
			</div>
			<form className="mt-4" onSubmit={onSubmit}>
				<div className="row row-add-student my-3">
					<div className="col-md-4 position-relative">
						<Input
							label="Ancien mot de passe"
							id="old_password"
							placeholder="********"
							{...register("old_password")}
							error={errors?.old_password}
						/>
					</div>
					<div className="col-md-4 position-relative">
						<Input
							label="Nouveau mot de passe"
							id="new_password"
							placeholder="********"
							{...register("new_password")}
							error={errors?.new_password}
						/>
					</div>

					<div className="col-md-4 position-relative">
						<Input
							label="Confirmer mot de passe"
							id="confirm_password"
							placeholder="********"
							{...register("confirm_password")}
							error={errors?.confirm_password}
						/>
					</div>
					<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
						<button
							className="btn btn-cancel-admin"
							type="button"
						>
							Annuler
						</button>
						<button
							className="btn btn-theme-admin w-100"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									<span
										className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
										role="status"
									></span>
									<span>
										Enrégistrement...
									</span>
								</>
							) : (
								"Enrégistrer"
							)}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ChangerPassword;
