import React, { useState } from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { useItem, usePagination, useSearch } from "../../../utils/hooks";
import { useGetListContactAdminQuery } from "../../../utils/api/contact/contact.api";
import { Contact } from "../../../utils/api/contact/contact.type";
import { AlertInfo } from "../../common/Alert";
import { CustomPagination } from "../../common/CustomPagination";
import { Collapse } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
	formatBadgeMessage,
	getLabelMessageStatus,
	showModal,
} from "../../../utils/Utils";
import ChangeStatusMessageModal from "./ChangeStatusMessageModal";
import DetailsMessageModal from "./DetailMessageModal";

function MessageContactItem(props: {
	item: Contact;
	getItem: (item: Contact) => void;
}) {
	const { item, getItem } = props;
	const [open, setOpen] = useState(false);

	return (
		<div
			key={item?.slug}
			className="mb-4 option-faq-ol-custom-li shadow-sm p-2"
			style={{ borderRadius: "6px" }}
		>
			<div className="row">
				<div className="col-md-7">
					<h5>{item?.subject}</h5>
				</div>
				<div className="col-md-2">
					<div
						className={`badge custom ${formatBadgeMessage(
							item?.status
						)}`}
						role="button"
						onClick={() => {
							showModal("ChangeStatusMessageModal");
							getItem(item);
						}}
					>
						{getLabelMessageStatus(item?.status)}
					</div>
				</div>
				<div className="col-md-3 flex-r gap-2">
					<span>{item?.nom_complet}</span>
					<button
						onClick={() => setOpen((open) => !open)}
						style={{
							background: "transparent",
							border: "none",
						}}
					>
						{open ? <BsChevronUp /> : <BsChevronDown />}
					</button>
				</div>
			</div>
			<Collapse in={open}>
				<div>
					<div className="cond-utilisation-ol-item-container">
						<p className="text-cgu-admin pb-2">
							{item?.message}
						</p>
					</div>
					<div className="flex-r pt-2">
						<button
							className="casadeliz-commentaire-item-btn-response"
							onClick={() => {
								showModal(
									"DetailsMessageModal"
								);
								getItem(item);
							}}
						>
							Répondre
						</button>
					</div>
				</div>
			</Collapse>
		</div>
	);
}

function MessagesContact() {
	const { search, handleSearch } = useSearch();
	const { limit, page, onChange } = usePagination(10);
	const { item, getItem } = useItem<Contact>();

	const { data, isLoading } = useGetListContactAdminQuery({
		limit,
		page,
		q: search,
	});

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Messages de contact" />
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className="row country-and-search-bar-content pb-4">
							<div className="offset-md-8 col-md-4 search-bar-container d-flex align-items-center gap-2">
								<input
									type="search"
									placeholder="Chercher"
									className="search-bar-conseil"
									onChange={handleSearch}
								/>
								<span className="icon-container">
									<HiOutlineBars3BottomRight />
								</span>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="col-lg-12 mb-3">
								<div className="content-graph-admin">
									{!isLoading ? (
										data &&
										data?.results
											?.length >
											0 ? (
											<>
												{data?.results?.map(
													(
														item
													) => (
														<MessageContactItem
															item={
																item
															}
															getItem={
																getItem
															}
														/>
													)
												)}
											</>
										) : (
											<AlertInfo message="Aucune donnée trouvée" />
										)
									) : (
										<></>
									)}
								</div>
								{data &&
									data?.count &&
									data?.count > limit && (
										<div className="custom-pagination-container">
											<CustomPagination
												nbPages={
													data?.count
												}
												page={
													page
												}
												onChange={
													onChange
												}
												perPage={
													limit
												}
											/>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
				<ChangeStatusMessageModal item={item} />
				<DetailsMessageModal item={item} />
			</div>
		</div>
	);
}

export default MessagesContact;
