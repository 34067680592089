import React from "react";
import ProductCard from "./ProductCard";
import { AlertInfo } from "../../common/Alert";
import { useGetListProductQuery } from "../../../utils/api/product/product.api";
import { useGetAllCategoryListQuery } from "../../../utils/api/category/category.api";
import { usePagination } from "../../../utils/hooks";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ProductList = ({ perPage }: { perPage: number }) => {

	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { t } = useTranslation();
	let [searchParams, setSearchParams] = useSearchParams();
	const categorie = searchParams.get("category");
	const { limit, page } = usePagination(perPage);
	const { data = { results: [] }, isLoading } = useGetListProductQuery({
		limit,
		page,
		categorie,
	});
	const { data: categories = { results: [] }, isLoading: isLoad } =
		useGetAllCategoryListQuery();

	return (
		<div className="shop">
			<ul className="tabs-container">
				<li
					role="button"
					className={`
                            tab-item
                            ${categorie === null ? "tab-item-active" : ""}
                        `}
					onClick={() => setSearchParams({})}
				>
					{t("all")}
				</li>
				{!isLoad &&
					categories?.results?.length > 0 &&
					categories?.results?.map((tab) => (
						<li
							role="button"
							className={`
                                tab-item
                                ${
							categorie === tab?.slug
								? "tab-item-active"
								: ""
						}
                            `}
							key={tab?.slug}
							onClick={() =>
								setSearchParams({
									category: tab?.slug,
								})
							}
						>
							{locale == 'fr' ? tab?.nom : tab?.nom_en}
						</li>
					))}
			</ul>
			<div className="product-content pt-5">
				<div className="row">
					{!isLoading ? (
						data?.results?.length > 0 ? (
							data?.results?.map((item) => (
								<div
									className="col-md-4 product-wrapper mb-4"
									key={item?.slug}
								>
									<ProductCard
										image={
											item
												?.images[0]
												?.picture
										}
										slug={item?.slug}
										category={
											item
												?.categorie
												?.nom
										}
										name={locale == 'fr' ? item.nom : item.nom_en}
										current_price={
											item
												.variations[0]
												?.prix
										}
										prix_promo={
											item
												.variations[0]
												?.prix_promo
										}
										rating={1}
										key={item.id}
										item={item}
									/>
								</div>
							))
						) : (
							<AlertInfo
								message={t("no_products_found")+" " + (categorie && t("for_this_category")) }
							/>
						)
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductList;
