import React from "react";
import {
	AiOutlineHeart,
	AiOutlineShoppingCart,
	AiOutlineEye,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import Empty from "../../../assets/appImages/category.png";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { Product } from "../../../utils/api/product/product.type";
import { useProduct, useToggleFavoriteProduct } from "./hooks/useProduct";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";


type Props = {
	image?: string;
	slug?: string;
	category?: string;
	name?: string;
	current_price?: string;
	prix_promo?: number;
	rating: number;
	item: Product;
};

function ProductCard(props: Props) {
	const { handleAddToCart } = useProduct();
	const { t } = useTranslation();

	const { image, slug, category, name, current_price, prix_promo, item } =
		props;

	const ratingChanged = (newRating) => {
		console.log(newRating);
	};
	const { toggleFavorite } = useToggleFavoriteProduct(item);

	const rutureDeStock = () => {
		Swal.fire({
			icon: "info",
			title: t("product_out_of_stock"),
			showConfirmButton: false,
			timer: 5000,
		});
	};

	return (
		<div className="product-card">
			<div className="product-image-wrapper position-relative">
				<img
					src={image ? getImage(image) : Empty}
					alt="image"
				/>
				<div className="product_big_padding_overlay">
					<div className="product_overlay">
						<div
							className="action_wrapper position-relative"
							role="button"
							onClick={toggleFavorite}
						>
							<AiOutlineHeart />
							<button className="btn_action__overlay">
								{t("add_to_favorites")}
							</button>
						</div>
						<div
							className="action_wrapper position-relative"
							role="button"
							onClick={() => item?.variations[0]?.stock <= 0 ? 
								rutureDeStock()
								:
								handleAddToCart(
									item?.variations[0],
									item
								)
							}
						>
							<AiOutlineShoppingCart />
							<button className="btn_action__overlay" >
								{ item?.variations[0]?.stock > 0 ? t("add_cart") : t("out_of_stock")}
							</button>
						</div>
						<div className="action_wrapper position-relative">
							<NavLink
								to={`/boutique/produit/${slug}`}
								className="no-link"
								state={item}
							>
								<AiOutlineEye />
							</NavLink>
							<button className="btn_action__overlay">
								{t("see_the_product")}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="product-info-wrapper pt-3">
				<p className="sm-description m-0">{category}</p>
				<p className="name m-0 py-4">{name}</p>
				<p className="prices d-flex align-items-center justify-content-between px-2 m-0">
					<span className="current-price">
						
						{/* {Number(prix_promo) > 0
							? formatCurrency(prix_promo)
							: 
							formatCurrency(current_price)
						} */}
						{ formatCurrency(current_price) }
					</span>
					{/* {Number(prix_promo) > 0 && (
						<span className="old-price">
							{formatCurrency(current_price)}
						</span>
					)} */}
				</p>
				<div className="rating-and-go d-flex align-items-center justify-content-end px-2 m-0 pt-3">
					{/* <ReactStars
						count={5}
						onChange={ratingChanged}
						size={24}
						emptyIcon={
							<i className="fa-duotone fa-star"></i>
						}
						halfIcon={
							<i className="fa fa-star-half-alt"></i>
						}
						fullIcon={<i className="fa fa-star"></i>}
						activeColor="#FFC600"
						edit={false}
						value={item?.rate}
					/> */}
					<NavLink
						to={`/boutique/produit/${slug}`}
						className="detail-btn"
						state={item}
					>
						<BsArrowRightShort />
					</NavLink>
				</div>
			</div>
		</div>
	);
}

export default ProductCard;
