import React from "react";
import {
  AiFillShopping,
  AiOutlineMenuUnfold,
  AiTwotonePieChart,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { FaShopify, FaShoppingBasket, FaUserFriends } from "react-icons/fa";
import { BiSolidUser, BiUser } from "react-icons/bi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
const SidebarMobile = () => {
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              {location?.pathname?.startsWith("/admin") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/dashboard"
                    >
                      <AiTwotonePieChart />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/mes-produits"
                    >
                      <AiFillShopping />
                      <span className="item-nav-menu drop-header">
                        Mes produits
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/commandes"
                    >
                      <FaShoppingBasket />
                      <span className="item-nav-menu drop-header">
                        Commandes
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/professionnels"
                    >
                      <FaUserFriends />
                      <span className="item-nav-menu drop-header">
                        Professionnels
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/clients"
                    >
                      <AiOutlineCalendar />
                      <span className="item-nav-menu drop-header">Clients</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/messages"
                    >
                      <IoMail />
                      <span className="item-nav-menu drop-header">
                        Messages
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/profil"
                    >
                      <BiSolidUser />
                      <span className="item-nav-menu drop-header">Profil</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/parametres"
                    >
                      <MdOutlineLocalOffer />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/options"
                    >
                      <BiUser />
                      <span className="item-nav-menu drop-header">Options</span>
                    </NavLink>
                  </li>
                </>
              )}
              {location?.pathname?.startsWith("/professionel") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/professionel/dashboard"
                    >
                      <AiTwotonePieChart />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/professionel/commandes"
                    >
                      <FaShopify />
                      <span className="item-nav-menu drop-header">
                        Commandes
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/professionel/catalogue"
                    >
                      <FaShopify />
                      <span className="item-nav-menu drop-header">
                        Catalogue
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/professionel/stock"
                    >
                      <FaShoppingBasket />
                      <span className="item-nav-menu drop-header">Stock</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/professionel/profil"
                    >
                      <BiSolidUser />
                      <span className="item-nav-menu drop-header">Profil</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
