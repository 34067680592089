import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { HiTrash } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { useItem, usePagination } from "../../../../utils/hooks";
import { useGetOrderByUserQuery } from "../../../../utils/api/order/order.api";
import { User } from "../../../../utils/api/user/user.type";
import {
	IconCancel,
	IconDelivered,
	IconInProgress,
	IconInRoad,
} from "../../../shared/Icons";
import { Order, OrderStatut } from "../../../../utils/api/order/order.type";
import { CustomPagination } from "../../../common/CustomPagination";
import { formatCurrency, formatDate, showModal } from "../../../../utils/Utils";
import ChangeStatusOrderModal from "../../../professionnel/CommandProfessionnel/tabs/ChangeStatusOrderModal";

function ToutesCommandesAdminTable({
	client,
	index,
}: {
	client: User;
	index: number;
}) {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetOrderByUserQuery({
		limit,
		page,
		slug: client?.slug,
		statut:
			index === 1
				? "confirmee"
				: index == 2
				? "livree"
				: index === 3
				? "annulee"
				: "",
	});
	const { item, getItem } = useItem<Order>();

	const actionFormatter = (cell: string, row: Order) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<NavLink
							to="details-commande"
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Voir"
							state={row}
						>
							<AiFillEye />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<button
							className="btn with-tooltip btn-action-icon-delete"
							data-tooltip-content="Supprimer"
						>
							<HiTrash />
						</button>
					</div>
				</div>
			</>
		);
	};

	const statutFormatter = (cell: OrderStatut, row: Order) => {
		return (
			<div
				className="justify-content-center"
				role="button"
				onClick={() => {
					showModal("ChangeStatusOrderModal");
					getItem(row);
				}}
			>
				{cell === "en_cours" && (
					<span className="status-en-cours">
						<span className="pe-2">
							<IconInProgress />
						</span>
						<span>{cell}</span>
					</span>
				)}
				{cell === "confirmee" && (
					<span className="status-en-chemin">
						<span className="pe-2">
							<IconInRoad />
						</span>
						<span>{cell}</span>
					</span>
				)}
				{cell === "livree" && (
					<span className="status-livre">
						<span className="pe-2">
							<IconDelivered />
						</span>
						<span>{cell}</span>
					</span>
				)}

				{cell === "annulee" && (
					<span className="status-annule">
						<span className="pe-2">
							<IconCancel />
						</span>
						<span>{cell}</span>
					</span>
				)}
			</div>
		);
	};
	const paiementFormatter = (cell: string) => {
		return <p>{cell === "CASH" ? "À la livraison" : "En ligne"}</p>;
	};
	const numeroFormatter = (cell: string) => {
		return (
			<div className="details-pro-admin-commande-numero-container">
				#{cell}
			</div>
		);
	};
	const totalFormatter = (cell: string) => {
		return <p className="fw-bold m-0">{formatCurrency(cell)}</p>;
	};
	const columns = [
		{
			dataField: "code_commande",
			text: `Commande n°`,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
			formatter: numeroFormatter,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "total",
			text: "Total",
			formatter: totalFormatter,
		},
		{
			dataField: "mode_paiement",
			text: "Mode de paiement",
			formatter: paiementFormatter,
		},
		{
			dataField: "statut",
			text: "Status",
			formatter: statutFormatter,
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/>
					</div>
				</>
			)}
			<ChangeStatusOrderModal item={item} />
		</>
	);
}

export default ToutesCommandesAdminTable;
