import React from "react";
import "./CommandProfessionnel.css";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import PanierAttente from "./tabs/PanierAttente";
import Commands from "./tabs/Commands";
import { isAdmin } from "../../../utils/Utils";
import { useAppSelector } from "../../../redux/hook";
import { useTranslation } from "react-i18next";


const steps = [
	{ id: "commands", Component: Commands },
	{ id: "waiting-shopping-card", Component: PanierAttente },
];

const CommandProfessionnel = () => {
	const { t } = useTranslation();
	const tabs = [t('orders'), t("cart_on_hold")];

	const { user } = useAppSelector((s) => s?.user);
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;

	const is_Admin = isAdmin(user);

	return (
		<div className="dashbord-admin-component colgate_wrapper p-3">
			<div className="dash-admin-page-content-container mb-3">
				<div className="d-flex top_title__container mb-4">
					<h2 className="chart-item-admin-title mb-0">
						{t('orders')}
					</h2>
					{is_Admin && (
						<div className="right-tabs-content mt-3">
							<ul className="tabs-container">
								{tabs.map((tab, key) => (
									<li
										role="button"
										className={`
                            tab-item 
                            ${index === key ? "tab-item-active" : ""}
                        `}
										onClick={() =>
											go(key)
										}
									>
										{tab}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
				<div className="content-graph-admin">
					<div className="tabs-content">
						<Component />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CommandProfessionnel;
