export type FieldType =
	| "dashboard"
	| "produits"
	| "commandes"
	| "particuliers"
	| "messages"
	| "parametres"
	| "professionnels"
	| "options"
	| "produits";

type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};
export const Authorisations: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/admin/dashboard",
	},
	{
		field: "produits",
		label: "Produits",
		path: "/admin/mes-produits",
	},
	{
		field: "commandes",
		label: "Commandes",
		path: "/admin/commandes",
	},
	{
		field: "particuliers",
		label: "Clients",
		path: "/admin/clients",
	},
	{
		field: "professionnels",
		label: "Professionnels",
		path: "/admin/professionnels",
	},
	{
		field: "options",
		label: "Options",
		path: "/admin/options",
	},
	{
		field: "messages",
		label: "Messages",
		path: "/admin/messages",
	},
	{
		field: "parametres",
		label: "Paramètres",
		path: "/admin/parametres",
	},
];
export const regions = [
	{
		label: "Dakar",
		value: "Dakar",
	},
	{
		label: "Diourbel",
		value: "Diourbel",
	},
	{
		label: "Fatick",
		value: "Fatick",
	},
	{
		label: "Kaffrine",
		value: "Kaffrine",
	},
	{
		label: "Kaolack",
		value: "Kaolack",
	},
	{
		label: "kédougou",
		value: "kédougou",
	},
	{
		label: "Kolda",
		value: "Kolda",
	},
	{
		label: "Louga",
		value: "Louga",
	},
	{
		label: "Matam",
		value: "Matam",
	},
	{
		label: "Saint-Louis",
		value: "Saint-Louis",
	},
	{
		label: "Sédhiou",
		value: "Sédhiou",
	},
	{
		label: "Tambacounda",
		value: "Tambacounda",
	},
	{
		label: "Thiès",
		value: "Thiès",
	},
	{
		label: "Ziguinchor",
		value: "Ziguinchor",
	},
];

export const Civilites = [
	{
		value: "homme",
		label: "Homme",
	},
	{
		value: "femme",
		label: "Femme",
	},
];

export const UPLOAD_FILE_END_POINT =
	"https://hub-services.volkeno-engineering.click/api/v1/app/upload-file";

