import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { TeamFormData, Team } from "./team.type";

export const TeamApi = createApi({
	reducerPath: "teamAPi",
	tagTypes: ["equipes", "team_by_slug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),

	endpoints: (builder) => ({
		getTeam: builder.query<PaginationResults<Team>, TypeQuery>({
			query: () => `equipes/`,
			providesTags: ["equipes"],
		}),
		getTeamForVisitors: builder.query<Team[], void>({
			query: () => ({
				url: `visiteur/equipes/`,
			}),
			providesTags: ["equipes"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdateTeam: builder.mutation<Team, { slug?: string; data: TeamFormData | FormData }>({
			invalidatesTags: ["equipes"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `equipes/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `equipes/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteTeam: builder.mutation<Team, string>({
			query: (slug) => ({
				url: `equipes/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["equipes"],
		}),
	}),
});

export const {
	useGetTeamQuery,
	useLazyGetTeamQuery,
	useAddOrUpdateTeamMutation,
	useDeleteTeamMutation,
	useGetTeamForVisitorsQuery,
} = TeamApi;
