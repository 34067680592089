import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { CategoryApi } from "../utils/api/category/category.api";
import { ProductApi } from "../utils/api/product/product.api";
import { StoreApi } from "../utils/api/store/store.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import storage from "redux-persist/lib/storage";
import useCartReducer from "./slice/useCartSlice";

import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import { PaymentModeApi } from "../utils/api/paymentMode/paymentMode.api";
import { DeliveryModeApi } from "../utils/api/deliveryMode/deliveryMode.api";
import { OrderApi } from "../utils/api/order/order.api";
import { FavoriteApi } from "../utils/api/favories/favorie.api";
import { PromotionApi } from "../utils/api/promotion/promotion.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { ZoneApi } from "../utils/api/zone/zone.api";
import { RateApi } from "../utils/api/rate/rate.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { RessourceApi } from "../utils/api/ressource/resssource.api";
import { ConfigApi } from "../utils/api/config/config.api";
import { DashboardApi } from "../utils/api/dashboard/dashboard.api";
import { ProfessionnelApi } from "../utils/api/professionnel/professionnel.api";
import { AdminApi } from "../utils/api/admin/admin.api";
import { FaqApi } from "../utils/api/faq/faq.api";
import { ArticleApi } from "../utils/api/article/article.api";
import { TeamApi } from "../utils/api/team/team.api";

const persistConfig = {
	key: "root",
	storage: storage,
	blacklist: [
		`${UserSlice.name}`,
		`${UserApi.reducerPath}`,
		`${ZoneApi.reducerPath}`,
		`${AuthApi.reducerPath}`,
		`${UserApi.reducerPath}`,
		`${CategoryApi.reducerPath}`,
		`${ProductApi.reducerPath}`,
		`${StoreApi.reducerPath}`,
		`${PaymentModeApi.reducerPath}`,
		`${DeliveryModeApi.reducerPath}`,
		`${OrderApi.reducerPath}`,
		`${FavoriteApi.reducerPath}`,
		`${PromotionApi.reducerPath}`,
		`${ContactApi.reducerPath}`,
		`${RateApi.reducerPath}`,
		`${NotificationApi.reducerPath}`,
		`${RessourceApi.reducerPath}`,
		`${ConfigApi.reducerPath}`,
		`${DashboardApi.reducerPath}`,
		`${ProfessionnelApi.reducerPath}`,
		`${AdminApi.reducerPath}`,
		`${AdminApi.reducerPath}`,
		`${FaqApi.reducerPath}`,
		`${ArticleApi.reducerPath}`,
		`${TeamApi.reducerPath}`,
	],
};

export const rootReducers = combineReducers({
	cart: useCartReducer,
	[AuthApi.reducerPath]: AuthApi.reducer,
	[UserSlice.name]: UserSlice.reducer,
	[UserApi.reducerPath]: UserApi.reducer,
	[CategoryApi.reducerPath]: CategoryApi.reducer,
	[ProductApi.reducerPath]: ProductApi.reducer,
	[StoreApi.reducerPath]: StoreApi.reducer,
	[PaymentModeApi.reducerPath]: PaymentModeApi.reducer,
	[DeliveryModeApi.reducerPath]: DeliveryModeApi.reducer,
	[OrderApi.reducerPath]: OrderApi.reducer,
	[FavoriteApi.reducerPath]: FavoriteApi.reducer,
	[PromotionApi.reducerPath]: PromotionApi.reducer,
	[ContactApi.reducerPath]: ContactApi.reducer,
	[ZoneApi.reducerPath]: ZoneApi.reducer,
	[RateApi.reducerPath]: RateApi.reducer,
	[NotificationApi.reducerPath]: NotificationApi.reducer,
	[RessourceApi.reducerPath]: RessourceApi.reducer,
	[ConfigApi.reducerPath]: ConfigApi.reducer,
	[DashboardApi.reducerPath]: DashboardApi.reducer,
	[ProfessionnelApi.reducerPath]: ProfessionnelApi.reducer,
	[AdminApi.reducerPath]: AdminApi.reducer,
	[FaqApi.reducerPath]: FaqApi.reducer,
	[ArticleApi.reducerPath]: ArticleApi.reducer,
	[TeamApi.reducerPath]: TeamApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
	reducer: persistedReducer,
	devTools: Env === currentEnv,
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
		AuthApi.middleware,
		UserApi.middleware,
		CategoryApi.middleware,
		ProductApi.middleware,
		StoreApi.middleware,
		PaymentModeApi.middleware,
		DeliveryModeApi.middleware,
		OrderApi.middleware,
		FavoriteApi.middleware,
		PromotionApi.middleware,
		ContactApi.middleware,
		ZoneApi.middleware,
		RateApi.middleware,
		NotificationApi.middleware,
		RessourceApi.middleware,
		ConfigApi.middleware,
		DashboardApi.middleware,
		ProfessionnelApi.middleware,
		AdminApi.middleware,
		FaqApi.middleware,
		ArticleApi.middleware,
		TeamApi.middleware,
	],
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
