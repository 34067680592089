/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, formatCodeService } from "../../../../utils/Utils";
import {
	OrderFormData,
	OrderMoyenPaiement,
} from "../../../../utils/api/order/order.type";
import { useAddOrEditOrderMutation } from "../../../../utils/api/order/order.api";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hook";
import {
	useCheckStatusPaiement,
	usePayByIntech,
} from "../../../../utils/intech/Intech";
import { PaiementData } from "../../../../utils/intech/intech.type";
import { AppBaseUrl } from "../../../../utils/http";


function UseAddOrUpdateOrderForm(cart?: any, total?: number) {
	const validationSchema = yup.object().shape({
		moyen_paiement: yup
			.string()
			.required()
			.label("Le mode de paiement"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<OrderFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [moyenPaiement, setMoyenPaiment] = useState<string>();
	const navigate = useNavigate();
	const { user } = useAppSelector((s) => s?.user);

	const [addOrUpdateOrder, { isLoading }] = useAddOrEditOrderMutation();

	const handleChangeMoyenPaiement = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = e.currentTarget.value;
		
		if (value === "delivery") {
			setValue("moyen_paiement", "CASH");
			setMoyenPaiment(value);
		} else if (value === "online") {
			setMoyenPaiment(value);
		} else {
			setValue("moyen_paiement", value as OrderMoyenPaiement);
		}
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	const { onPay, loading, externalTransactionId } = usePayByIntech();
	const {
		onLoading,
		isFetching,
		disabled,
		onGetTransactionStatus,
		seconds,
	} = useCheckStatusPaiement(externalTransactionId);

	const onSubmit = async (data: OrderFormData) => {
		let code_commande: number = new Date().getTime();
		data["code_commande"] = code_commande;
		data["user"] = user?.id;
		data["total"] = total;
		data["page"] = "paiement";
		data["env"] = AppBaseUrl.slice(0, -1); 
		data["items"] = cart?.map((item) => {
			return {
				prix: item?.prix_total,
				quantite: item?.quantite,
				variation: item?.variation?.id,
			};
		});

		const res = await addOrUpdateOrder({
			data: {
				...data,
				moyen_paiement: formatCodeService(
					data?.moyen_paiement
				) as OrderMoyenPaiement,
			},
		});

		if ("data" in res) {
			let url_wave = (res as any)?.data?.wave_launch_url ;
			if (data?.moyen_paiement == "CASH") {
				Swal.fire({
					icon: "success",
					title: `Votre Commande a été envoyée avec succès🚀!`,
					text: "La livraison sera effectuée dès que possible. Merci de votre confiance.",
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 5000,
				}).then(() => {
					navigate("/particulier/mon-compte", {
						replace: true,
						state: { active: 2 },
					});
				});
			} else {
				const paiementData: PaiementData = {
					apiCash: formatCodeService(data?.moyen_paiement),
					telephone: user?.telephone,
					amount: data?.total,
					transactionId: res?.data?.slug ?? code_commande,
				};
				// onPay(paiementData);
				window.location.href = url_wave;

			}
		}

		if ("error" in res) {
			const err = res?.error as any;

			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : "Une erreur est survenue",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading ?? loading,
		moyenPaiement,
		handleChangeMoyenPaiement,
		onLoading,
		isFetching,
		disabled,
		onGetTransactionStatus,
		seconds,
		user,
	};
}

export default UseAddOrUpdateOrderForm;
