import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { HiTrash } from "react-icons/hi2";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { usePagination } from "../../../../utils/hooks";
import { useGetListClientQuery } from "../../../../utils/api/user/user.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { Order } from "../../../../utils/api/order/order.type";
import { Cart } from "../../../../utils/api/product/product.type";
import { formatCurrency, formatDate } from "../../../../utils/Utils";
import { RelauchCustomerBtn } from "../../../professionnel/CommandProfessionnel/tables/PanierAttenteTable";
import { User } from "../../../../utils/api/user/user.type";
import { useGetCartByUserQuery } from "../../../../utils/api/product/product.api";



function PanierEnAttenteAdminTable(props) {
	const { limit, page, onChange } = usePagination(10);

	const { data, isLoading } = useGetCartByUserQuery(props?.client?.slug);
	const actionFormatter = (cell: User, row: Cart) => {
		return <RelauchCustomerBtn order={row} />;
	};
	const optionFormatter = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<div className="container-btn-action-icon-modal">
					<button
						onClick={() => props.go("details-commande")}
						className="btn btn-action-icon-edit with-tooltip"
						data-tooltip-content="Voir"
					>
						<AiFillEye />
					</button>
				</div>
				<div className="container-btn-action-icon-modal">
					<button
						className="btn with-tooltip btn-action-icon-delete"
						data-tooltip-content="Supprimer"
					>
						<HiTrash />
					</button>
				</div>
			</div>
		);
	};

	const numeroFormatter = (cell: any, row: any) => {
		return (
			<div className="details-pro-admin-commande-numero-container">
				#{cell}
			</div>
		);
	};
	const columns = [
		{
			dataField: "id",
			text: `Commande n°`,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
			formatter: numeroFormatter,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell) => formatDate(cell),
		},
		{
			dataField: "total",
			text: "Total",
			formatter: (cell) => formatCurrency(cell),
		},
		{
			dataField: "statut",
			text: "Status",
			formatter: actionFormatter,
		},
		{
			dataField: "id",
			text: "Options",
			formatter: optionFormatter,
			headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
	];
	const selectRow = {
		mode: "checkbox",
	};
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.items || []}
						columns={columns}
						selectRow={selectRow}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
					<div className="custom-pagination-container">
						{/* <CustomPagination
							nbPages={data?.items?.count}
							page={page}
							onChange={onChange}
							perPage={limit}
						/> */}
					</div>
				</>
			)}
		</>
	);
}

export default PanierEnAttenteAdminTable;
