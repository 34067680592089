import React, { useState } from "react";
import { FormError, Input, RequiredText } from "../../../common/Input";
import MyDropzone from "../../../common/MyDropzone";
import ReactQuill from "react-quill";
import { AiOutlinePlus } from "react-icons/ai";
import useCrudAccueil from "../hooks/useCrudAccueil";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PreviewImage } from "../../../common/MyDropzone";
import { getImage } from "../../../../utils/Utils";

function OptionAccueilAdmin() {
  const [pictures, setPictures] = useState<File | null>();

  const {
		register,
		errors,
		isLoading,
		onSubmit,
		handleChangeImage,
		handleChangeDescription,
		handleChangeDescriptionEn,
		description,
		descriptionEn,
		accueil,
	} = useCrudAccueil();

  return (
    <div className="container-fluid">
      <div className="form-add-container">
        {/* <form className="mt-4">
          <div className="row row-add-student my-3">
            <div className="col-lg-12 mb-3">
              <label
                className={`form-label form-label-modal-custom input-parametre-label-form-add`}
              >
                Bannière
              </label>
              <MyDropzone setImages={setPictures} maxFile={10} />
            </div>
            <div className="col-lg-12 mb-3">
              <label
                htmlFor="description_fr"
                className="form-label input-produit-label-form-add"
              >
                Phrase d’accroche
              </label>
              <ReactQuill
                className="produit-editor-cgu"
                theme="snow"
                value={description}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label
                htmlFor="description_fr"
                className="form-label input-produit-label-form-add"
              >
                Description
              </label>
              <ReactQuill
                className="produit-editor-cgu"
                theme="snow"
                value={description}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <div className="form-add-apropos-label">
                Ce que disent nos clients
              </div>
            </div>
            <div className="col-lg-12 mb-3">
              <label
                className={`form-label form-label-modal-custom input-parametre-label-form-add`}
              >
                Profil du client
              </label>
              <MyDropzone setImages={setPictures} maxFile={10} />
            </div>
            <div className="col-lg-12 mb-3">
              <label
                htmlFor="description_fr"
                className="form-label input-produit-label-form-add"
              >
                Texte
              </label>
              <ReactQuill
                className="produit-editor-cgu"
                theme="snow"
                value={description}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <div className="form-add-apropos-label">Nos partenaires</div>
            </div>
            <div className="col-lg-12 mb-3">
              <Input
                type="text"
                label="Nom du partenaire"
                id="nom"
                placeholder="Nom du partenaire"
                customClassLabel={"input-parametre-label-form-add"}
                customClassInput="input-parametre-form-add"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label
                className={`form-label form-label-modal-custom input-parametre-label-form-add`}
              >
                Logo du partenaire
              </label>
              <MyDropzone setImages={setPictures} maxFile={10} />
            </div>
            <div className="col-lg-12 mb-3">
              <button className="btn btn-add-more-raison">
                <AiOutlinePlus /> Ajouter un autre partenaire
              </button>
            </div>
            <div className="col-lg-12 mb-3">
              <div className="form-add-apropos-label">Newsletter</div>
            </div>
            <div className="col-lg-12 mb-3">
              <Input
                type="text"
                label="Titre"
                id="titre"
                placeholder="Titre"
                customClassLabel={"input-parametre-label-form-add"}
                customClassInput="input-parametre-form-add"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label
                htmlFor="description_fr"
                className="form-label input-produit-label-form-add"
              >
                Texte
              </label>
              <ReactQuill
                className="produit-editor-cgu"
                theme="snow"
                value={description}
              />
            </div>
            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
              <button className="btn btn-cancel-admin" type="button">
                Annuler
              </button>
              <button
                className="btn btn-theme-admin w-100"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Enrégistrement...</span>
                  </>
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </div>
        </form> */}
        <form className="mt-4" onSubmit={onSubmit}>
					<div className="row row-add-student my-3">
						
						<section className="row mb-5">
							{/* <div className="col-lg-12 mb-3">
								<div className="form-add-apropos-label">
									Notre histoire
								</div>
							</div> */}
							<div className="col-lg-12">
								<Input
									type="file"
									label="Accueil"
									id="image"
									required
									onChange={
										handleChangeImage
									}
								/>
								<FormError
									error={
										errors?.image
									}
								/>
							</div>
							<div className="col-lg-12 mb-4">
                <PreviewImage
                  source={getImage(
                    accueil?.image
                  )}
                />
              </div>
							<div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Titre(fr)"
									id="titre"
									placeholder="Titre(fr)"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("titre")}
									error={
										errors?.titre
									}
									required
								/>
							</div>
              				<div className="col-lg-6 mb-3">
								<Input
									type="text"
									label="Titre(en)"
									id="titre_en"
									placeholder="Titre(en)"
									customClassLabel={
										"input-parametre-label-form-add"
									}
									customClassInput="input-parametre-form-add"
									{...register("titre_en")}
									error={
										errors?.titre_en
									}
									required
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_fr"
									className="form-label input-produit-label-form-add"
								>
									Description(Fr)
									<RequiredText />
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={description}
									onChange={
										handleChangeDescription
									}
								/>
								<FormError
									error={
										errors?.description
									}
								/>
							</div>
							<div className="col-lg-12 mb-3">
								<label
									htmlFor="description_En"
									className="form-label input-produit-label-form-add"
								>
									Description(En)
								</label>
								<ReactQuill
									className="produit-editor-cgu"
									theme="snow"
									value={descriptionEn}
									onChange={
										handleChangeDescriptionEn
									}
								/>
								<FormError
									error={
										errors?.description_en
									}
								/>
							</div>
							<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5 mb-5 ">
								<button
									className="btn btn-cancel-admin"
									type="button"
								>
									Annuler
								</button>
								<button
									className="btn btn-theme-admin w-100"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<span
												className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
												role="status"
											></span>
											<span>
												Enrégistrement...
											</span>
										</>
									) : (
										"Modifier"
									)}
								</button>
							</div>
						</section>
						
					</div>
				</form>
      </div>
    </div>
  );
}

export default OptionAccueilAdmin;
