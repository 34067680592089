import BreadCrumb from "../../shared/BreadCrumb";
import { getAvatar, getName } from "../../../utils/Utils";
import DetailsProAdminTable from "./ProfessionnelAdminTable/DetailsProAdminTable";
import { useValidDemande } from "./hooks/useCrudPro";
import { Spinner } from "../../common/Button";
import ChangeStatusProfessionelModal from "./ProfessionnelAdminTable/ChangeStatusProfessionelModal";
import { useProFromLocation } from "../../../utils/api/professionnel/professionnel.api";
import moment from "moment";

function DetailsProfessionnelAdmin() {
	const [item] = useProFromLocation();
	const { onValid, isLoading } = useValidDemande(item);
	console.log({ item });

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Professionnels" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title mb-4">
						Détail du professionnel
					</div>
					<div className="row mb-3">
						<div className="col-lg-4 mb-3">
							<div className="container-fluid">
								<div className="details-pro-admin-user-infos-container mb-5">
									<div className="details-pro-admin-user-avatar-container">
										<img
											src={getAvatar(
												item?.avatar
											)}
											alt="user avatar"
											className="details-pro-admin-user-avatar"
										/>
									</div>
									<div className="details-pro-admin-username-container">
										<div className="details-pro-admin-username">
											{getName(
												item
											)}
										</div>
										<div className="details-pro-admin-user-email">
											{item?.email}
										</div>
									</div>
								</div>
								{item?.status ===
									"en_attente" && (
									<div className="details-pro-admin-btn-actions-container">
										<button
											className="btn details-pro-admin-btn-rejeter"
											data-bs-toggle="modal"
											data-bs-target="#ChangeStatusProfessionelModal"
										>
											Rejeter
										</button>
										{isLoading ? (
											<button
												disabled
												className="btn details-pro-admin-btn-accepter"
											>
												<Spinner />
											</button>
										) : (
											<button
												className="btn details-pro-admin-btn-accepter"
												onClick={
													onValid
												}
											>
												Accepter
												la
												demande
											</button>
										)}
									</div>
								)}
								{item?.status ===
									"acceptee" && (
									<button className="btn details-pro-admin-btn-accepter">
										Acceptée
									</button>
								)}
							</div>
						</div>
						<div className="col-lg-4 mb-3">
							<div className="container-fluid custom-border-x">
								<div className="details-pro-admin-subtitle mb-3">
									Autres informations{" "}
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Société
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{item?.societe}
									</div>
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										NINEA
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{item?.ninea}
									</div>
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Téléphone
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{item?.telephone}
									</div>
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Ville
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{item?.ville}
									</div>
								</div>
								<div className="details-pro-admin-autres-infos-label-container mb-3">
									<div className="details-pro-admin-autres-infos-label">
										Secteur d’activité
									</div>
									<div className="details-pro-admin-autres-infos-value">
										{item?.secteur}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mb-3">
							<div className="container-fluid">
								<div className="details-pro-admin-subtitle mb-3">
									Message
								</div>
								<div className="details-pro-admin-text-message">
									{item?.message}
								</div>
							</div>
						</div>
					</div>
					<div className="row details-pro-admin-infos-produit-container align-items-center py-2 mb-3">
						<div className="col-lg-4 mb-3">
							<div className="details-pro-admin-infos-produit-title-container container-fluid">
								<div className="details-pro-admin-infos-produit-title">
									Produits commandés
								</div>
							</div>
						</div>
						<div className="col-lg-4 mb-3">
							<div className="details-pro-admin-infos-produit-title-container container-fluid">
								<div className="details-pro-admin-infos-produit-title">
									Stock disponible
								</div>
								<div className="details-pro-admin-infos-produit-stock-value">
									{item?.sum_stock}
								</div>
							</div>
						</div>
						{item?.last_date_updated !== 0 &&
							item?.last_date_updated !==
								undefined && (
								<div className="col-lg-4 mb-3">
									<div className="details-pro-admin-infos-produit-title-container container-fluid">
										<div className="details-pro-admin-infos-produit-title">
											Dernière mise
											à jour
										</div>
										<div className="details-pro-admin-infos-produit-date-maj">
											{moment(
												item?.last_date_updated
											).fromNow(
												true
											)}
										</div>
									</div>
								</div>
							)}
					</div>
					<div className="row mb-3">
						<div className="col-lg-12">
							<DetailsProAdminTable pro={item} />
						</div>
					</div>
				</div>
			</div>
			<ChangeStatusProfessionelModal item={item} status="refusee" />
		</div>
	);
}

export default DetailsProfessionnelAdmin;
