export const AllCommandTableData = [
    {
        id: 1,
        slug: "cmd-1",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        payment: "À la livraison",
        payment_mode: "Cash",
        statut: "En cours",
    },
    {
        id: 2,
        slug: "cmd-2",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        payment: "À la livraison",
        payment_mode: "Cash",
        statut: "En cours",
    },
    {
        id: 3,
        slug: "cmd-3",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        payment: "À la livraison",
        payment_mode: "Cash",
        statut: "En cours",
    },
    {
        id: 4,
        slug: "cmd-4",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        payment: "À la livraison",
        payment_mode: "Cash",
        statut: "En cours",
    },
    {
        id: 5,
        slug: "cmd-5",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        payment: "À la livraison",
        payment_mode: "Cash",
        statut: "En cours",
    },
    {
        id: 6,
        slug: "cmd-6",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        payment: "À la livraison",
        payment_mode: "Cash",
        statut: "En cours",
    },
];


export const PanierEnAttenteTableData = [
    {
        id: 1,
        slug: "pan-1",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        action: "Finaliser"
    },
    {
        id: 2,
        slug: "pan-2",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        action: "Finaliser"
    },
    {
        id: 3,
        slug: "pan-3",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        action: "Finaliser"
    },
    {
        id: 4,
        slug: "pan-4",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        action: "Finaliser"
    },
    {
        id: 5,
        slug: "pan-5",
        number: 25046,
        date: "11/07/2023",
        total: 360.00,
        action: "Finaliser"
    },
]


export const DetailsCommandTableData = [
    {
        id: 1,
        product: "Jus de Cajou",
        taille: "1L",
        price: "30.00",
        quantity: 3,
        amount: "90.00",
    },
    {
        id: 2,
        product: "Jus de Cajou",
        taille: "1L",
        price: "30.00",
        quantity: 3,
        amount: "90.00",
    }
]