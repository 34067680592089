import React from "react";
import { Input } from "../../../common/Input";
import useCrudTeam from "../hooks/useCrudTeam";
import { getAvatar } from "../../../../utils/Utils";

function OptionAddTeamMember({ resetItem, go, item }) {
	const { register, onSubmit, errors, isLoading, handleChangeImage, image } = useCrudTeam(resetItem, go, item);

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row">
					<div className="col-md-12">
						<div className="content-graph-admin medsain-page-title-container mb-2">
							<div className="container-display-title-theme">
								<div className="medsain-page-title">
									{item ? "Modifier le" : "Ajouter un"} membre
								</div>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="form-add-container">
								<form onSubmit={onSubmit}>
									<div className="row row-add-student my-3">
										<div className="col-lg-12 mb-3">
											<Input
												type="file"
												label="Photo"
												id="photo"
												placeholder="Photo"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												error={errors?.avatar}
												accept="images/*"
												onChange={handleChangeImage}
											/>
											{(image || item?.avatar) && (
												<img
													src={image ?? getAvatar(item?.avatar)}
													style={{
														width: "100px",
														height: "100px",
													}}
													className="img-thumbnail"
												/>
											)}
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="text"
												label="Nom"
												id="nom"
												placeholder="Nom"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register("nom")}
												error={errors?.nom}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="text"
												label="Prénom"
												id="question"
												placeholder="Prénom"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register("prenom")}
												error={errors?.prenom}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="text"
												label="Fonction"
												id="fonction"
												placeholder="Fonction"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register("fonction")}
												error={errors?.fonction}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="url"
												label="Facebook"
												id="facebook"
												placeholder="Facebook"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register("facebook")}
												error={errors?.facebook}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<Input
												type="url"
												label="Twitter"
												id="twitter"
												placeholder="Twitter"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register("x")}
												error={errors?.x}
											/>
										</div>
										<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
											<button
												className="btn btn-cancel-admin"
												type="button"
											>
												Annuler
											</button>
											<button
												className="btn btn-theme-admin w-100"
												type="submit"
												disabled={isLoading}
											>
												{isLoading ? (
													<>
														<span
															className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
															role="status"
														></span>
														<span>Enrégistrement...</span>
													</>
												) : (
													<>{item ? "Modifier" : "Ajouter"}</>
												)}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OptionAddTeamMember;
