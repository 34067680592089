/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, useLocationState } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { GoogleAdresseType } from "../../../../utils/type";
import { User, UserFormData } from "../../../../utils/api/user/user.type";
import { useAddOrEditClientMutation } from "../../../../utils/api/user/user.api";
import { useNavigate } from "react-router-dom";

function useCrudClient() {
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Prenom"),
		nom: yup.string().required().label("Nom"),
		email: yup
			.string()
			.required()
			.email("email invalide")
			.label("Email"),
		telephone: yup.string().required().label("Téléphone"),
		adresse: yup.string().required().label("Adresse"),
	});

	const item = useLocationState<User>(undefined);
	const [ville, setVille] = useState("");
	const [phone, setPhone] = useState<string | null>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<UserFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [save, { isLoading }] = useAddOrEditClientMutation();
	const navigate = useNavigate();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 6000);
		}
	}, [errors]);

	useEffect(() => {
		if (item) {
			setValue("telephone", item?.telephone);
			setValue("adresse", item?.adresse);
			setVille(item?.adresse);
			setPhone(item?.telephone);
		}
	}, [item]);

	const onChangeVille = (val: GoogleAdresseType) => {
		setVille(val?.rue);
		if (val?.rue) {
			setValue(`adresse`, val?.rue);
		}
	};

	const handleChangePhone = (item: string, country: any) => {
		setPhone(item);
		if (parseInt(item) !== parseInt(country?.dialCode)) {
			setValue("telephone", item);
		} else {
			setValue("telephone", "");
		}
	};

	const onSubmit = async (data: UserFormData) => {
		const res = await save({ slug: item?.slug, data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Client ${
					item ? "modifié" : "ajouté"
				} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => navigate(-1));
		}
		if ("error" in res) {
			const err = res.error as any;
			if (
				err?.data?.email?.includes(
					"user with this email already exists."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de l'envoie des données",
					showConfirmButton: false,
					timer: 2000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		item,
		handleChangePhone,
		onChangeVille,
		ville,
		phone,
	};
}

export function useToggleArchiveClient(user: User) {
	const [editData] = useAddOrEditClientMutation();
	const onArchive = async () => {
		let data: UserFormData = {
			is_archive: !user.is_archive,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				user?.is_archive === true ? "Débloquer" : "Bloquer"
			} ce client ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: user?.is_archive ? Color.success : Color.danger,
			confirmButtonColor: user?.is_archive
				? Color.success
				: Color.danger,
			preConfirm: () => {
				return editData({
					slug: user.slug,
					data: data,
				});
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Client ${
						result?.value?.data?.is_archive
							? "bloqué "
							: "débloqué"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1200,
				});
			}
		});
	};
	return onArchive;
}

export default useCrudClient;
