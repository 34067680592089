import React, { useState } from 'react'
import { AlertInfo } from '../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from '../../../common/TableSkeleton';
import { DetailsCommandTableData } from '../tables';
import imgProd from "../../../../assets/appImages/imgProduit.svg";


function RecapCommandTable(props) {
  const [pages, setPages] = useState(DetailsCommandTableData)
    
    const nameFormatter = (cell: any, row: any) => {
        return (
        <div className="d-flex gap-3 align-items-center">
            <div className="content-img-profil-itemt-table">
            <img
                src={imgProd}
                alt="Profil"
                className="img-profil-itemt-table"
            />
            </div>
        </div>
        );
    };
    
    const columns = [
      {
        dataField: "product",
        text: `Produit`,
        formatter: nameFormatter,
      },
      {
        dataField: "taille",
        text: "Taille",
      },
      {
        dataField: "price",
        text: "Prix unitaire",
      },
      {
        dataField: "quantity",
        text: "Quantité",
      },
      {
        dataField: "amount",
        text: "Montant",
      },
    ];

    return (
      <>
        {props?.isLoading && <TableSkeleton headers={columns} />}
        {!props?.isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={pages}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
          </>
        )}
      </>
    );
}

export default RecapCommandTable;