import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import BreadCrumb from "../../shared/BreadCrumb";
import { NavLink, useLocation } from "react-router-dom";
import ConditionsUtilisation from "./ParametreTabs/ConditionsUtilisation";
import "./ParametreAdmin.css";
import PolitiquesConfidentialite from "./ParametreTabs/PolitiquesConfidentialite";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import SousAdminTable from "./ParametreTable/SousAdminTable";
import { useSearch } from "../../../utils/hooks";
import { useGetConditionsQuery } from "../../../utils/api/config/config.api";
import {
	Condition,
	conditionType,
} from "../../../utils/api/config/config.type";
import SubscribersTable from "./ParametreTable/SubscribersTable";

const steps = [
	{ id: "sous-admin", Component: SousAdminTable },
	{ id: "subscribers", Component: SubscribersTable },
	{ id: "condition-utilisation", Component: ConditionsUtilisation },
	{ id: "politique-confidentialite", Component: PolitiquesConfidentialite },
];

const tabs = [
	"Sous admin",
	"Abonnés à la newsletter",
	"Conditions générales  d’utilisation",
	"Politique de confidentialité",
];

function ParametreAdmin() {
	const location = useLocation();
	const {
		navigation: { go },
		index,
	}: useStepType = useStep({
		initialStep: location?.state?.index ?? 0,
		steps,
	});

	const { data: conditions } = useGetConditionsQuery();
	let pc = conditions?.filter(
		(item: Condition) => item?.type === conditionType.pc
	)[0];
	let cgu = conditions?.filter(
		(item: Condition) => item?.type === conditionType.cgu
	)[0];

	const { search, handleSearch } = useSearch();

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Paramètres" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<ul className="tabs-container tabs-container-admin mb-0 pb-2 ps-0">
						{tabs.map((tab: string, key: number) => (
							<li
								className={`tab-item  ${
									index === key &&
									"tab-active-item-admin"
								}`}
								key={key}
								onClick={() => go(key)}
								role="button"
							>
								{tab}
							</li>
						))}
					</ul>
				</div>
				<div className="row">
					<div className="col-md-12">
						{index === 0 && (
							<div className="row country-and-search-bar-content pb-4">
								<div className="col-md-3 country-selection"></div>
								<div className="col-md-4 search-bar-container d-flex align-items-center gap-2">
									<input
										type="search"
										placeholder="Chercher"
										className="search-bar-conseil"
										onChange={
											handleSearch
										}
									/>
									<span className="icon-container">
										<HiOutlineBars3BottomRight />
									</span>
								</div>
							</div>
						)}
						<div className="content-graph-admin medsain-page-title-container mb-2">
							{index === 0 ? (
								<div className="container-display-title-theme">
									<NavLink
										to="/admin/ajouter-sous-admin"
										className="btn btn-theme-admin"
									>
										<span>
											Ajouter un
											sous-admin
											<AiOutlinePlusCircle />
										</span>
									</NavLink>
								</div>
							) : index === 1 ? (
								<div className="container-display-title-theme">
									<NavLink
										to="/admin/ajouter-conditions-utilisations"
										className="btn btn-theme-admin"
										state={cgu}
									>
										<span>
											{cgu ? (
												"Mettre à jour"
											) : (
												<>
													Ajouter
													<AiOutlinePlusCircle />
												</>
											)}
										</span>
									</NavLink>
								</div>
							) : (
								<div className="container-display-title-theme">
									<NavLink
										to="/admin/ajouter-politique-confidentialite"
										className="btn btn-theme-admin"
										state={pc}
									>
										<span>
											{pc ? (
												"Mettre à jour"
											) : (
												<>
													Ajouter
													<AiOutlinePlusCircle />
												</>
											)}
										</span>
									</NavLink>
								</div>
							)}
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="hippocrate-tabs-locataire-container">
								<div className="tabs-component-container">
									{/* <Component {...props} /> */}
									{index === 0 && (
										<SousAdminTable
											q={search}
										/>
									)}
									{index === 1 && (
										<SubscribersTable
											q={search}
										/>
									)}
									{index === 2 && (
										<ConditionsUtilisation
											condition={
												cgu
											}
										/>
									)}
									{index === 2 && (
										<PolitiquesConfidentialite
											condition={pc}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ParametreAdmin;
