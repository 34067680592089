import React from "react";
import ReactQuill from "react-quill";
import { FormError, Input } from "../../../common/Input";
import useCrudFaq from "../hooks/useCrudFaq";

function OptionMiseAJourFaq({ resetItem, go, item }) {
	const { register, onSubmit, errors, response, onChangeResponse, isLoading } = useCrudFaq(resetItem, go, item);

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row">
					<div className="col-md-12">
						<div className="content-graph-admin medsain-page-title-container mb-2">
							<div className="container-display-title-theme">
								<div className="medsain-page-title">Mise à jour</div>
							</div>
						</div>

						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="form-add-container">
								<form onSubmit={onSubmit}>
									<div className="row row-add-student my-3">
										<div className="col-lg-12 mb-3">
											<Input
												type="text"
												label="Question"
												id="question"
												placeholder="Question"
												customClassLabel={
													"input-parametre-label-form-add"
												}
												customClassInput="input-parametre-form-add"
												{...register("titre")}
												error={errors?.titre}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<label
												htmlFor="description_fr"
												className="form-label input-produit-label-form-add"
											>
												Réponse
											</label>
											<ReactQuill
												className="produit-editor-cgu"
												theme="snow"
												value={response}
												onChange={onChangeResponse}
											/>
											<FormError error={errors?.titre} />
										</div>
										{/* <div className="col-lg-12 mb-3">
                      <button className="btn btn-add-more-raison">
                        <AiOutlinePlus /> Ajouter une autre question
                      </button>
                    </div>
                     */}
										<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
											<button
												className="btn btn-cancel-admin"
												type="button"
											>
												Annuler
											</button>
											<button
												className="btn btn-theme-admin w-100"
												type="submit"
												disabled={isLoading}
											>
												{isLoading ? (
													<>
														<span
															className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
															role="status"
														></span>
														<span>Enrégistrement...</span>
													</>
												) : (
													<>{item ? "Modifier" : "Ajouter"}</>
												)}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OptionMiseAJourFaq;
