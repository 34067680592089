/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import { SelectInput } from "../../common/SelectInput";
import useCrudPro from "./hooks/useCrudPro";
import { BtnSubmit } from "../../common/Button";
import { Civilites } from "../../../utils/constante";
import GoogleInput from "../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";
import FormLabel from "../../common/Label";

function AddOrUpdateProfessionnel() {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		item,
		handleChangePhone,
		onChangeVille,
		ville,
		phone,
	} = useCrudPro();

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Professionnels" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<div className="medsain-page-title">
						{!item ? "Ajouter un " : "Modifier le "}
						professionnel
					</div>
					<div className="form-add-container">
						<form className="mt-4" onSubmit={onSubmit}>
							<div className="row row-add-student my-3">
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Société"
										id="societe"
										placeholder="Société"
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("societe")}
										error={errors.societe}
										defaultValue={item?.societe}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="NINEA"
										id="ninea"
										placeholder="NINEA"
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("ninea")}
										error={errors.ninea}
										defaultValue={item?.ninea}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Nom"
										id="nom"
										placeholder="Nom"
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("nom")}
										error={errors.nom}
										defaultValue={item?.nom}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Prenom"
										id="prenom"
										placeholder="Prenom"
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("prenom")}
										error={errors.prenom}
										defaultValue={item?.prenom}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="email"
										label="Email"
										id="email"
										placeholder="Email"
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("email")}
										error={errors.email}
										defaultValue={item?.email}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<FormLabel
										label="N° Téléphone"
										required
										customClassLabel={"input-parametre-label-form-add"}
									/>
									<div className="input-group mb-3">
										<PhoneInput
											defaultMask={".. ... .. .."}
											containerClass="react-custom-tel-input mb-3 p-0"
											inputClass=" form-control form-control-modal-custom input-parametre-form-add"
											country={"sn"}
											inputProps={{
												name: "telephone",
												required: true,
												autoFocus: true,
											}}
											countryCodeEditable={false}
											enableAreaCodes={true}
											disableDropdown={true}
											prefix="+"
											value={phone}
											onChange={(phone, country: any) =>
												handleChangePhone(phone, country)
											}
											inputStyle={{
												width: "100%",
												paddingBottom: "22px",
												borderRadius: "10px",
												height: 50,
											}}
										/>
										<FormError error={errors?.telephone} />
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<FormLabel
										label="Ville"
										required
										customClassLabel={"input-parametre-label-form-add"}
									/>
									<GoogleInput
										className="form-control form-control-modal-custom input-parametre-form-add"
										value={ville}
										onChange={onChangeVille}
										placeholder="Ville"
									/>
									<FormError error={errors?.ville} />
								</div>
								<div className="col-md-6 mb-3">
									<Input
										type="text"
										label="Secteur d’activité"
										id="secteur_activite"
										placeholder="Secteur d’activité"
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("secteur")}
										error={errors.secteur}
										defaultValue={item?.secteur}
										required
									/>
								</div>
								<div className="col-md-6 mb-3">
									<SelectInput
										label="Civilite"
										options={Civilites}
										customClassLabel={"input-parametre-label-form-add"}
										customClassInput="input-parametre-form-add"
										{...register("civilite")}
										id="civilite"
										error={errors?.civilite}
										defaultValue={item?.civilite}
										required
									/>
								</div>
								<div className="col-md-3 offset-md-9 auth-submit-btn-container pt-5">
									<BtnSubmit
										className="btn btn-theme-admin w-100"
										isLoading={isLoading}
										type="submit"
										label={item ? "Modifier" : "Ajouter"}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddOrUpdateProfessionnel;
