import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
	Contact,
	ResponseContactFormData,
} from "../../../utils/api/contact/contact.type";
import { useAppSelector } from "../../../redux/hook";
import { useSendResponseContactMutation } from "../../../utils/api/contact/contact.api";
import { cleannerError, closeModal } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import { QueryError } from "../../../utils/type";

const useResponseContactForm = (item?: Contact) => {
	const validationSchema = yup.object().shape({
		response: yup
			.string()
			.required()
			.label("La réponse")
			.transform((val) => (val === "<p><br></p>" ? "" : val)),
	});
	const { user: authUser } = useAppSelector((s) => s.user);
	const [response, setResponse] = useState<string>("");
	const [sendMessage, { isLoading }] = useSendResponseContactMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<ResponseContactFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChangeResponse = (value: string) => {
		setResponse(value);
		setValue("response", value);
	};

	const onsubmit = async (data: ResponseContactFormData) => {
		const res = await sendMessage({
			...data,
			user: authUser?.id,
			message: item?.id as number,
		});
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Réponse envoyée avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
			reset();
			setResponse("");
			closeModal("DetailsMessageModal");
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${
							err?.status || "inconnue"
					  } est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		response,
		handleChangeResponse,
		setResponse,
	};
};

export default useResponseContactForm;
