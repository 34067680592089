import React, {useEffect} from "react";
import "./DetailsProduitParticulier.css";
import ReactStars from "react-rating-stars-component";
import { Input } from "../../common/Input";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import Description from "./Tabs/Description";
import Commentaire from "./Tabs/Commentaire";
import Apropos from "./Tabs/Apropos";
import otherItem from "../../../assets/appImages/otherItem.svg";
import { createMarkup, formatCurrency } from "../../../utils/Utils";
import { useProduct } from "../../front/boutique/hooks/useProduct";
import { ProductCarousselItem } from "../../front/boutique/DetailBoutique";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useLazyProductSimilaireQuery } from "../../../utils/api/product/product.api";

const steps = [
	{ id: "description", Component: Description },
	{ id: "commentaire", Component: Commentaire },
	{ id: "a-propos", Component: Apropos },
];

const tabs = ["Description", "Commentaires", "À propos de Casadeliz"];
function DetailsProduitParticulier() {
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { t } = useTranslation();

	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;

	const {
		product,
		setProductNumber,
		handleAddToCart,
		variation,
		handleChangeCurrentVariation,
	} = useProduct();

	const [findProduitSimilaire, { data, isError, isLoading }] = useLazyProductSimilaireQuery();

	useEffect(() => {
		if (product?.slug) {
			findProduitSimilaire(product?.slug as string);
		}
	}, [product]);



	
	return (
		<div className="page-content mt-0  pt-5">
			<div className="container-casadeliz mb-5">
				<section className="section-boutique custom-section-divider">
					<div className="row mb-5">
						<div className="col-lg-5">
							<ProductCarousselItem
								images={product?.images}
							/>
						</div>
						<div className="col-lg-7">
							<div>
								<div className="particulier-details-produit-titre mb-3">
									{locale == 'fr' ? product?.nom : product?.nom_en}
								</div>
								<div className="particulier-details-produit-notes-container">
									<ReactStars
										count={5}
										size={14}
										emptyIcon={
											<i className="fa-duotone fa-star"></i>
										}
										halfIcon={
											<i className="fa fa-star-half-alt"></i>
										}
										fullIcon={
											<i className="fa fa-star"></i>
										}
										activeColor="#FFC600"
										edit={false}
										value={5}
									/>
								</div>
								<div className="particulier-details-produit-prix mb-3">
									{formatCurrency(
										variation?.prix
									)}
								</div>
								<div
									className="particulier-details-produit-texte mb-3"
									dangerouslySetInnerHTML={createMarkup(
										locale == "fr" ? product?.description : product?.description_en 
									)}
								/>
								{product?.variations?.length &&
								(
									<div className="d-flex align-items-center gap-5 qat_wrapper mb-4">
										<span className="custom_sm__title">
											Format
										</span>
										<div className="d-flex align-items-center flex-wrap gap-3">
											{product?.variations?.map(
												(
													item
												) => (
													<div
														key={
															item?.slug
														}
														className={`variation-item ${
															variation?.id ===
															item?.id
																? "active"
																: ""
														}`}
														role="button"
														onClick={() =>
															handleChangeCurrentVariation(
																item
															)
														}
													>
														{
															item?.taille
														}
													</div>
												)
											)}
										</div>
									</div>
								)}
								<div className="particulier-details-produit-btn-container">
									<div className="particulier-details-produit-titre-quantite mb-3">
										{t('quantity')} :
									</div>
									<Input
										type="number"
										label="Min"
										id="min"
										min={1}
										placeholder="0"
										defaultValue={1}
										hideLabel={true}
										customClassLabel={
											"input-particulier-details-produit-label-form"
										}
										customClassInput="input-particulier-details-produit-form-add"
										onChange={(e) => {
											setProductNumber(
												Number(
													e
														.currentTarget
														.value
												)
											);
										}}
									/>
									<button
										className="btn particulier-details-produit-btn-ajout-panier mb-3"
										onClick={() =>
											handleAddToCart(
												variation,
												product
											)
										}
									>
										{t("add_cart")}
										<BsFillArrowRightCircleFill />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-8">
							<div className="particulier-details-produit-section-tabs-container">
								<div className="particulier-details-produit-section-tabs-list-container">
									<ul className="tabs-container tabs-container-admin mb-0 pb-2 ps-0">
										{tabs.map(
											(
												tab: string,
												key: number
											) => (
												<li
													className={`tab-item  ${
														index ===
															key &&
														"tab-active-item-admin"
													}`}
													key={
														key
													}
													onClick={() =>
														go(
															key
														)
													}
													role="button"
												>
													{
														tab
													}
												</li>
											)
										)}
									</ul>
								</div>
								<div className="tabs-component-container">
									<Component
										product={product}
									/>
								</div>
							</div>
						</div>
						{data?.results?.length > 0 && 
						(
						<div className="col-lg-4">
							<div className="particulier-details-produit-section-tabs-container py-3 px-2">
								<div className="particulier-details-produit-titre-other-items mb-3">
									{t('you_might_like')}
								</div>
								{data?.results?.map(
									(prod, i) => (
										<NavLink
											to={`/particulier/produit/${prod?.slug}`}
											className="no-link"
											state={prod}
										>
											<div
												className="particulier-details-produit-titre-other-items-item mb-1"
												key={i}>
												<div className="particulier-details-produit-titre-other-items-img-container">
													<img
														src={
															otherItem
														}
														alt="image produit casadeliz"
														className="particulier-details-produit-titre-other-items-img"
													/>
												</div>
												<div>
													<div className="particulier-details-produit-titre-other-items-titre">
														{prod?.nom} {prod?.taille}
													</div>
													<div className="particulier-details-produit-titre-other-items-prix">
														{formatCurrency(
															prod?.variations[0]?.prix
														)}
													</div>
												</div>
											</div>
										</NavLink>
									)
								)}
							</div>
						</div>
						)
						}
					</div>
				</section>
			</div>
		</div>
	);
}

export default DetailsProduitParticulier;
