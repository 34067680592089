import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import {
	CommentArticle,
	ResponseCommentFormData,
} from "../../../utils/api/article/article.type";
import { useAppSelector } from "../../../redux/hook";
import { QueryError } from "../../../utils/type";
import { cleannerError } from "../../../utils/Utils";
import { usePostResponseCommentMutation } from "../../../utils/api/article/article.api";

export function usePostResponseComment(item?: CommentArticle) {
	const validationSchema = yup.object().shape({
		content: yup.string().required("Ce champ est obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm<ResponseCommentFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [response, setResponse] = useState<string>();
	const [postResponseComment, { isLoading }] = usePostResponseCommentMutation();
	const { user: authUser } = useAppSelector((s) => s?.user);

	const handleChangeResponseCommentField = (value: string) => {
		setResponse(value);
		setValue("content", value);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: ResponseCommentFormData) => {
		const newData: ResponseCommentFormData = {
			...data,
			comment: item?.id as number,
			created_by: authUser?.id as number,
		};
		const res = await postResponseComment(newData);
		if ("data" in res) {
			setResponse("");
			setValue("content", "");
			// toast.success("Réponse envoyé avec succès!");
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		handleChangeResponseCommentField,
		response,
		authUser,
	};
}
