import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { UserFormData } from "../../../../utils/api/user/user.type";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { useAddOrEditUserMutation } from "../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";

function UseEditInfosForm() {
	const [code, setCode] = useState<string>("");
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Le prénom"),
		nom: yup.string().required().label("Le nom"),
		adresse: yup.string().required().label("L'adresse"),
		telephone: validatePhone(yup, code).required(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<UserFormData>({
		resolver: yupResolver(validationSchema),
	});
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [avatar, setAvatar] = useState<string>();
	const { user } = useAppSelector((s) => s?.user);

	const [editUser, { isLoading, isSuccess, error, isError, data }] =
		useAddOrEditUserMutation();
	const [phone, setPhone] = useState<string | null>(null);

	const handleChangePhone = (item, country) => {
		setCode(country?.countryCode);
		setPhone(item);
		if (parseInt(item) !== parseInt(country?.dialCode)) {
			setValue("telephone", item);
		} else {
			setValue("telephone", "");
		}
	};

	const handelImageChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const file: any = e.target.files ? e.target.files[0] : null;

			if (file) {
				setAvatar(URL.createObjectURL(file));
				register("avatar");
				setValue("avatar", file);
			}
		},
		[]
	);

	useEffect(() => {
		if (user?.id) {
			const fields: (keyof UserFormData)[] = [
				"slug",
				"prenom",
				"nom",
				"email",
				"adresse",
				"id",
				"telephone",
			];
			for (let field of fields) {
				register(field);
				setValue(field, user[field]);
			}

			if (user?.telephone) {
				setPhone(user?.telephone);
			}
		}
	}, [user]);

	useEffect(() => {
		if (isSuccess) {
			dispatch(onSetUser(data));
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Informations modifiées avec succès !",
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				if (user?.user_type === "particulier") {
					navigate("/particulier/mon-compte");
				} else {
					navigate("/particulier/mon-compte");
				}
			});
		}
		const err = error as any;

		if (isError) {
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [error, isError, isSuccess, navigate]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	const onSubmit = async (data: UserFormData) => {
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			fd.append(key, data[key]);
		}
		await editUser({ slug: data?.slug, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		phone,
		handleChangePhone,
		// address,
		code,
		handelImageChange,
		avatar,
		user,
	};
}

export default UseEditInfosForm;
