import "./HeaderAdmin.css";
import { NavLink, Link, useLocation } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import React,{useState} from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { getAvatar, getName } from "../../../../utils/Utils";
import { onlogout } from "../../../../redux/slice/User.slice";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useCart } from "../../../front/panier/useCart";
import { useNotification } from "../../../../utils/api/notification/notification.api";
import { useTranslation } from "react-i18next";
import Select, { components, SingleValueProps } from "react-select";
import ImgLangFr from "../../../../assets/lang/fr.webp";
import ImgLangEn from "../../../../assets/lang/en.webp";
import i18next from "i18next";
import moment from "moment";
import { formatDateHour , isAdmin ,showModal} from "../../../../utils/Utils";
import ShowNotificationModal from "../../../admin/ProfilAdmin/modal/ShowNotificationModal";
import { useNavigate } from "react-router-dom";


export const LogoutButton = () => {
	const dispatch = useAppDispatch();

	const onLogout = () => {
		dispatch(onlogout());
	};
	return (
		<button
			type="button"
			className="dropdown-item"
			style={{ fontSize: 16 }}
			onClick={() => onLogout()}
		>
			Déconnexion
		</button>
	);
};

const HeaderAdmin: React.FC = () => {
	const location = useLocation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { i18n, t } = useTranslation();
	const { user } = useAppSelector((s) => s?.user);
	const { count, data, readNotif, readAll } = useNotification();
	const [notification, setNotification] = useState();

	const navigate = useNavigate();

	const [showNotifications, setShowNotifications] = React.useState(false);

	const onShowAndHideNotification = () => {
		if (showNotifications === false) {
			setShowNotifications(true);
		} else {
			setShowNotifications(false);
		}
	};
	const { panierCount } = useCart();
  
	const countries = [
		{ value: "fr", icon: ImgLangFr, label: "Français" },
		{ value: "en", icon: ImgLangEn, label: "English" },
	];
  
	const Option = (props: any) => (
		<components.Option {...props} className="country-option">
			<img src={props.data.icon} alt="logo" className="country-logo" />
			{props.data.label}
		</components.Option>
	);
	const [selectedCountry, setSelectedCountry] = useState(
		(!locale || locale === undefined)
			? countries?.find((country: any) => country?.value === "fr")
			: countries?.find((country: any) => country?.value === locale)
	);
  
	const handleChange = (value: any) => {
		setSelectedCountry(value);
		window.sessionStorage.setItem("locale", value?.value);
		i18n.changeLanguage(value?.value);
	};
  
	const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
		<components.SingleValue {...props}>
			<img
				src={selectedCountry?.icon}
				alt="s-logo"
				className="selected-logo"
			/>
			{children}
		</components.SingleValue>
	);

	function openModalNotification(item : any) {
		setNotification(item);
		readNotif(item?.slug)
		showModal("DetailsNotificationModal");
	  }

	const seeAllNotification = () => {
		navigate("/admin/profil", {
			state: { active: 2 },
		});
	};
	  

	return (
		<div className="admin-header-component">
			<nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark">
				<div className="headering-admin">
					<div className="header-others-items">
						<div className="container-info-user-connect-header">
							<div className="content-select-lang">
								<Select
									value={selectedCountry}
									options={countries}
									onChange={handleChange}
									styles={{
									singleValue: (base) => ({
										...base,
										display: "flex",
										alignItems: "center",
									}),
									}}
									components={{
									Option,
									SingleValue,
									}}
								/>
							</div>
							{/* <div className="connected-user-container" id="header-user-admin">
								<div className="connected-user-content ml-auto">
									<div className="dropdown dis-flex">
										<button
											className="user-acount-dashboard-header dropdown-toggle btn"
											type="button"
											id="dropdownMenuButton"
											data-bs-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<div className="user-acount-dashboard-header-display gap-2">
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="21"
														height="16"
														viewBox="0 0 21 16"
														fill="none"
													>
														<g clipPath="url(#clip0_1805_829)">
															<mask
																id="mask0_1805_829"
																style={{
																	maskType: "alpha",
																}}
																maskUnits="userSpaceOnUse"
																x="0"
																y="0"
																width="21"
																height="16"
															>
																<path
																	d="M19 0.5H2C0.89543 0.5 0 1.39543 0 2.5V13.5C0 14.6046 0.89543 15.5 2 15.5H19C20.1046 15.5 21 14.6046 21 13.5V2.5C21 1.39543 20.1046 0.5 19 0.5Z"
																	fill="white"
																/>
															</mask>
															<g mask="url(#mask0_1805_829)">
																<path
																	d="M19 0.5H2C0.89543 0.5 0 1.39543 0 2.5V13.5C0 14.6046 0.89543 15.5 2 15.5H19C20.1046 15.5 21 14.6046 21 13.5V2.5C21 1.39543 20.1046 0.5 19 0.5Z"
																	fill="#22438B"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M3.00012 1.5L0.974121 1.526L1.00012 3.5L17.9821 14.528L20.0191 14.491L19.9811 12.529L3.00012 1.5Z"
																	fill="white"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M2 1.5L1 2.5L19 14.5L20 13.5L2 1.5Z"
																	fill="#C7152A"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M18 1.5H20V3.5C20 3.5 8.24999 10.896 3.01799 14.528C2.95499 14.572 1.01999 14.531 1.01999 14.531L0.86499 12.63L18 1.5Z"
																	fill="white"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M19.043 1.47266L20 2.50066L2 14.5007L1 13.5007L19.043 1.47266Z"
																	fill="#C7152A"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M8 1.5H13V5.5H20V10.5H13V14.5H8V10.5H1V5.5H8V1.5Z"
																	fill="white"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M9 1.5H12V6.5H20V9.5H12V14.5H9V9.5H1V6.5H9V1.5Z"
																	fill="#C7152A"
																/>
																<path
																	d="M19 1H2C1.17157 1 0.5 1.67157 0.5 2.5V13.5C0.5 14.3284 1.17157 15 2 15H19C19.8284 15 20.5 14.3284 20.5 13.5V2.5C20.5 1.67157 19.8284 1 19 1Z"
																	stroke="black"
																	strokeOpacity="0.1"
																/>
															</g>
														</g>
														<defs>
															<clipPath id="clip0_1805_829">
																<rect
																	width="21"
																	height="15"
																	fill="white"
																	transform="translate(0 0.5)"
																/>
															</clipPath>
														</defs>
													</svg>
												</span>
												<span>
													English
												</span>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
													>
														<mask
															id="mask0_1805_841"
															style={{
																maskType: "alpha",
															}}
															maskUnits="userSpaceOnUse"
															x="0"
															y="0"
															width="16"
															height="16"
														>
															<rect
																width="16"
																height="16"
																fill="#D9D9D9"
															/>
														</mask>
														<g mask="url(#mask0_1805_841)">
															<path
																d="M7.99987 9.98385C7.91098 9.98385 7.82498 9.96719 7.74187 9.93385C7.65831 9.90052 7.58876 9.85608 7.5332 9.80052L4.46654 6.73385C4.34431 6.61163 4.2832 6.45608 4.2832 6.26719C4.2832 6.0783 4.34431 5.92274 4.46654 5.80052C4.58876 5.6783 4.74431 5.61719 4.9332 5.61719C5.12209 5.61719 5.27765 5.6783 5.39987 5.80052L7.99987 8.40052L10.5999 5.80052C10.7221 5.6783 10.8776 5.61719 11.0665 5.61719C11.2554 5.61719 11.411 5.6783 11.5332 5.80052C11.6554 5.92274 11.7165 6.0783 11.7165 6.26719C11.7165 6.45608 11.6554 6.61163 11.5332 6.73385L8.46654 9.80052C8.39987 9.86719 8.32765 9.9143 8.24987 9.94185C8.17209 9.96985 8.08876 9.98385 7.99987 9.98385Z"
																fill="#3B4758"
															/>
														</g>
													</svg>
												</span>
											</div>
										</button>
										<div
											className="dropdown-menu dropdown-menu-custom menu-lang"
											aria-labelledby="dropdownMenuButton"
										>
											<button
												className="dropdown-item d-flex gap-2"
												style={{
													fontSize: 16,
												}}
											>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="21"
														height="22"
														viewBox="0 0 21 22"
														fill="none"
													>
														<g clipPath="url(#clip0_442_10296)">
															<path
																d="M21 16.2493C21 16.8682 20.7542 17.4617 20.3166 17.8993C19.879 18.3369 19.2855 18.5827 18.6667 18.5827H14V3.41602H18.6667C19.2855 3.41602 19.879 3.66185 20.3166 4.09943C20.7542 4.53702 21 5.13051 21 5.74935V16.2493Z"
																fill="#ED2939"
															/>
															<path
																d="M2.33333 3.41602C1.7145 3.41602 1.121 3.66185 0.683418 4.09943C0.245833 4.53702 0 5.13051 0 5.74935L0 16.2493C0 16.8682 0.245833 17.4617 0.683418 17.8993C1.121 18.3369 1.7145 18.5827 2.33333 18.5827H7V3.41602H2.33333Z"
																fill="#002495"
															/>
															<path
																d="M7 3.41602H14V18.5827H7V3.41602Z"
																fill="#EEEEEE"
															/>
														</g>
														<defs>
															<clipPath id="clip0_442_10296">
																<rect
																	width="21"
																	height="21"
																	fill="white"
																	transform="translate(0 0.5)"
																/>
															</clipPath>
														</defs>
													</svg>
												</span>
												<span>
													Français
												</span>
											</button>
										</div>
									</div>
								</div>
							</div> */}
							{location?.pathname?.startsWith(
								"/professionel"
							) && (
								<div className="content-btn-cta-particulier">
									<NavLink
										to={`/professionel/panier`}
										className="btn btn-menu-header-particulier position-relative"
										role="button"
									>
										<HiOutlineShoppingCart />
										<span className="counter-notif-panier">
											{panierCount}
										</span>
									</NavLink>
								</div>
							)}
							<div className="container-menu-header-admin gap-3">
								<div
									className="notification-item-container linkable position-relative"
									onClick={() =>
										onShowAndHideNotification()
									}
								>
									<div className="notification-item-content">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
										>
											<mask
												id="mask0_1805_845"
												style={{
													maskType: "alpha",
												}}
												maskUnits="userSpaceOnUse"
												x="0"
												y="0"
												width="24"
												height="24"
											>
												<rect
													width="24"
													height="24"
													fill="#D9D9D9"
												/>
											</mask>
											<g mask="url(#mask0_1805_845)">
												<path
													d="M5 19C4.71667 19 4.479 18.904 4.287 18.712C4.09567 18.5207 4 18.2833 4 18C4 17.7167 4.09567 17.4793 4.287 17.288C4.479 17.096 4.71667 17 5 17H6V10C6 8.61667 6.41667 7.38733 7.25 6.312C8.08333 5.23733 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.646 2.72933 10.938 2.438C11.2293 2.146 11.5833 2 12 2C12.4167 2 12.7707 2.146 13.062 2.438C13.354 2.72933 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.23733 16.75 6.312C17.5833 7.38733 18 8.61667 18 10V17H19C19.2833 17 19.5207 17.096 19.712 17.288C19.904 17.4793 20 17.7167 20 18C20 18.2833 19.904 18.5207 19.712 18.712C19.5207 18.904 19.2833 19 19 19H5ZM12 22C11.45 22 10.9793 21.8043 10.588 21.413C10.196 21.021 10 20.55 10 20H14C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22Z"
													fill="#586A84"
												/>
											</g>
										</svg>
										<span className="notification-counter">
											{count}
										</span>
									</div>
								</div>
							</div>

							{location?.pathname?.startsWith(
								"/patient"
							) ? (
								<div
									className="connected-user-container"
									id="header-user-admin"
								>
									<div className="connected-user-content ml-auto">
										<div className="user-acount-dashboard-header-display g-2">
											<NavLink to="/patient/profil">
												<img
													src={getAvatar(
														user?.avatar
													)}
													className="user-avatar"
													alt="User Avatar"
												/>
											</NavLink>
										</div>
									</div>
								</div>
							) : (
								<div
									className="connected-user-container"
									id="header-user-admin"
								>
									<div className="connected-user-content ml-auto">
										<div className="dropdown dis-flex">
											<button
												className="user-acount-dashboard-header dropdown-toggle btn"
												type="button"
												id="dropdownMenuButton"
												data-bs-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<div className="user-acount-dashboard-header-display g-2">
													<img
														src={getAvatar(
															user?.avatar
														)}
														className="user-avatar"
														alt="User Avatar"
													/>
												</div>
											</button>
											<div
												className="dropdown-menu dropdown-menu-custom"
												aria-labelledby="dropdownMenuButton"
											>
												<NavLink
													to="/admin/profil"
													className="dropdown-item"
													style={{
														fontSize: 16,
													}}
												>
													{getName(
														user
													)}
												</NavLink>
												<div className="dropdown-divider"></div>
												<LogoutButton />
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="hiddenable-nav-mobile-container no-view-desktop">
								<div className="hiddenable-nav-mobile-content">
									<div className="container-nav-mobile">
										<SidebarMobile />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{showNotifications && (
					<div
						className={
							"header-hiddenable-notification-section"
						}
					>
						{data && data?.length > 0 ? (
							<ul className="header-hiddenable-notification-ul mb-0">
								{data
									?.slice(0, 4)
									?.map((item) => (
										<li
											className="header-hiddenable-notification-li"
											key={
												item?.slug
											}
										>
											<NavLink
												to="#"
												onClick={() => openModalNotification(item)}
												className="notification-item-link d-flex no-link b-0"
											>
												<div className="content-text-notif ps-2">
													<p className="text-notif mb-1">
														{
															item?.content
														}
													</p>
													<p className="text-date-notif mb-0">
														{moment(
															item?.created_at
														).format(
															"DD/MM/YYYY-HH:MM"
														)}
													</p>
												</div>
											</NavLink>
										</li>
									))}
								<li className="header-hiddenable-notification-li">
									<Link to='/admin/profil'
										state= {{active: 2}}
										className="notification-item-link d-flex no-link"
									>
										<div className="content-text-notif ps-2">
											<p className="text-notif m-0">
												{t("see_all_the_notifications")}
											</p>
										</div>
									</Link>
								</li>
							</ul>
						) : (
							<ul className="header-hiddenable-notification-ul">
								<li className="header-hiddenable-notification-li">
									Il n'y a aucune
									notification .
								</li>
							</ul>
						)}
					</div>
				)}
			</nav>
			<ShowNotificationModal notification={notification}/>
		</div>
	);
};

export default HeaderAdmin;
