import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import {
	Professionnel,
	ProFormData,
	StatusDemande,
} from "../../../../utils/api/professionnel/professionnel.type";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import { useEditDemandeMutation } from "../../../../utils/api/professionnel/professionnel.api";
import { Color } from "../../../../utils/theme";
import { useNavigate } from "react-router-dom";

function ChangeStatusProfessionelModal({
	item,
	status,
}: {
	item?: Professionnel;
	status?: StatusDemande;
}) {
	const [isShowMotifField, setIsShowMotifField] = useState<boolean>(false);
	const validationSchema = yup.object().shape({
		motif: yup.string().required().label("Motif"),
	});
	const navigate = useNavigate();
	const [changeStatus, { isLoading }] = useEditDemandeMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<ProFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
		}
	}, [item]);
	useEffect(() => {
		if (status) {
			setValue("status", status);
			setIsShowMotifField(true);
		}
	}, [status]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	// const handleShowMotifField = (
	// 	e: React.ChangeEvent<HTMLSelectElement>
	// ) => {
	// 	if (e.currentTarget.value === "rejetee") {
	// 		setIsShowMotifField(true);
	// 	} else {
	// 		setIsShowMotifField(false);
	// 	}
	// };

	const onSubmit = async (data: ProFormData) => {
		const res = await changeStatus({
			slug: item?.slug,
			data: {
				status: data?.status,
				nom: item?.nom,
				prenom: item?.prenom,
				email: item?.email,
				telephone: item?.telephone,
				motif: data?.motif,
			},
		});
		if ("data" in res) {
			reset();
			closeModal("ChangeStatusProfessionelModal");
			Swal.fire({
				icon: "success",
				title: `Demande rejetée avec  succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() =>  navigate("/admin/professionnels"));
		} else if ("error" in res) {
			console.log("error", res.error);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			if (
				err?.data?.motif &&
				err?.data?.motif[0]?.includes(
					"This field may not be blank."
				)
			) {
				return Swal.fire({
					icon: "error",
					title: `Motif est un champ obligatoire`,
					showConfirmButton: false,
					timer: 2000,
				});
			}
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return (
		<div
			className="modal fade"
			id="ChangeStatusProfessionelModal"
			aria-labelledby="ChangeStatusProfessionelModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusProfessionelModal"
						>
							Rejeter la demande
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form
							className="w-100"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="row">
								{/* <div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register(
												"status"
											)}
											onChange={
												handleShowMotifField
											}
											disabled
										>
											<option value="en_attente">
												En
												attente
											</option>
											<option value="acceptee">
												Acceptée
											</option>
											<option value="refusee">
												Rejetée
											</option>
										</select>
										<FormError
											error={
												errors?.status
											}
										/>
									</div>
								</div> */}
								{isShowMotifField && (
									<div className="col-12">
										<label className="form-label label-form-admin">
											Motif
											<span className="text-danger">
												*
											</span>
										</label>
										<textarea
											className="form-control form-control-modal mb-2"
											rows={4}
											{...register(
												"motif"
											)}
											placeholder="Motif de rejet"
										/>
										<FormError
											error={
												errors?.motif
											}
										/>
									</div>
								)}
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
									className="btn btn-theme-admin w-100"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusProfessionelModal;
