import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import {
	useDeleteCategoryMutation,
	useGetCategoriesQuery,
} from "../../../../utils/api/category/category.api";
import { Category } from "../../../../utils/api/category/category.type";
import { formatDate, showModal } from "../../../../utils/Utils";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddCategoryModal from "./AddCategoryModal";

function DeleteCategorie({ item }: { item: Category }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteCategoryMutation();
	const onDelete = useDelete<Category>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cette catégorie?`,
		successMessage: `La catégorie a été supprimée`,
	});
	return <ButtonDelete onClick={onDelete} />;
}
function CategoriesTable({ q }: { q?: string }) {
	const { limit, page, onChange } = usePagination(10);
	const { data, isLoading } = useGetCategoriesQuery({
		limit,
		page,
		q,
	});
	const { item, getItem, resetItem } = useItem<Category>();

	const actionFormatter = (cell: string, row: Category) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<div className="container-btn-action-icon-modal">
					<ButtonEdit
						onClick={() => {
							showModal("AddCategoryModal");
							getItem(row);
						}}
					/>
				</div>
				<div className="container-btn-action-icon-modal">
					<DeleteCategorie item={row} />
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom",
			text: `Nom Catégorie`,
		},
		{
			dataField: "created_at",
			text: "Date d'ajout",
			formatter: (cell) => formatDate(cell),
		},
		{
			dataField: "id",
			text: "Action",
			formatter: actionFormatter,
		},
	];

	const selectRow = {
		mode: "checkbox",
	};

	return (
		<>
			<div className="content-graph-admin medsain-page-title-container d-flex justify-content-between gap-3 flex-wrap mb-2">
				<div className="container-display-title-theme">
					<button
						className="btn btn-theme-admin"
						data-bs-toggle="modal"
						data-bs-target="#AddCategoryModal"
					>
						<span>
							Ajouter une categorie
							<AiOutlinePlusCircle />
						</span>
					</button>
				</div>
			</div>
			<div className="hippocrate-dash-admin-page-content-container mb-1">
				<div className="hippocrate-tabs-locataire-container">
					<div className="tabs-component-container">
						{isLoading && <TableSkeleton headers={columns} />}
						{!isLoading && (
							<>
								<BootstrapTable
									keyField="id"
									data={data?.results}
									columns={columns}
									selectRow={selectRow}
									condensed
									hover
									wrapperClasses="table-responsive admin-table"
									noDataIndication={() => (
										<AlertInfo message="Aucune donnée trouvée" />
									)}
								/>
								<div className="custom-pagination-container">
									<div className="custom-pagination-container">
										<CustomPagination
											nbPages={data?.count}
											page={page}
											onChange={onChange}
											perPage={limit}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<AddCategoryModal item={item} resetItem={resetItem} />
		</>
	);
}

export default CategoriesTable;
