/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ProFormData, Professionnel } from "../../../../utils/api/professionnel/professionnel.type";
import {
	useAddOrEditProMutation,
	useValidDemandeMutation,
} from "../../../../utils/api/professionnel/professionnel.api";
import { cleannerError, useLocationState } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { GoogleAdresseType } from "../../../../utils/type";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../../../utils/api/user/user.type";

function useCrudPro() {
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Prenom"),
		nom: yup.string().required().label("Nom"),
		email: yup
			.string()
			.required()
			.email("email invalide")
			.label("Email"),
		telephone: yup.string().required().label("Téléphone"),
		ville: yup.string().required().label("Ville"),
		secteur: yup.string().required().label("Secteur"),
		ninea: yup.string().required().label("Ninea"),
		societe: yup.string().required().label("Société"),
	});

	const item = useLocationState<Professionnel>(undefined);
	const [ville, setVille] = useState("");
	const [phone, setPhone] = useState<string | null>(null);
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<ProFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: { status: "acceptee" },
	});

	const [registerPro, { isLoading }] = useAddOrEditProMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 6000);
		}
	}, [errors]);

	useEffect(() => {
		if (item) {
			setValue("ville", item?.ville);
			setValue("telephone", item?.telephone);
			setVille(item?.ville);
			setPhone(item?.telephone);
		}
	}, [item]);

	const onChangeVille = (val: GoogleAdresseType) => {
		setVille(val?.ville);
		if (val?.ville) {
			setValue(`ville`, val?.ville);
		}
	};

	const handleChangePhone = (item: string, country: any) => {
		setPhone(item);
		if (parseInt(item) !== parseInt(country?.dialCode)) {
			setValue("telephone", item);
		} else {
			setValue("telephone", "");
		}
	};

	const onSubmit = async (data: ProFormData) => {
		const res = await registerPro({ slug: item?.slug, data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Professionnel ${
					item ? "modifié" : "ajouté"
				} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => navigate("/admin/professionnels"));
		}
		if ("error" in res) {
			const err = res.error as any;
			if (
				err?.data?.email?.includes(
					"Utilisateur avec ce courriel existe déjà."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de l'envoie des données",
					showConfirmButton: false,
					timer: 2000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		item,
		handleChangePhone,
		onChangeVille,
		ville,
		phone,
	};
}

export const useValidDemande = (item: Professionnel) => {
	const [validPro, { isLoading }] = useValidDemandeMutation();
	const navigate = useNavigate();

	const onValid = async () => {
		const res = await validPro(item?.slug);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Demande validée avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => navigate("/admin/professionnels"));
		}
		if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur inconnue a survenue !",
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};

	return { onValid, isLoading };
};

export function useToggleArchivePro(user: Professionnel) {
	const [editData] = useAddOrEditProMutation();
	const onArchive = async () => {
		let data: ProFormData = {
			is_archive: !user.is_archive,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				user?.is_archive === true ? "Débloquer" : "Bloquer"
			} ce professionnel ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: user?.is_archive ? Color.success : Color.danger,
			confirmButtonColor: user?.is_archive
				? Color.success
				: Color.danger,
			preConfirm: () => {
				return editData({
					slug: user.slug,
					data: data,
				});
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Professionnel ${
						result?.value?.data?.is_archive
							? "bloqué "
							: "débloqué"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1200,
				});
			}
		});
	};
	return onArchive;
}

export default useCrudPro;
