import React from "react";
import "./OptionAdmin.css";
import BreadCrumb from "../../shared/BreadCrumb";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import OptionAProposAdmin from "./Tabs/OptionAProposAdmin";
import OptionFaqAdmin from "./Tabs/OptionFaqAdmin";
import OptionNotreActuAdmin from "./Tabs/OptionNotreActuAdmin";
import OptionAccueilAdmin from "./Tabs/OptionAccueilAdmin";
import OptionContactAdmin from "./Tabs/OptionContactAdmin";
import OptionMiseAJourFaq from "./Tabs/OptionMiseAJourFaq";
import OptionAjoutActualite from "./Tabs/OptionAjoutActualite";
import OptionDetailsActuAdmin from "./Tabs/OptionDetailsActuAdmin";
import { useItem } from "../../../utils/hooks";
import { Faq } from "../../../utils/api/faq/faq.type";
import { Article } from "../../../utils/api/article/article.type";
import OptionTeamAdmin from "./Tabs/OptionTeamAdmin";
import OptionAddTeamMember from "./Tabs/OptionAddTeamMember";
import OptionTestimonialAdmin from "./Tabs/OptionTestimonialAdmin";
import OptionAddTestimonial from "./Tabs/OptionAddTestimonial";
import OptionPartenaireAdmin from "./Tabs/OptionPartenaireAdmin";
import OptionCatalogueAdmin from "./Tabs/OptionCatalogueAdmin";

const steps = [
	{ id: "a-propos", Component: OptionAProposAdmin },
	{ id: "equipe", Component: OptionTeamAdmin },
	{ id: "testimonial", Component: OptionTestimonialAdmin },
	{ id: "faq", Component: OptionFaqAdmin },
	{ id: "notre-actu", Component: OptionNotreActuAdmin },
	{ id: "accueil", Component: OptionAccueilAdmin },
	{ id: "partenaire", Component: OptionPartenaireAdmin },
	{ id: "contact", Component: OptionContactAdmin },
	{ id: "catalogue", Component: OptionCatalogueAdmin },
	{ id: "maj-faq", Component: OptionMiseAJourFaq },
	{ id: "ajout-actu", Component: OptionAjoutActualite },
	{ id: "details-actu", Component: OptionDetailsActuAdmin },
	{ id: "add-member", Component: OptionAddTeamMember },
	{ id: "addTestimonial", Component: OptionAddTestimonial },
];

const tabs = [
	"À propos",
	"Equipe",
	"Témoignages",
	"FAQ",
	"Notre actu",
	"Accueil",
	"Partenaires",
	"Contacts",
	"Catalogue"
];
function OptionAdmin() {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { item, getItem, resetItem } = useItem<Faq>();
	const { item: article, getItem: getArticle, resetItem: resetArticle } = useItem<Article>();
	const { Component } = step;
	const props = {
		go,
		index,
		item,
		getItem,
		article,
		getArticle,
		resetItem,
		resetArticle,
	};

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<BreadCrumb pageName="Options" />
				</div>
				<div className="content-graph-admin medsain-page-title-container mb-2">
					<ul className="tabs-container tabs-container-admin mb-0 pb-2 ps-0">
						{tabs.map((tab: string, key: number) => (
							<li
								className={`tab-item  ${
									index === key &&
									"tab-active-item-admin"
								}`}
								key={key}
								onClick={() => go(key)}
								role="button"
							>
								{tab}
							</li>
						))}
					</ul>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="hippocrate-dash-admin-page-content-container mb-1">
							<div className="hippocrate-tabs-locataire-container">
								<div className="tabs-component-container">
									<Component {...props} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OptionAdmin;
