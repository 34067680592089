import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AlertInfo } from "../../common/Alert";


function VoirCatalogueModal({
	item,
}: {
	item?: any;
}) {



	return (
		<div
			className="modal fade"
			id="VoirCatalogueModal"
			aria-labelledby="VoirCatalogueModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusProfessionelModal"
						>
							Catalogue
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100 ">
						
						{item?.catalogue ? (
							<iframe
								height={500}
								width="100%"
								src={item?.catalogue}
							/>
						) : (
							<AlertInfo message="Aucun fichier trouvé" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default VoirCatalogueModal;
