import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Category, CategoryFormData } from "./category.type";

export const CategoryApi = createApi({
	reducerPath: "categoryApi",
	tagTypes: ["category", "categoryList", "categoryByVisitor", "categoryById", "allCategoryList"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCategories: builder.query<PaginationResults<Category>, TypeQuery>({
			providesTags: ["categoryList"],
			query: (query) => QueryUrl("categories/", query),
		}),
		addOrEditCategory: builder.mutation<Category, { slug?: string; data: CategoryFormData }>({
			invalidatesTags: ["categoryList", "categoryByVisitor", "category", "allCategoryList"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `categories/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `categories/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCategory: builder.mutation<Category, string>({
			query: (id) => ({
				url: `categories/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["category", "categoryList", "categoryByVisitor"],
		}),
		categoryBySlug: builder.query<Category, string>({
			query: (slug) => `categories/${slug}/`,
			providesTags: ["categoryById"],
		}),
		getAllCategoryList: builder.query<PaginationResults<Category>, void>({
			providesTags: ["allCategoryList"],
			query: () => {
				return "visiteur/categories/";
			},
		}),
	}),
});

export const {
	useAddOrEditCategoryMutation,
	useDeleteCategoryMutation,
	useGetCategoriesQuery,
	useCategoryBySlugQuery,
	useLazyCategoryBySlugQuery,
	useGetAllCategoryListQuery
} = CategoryApi;

export function useCategoryFromLocation(): [
	Category,
	boolean,
	string,
	(id: string) => void
] {
	const itemState = useLocationState<Category | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { id } = useParams<{ id: string }>();
	const [findById, { data, isError, isLoading }] =
		useLazyCategoryBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (id) {
			findById(id as string);
		}
	}, [id]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/admin/produits", {
				replace: true,
			});
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			console.log("data", data);
			setItem(data);
		}
	}, [data]);

	return [item as Category, isLoading, id as string, findById];
}
