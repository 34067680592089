/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	Cart,
	CartFormData,
	CommentProductData,
	Product,
	ProductFormData,
	RateProductData,
	TFavoriteProduct,
	Variation,
	VariationFormData,
} from "./product.type";
import { Favorie } from "../favories/favorie.type";

export const ProductApi = createApi({
	reducerPath: "productApi",
	tagTypes: [
		"product",
		"productList",
		"productByVisitor",
		"carts",
		"cartByUser",
		"variation",
		"productPromote",
		"favorite",
		"stockByPro",
		"catalogue",
		"productSimilaire",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getListProductAdmin: builder.query<
			PaginationResults<Product>,
			TypeQuery
		>({
			providesTags: ["productList"],
			query: (query) => QueryUrl("produits/", query),
		}),
		getListProduct: builder.query<
			PaginationResults<Product>,
			TypeQuery
		>({
			providesTags: ["productList"],
			query: (query) => QueryUrl("visiteur/produits/", query),
		}),
		getProductList: builder.query<
			PaginationResults<Product>,
			TypeQuery
		>({
			providesTags: ["product"],
			query: (query) => QueryUrl("visiteur/produits/", query),
		}),
		getSearchProductList: builder.query<
			PaginationResults<Product>,
			TypeQuery
		>({
			query: (query) =>
				QueryUrl("visiteur/produits/search/", query),
		}),
		addOrEditProduct: builder.mutation<Product,{ id?: string; data: ProductFormData | FormData }>({
			query: ({ id, data }) => {
				if (id) {
					return {
						url: `produits/${id}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `produits/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: [
				"productList",
				"productByVisitor",
				"product",
			],
		}),
		deleteProduct: builder.mutation<Product, string>({
			query: (id) => ({
				url: `produits/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: [
				"product",
				"productList",
				"productByVisitor",
			],
		}),
		productBySlug: builder.query<Product | any, string>({
			query: (slug) => `visiteur/produits/${slug}/`,
			providesTags: ["product"],
		}),
		deleteProductImage: builder.mutation<Product| any, string>({
			query: (slug) => ({
				url: `images/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: [
				"product",
				"productList",
				"productByVisitor",
			],
		}),
		editVariation: builder.mutation<Variation,{ slug: string; data: VariationFormData | FormData }>({
			query: ({ slug, data }) => {
				return {
					url: `variations/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["product"],
		}),
		findProductBySlug: builder.query<Variation | any, string>({
			query: (slug) => `visiteur/produits/${slug}/`,
			providesTags: ["variation"],
		}),
		getCarts: builder.query<PaginationResults<Cart>, TypeQuery>({
			providesTags: ["carts"],
			query: (query) => QueryUrl("carts", query),
		}),
		deleteCartItem: builder.mutation<Cart, string>({
			query: (slug) => ({
				url: `carts/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["carts", "cartByUser"],
		}),
		getCartByUser: builder.query<Cart, string>({
			providesTags: ["cartByUser"],
			query: (slug) => `user/${slug}/cart/`,
		}),
		addProductCart: builder.mutation<Product, CartFormData>({
			query: (data) => {
				return {
					url: `cart/add/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["carts", "cartByUser"],
		}),
		editCartitems: builder.mutation<Product,{ slug: string; data: any | FormData } >({
			query: ({ slug, data }) => {
				return {
					url: `cartitems/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["carts", "cartByUser"],
		}),
		deleteProductCart: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `cartitems/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["carts", "cartByUser"],
		}),
		clearCart: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `cart/${slug}/clear/`,
				method: "GET",
			}),
			invalidatesTags: ["carts", "cartByUser"],
		}),
		addMultipleToCart: builder.mutation<Product, any>({
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(
					ProductApi.util.invalidateTags(["cartByUser"])
				);
			},
			query: (data) => {
				return {
					url: `cart/multiple_add/`,
					method: "POST",
					body: data,
				};
			},
		}),
		addFavoriteProduct: builder.mutation<Product, TFavoriteProduct>({
			query: (data) => ({
				url: "favoris/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["favorite"],
		}),
		getFavoriteProductByUser: builder.query<Favorie[], TypeQuery>({
			providesTags: ["favorite"],
			query: ({ slug, ...query }) =>
				QueryUrl(`user/${slug}/favoris/`, query),
		}),
		// comments
		postCommentProduct: builder.mutation<Product, CommentProductData>({
			query: (data) => {
				return {
					url: `comment/produit/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["product"],
			// transformResponse: ({ data }) => data,
		}),
		// rates
		rateProduct: builder.mutation<Product, RateProductData>({
			query: (data) => {
				return {
					url: `notes/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["product"],
		}),
		stockByPro: builder.query<PaginationResults<Product>, TypeQuery>({
			query: ({ slug, ...query }) =>
				QueryUrl(`professionnel/${slug}/stocks/`, query),
			providesTags: ["stockByPro"],
		}),
		changeStock: builder.mutation<Product, any>({
			query: (data) => {
				return {
					url: `professionnel/vente_stocks/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["stockByPro"],
		}),
		productSimilaire: builder.query<Product | any, string>({
			query: (slug) => `produit/${slug}/produits_similaires/`,
			providesTags: ["productSimilaire"],
		}),

	}),
});

export const {
	useAddOrEditProductMutation,
	useGetListProductAdminQuery,
	useGetListProductQuery,
	useGetProductListQuery,
	useDeleteProductMutation,
	useLazyProductBySlugQuery,
	useDeleteProductImageMutation,
	useGetSearchProductListQuery,
	useEditVariationMutation,
	useLazyFindProductBySlugQuery,
	useGetCartByUserQuery,
	useGetCartsQuery,
	useAddProductCartMutation,
	useDeleteProductCartMutation,
	useClearCartMutation,
	useLazyGetCartByUserQuery,
	useAddFavoriteProductMutation,
	usePostCommentProductMutation,
	useRateProductMutation,
	useGetFavoriteProductByUserQuery,
	useAddMultipleToCartMutation,
	useStockByProQuery,
	useDeleteCartItemMutation,
	useEditCartitemsMutation,
	useChangeStockMutation,
	useLazyProductSimilaireQuery,
} = ProductApi;

export function useProductFromLocation(): [
	Product,
	boolean,
	string,
	(slug: string) => any
] {
	const itemState = useLocationState<Product | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] =
		useLazyProductBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			// console.log("data", data);
			setItem(data);
		}
	}, [data]);

	return [item as Product, isLoading, slug as string, findBySlug];
}

export function useVariationFromLocation(): [
	Variation,
	boolean,
	string,
	(slug: string) => any
] {
	const itemState = useLocationState<Variation | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] =
		useLazyFindProductBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			// console.log("data", data);
			setItem(data);
		}
	}, [data]);
	return [item as Variation, isLoading, slug as string, findBySlug];
}
