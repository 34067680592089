import React from "react";
import { FormError, Input } from "../../../common/Input";
import useEditProfil from "./useEditProfil";
import FormLabel from "../../../common/Label";
import GoogleInput from "../../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";

function InfosPersonnelles() {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		phone,
		onChangePhone,
		adresse,
		handleChangeAdress,
	} = useEditProfil();

	return (
		<div className="medsain-profil-tab-item">
			<div className="medsain-profil-tab-title">
				Information générale
			</div>
			<form className="mt-4" onSubmit={onSubmit}>
				<div className="row row-add-student my-3">
					<div className="col-md-4 position-relative">
						<Input
							type="text"
							label="Prénom"
							id="prenom"
							customClassLabel={
								"input-modal-label-form-add"
							}
							customClassInput="input-modal-form-add"
							{...register("prenom")}
							error={errors?.prenom}
						/>
					</div>
					<div className="col-md-4 position-relative">
						<Input
							type="text"
							label="Nom"
							id="nom"
							customClassLabel={
								"input-modal-label-form-add"
							}
							customClassInput="input-modal-form-add"
							{...register("nom")}
							error={errors?.nom}
						/>
					</div>
					<div className="col-md-4 position-relative">
						<FormLabel
							label="N° Téléphone"
							required
							customClassLabel={
								"input-parametre-label-form-add"
							}
						/>
						<div className="input-group mb-3">
							<PhoneInput
								defaultMask={".. ... .. .."}
								containerClass="react-custom-tel-input mb-3 p-0"
								inputClass=" form-control form-control-modal-custom input-parametre-form-add"
								country={"sn"}
								inputProps={{
									name: "telephone",
									required: true,
									autoFocus: true,
								}}
								countryCodeEditable={false}
								enableAreaCodes={true}
								disableDropdown={true}
								prefix="+"
								value={phone}
								onChange={(phone) =>
									onChangePhone(phone)
								}
								inputStyle={{
									width: "100%",
									paddingBottom: "22px",
									borderRadius: "10px",
									height: 50,
								}}
							/>
							<FormError
								error={errors?.telephone}
							/>
						</div>
					</div>
					<div className="col-md-4 position-relative">
						<Input
							type="email"
							label="Email"
							id="email"
							customClassLabel={
								"input-modal-label-form-add"
							}
							customClassInput="input-modal-form-add"
							{...register("email")}
							error={errors?.email}
						/>
					</div>
					<div className="col-md-4 position-relative">
						<FormLabel
							label="Adresse"
							required
							customClassLabel={
								"input-parametre-label-form-add"
							}
						/>
						<GoogleInput
							className="form-control form-control-modal-custom input-parametre-form-add"
							onChange={handleChangeAdress}
							value={adresse}
							placeholder="Adresse"
						/>
						<FormError error={errors?.adresse} />
					</div>
					<div className="col-md-4 position-relative">
						<Input
							type="text"
							label="Ville"
							id="ville"
							customClassLabel={
								"input-modal-label-form-add"
							}
							customClassInput="input-modal-form-add"
							{...register("ville")}
							error={errors?.ville}
						/>
					</div>
					<div className="col-md-4 position-relative">
						<Input
							type="text"
							label="Société"
							id="societe"
							customClassLabel={
								"input-modal-label-form-add"
							}
							customClassInput="input-modal-form-add"
							{...register("societe")}
							error={errors?.societe}
						/>
					</div>
					<div className="col-md-4 position-relative">
						<Input
							type="text"
							label="Secteur"
							id="secteur"
							customClassLabel={
								"input-modal-label-form-add"
							}
							customClassInput="input-modal-form-add"
							{...register("secteur")}
							error={errors?.secteur}
						/>
					</div>
					<div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
						<button
							className="btn btn-theme-admin w-100"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									<span
										className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
										role="status"
									></span>
									<span>Mis à jour...</span>
								</>
							) : (
								"Mettre à jour"
							)}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default InfosPersonnelles;
