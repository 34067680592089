import React from "react";
import { AlertInfo } from "../../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../../common/TableSkeleton";
import "../TopVentesTable/Table.css";
import { Order } from "../../../../../utils/api/order/order.type";
import {
	IconCancel,
	IconDelivered,
	IconInProgress,
	IconInRoad,
} from "../../../../shared/Icons";
import { formatCurrency, getAvatar } from "../../../../../utils/Utils";
import moment from "moment";
import { useTranslation } from "react-i18next";


function CommandesRecentsTable({
	recentOrders,
	isLoading,
}: {
	recentOrders?: Order[];
	isLoading: boolean;
}) {

	const { t } = useTranslation();

	const statusFormatter = (cell: string) => {
		if (cell === "en_cours") {
			return (
				<div className="justify-content-center">
					<span className="status-en-cours">
						<span className="pe-2">
							<IconInProgress />
						</span>
						<span>{t("progress")}</span>
					</span>
				</div>
			);
		} else if (cell === "confirmee") {
			return (
				<div className="justify-content-center">
					<span className="status-en-chemin">
						<span className="pe-2">
							<IconInRoad />
						</span>
						<span>{t("confirm")}</span>
					</span>
				</div>
			);
		} else if (cell === "livree") {
			return (
				<div className="justify-content-center">
					<span className="status-livre">
						<span className="pe-2">
							<IconDelivered />
						</span>
						<span>{t("delivered")}</span>
					</span>
				</div>
			);
		} else {
			return (
				<div className="justify-content-center">
					<span className="status-annule">
						<span className="pe-2">
							<IconCancel />
						</span>
						<span>{t('cancelled')}</span>
					</span>
				</div>
			);
		}
	};
	const nameFormatter = (cell: string, row: Order) => {
		return (
			<div className="d-flex gap-3 align-items-center">
				<div className="content-img-profil-praticient-table">
					<img
						src={getAvatar(row?.user?.avatar)}
						alt="Profil"
						className="img-profil-praticient-table"
					/>
				</div>
				<div>
					<p className="name-profil-client-table mb-1">
						{row.user?.prenom}
					</p>
					<p className="date-commandetable-formatter mb-0">
						{moment(row?.created_at).fromNow()}
					</p>
				</div>
			</div>
		);
	};
	const priceFormatter = (cell: string) => {
		return (
			<p className="item-price-content-table mb-0">
				{formatCurrency(cell)}
			</p>
		);
	};
	const columns = [
		{
			dataField: "user",
			text: t("customer"),
			formatter: nameFormatter,
		},
		{
			dataField: "total",
			text: t("amount"),
			formatter: priceFormatter,
		},
		{
			dataField: "statut",
			text: t("status"),
			formatter: statusFormatter,
			headerStyle: () => {
				return { display: "flex", justifyContent: "flex-end" };
			},
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={recentOrders}
						columns={columns}
						condensed
						hover
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvée" />
						)}
					/>
				</>
			)}
		</>
	);
}

export default CommandesRecentsTable;
