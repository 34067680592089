import React from "react";
import { ListGroup } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import RangeSlider from "react-range-slider-input";
import "./ProduitDisponibleParticulier.css";
import { Input } from "../../common/Input";
import ReactStars from "react-rating-stars-component";
import imgProduit from "../../../assets/appImages/listproduitImg.svg";
import { IoCartOutline } from "react-icons/io5";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useGetListProductQuery } from "../../../utils/api/product/product.api";
import Empty from "../../../assets/appImages/category.png";
import { createMarkup, formatCurrency, getImage } from "../../../utils/Utils";
import { useItem } from "../../../utils/hooks";
import { useToggleFavoriteProduct } from "../../front/boutique/hooks/useProduct";
import { useTranslation } from "react-i18next";


function ProduitDisponibleParticulier() {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';

	const { data, refetch } = useGetListProductQuery({});

	return (
		<div className="page-content mt-0 pt-5">
			<div className="container-casadeliz mb-5">
				<section className="section-boutique custom-section-divider">
					<div className="row">
						<div className="col-lg-4">
							<div className="liste-produit-filtre-container">
								<Accordion
									defaultActiveKey={["0"]}
									alwaysOpen
								>
									<Accordion.Item eventKey="0">
										<Accordion.Header className="liste-produit-accordion-header">
											{t("category")}
										</Accordion.Header>
										<Accordion.Body className="liste-produit-accordion-body">
											<ListGroup>
												<ListGroup.Item className="liste-produit-accordion-list-group cursor">
												{t("category")}
													1
												</ListGroup.Item>
												<ListGroup.Item className="liste-produit-accordion-list-group cursor">
												{t("category")}
													2
												</ListGroup.Item>
												<ListGroup.Item className="liste-produit-accordion-list-group cursor">
												{t("category")}
													3
												</ListGroup.Item>
												<ListGroup.Item className="liste-produit-accordion-list-group cursor">
												{t("category")}
													4
												</ListGroup.Item>
												<ListGroup.Item className="liste-produit-accordion-list-group-last-child cursor">
													{t("see_all")}
												</ListGroup.Item>
											</ListGroup>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header className="liste-produit-accordion-header">
											{t("price_scale")}
										</Accordion.Header>
										<Accordion.Body className="liste-produit-accordion-body">
											<div className="my-3">
												<RangeSlider id="range-slider-yellow" />
											</div>
											<div className="row mb-5">
												<div className="col-lg-6 mb-3">
													<Input
														type="number"
														label="Min"
														id="min"
														placeholder="0"
														customClassLabel={
															"input-parametre-label-form-add"
														}
														customClassInput="input-parametre-form-add"
													/>
												</div>
												<div className="col-lg-6 mb-3">
													<Input
														type="number"
														label="Max"
														id="max"
														placeholder="10000"
														customClassLabel={
															"input-parametre-label-form-add"
														}
														customClassInput="input-parametre-form-add"
													/>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header className="liste-produit-accordion-header">
											Condition
										</Accordion.Header>
										<Accordion.Body className="liste-produit-accordion-body">
											<div className="my-3">
												<div className="liste-produit-form-check-container">
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="radio"
															name="flexRadioDefault"
															id="flexRadioDefault1"
															checked
														/>
														<label
															className="form-check-label add-produit-custom-form-check-label"
															htmlFor="flexRadioDefault1"
														>
															{t("any_of_them")}
														</label>
													</div>
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="radio"
															name="flexRadioDefault"
															id="flexRadioDefault2"
														/>
														<label
															className="form-check-label add-produit-custom-form-check-label"
															htmlFor="flexRadioDefault2"
														>
															{t("fees")}
														</label>
													</div>
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="radio"
															name="flexRadioDefault"
															id="flexRadioDefault3"
														/>
														<label
															className="form-check-label add-produit-custom-form-check-label"
															htmlFor="flexRadioDefault3"
														>
															{t("hot")}
														</label>
													</div>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header className="liste-produit-accordion-header">
											Note
										</Accordion.Header>
										<Accordion.Body className="liste-produit-accordion-body">
											<div className="my-3">
												<div className="liste-produit-form-check-container">
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="checkbox"
															id="flexRadioDefault1"
														/>
														<ReactStars
															count={
																5
															}
															size={
																18
															}
															emptyIcon={
																<i className="fa-duotone fa-star"></i>
															}
															halfIcon={
																<i className="fa fa-star-half-alt"></i>
															}
															fullIcon={
																<i className="fa fa-star"></i>
															}
															activeColor="#FFC600"
															edit={
																false
															}
															value={
																5
															}
														/>
													</div>
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="checkbox"
															id="flexRadioDefault2"
														/>
														<ReactStars
															count={
																5
															}
															size={
																18
															}
															emptyIcon={
																<i className="fa-duotone fa-star"></i>
															}
															halfIcon={
																<i className="fa fa-star-half-alt"></i>
															}
															fullIcon={
																<i className="fa fa-star"></i>
															}
															activeColor="#FFC600"
															edit={
																false
															}
															value={
																4
															}
														/>
													</div>
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="checkbox"
															id="flexRadioDefault3"
														/>
														<ReactStars
															count={
																5
															}
															size={
																18
															}
															emptyIcon={
																<i className="fa-duotone fa-star"></i>
															}
															halfIcon={
																<i className="fa fa-star-half-alt"></i>
															}
															fullIcon={
																<i className="fa fa-star"></i>
															}
															activeColor="#FFC600"
															edit={
																false
															}
															value={
																3
															}
														/>
													</div>
													<div className="form-check mb-3">
														<input
															className="form-check-input add-produit-custom-form-check-input"
															type="checkbox"
															id="flexRadioDefault4"
														/>
														<ReactStars
															count={
																5
															}
															size={
																18
															}
															emptyIcon={
																<i className="fa-duotone fa-star"></i>
															}
															halfIcon={
																<i className="fa fa-star-half-alt"></i>
															}
															fullIcon={
																<i className="fa fa-star"></i>
															}
															activeColor="#FFC600"
															edit={
																false
															}
															value={
																2
															}
														/>
													</div>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="liste-produit-details-container">
								<div className="liste-produit-details-nbr-element mb-3">
									{data?.results?.length}{" "}
									{t("items_found")}
								</div>
								<div className="row">
									{data?.results.map(
										(produit, i) => (
											<CardVariationParticulier
												item={
													produit
												}
												key={i}
											/>
										)
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default ProduitDisponibleParticulier;

export const CardVariationParticulier = ({
	item,
	showIconsAdd = true,
	showIconsFavoris = true,
}) => {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';

	return (
		<div className="col-lg-12 mb-3">
			<div className="liste-produit-details-card-produit">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 mb-3">
							<div className="w-100">
								<img
									src={getImage(
										item?.variation
											?.image
									)}
									alt="image produit"
									className="liste-produit-details-card-produit-image"
								/>
							</div>
						</div>
						<div className="col-lg-9 mb-3">
							<div className="liste-produit-details-card-produit-title mb-2">
								{item?.variation?.taille}
							</div>
							<div className="liste-produit-details-card-produit-price-container mb-2">
								<div className="liste-produit-details-card-produit-price">
									{formatCurrency(
										item?.prix
									)}
								</div>
								<div className="liste-produit-details-card-produit-icon-container">
									{showIconsAdd && (
										<button className="btn liste-produit-details-card-produit-icon">
											<IoCartOutline />
										</button>
									)}
									{showIconsFavoris && (
										<button className="btn liste-produit-details-card-produit-icon">
											<AiOutlineHeart />
										</button>
									)}
								</div>
							</div>
							<div className="liste-produit-details-card-produit-infos-sup-container mb-3">
								<div className="liste-produit-details-card-produit-infos-sup-note-value-container">
									<ReactStars
										count={5}
										size={14}
										emptyIcon={
											<i className="fa-duotone fa-star"></i>
										}
										halfIcon={
											<i className="fa fa-star-half-alt"></i>
										}
										fullIcon={
											<i className="fa fa-star"></i>
										}
										activeColor="#FFC600"
										edit={false}
										value={
											item
												?.variation
												?.rate
										}
									/>
									<div className="liste-produit-details-card-produit-infos-sup-note-value">
										{
											item
												?.variation
												?.rate
										}
									</div>
								</div>
								<div className="liste-produit-details-card-produit-infos-sup-note-value-container">
									<div className="liste-produit-details-card-produit-infos-sup-icon-dot"></div>
									<div className="liste-produit-details-card-produit-infos-sup-commentaire">
										{
											item
												?.variation
												?.comment_count
										}{" "}
										{t("comments")}
									</div>
								</div>
								<div className="liste-produit-details-card-produit-infos-sup-note-value-container">
									<div className="liste-produit-details-card-produit-infos-sup-icon-dot"></div>
									<div className="liste-produit-details-card-produit-infos-sup-avertissement">
										{t("this_product_not_refundable")}
									</div>
								</div>
							</div>
							<div
								className="liste-produit-details-card-produit-text mb-2"
								dangerouslySetInnerHTML={createMarkup(
									(locale == 'fr' ? item?.variation?.item?.description : item?.variation?.item?.description_en)?.slice(
										0,
										200
									)
								)}
							/>
							<NavLink
								to={`/particulier/produit/${item?.variation?.item?.slug}`}
								className="liste-produit-details-card-produit-btn-voir-plus"
								state={item?.variation?.item}
							>
								{t("see_details")}
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const CardProduitParticulier = ({ item, showIconsFavoris = true }) => {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const { toggleFavorite } = useToggleFavoriteProduct(item, true);
	return (
		<div className="col-lg-12 mb-3">
			<div className="liste-produit-details-card-produit">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 mb-3">
							<div className="w-100">
								<img
									src={getImage(
										item?.images[0]
											?.picture
									)}
									alt="image produit"
									className="liste-produit-details-card-produit-image"
								/>
							</div>
						</div>
						<div className="col-lg-9 mb-3">
							<div className="liste-produit-details-card-produit-title mb-2">
								{locale == 'fr' ? item?.nom : item?.nom_en}
							</div>
							<div className="liste-produit-details-card-produit-price-container mb-2">
								<div className="liste-produit-details-card-produit-price">
									{/* {item?.n} */}
								</div>
								<div className="liste-produit-details-card-produit-icon-container">
									{showIconsFavoris && (
										<button
											className="btn liste-produit-details-card-produit-icon"
											onClick={
												toggleFavorite
											}
										>
											<AiFillHeart />
										</button>
									)}
								</div>
							</div>
							<div className="liste-produit-details-card-produit-infos-sup-container mb-3">
								<div className="liste-produit-details-card-produit-infos-sup-note-value-container">
									<ReactStars
										count={5}
										size={14}
										emptyIcon={
											<i className="fa-duotone fa-star"></i>
										}
										halfIcon={
											<i className="fa fa-star-half-alt"></i>
										}
										fullIcon={
											<i className="fa fa-star"></i>
										}
										activeColor="#FFC600"
										edit={false}
										value={
											item
												?.variation
												?.rate
										}
									/>
									<div className="liste-produit-details-card-produit-infos-sup-note-value">
										{
											item
												?.variation
												?.rate
										}
									</div>
								</div>
								<div className="liste-produit-details-card-produit-infos-sup-note-value-container">
									<div className="liste-produit-details-card-produit-infos-sup-icon-dot"></div>
									<div className="liste-produit-details-card-produit-infos-sup-commentaire">
										{
											item
												?.variation
												?.comment_count
										}{" "}
										{t("comments")}
									</div>
								</div>
							</div>
							<div
								className="liste-produit-details-card-produit-text mb-2"
								dangerouslySetInnerHTML={createMarkup(
									(locale == 'fr' ? item?.description : item?.description_en)?.slice(
										0,
										200
									)
								)}
							/>
							<NavLink
								to={`/particulier/produit/${item?.slug}`}
								className="liste-produit-details-card-produit-btn-voir-plus"
								state={item}
							>
								{t("see_details")}
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
