import React from "react";
import { AlertInfo } from "../../../common/Alert";
import { createMarkup } from "../../../../utils/Utils";
import { BiEditAlt } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import {
	Condition,
	conditionType,
} from "../../../../utils/api/config/config.type";
import { useDeleteConditionMutation } from "../../../../utils/api/config/config.api";
import { useDelete } from "../../../../utils/hooks";
import { ButtonDelete } from "../../../common/Button";

export function DeleteCondition({
	item,
	type,
}: {
	item?: Condition;
	type: conditionType;
}) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteConditionMutation();
	const onDelete = useDelete<Condition>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `les ${
			type === conditionType.cgu
				? "condition génerales ..."
				: "politiques de confidentialité"
		}?`,
		successMessage: `Supprimée`,
	});
	return <ButtonDelete onClick={onDelete} />;
}
function ConditionsUtilisation({ condition }: { condition?: Condition }) {
	return (
		<div className="content-graph-admin">
			{condition ? (
				<div
					key={condition?.slug}
					className="mb-5 cond-utilisation-ol-custom-li"
				>
					<div className="cond-utilisation-ol-item-container">
						<p
							className="text-cgu-admin pb-2"
							dangerouslySetInnerHTML={createMarkup(
								condition?.text_fr
							)}
						/>
					</div>
					<div className="eglise-cond-utilisation-ol-btn-container">
						<NavLink
							to="/admin/ajouter-conditions-utilisations"
							className="btn eglise-cond-utilisation-ol-btn-edit"
							state={condition}
						>
							<BiEditAlt />
						</NavLink>
						<DeleteCondition
							item={condition}
							type={conditionType.cgu}
						/>
					</div>
				</div>
			) : (
				<AlertInfo message="Pas de données" />
			)}
		</div>
	);
}

export default ConditionsUtilisation;
