import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { ChangePasswordData } from "../auth/auth.type";
import {
	AuthState,
	User,
	PaginationResults,
	TypeQuery,
	UserFormData,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
	const token =
		(getState() as { user: AuthState }).user.token ??
		AppStorage.getItem<AuthState>("user")?.token;
	if (token) {
		headers.set("Authorization", `Bearer ${token}`);
	}
	return headers;
};

export const UserApi = createApi({
	reducerPath: "userApi",
	tagTypes: ["user", "userList", "userById", "adminsList", "client"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addOrEditUser: builder.mutation<
			User,
			{ slug?: string; data: UserFormData | FormData }
		>({
			invalidatesTags: ["user", "userList"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `users/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `users/`,
					method: "POST",
					body: data,
				};
			},
		}),
		addOrEditAdmin: builder.mutation<
			User,
			{ slug?: string; data: UserFormData | FormData }
		>({
			invalidatesTags: ["user", "adminsList", "userList"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `users/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `users/`,
					method: "POST",
					body: data,
				};
			},
		}),
		changePassword: builder.mutation<any, ChangePasswordData>({
			query: (data) => ({
				url: `auth/change-password/`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		getListUser: builder.query<PaginationResults<User>, TypeQuery>({
			providesTags: ["userList"],
			query: (query) => QueryUrl("users/", query),
		}),
		getListAdminUser: builder.query<PaginationResults<User>, TypeQuery>(
			{
				providesTags: ["adminsList"],
				query: (query) => QueryUrl("user/admin", query),
			}
		),
		userById: builder.query<User, string>({
			query: (id) => `clients/${id}`,
			providesTags: ["userById"],
		}),
		relaunchCustomer: builder.query<
			User,
			{ user?: string; order?: string }
		>({
			query: ({ user, order }) =>
				`user/${user}/rappel_order/${order}/`,
		}),
		deleteUser: builder.mutation<User, string>({
			query: (slug) => ({
				url: `users/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["userList"],
		}),

		// client
		getListClient: builder.query<PaginationResults<User>, TypeQuery>({
			providesTags: ["client"],
			query: (query) => QueryUrl("clients/", query),
		}),
		deleteClient: builder.mutation<User, string>({
			query: (slug) => ({
				url: `clients/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["client"],
		}),
		addOrEditClient: builder.mutation<User,{ slug?: string; data: UserFormData | FormData }>({	
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `clients/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `clients/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["user", "userList", "userById", "adminsList", "client"],
		}),
	}),
});

export const {
	useAddOrEditUserMutation,
	useChangePasswordMutation,
	useGetListUserQuery,
	useAddOrEditAdminMutation,
	useGetListAdminUserQuery,
	useLazyUserByIdQuery,
	useGetListClientQuery,
	useDeleteClientMutation,
	useDeleteUserMutation,
	useAddOrEditClientMutation,
	useRelaunchCustomerQuery,
	useLazyRelaunchCustomerQuery,
} = UserApi;

export function useUserFromLocation(): [
	User,
	boolean,
	string,
	(id: string) => any
] {
	const itemState = useLocationState<User | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findById, { data, isError, isLoading }] = useLazyUserByIdQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findById(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			setItem(data);
		}
	}, [data]);

	return [item as User, isLoading, slug as string, findById];
}